export enum ActionTypes {
  nextStep = 'FORM_NEXT_STEP',
  nextFormStep = 'FORM_NEXT_FORM_STEP',
  nextPurposeStep = 'FORM_NEXT_PURPOSE_STEP',
  nextIncomeStep = 'FORM_NEXT_INCOME_STEP',
  nextOutcomeStep = 'FORM_NEXT_OUTCOME_STEP',
  addStep = 'FORM_ADD_STEP',
  prevStep = 'FORM_PREV_STEP',
  navigateBackTo = 'FORM_NAVIGATE_BACK_TO',
  resetState = 'FORM_RESET_FORM_PROGRESS_STATE',
}
