import {MutationTree} from 'vuex';
import {ProgressState} from '../state';
import {MutationType} from './mutation-types';
import {ProgressStep} from '@/store/modules/ProgressModule/state/progress-step.interface';

export type Mutations = {
  [MutationType.addPassedStep](state: ProgressState, value: ProgressStep): void;
  [MutationType.removeLastPassedStep](state: ProgressState): void;
  [MutationType.setCurrentPage](state: ProgressState, value: ProgressStep): void;
  [MutationType.setCurrentParams](state: ProgressState, newParams: any): void;
};

export const mutations: MutationTree<ProgressState> & Mutations = {
  [MutationType.addPassedStep](state, nextStep) {
    state.stepsPassed.push(nextStep);
  },
  [MutationType.setCurrentPage](state, nextStep) {
    window.scrollTo(0, 0);
    state.currentStep = nextStep;
  },
  [MutationType.removeLastPassedStep](state) {
    state.stepsPassed.pop();
  },
  [MutationType.setCurrentParams](state, newParams) {
    state.currentStep.params = newParams;
    state.stepsPassed[state.stepsPassed.length - 1].params = newParams;
  },
};
