import {Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {FormHint} from '../../page-form/interfaces/form-hint.interface';
import {namespace} from 'vuex-class';
import {Ref, ref, watch} from 'vue';
import {FormInputProperty} from '@/store/modules/FormModule/state';
import {ValidationErrorData} from '@/store/modules/FormModule/validators/validator-type';
import {ActionTypes} from '@/store/modules/FormModule/actions/action-types';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {AvailableCustomInputs, CustomFormGroup} from '@/app/models/custom-form-group.interface';
import filters from '@/app/modules/shared/filters';
import {runValidation} from '../services/utilities.service';
import {detectMobile, scrollToElement} from '@/app/modules/shared/helpers/mobile.helper';
const formModule = namespace('form');

export default class FormElementSimple extends Vue {
  @Prop({default: 0}) refresher!: number;
  @Prop({default: null}) customForm!: CustomFormGroup;
  @Prop() fieldHint?: FormHint;
  @Prop({default: false}) isIncomeForm!: boolean;
  @Prop() controlName!: string;
  @Prop() validationPrefix!: string;
  @Prop({default: false}) fitToContainer!: boolean;
  @formModule.Getter(GetterTypes.inputByName.toString()) inputByName: any;
  @formModule.Getter(GetterTypes.validationsByName.toString()) validationsByName: any;
  @formModule.Action(ActionTypes.setValidation) setValidation: any;
  controlRef!: Ref<FormInputProperty | AvailableCustomInputs>;
  public control!: FormInputProperty | AvailableCustomInputs;

  protected additionalValidation?: () => string | null;
  public validationMessage: string | null = null;

  created() {
    watch(
      () => this.refresher,
      () => {
        if (this.refresher) {
          this.configComponent();
        }
      }
    );
    this.configComponent();
  }

  mounted() {
    const inputEl = this.$refs['input' + this.controlName] as HTMLElement;
    if (inputEl && detectMobile()) {
      inputEl.addEventListener('focus', this.scrollToInput);
    }
  }
  unmounted() {
    const inputEl = this.$refs['input' + this.controlName] as HTMLElement;
    if (inputEl && detectMobile()) {
      inputEl.removeEventListener('focus', this.scrollToInput);
    }
  }

  scrollToInput() {
    scrollToElement(this.$refs['input' + this.controlName] as HTMLElement);
  }

  configComponent() {
    if (this.customForm) {
      this.control = this.customForm[this.controlName] as AvailableCustomInputs;
    } else {
      this.control = this.inputByName(this.controlName, this.isIncomeForm);

      if (this.control?.dependsOn?.length) {
        this.control.dependsOn.forEach((dependOnName) => {
          watch(this.inputByName(dependOnName, false), () => {
            this.updateValidationMessage();
          });
        });
      }
    }
    this.controlRef = ref(this.control);

    watch(this.controlRef.value, () => {
      this.updateValidationMessage();
    });

    this.updateValidationMessage();
    if (!this.control?.dirty) {
      this.validationMessage = null;
    }
  }

  onFocus() {
    this.control.dirty = true;
    this.setFocusHint(this.fieldHint, true);
  }

  setFocusHint(hint?: FormHint, onlyIfDefined = false) {
    // if (!onlyIfDefined || hint) {
    //   this.$store.dispatch(`hint/${HINT_ACTIONS.setFocusHint}`, hint);
    // }
  }
  setHoverHint(hint?: FormHint, onlyIfDefined = false) {
    // if (!onlyIfDefined || hint) {
    //   this.$store.dispatch(`hint/${HINT_ACTIONS.setHoverHint}`, hint);
    // }
  }
  setMainHint(hint?: FormHint) {
    // this.$store.dispatch(`hint/${HINT_ACTIONS.setMainHint}`, hint);
  }

  updateValidationMessage(): void {
    const additionalError = this.additionalValidation && this.additionalValidation();

    if (this.customForm) {
      if (this.control && this.control.validations) {
        const error = runValidation(this.control);

        if (error || additionalError) {
          this.control.valid = false;
          this.validationMessage = error ? this.getErrorTranslation(error) : (additionalError as string);
        } else {
          this.control.valid = true;
          this.validationMessage = null;
        }
      }
    } else {
      const error: ValidationErrorData | null = this.validationsByName(this.controlName, this.isIncomeForm);
      if (error || additionalError) {
        this.control.valid = false;
        this.validationMessage = error ? this.getErrorTranslation(error) : (additionalError as string);
        this.setValidation({controlName: this.controlName, isInvalid: true});
      } else {
        this.control.valid = true;
        this.validationMessage = null;
        this.setValidation({controlName: this.controlName, isInvalid: false});
      }
    }
  }

  public getErrorTranslation(validationErrorData: ValidationErrorData): string {
    const translationKey: string =
      (this.validationPrefix ? this.validationPrefix : 'dynamic.form.validation.') + validationErrorData.key;
    if (this.$t(translationKey) === translationKey) {
      if (validationErrorData.data) {
        const chunkedValue = filters.amountFilter(validationErrorData.data.value, false);
        return this.$t(`dynamic.form.validation.${validationErrorData.key}`, {value: chunkedValue});
      } else {
        return this.$t(`dynamic.form.validation.${validationErrorData.key}`);
      }
    } else {
      if (validationErrorData.data) {
        const chunkedValue = filters.amountFilter(validationErrorData.data.value, false);
        return this.$t(translationKey, {value: chunkedValue});
      } else {
        return this.$t(translationKey);
      }
    }
  }
}
