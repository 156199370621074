
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {namespace} from 'vuex-class';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusExpandPage from '@/app/modules/shared/components/expand-page/expand-page.component.vue';
import NotusPageComparitionBoxes from '@/app/modules/page-comparition-boxes/page-comparition-boxes.component.vue';
import {OffersActionTypes} from '@/store/modules/OffersModule/actions/action-types';
import {OffersGetter} from '@/store/modules/OffersModule/getters';
import {OfferResponse} from '../shared/interface/offer.response.interface';
import NotusWhyUsSection from '@/app/modules/page-summary/components/why-us/why-us.component.vue';
import NotusSummaryFooterSection from '@/app/modules/page-summary/components/summary-footer/summary-footer.component.vue';
import NotusCtaInterested from '@/app/modules/shared/sections/cta-interested/cta-interested.component.vue';
import NotusSingleOfferDetails from '@/app/modules/page-offer-details/components/single-offer-details/single-offer-details.vue';
import NotusRepresentativeExample from '@/app/modules/page-offer-details/components/representative-example/representative-example.vue';
import NotusContactUsSection from '@/app/modules/page-contact/components/contact-us.component.vue';
import NotusIntro from '@/app/modules/shared/sections/intro/intro.component.vue';

const offersModule = namespace('offers');

@Options({
  name: 'NotusPageOfferDetails',
  components: {
    NotusSingleOfferDetails,
    NotusCtaInterested,
    NotusSummaryFooterSection,
    NotusWhyUsSection,
    NotusSvgIcon,
    NotusButton,
    NotusExpandPage,
    NotusPageComparitionBoxes,
    NotusContactUsSection,
    NotusIntro,
    NotusRepresentativeExample,
  },
  computed: {
    representativeExample() {
      return this.detailedOffer ? this.detailedOffer.simulation.representativeExample : null;
    }
  }
})
export default class NotusPageOfferDetails extends Vue {
  @offersModule.Action(OffersActionTypes.setDetailedOffer) setDetailedOffer!: any;
  @offersModule.Getter(OffersGetter.detailedOffer) detailedOffer!: OfferResponse;
}
