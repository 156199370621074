export enum ContactReason {
  LEADING_DETAILS = 'LEADING_DETAILS',
  CHAT = 'CHAT',
  INCORRECT_AGE = 'INCORRECT_AGE',
  NO_INCOME = 'NO_INCOME',
  NO_OFFERS = 'NO_OFFERS',
  NON_PLN_CURRENCY = 'NON_PLN_CURRENCY',
  CTA_INTERESTED = 'CTA_INTERESTED',
  SUMMARY_NO_OFFERS = 'SUMMARY_NO_OFFERS',
  ERROR = 'ERROR',
}
