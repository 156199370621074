import {OfferResponse, SubproductDetails} from '@/app/modules/shared/interface/offer.response.interface';
import filters from '@/app/modules/shared/filters';

export type InsuranceObjType = {
  value?: number;
  text?: string;
  currency?: string;
  period?: number;
};
type ComparisonResultsType = {
  id: string;
  RRSO: string;
  detailed_information: {
    logo: string;
    text: string;
  };
  loan_period: number;
  interest_rate: string;
  monthly_rate: {
    value: string | number;
    currency: string;
  };
  interest_rate_type: string;
  instalment_type: string;
  margin: string;
  commission: {
    percent: string;
    value?: number;
    currency?: string;
  };
  valuation?: {
    value?: number;
    text?: string;
    currency?: string;
  };
  initial_costs: {
    value: string | number;
    currency: string;
  };
  total_amount_to_paid: {
    value: string | number;
    currency: string;
  };
  LTV: string;
  insurance: string;
  mortage_insurance: InsuranceObjType;
  insurance_missing_contribution: InsuranceObjType;
  real_estate_insurance: InsuranceObjType;
  life_insurance: InsuranceObjType;
  employment_insurance: InsuranceObjType;
  additional_products: string;
  ROR?: SubproductDetails;
  ROR_income_protection?: string;
  credit_card?: SubproductDetails;
  debit_card?: SubproductDetails;
};

const mapResultsToTableValues = (result: OfferResponse): ComparisonResultsType => {
  return {
    id: result.id,
    RRSO: filters.percentCount(result.simulation.apr),
    detailed_information: {
      logo: result.product.bankName,
      text: result.product.name,
    },
    loan_period: result.simulation?.installmentsCount / 12,
    interest_rate: filters.percentCount(result.simulation.interestRate.rates[0].rate),
    monthly_rate: {
      value: result.simulationSummary.finalInstallment.CAPITAL_AND_INTEREST.value,
      currency: result.simulationSummary.finalInstallment.CAPITAL_AND_INTEREST.currency,
    },
    interest_rate_type: result.simulation.interestRate.type,
    instalment_type: result.simulation.installmentType,
    margin:
      result.simulation.interestRate.type === 'CONSTANT' ? '-' : filters.percentCount(result.simulation.margin.rates[0].rate),
    commission: {
      percent: filters.percentCount(result.simulation.productComponents.COMMISSION?.rate),
      value: result.simulation.productComponents.COMMISSION?.amount?.value,
      currency: result.simulation.productComponents.COMMISSION?.amount?.currency,
    },
    valuation: result.simulation.productComponents?.PROPERTY_VALUATION?.valuations
      ? {
          value: result.simulation.productComponents?.PROPERTY_VALUATION?.valuations[0]?.amount?.value,
          text: result.simulation.productComponents?.PROPERTY_VALUATION?.valuations[0]?.valuationType,
          currency: result.simulation.productComponents?.PROPERTY_VALUATION?.valuations[0]?.amount?.currency,
        }
      : undefined,
    initial_costs: {
      value: result.simulationSummary.initialCosts.TOTAL.value,
      currency: result.simulationSummary.initialCosts.TOTAL.currency,
    },
    total_amount_to_paid: {
      value: result.simulationSummary.totalCosts.SCHEDULE_TOTAL?.value,
      currency: result.simulationSummary.totalCosts.SCHEDULE_TOTAL?.currency,
    },
    LTV: filters.percentCount(result.simulation?.ltv),
    insurance: '',
    mortage_insurance: {
      value: result.simulation.productComponents.MORTGAGE_REGISTRATION?.premium?.value,
      text: result.simulation.productComponents.MORTGAGE_REGISTRATION?.frequency,
      currency: result.simulation.productComponents.MORTGAGE_REGISTRATION?.premium?.currency,
      period: result.simulation.productComponents.MORTGAGE_REGISTRATION?.period,
    },
    insurance_missing_contribution: {
      value: result.simulation.productComponents.LOW_OWN_CONTRIBUTION_RISK?.premium?.value,
      text: result.simulation.productComponents.LOW_OWN_CONTRIBUTION_RISK?.frequency,
      currency: result.simulation.productComponents.LOW_OWN_CONTRIBUTION_RISK?.premium?.currency,
      period: result.simulation.productComponents.LOW_OWN_CONTRIBUTION_RISK?.period,
    },
    real_estate_insurance: {
      value: result.simulation.productComponents.PROPERTY_INSURANCE?.premium?.value,
      text: result.simulation.productComponents.PROPERTY_INSURANCE?.frequency,
      currency: result.simulation.productComponents.PROPERTY_INSURANCE?.premium?.currency,
      period: result.simulation.productComponents.PROPERTY_INSURANCE?.period,
    },
    life_insurance: {
      value: result.simulation.productComponents.LIFE_INSURANCE?.premium?.value,
      text: result.simulation.productComponents.LIFE_INSURANCE?.frequency,
      currency: result.simulation.productComponents.LIFE_INSURANCE?.premium?.currency,
      period: result.simulation.productComponents.LIFE_INSURANCE?.period,
    },
    employment_insurance: {
      value: result.simulation.productComponents.JOB_LOSS_INSURANCE?.premium?.value,
      text: result.simulation.productComponents.JOB_LOSS_INSURANCE?.frequency,
      currency: result.simulation.productComponents.JOB_LOSS_INSURANCE?.premium?.currency,
      period: result.simulation.productComponents.JOB_LOSS_INSURANCE?.period,
    },
    additional_products: '',
    ROR: result.simulation.subproducts?.ROR,
    ROR_income_protection: result.simulation.subproducts.ROR?.detailedDescription,
    credit_card: result.simulation.subproducts?.CREDIT_CARD,
    debit_card: result.simulation.subproducts?.DEBIT_CARD,
  };
};

export {ComparisonResultsType, mapResultsToTableValues};
