import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-704d2836")
const _hoisted_1 = { class: "page-contact_form" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_form_input = _resolveComponent("notus-form-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_notus_form_input, {
        customForm: _ctx.formLead,
        controlName: "lead_name",
        inputDataType: "string",
        placeholder: _ctx.$t('page.contact.form.name.label'),
        fitToContainer: true,
        limitSigns: 25
      }, null, 8, ["customForm", "placeholder"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_notus_form_input, {
        customForm: _ctx.formLead,
        controlName: "lead_phone",
        inputDataType: "phone",
        fitToContainer: false
      }, null, 8, ["customForm"])
    ])
  ]))
}