import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-46371e1d")
const _hoisted_1 = { class: "compare-cta" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-md-7 col-lg-6 order-2 order-md-1" }
const _hoisted_4 = { class: "title title--small mb-4" }
const _hoisted_5 = { class: "color-secondary fs-18 lh-27" }
const _hoisted_6 = { class: "text-center compare-buttons" }
const _hoisted_7 = { class: "d-block m-4" }
const _hoisted_8 = { class: "d-block" }
const _hoisted_9 = { class: "col-12 col-md-5 col-lg-6 d-flex align-middle justify-content-center justify-content-lg-end order-1 order-md-1" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_link = _resolveComponent("notus-link")!
  const _component_notus_button = _resolveComponent("notus-button")!
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('page.comparition.boxes.section.showMore.title')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('page.comparition.boxes.section.showMore.description')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_notus_link, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onExampleClicked()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('page.comparition.boxes.section.showMore.exampleButton')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_notus_button, {
              class: "px-4 compare-button",
              size: 'large',
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDetailedClicked()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('page.comparition.boxes.section.showMore.detailedButton')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_notus_svg_icon, {
          iconType: 'compare-cta',
          cover: false
        })
      ])
    ])
  ]))
}