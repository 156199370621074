
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import 'vue3-carousel/dist/carousel.css';
import {benefitsList} from '@/app/modules/page-contact/components/benefits/benefits.config';
import {getLeadContent} from '@/app/modules/shared/form-lead-content.service';

@Options({
  name: 'NotusBenefits',
  components: {
    NotusSvgIcon,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
})
export default class NotusBenefits extends Vue {
  public benefits = benefitsList;

  settings: any = {
    itemsToShow: 1.15,
    snapAlign: 'center',
  };

  breakpoints = {
    0: {
      itemsToShow: 1.15,
      snapAlign: 'center',
    },
    667: {
      itemsToShow: 2.15,
      snapAlign: 'start',
    },
  };

  getLeadContent(variableName: string, defaultI18nKey?: string, defaultValue?: string): string {
    return getLeadContent(variableName, defaultI18nKey, defaultValue)
  }
}
