import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7a830ee8")
const _hoisted_1 = { class: "page-comparition-boxes" }
const _hoisted_2 = { class: "page-comparition-boxes_top" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "page-comparition-boxes_header" }
const _hoisted_7 = { class: "title title--medium align-content-center" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6 col-lg-3 mb-5 mb-lg-0" }
const _hoisted_10 = { class: "col-6 col-lg-3 mb-5 mb-lg-0" }
const _hoisted_11 = { class: "col-6 col-lg-3 mb-5 mb-lg-0" }
const _hoisted_12 = { class: "col-6 col-lg-3 mb-5 mb-lg-0" }
const _hoisted_13 = { class: "page-comparition-boxes_btn my-3" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-12 pb-5 px-0" }
const _hoisted_16 = { class: "page-comparition-boxes_bottom" }
const _hoisted_17 = { class: "container" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_form_static = _resolveComponent("notus-form-static")!
  const _component_notus_compartion_rotor = _resolveComponent("notus-compartion-rotor")!
  const _component_notus_compare_cta = _resolveComponent("notus-compare-cta")!
  const _component_notus_why_us_section = _resolveComponent("notus-why-us-section")!
  const _component_notus_summary_footer_section = _resolveComponent("notus-summary-footer-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "page-comparition-boxes_header_button cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeClicked())),
                role: "button"
              })
            ]),
            _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('page.comparition.boxes.title')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_notus_form_static, {
              value: _ctx.getValue(1),
              label: _ctx.getLabel(1)
            }, null, 8, ["value", "label"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_notus_form_static, {
              value: _ctx.getValue(_ctx.valueControlName),
              label: _ctx.getLabel(_ctx.valueControlName)
            }, null, 8, ["value", "label"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_notus_form_static, {
              value: _ctx.getValue(6),
              label: _ctx.getLabel(6)
            }, null, 8, ["value", "label"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_notus_form_static, {
              value: _ctx.getValue(7),
              label: _ctx.getLabel(7)
            }, null, 8, ["value", "label"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", {
              class: "close-btn color-accent fw-medium fs-18 h-18 px-5 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeClicked()))
            }, _toDisplayString(_ctx.$t('page.comparition.boxes.button')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_notus_compartion_rotor, { showDetails: _ctx.showDetails }, null, 8, ["showDetails"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_notus_compare_cta, { class: "py-5" }),
            _createVNode(_component_notus_why_us_section),
            _createVNode(_component_notus_summary_footer_section)
          ])
        ])
      ])
    ])
  ]))
}