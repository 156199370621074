import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';

export default function fetchOnePerBank(offersToFilter: OfferResponse[]): OfferResponse[] {
  const banksIncluded: string[] = [];
  return offersToFilter.filter((offer) => {
    const bankName = offer.product.bankName;
    if (banksIncluded.includes(bankName)) {
      return false;
    }
    banksIncluded.push(bankName);
    return true;
  });
}
