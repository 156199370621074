
import {Options, Vue} from 'vue-class-component';
import {mapState} from 'vuex';
import PageSummary from '@/app/modules/page-summary/page-summary.component.vue';
import {clearStore, clearLeadForm} from '@/store';
import {scrollToElement} from '@/app/modules/shared/helpers/mobile.helper';

@Options({
  name: 'App',
  components: {PageSummary},
  data: () => {
    return {
      partnerLogo: undefined
    }
  },
  computed: {
    ...mapState('progress', ['currentStep']),
  },
  methods: {
    ...mapState('offers', ['isInitialized']),
  },
  mounted() {
    setTimeout(() => {
      this.partnerLogo = this.$route.query.partnerLogo;
      this.$forceUpdate()
    })
  },
  created() {
    clearStore(true);
    clearLeadForm();
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      window.addEventListener('resize', function () {
        if (document.activeElement?.tagName == 'INPUT' || document.activeElement?.tagName == 'TEXTAREA') {
          scrollToElement();
        }
      });
    }
  },
})
export default class App extends Vue {}
