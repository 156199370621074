import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {PropertyType} from '@/app/modules/page-form/enums/property-type.enum';
import {genIncomeGroupName} from '@/store/modules/FormModule/getters';
import {FormValues} from '@/store/modules/FormModule/state';
import {Citizenship} from '../../page-form/enums/citizenship.enum';
import {FormApplicant} from '../../page-form/enums/form-applicant.enum';
import {
  FormIncomeDGType,
  FormIncomeOtherType,
  FormIncomeType,
  FormIncomeUoPType,
} from '../../page-form/enums/form-income-type.enum';
import {FormPurpose} from '../../page-form/enums/form-purpose.enum';
import {Period} from '../../page-form/enums/period.enum';
import {Location} from '../components/form-location/location-response-type';
import {Borrower, Income, INIT_OFFER_REQUEST, OfferRequest} from '../interface/offer.request.interface';
import {dereformatNumberValue} from '../services/utilities.service';
import {store} from '@/store';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {isEmpty} from 'lodash';
import {FORM_CONFIG} from '@/app/config/form.config';

export function generateSimulationRequest(form: FormValues, incomeForm: {[key: string]: FormValues}): OfferRequest {
  const purpose = FormPurpose[form[DynamicFormControlName.purpose] as FormPurpose];

  const purposeValue =
    getNumber(form, DynamicFormControlName['purposes-value']) ||
    dereformatNumberValue(FORM_CONFIG.defaultValues[DynamicFormControlName['purposes-value']]);

  const loanValue =
    getNumber(form, DynamicFormControlName['loan-value']) ||
    dereformatNumberValue(FORM_CONFIG.defaultValues[DynamicFormControlName['loan-value']]);
  const loanPeriod =
    getNumber(form, DynamicFormControlName['loan-period']) ||
    dereformatNumberValue(FORM_CONFIG.defaultValues[DynamicFormControlName['loan-period']]);
  let propertyType = PropertyType[form[DynamicFormControlName.property_type] as number];
  const propertyArea = getNumber(form, DynamicFormControlName.property_details_area);
  // const isFinishing = form[DynamicFormControlName['purposes-finishing']] as boolean;

  // if (isFinishing) {
  //   purposeValue += getNumber(form, DynamicFormControlName['purposes-finishing-value']);
  // }
  const location = form[DynamicFormControlName.property_details_location] as Location;
  if (!propertyType) {
    propertyType = PropertyType[PropertyType.FLAT];
  }

  const request: OfferRequest = {
    ...INIT_OFFER_REQUEST,
    installmentType: store.getters[`form/${GetterTypes.installmentType}`],
    purposes: [
      {
        type: FormPurpose[form[DynamicFormControlName.purpose] as FormPurpose],
        value: {
          currency: 'PLN',
          value: loanValue,
        },
      },
    ],
    collaterals: [
      {
        type: propertyType,
        value: {
          currency: 'PLN',
          value: purposeValue,
        },
        localization: isEmpty(location) ? undefined : (location as Location),
        area: propertyArea,
        thirdPartyOwner: false,
      },
    ],
    creditedProperties: [FormPurpose.MORTGAGE, FormPurpose.CONSOLIDATION].includes(purpose)
      ? []
      : [
          {
            type: propertyType,
            area: propertyArea,
          },
        ],
    creditedPropertyIsCollateral: [FormPurpose.MORTGAGE, FormPurpose.CONSOLIDATION].includes(purpose) ? false : true,
    installmentsCount: loanPeriod ? 12 * loanPeriod : 1,
    tranches: [
      {
        month: 1,
        amount: {
          value: loanValue,
          currency: 'PLN',
        },
      },
    ],
    additionalFilters: {
      borrowers: getBorrowers(form, incomeForm),
    },
  };

  if (request.collaterals && request.collaterals[0]?.localization) {
    request.collaterals[0].localization.label = undefined;
  }

  /* Brak miejsca dla:
      * Kiedy planujesz transakcję
      *

  */
  return request;
}

function getNumber(form: FormValues, property: DynamicFormControlName): number {
  const formValue = dereformatNumberValue(form[property] as string | number);

  if (formValue === undefined || formValue === null) {
    return 0;
  }
  return formValue;
}

function getBorrowers(form: FormValues, incomeForm: {[key: string]: FormValues}): Borrower[] {
  const borrowers: Borrower[] = [];
  const isMultiApplicant = form[DynamicFormControlName.loan_applicant_multi];
  const age = form[DynamicFormControlName.age] as number;

  if (isMultiApplicant !== undefined) {
    borrowers.push({
      age: age ? age : undefined,
      birthDate: age ? getBirthDate(age) : undefined,
      citizenship:
        form[DynamicFormControlName.EU_citizien_self] === true
          ? Citizenship[Citizenship.UE]
          : Citizenship[Citizenship.OUTSIDE_UE],
      isForeigner: form[DynamicFormControlName.EU_citizien_self] === true ? false : true,
      incomes: getIncomes(incomeForm, FormApplicant.SOLO),
    });

    if (isMultiApplicant) {
      borrowers.push({
        age: age ? age : undefined,
        birthDate: age ? getBirthDate(age) : undefined,
        citizenship:
          form[DynamicFormControlName.EU_citizien_multi] === true
            ? Citizenship[Citizenship.UE]
            : Citizenship[Citizenship.OUTSIDE_UE],
        isForeigner: form[DynamicFormControlName.EU_citizien_multi] === true ? false : true,
        incomes: getIncomes(incomeForm, FormApplicant.MULTI),
      });
    }
  }
  return borrowers;
}

function getBirthDate(age: number): string {
  const birthDate = new Date();
  birthDate.setFullYear(birthDate.getFullYear() - age);

  return birthDate.toISOString().split('T')[0];
}
function getIncomes(incomeForm: {[key: string]: FormValues}, applicant: FormApplicant): Income[] {
  const incomes: Income[] = [];

  for (
    let i = 0, incomeDetails = incomeForm[genIncomeGroupName(applicant, i)];
    incomeDetails !== undefined;
    incomeDetails = incomeForm[genIncomeGroupName(applicant, ++i)]
  ) {
    if (incomeDetails[DynamicFormControlName.income_monthly_value]) {
      let incomeSource: FormIncomeUoPType | FormIncomeDGType | FormIncomeOtherType | undefined;
      const incomeType = FormIncomeType[incomeDetails[DynamicFormControlName.income_type] as FormIncomeType];
      switch (incomeType) {
        case FormIncomeType.None:
          continue;
        case FormIncomeType.UoP:
          if (incomeDetails[DynamicFormControlName.income_UoPMaternity]) {
            incomeSource = FormIncomeOtherType.MATERNITY_ALLOWANCE;
          } else {
            incomeSource = FormIncomeUoPType[incomeDetails[DynamicFormControlName.income_UoPType] as FormIncomeUoPType];
          }
          break;
        case FormIncomeType.DG:
          incomeSource = FormIncomeDGType[incomeDetails[DynamicFormControlName.income_taxType] as FormIncomeDGType];
          break;
        case FormIncomeType.Other:
          incomeSource = FormIncomeOtherType[incomeDetails[DynamicFormControlName.income_otherType] as FormIncomeOtherType];
      }

      let incomeCurrency: string;
      if (Array.isArray(incomeDetails[DynamicFormControlName.income_monthly_currency])) {
        incomeCurrency = (incomeDetails[DynamicFormControlName.income_monthly_currency] as string[])[0];
      } else {
        incomeCurrency = incomeDetails[DynamicFormControlName.income_monthly_currency] as string;
      }

      incomes.push({
        durationBackwardIncomeMonths: getDurationIncomeMonths(incomeDetails[DynamicFormControlName.income_time_from] as Period[]),
        durationOnwardIncomeMonths: getDurationIncomeMonths(incomeDetails[DynamicFormControlName.income_time_to] as Period[]),
        netMonthlyIncome: dereformatNumberValue(incomeDetails[DynamicFormControlName.income_monthly_value] as string | number),
        incomeCurrency: incomeCurrency,
        incomeSource: incomeSource,
      });
    }
  }
  return incomes;
}

function getDurationIncomeMonths(duration: Period[] | undefined): number | undefined {
  if (!duration || !duration.length || duration[0] === Period.TEMP) {
    return undefined;
  }
  switch (duration[0]) {
    case Period.UP_TO_FEW_MONTHS:
      return 3;
    case Period.UP_TO_HALF_YEAR:
      return 6;
    case Period.UP_TO_YEAR:
      return 12;
    case Period.UP_TO_TWO_YEARS:
      return 24;
    case Period.OVER_TWO_YEARS:
      return 36;
  }
}
