import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {FORM_CONFIG} from '@/app/config/form.config';
import {FORM_INPUTS_CONFIG, PROPERTY_TYPE_OPTIONS} from '@/app/config/input-form.config';
import {FormControlOptions} from '@/store/modules/FormModule';
import {AvailableFormValue} from '@/store/modules/FormModule/state';
import {isArray} from 'lodash';
import {FormApplicant} from '../../page-form/enums/form-applicant.enum';
import {
  DEFAULT_INCOME_TYPES,
  FormIncomeOtherType,
  FormIncomeType,
  FormIncomeUoPType,
} from '../../page-form/enums/form-income-type.enum';
import {FormPurpose} from '../../page-form/enums/form-purpose.enum';
import {DEFAULT_PERIODS, Period} from '../../page-form/enums/period.enum';
import {FormControlOptionsCard} from '../../page-form/interfaces/form-control-option-card.interface';
import {FormControlSelect} from '../../page-form/interfaces/form-control-select.interface';
import {AvailableInputs} from '../../page-form/models/form-dynamic-page';
import {FormObjectKey} from '../../page-form/models/form-title';
import {SelectGroup} from '../components/form-dropdown/select-group.interface';
import {RadioOption} from '../components/form-radiobutton-group/radio-option.interface';
import {getValidation} from './utilities.service';

export function prepareInputOptions(
  input: AvailableInputs,
  purpose?: FormPurpose,
  multiApplicant?: FormApplicant,
  currentValue?: AvailableFormValue,
  isIncomeForm?: boolean,
  incomeIdx?: number
): FormControlOptions {
  let defaultValue: AvailableFormValue = input.type === 'select' ? [] : '';
  const defaultPurpose = FORM_INPUTS_CONFIG[DynamicFormControlName['purpose']].defaultValue as FormPurpose;
  if (input.defaultValue !== undefined) {
    if (typeof input.defaultValue === 'object' && !isArray(input.defaultValue)) {
      const purposeValue = (input.defaultValue as {[key in FormObjectKey]?: AvailableFormValue})[purpose || defaultPurpose];
      const applicantValue = (input.defaultValue as {[key in FormObjectKey]?: AvailableFormValue})[
        multiApplicant || FormApplicant.SOLO
      ];

      if (purposeValue) {
        defaultValue = purposeValue;
      } else {
        defaultValue = applicantValue;
      }
    } else {
      defaultValue = input.defaultValue;
    }
  }

  let forceValue = false;
  if (currentValue && (input.type === 'options-cards-with-icon' || input.type === 'options-cards')) {
    const isAvailable = getInputOptions(input, purpose || defaultPurpose, incomeIdx).some(
      (option) => option.value === currentValue
    );
    forceValue = !isAvailable;
  }

  return {
    inputName: input.formControlName as DynamicFormControlName,
    isIncomeForm: isIncomeForm ? true : false,
    dirty: false,
    value: defaultValue,
    forceValue,
    validations: getValidation(input.formControlName as DynamicFormControlName),
    validationPrefix: input.validationPrefix,
    dependsOn: FORM_INPUTS_CONFIG[input.formControlName as DynamicFormControlName].dependsOn,
  };
}

export function getInputOptions(input: AvailableInputs, purpose: FormPurpose, incomeIdx?: number): Array<RadioOption> {
  const radioInput = input as unknown as FormControlOptionsCard<DynamicFormControlName>;
  if (input.formControlName === DynamicFormControlName.property_type) {
    return PROPERTY_TYPE_OPTIONS[purpose] as Array<RadioOption>;
  } else if (input.formControlName === DynamicFormControlName.income_type) {
    if (incomeIdx !== 0) {
      return (JSON.parse(JSON.stringify(DEFAULT_INCOME_TYPES)) as Array<RadioOption>).filter(
        (option) => option.value !== FormIncomeType.None
      );
    } else {
      return JSON.parse(JSON.stringify(DEFAULT_INCOME_TYPES)) as Array<RadioOption>;
    }
  } else {
    if (Array.isArray(radioInput.options)) {
      return JSON.parse(JSON.stringify(radioInput.options)) as Array<RadioOption>;
    } else {
      return JSON.parse(JSON.stringify(radioInput.options[purpose])) as Array<RadioOption>;
    }
  }
}

export function getDropdownGroups(
  input: AvailableInputs,
  incomeType: FormIncomeType,
  incomeOtherType: FormIncomeOtherType,
  incomeUoPType: FormIncomeUoPType
): Array<SelectGroup> {
  const dropdownInput = input as unknown as FormControlSelect<DynamicFormControlName>;
  if (input.formControlName === DynamicFormControlName.income_time_to) {
    if (
      (incomeType === FormIncomeType.UoP && incomeUoPType) ||
      (incomeType === FormIncomeType.Other && FORM_CONFIG.jobsWithUnknownFixedTime.indexOf(incomeOtherType) === -1)
    ) {
      return [
        {
          options: [...JSON.parse(JSON.stringify(DEFAULT_PERIODS))],
        },
      ];
    } else {
      return [
        {
          options: [
            ...JSON.parse(JSON.stringify(DEFAULT_PERIODS)),
            {
              value: Period.TEMP,
              label: 'general.period.TEMP.label',
            },
          ],
        },
      ];
    }
  } else if (input.formControlName === DynamicFormControlName.income_time_from) {
    return [
      {
        options: [...JSON.parse(JSON.stringify(DEFAULT_PERIODS))],
      },
    ];
  }
  return dropdownInput.options;
}

export function getShouldBeShown(
  formControlName: DynamicFormControlName,
  incomeType: FormIncomeType,
  incomeOtherType: FormIncomeOtherType,
  incomeUoPType: FormIncomeUoPType
) {
  if (formControlName === DynamicFormControlName.income_time_to) {
    return (
      (incomeType === FormIncomeType.Other && FORM_CONFIG.jobsWithFixedTime.indexOf(incomeOtherType) >= 0) ||
      (incomeType === FormIncomeType.UoP && FORM_CONFIG.jobsWithFixedTime.indexOf(incomeUoPType) >= 0)
    );
  }
  return true;
}
