// eslint-disable-next-line @typescript-eslint/ban-types
import {OffersState} from '@/store/modules/OffersModule/state';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {OrderType} from '@/app/modules/page-summary/models/order-type.enum';

export enum OffersGetter {
  'offersList' = 'OFFERS_LIST',
  'detailedOffer' = 'DETAILED_OFFER',
  'orderType' = 'ORDER_TYPE',
}

export default {
  [OffersGetter.offersList]: (state: OffersState): OfferResponse[] | undefined => {
    return state.offers;
  },
  [OffersGetter.detailedOffer]: (state: OffersState): OfferResponse | undefined => {
    return state.detailedOffer;
  },
  [OffersGetter.orderType]: (state: OffersState): OrderType | undefined => {
    return state.orderType;
  },
};
