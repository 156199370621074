import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-51c61602")
const _hoisted_1 = { class: "container mb-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "fs-14 lh-21 color-font fw-normal mt-5" }
const _hoisted_4 = { class: "container pt-5" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_notus_intro = _resolveComponent("notus-intro")!
  const _component_notus_single_offer_details = _resolveComponent("notus-single-offer-details")!
  const _component_notus_representative_example = _resolveComponent("notus-representative-example")!
  const _component_notus_contact_us_section = _resolveComponent("notus-contact-us-section")!
  const _component_notus_why_us_section = _resolveComponent("notus-why-us-section")!
  const _component_notus_summary_footer_section = _resolveComponent("notus-summary-footer-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "page-comparition-boxes_header_button cursor-pointer position-absolute",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDetailedOffer()))
        }, [
          _createVNode(_component_notus_svg_icon, { "icon-type": "close_muted" })
        ]),
        _createVNode(_component_notus_intro, { inner: true }),
        _createVNode(_component_notus_single_offer_details, { offer: _ctx.detailedOffer }, null, 8, ["offer"]),
        _createVNode(_component_notus_representative_example, { representativeExample: _ctx.representativeExample }, null, 8, ["representativeExample"]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('page.comparition.table.offer_details')), 1)
      ])
    ]),
    _createVNode(_component_notus_contact_us_section, { class: "page-summary_contact_us mb-5" }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_notus_why_us_section),
      _createVNode(_component_notus_summary_footer_section)
    ])
  ]))
}