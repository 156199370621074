import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1eda11d5")
const _hoisted_1 = { class: "offer-details-accordion" }
const _hoisted_2 = { class: "row align-items-start align-items-lg-center" }
const _hoisted_3 = { class: "col-4 col-lg-6 text-start offer-details-accordion__title--value" }
const _hoisted_4 = { class: "color-primary fw-semi-bold" }
const _hoisted_5 = { class: "color-secondary fw-normal" }
const _hoisted_6 = { class: "col-2 col-lg-1 align-items-end" }
const _hoisted_7 = { class: "text-end position-relative offer-details-accordion__trigger-icon-wrapper" }
const _hoisted_8 = { class: "offer-details-accordion__content lh-21 color-font fw-normal" }
const _hoisted_9 = { class: "offer-details-accordion__content-inner" }
const _hoisted_10 = { content: "row" }
const _hoisted_11 = { class: "col-12 col-lg-11" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slide_up_down = _resolveComponent("slide-up-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "offer-details-accordion__title",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["col-6 col-lg-5 fw-normal", [_ctx.active ? 'color-primary' : 'color-secondary']])
        }, [
          _renderSlot(_ctx.$slots, "label", {}, undefined, true)
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "value", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "additional", {}, undefined, true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", {
              class: _normalizeClass(["offer-details-accordion__trigger-icon", {'is-active': _ctx.active}])
            }, null, 2)
          ])
        ])
      ])
    ]),
    _createVNode(_component_slide_up_down, {
      modelValue: _ctx.active,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = $event)),
      duration: 300
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _renderSlot(_ctx.$slots, "content", {}, undefined, true)
              ])
            ])
          ])
        ])
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}