
import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {ButtonThemeType, ButtonType, ButtonSizeType, ButtonAlign} from './button.types';

@Options({
  name: 'NotusButton',
})
export default class NotusButton extends Vue {
  @Prop({default: 'notus'}) theme!: ButtonThemeType;
  @Prop({default: 'normal'}) size!: ButtonSizeType;
  @Prop({default: 'button'}) type!: ButtonType;
  @Prop({default: 'center'}) align!: ButtonAlign;
  @Prop(Boolean) readonly disabled: string | undefined;

  get classes(): string {
    return `btn-${this.theme} btn-${this.size} btn-align-${this.align}`;
  }
}
