import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {FormPurpose} from '../page-form/enums/form-purpose.enum';
import {PropertyType} from '../page-form/enums/property-type.enum';
import {OfferResponse} from '../shared/interface/offer.response.interface';

export const EXAMPLE_PARAMS: {[key in DynamicFormControlName]?: string} = {
  [DynamicFormControlName['loan-value']]: '100 000',
  [DynamicFormControlName['loan-period']]: '35',
  [DynamicFormControlName.purpose]: FormPurpose.PURCHASE,
  [DynamicFormControlName.property_type]: PropertyType.FLAT.toString(),
};

export const COMPARITION_EXAMPLE: OfferResponse[] = [
  {
    id: 'b6437153-00aa-4a4d-b7d2-8a6a3f436a85',
    product: {
      definitionId: {
        id: 'PKOBP-PAKIET_WLASNY_KAT',
        version: 1,
      },
      name: 'Pakiet własny kąt hipoteczny',
      bankName: 'PKO BP',
      eco: false,
    },
    simulation: {
      status: {
        status: 'AVAILABLE',
        reasons: [],
        available: true,
      },
      frozen: false,
      recalculated: false,
      creditAmount: {
        value: 100000,
        currency: 'PLN',
      },
      creditGrossAmount: {
        value: 100000,
        currency: 'PLN',
      },
      collateralAmount: {
        value: 250000,
        currency: 'PLN',
      },
      creditTranches: [
        {
          month: 1,
          amount: {
            value: 100000,
            currency: 'PLN',
          },
        },
      ],
      creditGrossValue: {
        value: {
          value: 100000,
          currency: 'PLN',
        },
      },
      ltv: 0.4,
      interestRate: {
        type: 'VARIABLE',
        rates: [
          {
            baseRate: null,
            customRate: null,
            marginRate: 0.011,
            period: {
              from: 0,
              to: 11,
            },
            rate: 0.014,
            referenceRate: 0.003,
            referenceRateType: 'W6',
          },
          {
            baseRate: null,
            customRate: null,
            marginRate: 0.0257,
            period: {
              from: 12,
              to: 419,
            },
            rate: 0.0287,
            referenceRate: 0.003,
            referenceRateType: 'W6',
          },
        ],
      },
      referenceRate: {
        rates: [
          {
            period: {
              from: 0,
              to: 419,
            },
            rate: 0.003,
            type: 'W6',
          },
        ],
      },
      margin: {
        minimalRate: 0.011,
        rates: [
          {
            period: {
              from: 0,
              to: 11,
            },
            baseRate: 0.011,
            rate: 0.011,
            marginRate: null,
            customizable: false,
          },
          {
            period: {
              from: 12,
              to: 419,
            },
            baseRate: 0.0272,
            rate: 0.0257,
            marginRate: null,
            customizable: true,
          },
        ],
        changers: [
          {
            id: 'PAYMENT_ON_ACCOUNT',
            value: -0.0015,
          },
        ],
      },
      marginRange: {
        from: 0,
        to: 0.15,
      },
      customInstallmentsCount: null,
      installmentsCount: 420,
      installmentsCountRange: {
        from: 4,
        to: 420,
      },
      installmentType: 'EQUAL',
      apr: 0.0346,
      productComponents: {
        COMMISSION: {
          '@type': 'fee',
          code: 'COMMISSION',
          name: 'Prowizja',
          description: 'Prowizja',
          detailedDescription: null,
          applied: true,
          creditable: true,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          amount: {
            value: 0,
            currency: 'PLN',
          },
          rateRange: {
            from: 0,
            to: 0.2,
          },
          rate: 0,
          customRate: null,
        },
        MORTGAGE_REGISTRATION: {
          '@type': 'insurance',
          code: 'MORTGAGE_REGISTRATION',
          name: 'Wpis do hipoteki',
          description: 'Podwyższenie marży o 0.9 p.p. do czasu ustanowienia hipoteki.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: true,
          source: null,
          variant: null,
          applicationPeriod: 3,
          period: 3,
          availableVariants: null,
          availableSources: null,
          availableFrequencies: null,
          premium: {
            value: 75,
            currency: 'PLN',
          },
          rate: 0.009,
        },
        PROPERTY_INSURANCE: {
          '@type': 'insurance',
          code: 'PROPERTY_INSURANCE',
          name: 'Ubezpieczenie nieruchomości',
          description: 'Zakres: ogień i inne zdarzenia losowe. Składka roczna: 0,08% kwoty kredytu.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1Y',
          required: true,
          source: 'BANK',
          variant: 'Pkobp_Nieruchomość',
          applicationPeriod: 420,
          period: 420,
          availableVariants: ['Pkobp_Nieruchomość'],
          availableSources: ['BANK', 'EXTERNAL'],
          availableFrequencies: ['1Y'],
          premium: {
            value: 80,
            currency: 'PLN',
          },
          rate: 0.0008,
        },
        JOB_LOSS_INSURANCE: {
          '@type': 'insurance',
          code: 'JOB_LOSS_INSURANCE',
          name: 'Ubezpieczenie od utraty pracy',
          description:
            'Ub. co najmniej dla 1 osoby przez 4 lata. Rezygnacja wcześniej: marża + 0,5 p.p. Zakres: utrata pracy. Składka za 4 lata: jednorazowo 3,25% kwoty kredytu. Brak możliwości kontynuacji.',
          detailedDescription: null,
          applied: true,
          creditable: true,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          source: 'BANK',
          variant: 'Utrata_pracy',
          applicationPeriod: 48,
          period: 48,
          availableVariants: ['Utrata_pracy'],
          availableSources: ['BANK'],
          availableFrequencies: ['1O'],
          premium: {
            value: 3250,
            currency: 'PLN',
          },
          rate: 0.0325,
        },
        LIFE_INSURANCE: {
          '@type': 'insurance',
          code: 'LIFE_INSURANCE',
          name: 'Ubezpieczenie na życie',
          description:
            'Przy skorzystaniu z zakupu karty kredytowej i skorzystania z ubezpieczenia na życie oferowanego przez PKO BP SA – uprawnienie do obniżenia marży o 0,06 p.p. Co najmniej jeden kredytobiorca, który nie ukończył 70 lat, musi przystąpić do ub. Jeżeli do ub. przystępuje 2 kredytobiorców, to każdy z nich jest ub. na 50%. Wymagane przez cały okres kredytowania Rezygnacja: marża + 0,06 p.p. Zakres: życie. Pierwsza składka roczna: 0,33276% kwoty kredytu. Następne: 0,02773% m-cznie od salda kredytu.',
          detailedDescription: null,
          applied: null,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1Y',
          required: false,
          source: 'BANK',
          variant: 'Pkobp_Życie',
          applicationPeriod: 420,
          period: 420,
          availableVariants: ['Pkobp_Życie'],
          availableSources: ['BANK'],
          availableFrequencies: ['1Y'],
          premium: null,
          rate: 0.0033276,
        },
        LOW_OWN_CONTRIBUTION_RISK: {
          '@type': 'insurance',
          code: 'LOW_OWN_CONTRIBUTION_RISK',
          name: 'Ryzyko niskiego wkładu własnego',
          description: '-',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: false,
          source: null,
          variant: null,
          applicationPeriod: 0,
          period: 0,
          availableVariants: null,
          availableSources: null,
          availableFrequencies: null,
          premium: null,
          rate: 0.0025,
        },
        PROPERTY_VALUATION: {
          '@type': 'property-valuation',
          code: 'PROPERTY_VALUATION',
          name: 'Wycena nieruchomości',
          description: 'Wycena nieruchomości',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          availableTypes: ['BANK'],
          priceRange: {
            from: 0,
            to: 5000,
          },
          valuations: [
            {
              propertyType: 'FLAT',
              valuationType: 'BANK',
              amount: {
                value: 400,
                currency: 'PLN',
              },
            },
          ],
        },
      },
      subproducts: {
        ROR: {
          code: 'ROR',
          name: 'ROR',
          description: 'Dowolne rachunek w PKO BP SA',
          detailedDescription:
            'Co najmniej jeden kredytobiorca powinien posiadać konto przez cały okres kredytowania. Rezygnacja w ciągu pierwszych 12 m-cy: marża 1.1% przestaje obowiązywać. Rezygnacja w kolejnych miesiącach: marża + 0,1 p.p.',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1M',
          required: true,
          premium: {
            value: 0,
            currency: 'PLN',
          },
        },
        PAYMENT_ON_ACCOUNT: {
          code: 'PAYMENT_ON_ACCOUNT',
          name: 'Wpływ na rachunek',
          description: 'Wpływ min. 3000 zł.',
          detailedDescription:
            'Obniża marżę o 0,15 p.p. Co najmniej jeden kredytobiorca muzi zapwnić wpływ przez cały okres kredytowania. Brak: marża + 0,15 p.p.',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1O',
          required: false,
          premium: null,
        },
        CREDIT_CARD: {
          code: 'CREDIT_CARD',
          name: 'Karta kredytowa',
          description: 'Karta kredytowa dla klienta detalicznego.',
          detailedDescription:
            'Co najmniej jeden kredytobiorca musi posiadać kartę przez cały okres kredytowania. Zamiast karty kredytowej może skorzystać z ub. na życie za pośrednictwem banku. Rezygnacja w ciągu pierwszych 12 m-cy: marża 1.1% przestaje obowiązywać. Rezygnacja w kolejnych miesiącach: marża + 0,1 p.p.',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1M',
          required: true,
          premium: {
            value: 0,
            currency: 'PLN',
          },
        },
      },
      additionalCosts: {
        costs: null,
      },
      additionalInfo: {
        EARLY_PARTIAL_REPAYMENT: 'Brak opłaty w całym okresie kredytowania.',
        TRANCHE_SETTLEMENT:
          'W oparciu o inspekcję pracownika banku. Koszt rozliczenia transz: każdorazowo 200 zł. Faktury nie są wymagane, nawet przy budowie domu.',
        EARLY_TOTAL_REPAYMENT: 'Brak opłaty w całym okresie kredytowania.',
        CONVERSION: 'nie dotyczy',
      },
      additionalConditions: [null],
      additionalInfoForSalesman: [
        'Możliwość obniżenia marży o 0,05 p.p. dla klienta posiadającego od min. 6 m-cy ROR  w Banku z systematycznymi wpływami',
        'Możliwość obniżenia marży o 0,11 p.p., jeżeli nieruchomość stanowiąca zabezpieczenie kredytu/pożyczki hipotecznej położona jest w mieście stołecznym Warszawa',
        'Możliwość obniżenia marży o 0,05 p.p., jeżeli nieruchomość stanowiąca zabezpieczenie kredytu/pożyczki hipotecznej to lokal mieszkalny położony poza miastem stołecznym Warszawa',
        'Możliwość obniżenia marży o 0,02 p.p., jeżeli Kredytobiorca po zawarciu umowy kredytowej dostarczy świadectwa charakterystyki energetycznej dla nieruchomości będącej zabezpieczeniem kredytu. Bank dokona obniżenia marży od dnia następującego po terminie obowiązywania marży promocyjnej 1,1 p.p.',
        'Warunki niezbędne do zastosowania 90% LTV: Głównym zabezpieczeniem kredytu będzie hipoteka na lokalu mieszkalnym. Jeżeli zabezpieczeniem kredytu będzie nieruchomość należąca do osoby maksymalny poziom LTV nie może przekraczać 80%. Warunki nie dotyczą nieruchomości określanych jako lokalo-domy (nieruchomość, która prawnie stanowi lokal mieszkalny natomiast funkcjonalnie jest domem jednorodzinnym). Zastosowanie możliwe gdy cel kredytowania jest różny od zabezpieczenia',
      ],
      available: true,
    },
    simulationSummary: {
      installment: {
        TOTAL: {
          value: 346.87,
          currency: 'PLN',
        },
        MORTGAGE_REGISTRATION: {
          value: 75,
          currency: 'PLN',
        },
        CAPITAL_AND_INTEREST: {
          value: 271.87,
          currency: 'PLN',
        },
      },
      finalInstallment: {
        TOTAL: {
          value: 301.58,
          currency: 'PLN',
        },
        CAPITAL_AND_INTEREST: {
          value: 301.58,
          currency: 'PLN',
        },
      },
      initialCosts: {
        COMMISSION: {
          value: 0,
          currency: 'PLN',
        },
        TOTAL: {
          value: 3730,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 80,
          currency: 'PLN',
        },
        JOB_LOSS_INSURANCE: {
          value: 3250,
          currency: 'PLN',
        },
        PROPERTY_VALUATION: {
          value: 400,
          currency: 'PLN',
        },
        PAYMENT_ON_ACCOUNT: {
          value: 0,
          currency: 'PLN',
        },
      },
      totalCosts: {
        COMMISSION: {
          value: 0,
          currency: 'PLN',
        },
        TOTAL: {
          value: 63557.87,
          currency: 'PLN',
        },
        MORTGAGE_REGISTRATION: {
          value: 224.65,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 2800,
          currency: 'PLN',
        },
        INTERESTS: {
          value: 56883.22,
          currency: 'PLN',
        },
        JOB_LOSS_INSURANCE: {
          value: 3250,
          currency: 'PLN',
        },
        PROPERTY_VALUATION: {
          value: 400,
          currency: 'PLN',
        },
        SCHEDULE_TOTAL: {
          value: 159907.87,
          currency: 'PLN',
        },
        PAYMENT_ON_ACCOUNT: {
          value: 0,
          currency: 'PLN',
        },
        CAPITAL: {
          value: 100000,
          currency: 'PLN',
        },
      },
    },
    available: true,
  },
  {
    id: '87d6825e-c44e-4c21-8b88-60d6677b1fb9',
    product: {
      definitionId: {
        id: 'ING-LEKKA_RATA_PERSONAL_BANKING',
        version: 1,
      },
      name: 'Kredyt hipoteczny Lekka rata - Klient Premium (Personal Banking)',
      bankName: 'ING',
      eco: false,
    },
    simulation: {
      status: {
        status: 'AVAILABLE',
        reasons: [],
        available: true,
      },
      frozen: false,
      recalculated: false,
      creditAmount: {
        value: 100000,
        currency: 'PLN',
      },
      creditGrossAmount: {
        value: 100000,
        currency: 'PLN',
      },
      collateralAmount: {
        value: 250000,
        currency: 'PLN',
      },
      creditTranches: [
        {
          month: 1,
          amount: {
            value: 100000,
            currency: 'PLN',
          },
        },
      ],
      creditGrossValue: {
        value: {
          value: 100000,
          currency: 'PLN',
        },
      },
      ltv: 0.4,
      interestRate: {
        type: 'VARIABLE',
        rates: [
          {
            baseRate: null,
            customRate: null,
            marginRate: 0.0205,
            period: {
              from: 0,
              to: 419,
            },
            rate: 0.0231,
            referenceRate: 0.0026,
            referenceRateType: 'W6',
          },
        ],
      },
      referenceRate: {
        rates: [
          {
            period: {
              from: 0,
              to: 419,
            },
            rate: 0.0026,
            type: 'W6',
          },
        ],
      },
      margin: {
        minimalRate: 0,
        rates: [
          {
            period: {
              from: 0,
              to: 419,
            },
            baseRate: 0.0245,
            rate: 0.0205,
            marginRate: null,
            customizable: true,
          },
        ],
        changers: [
          {
            id: 'PAYMENT_ON_ACCOUNT',
            value: -0.004,
          },
        ],
      },
      marginRange: {
        from: 0,
        to: 0.15,
      },
      customInstallmentsCount: null,
      installmentsCount: 420,
      installmentsCountRange: {
        from: 12,
        to: 420,
      },
      installmentType: 'EQUAL',
      apr: 0.026,
      productComponents: {
        COMMISSION: {
          '@type': 'fee',
          code: 'COMMISSION',
          name: 'Prowizja',
          description: 'Prowizja',
          detailedDescription: null,
          applied: true,
          creditable: true,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          amount: {
            value: 1100,
            currency: 'PLN',
          },
          rateRange: {
            from: 0,
            to: 0.2,
          },
          rate: 0.011,
          customRate: null,
        },
        MORTGAGE_REGISTRATION: {
          '@type': 'insurance',
          code: 'MORTGAGE_REGISTRATION',
          name: 'Wpis do hipoteki',
          description: 'Podwyższenie marży o 0,05 p.p. do czasu ustanowienia hipoteki.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: true,
          source: null,
          variant: null,
          applicationPeriod: 3,
          period: 3,
          availableVariants: null,
          availableSources: null,
          availableFrequencies: null,
          premium: {
            value: 4.17,
            currency: 'PLN',
          },
          rate: 0.0005,
        },
        PROPERTY_INSURANCE: {
          '@type': 'insurance',
          code: 'PROPERTY_INSURANCE',
          name: 'Ubezpieczenie nieruchomości',
          description:
            'Zakres: ochrona nieruchomości od ognia i innych zdarzeń losowych oraz assistance dom. Składka m-czna: 0,0096%  kwoty udzielonego kredytu.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: true,
          source: 'BANK',
          variant: 'Ochrona_podstawowa',
          applicationPeriod: 420,
          period: 420,
          availableVariants: ['Ochrona_podstawowa', 'Ochrona_rozszerzona'],
          availableSources: ['BANK', 'EXTERNAL'],
          availableFrequencies: ['1M'],
          premium: {
            value: 9.6,
            currency: 'PLN',
          },
          rate: 0.000096,
        },
        LIFE_INSURANCE: {
          '@type': 'insurance',
          code: 'LIFE_INSURANCE',
          name: 'Ubezpieczenie na życie',
          description:
            'Ub. min. 1 osoba. Max. wiek 50 lat. Max. kwota kredytu 700 000 zł. Zakres: śmierć. Składka m-czna: 0,035% salda kredytu.',
          detailedDescription: null,
          applied: null,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: false,
          source: 'BANK',
          variant: 'NN_Życie_plus',
          applicationPeriod: 36,
          period: 36,
          availableVariants: [
            'Aviva_Bezpieczny_kredyt_klient_50_plus',
            'Aviva_Bezpieczny_kredyt_klient_do_50l',
            'Aviva_Życie_plus',
            'NN_Bezpieczny_kredyt_klient_50_plus',
            'NN_Bezpieczny_kredyt_klient_do_50l',
            'NN_Bezpieczny_kredyt_Prosta_Ochrona_klient_50_plus',
            'NN_Bezpieczny_kredyt_Prosta_Ochrona_klient_do_50l',
            'NN_Życie_plus',
          ],
          availableSources: ['BANK'],
          availableFrequencies: ['1M'],
          premium: null,
          rate: 0.00035,
        },
        PROPERTY_VALUATION: {
          '@type': 'property-valuation',
          code: 'PROPERTY_VALUATION',
          name: 'Wycena nieruchomości',
          description: 'Wycena nieruchomości',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          availableTypes: ['BANK', 'EXTERNAL'],
          priceRange: {
            from: 0,
            to: 5000,
          },
          valuations: [
            {
              propertyType: 'FLAT',
              valuationType: 'BANK',
              amount: {
                value: 420,
                currency: 'PLN',
              },
            },
          ],
        },
      },
      subproducts: {
        ROR: {
          code: 'ROR',
          name: 'ROR',
          description: 'Dowolny rachunek w ING',
          detailedDescription:
            'Co najmnie jeden kredytobiorca musi posiadac konto przez 36 m-cy. Rezygnacja wcześniej: marża + 0,4 p.p.',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1M',
          required: true,
          premium: {
            value: 0,
            currency: 'PLN',
          },
        },
        PAYMENT_ON_ACCOUNT: {
          code: 'PAYMENT_ON_ACCOUNT',
          name: 'Wpływ na rachunek',
          description: 'Wpływ min. 2000 zł.',
          detailedDescription:
            'Obniża marżę o 0.4 p.p. Co najmniej jeden kredytobiorca powinien zapewnić wpływ na konto min. 2000 zł przez 36 m-cy. Brak wpływu: marża + 0,4 p.p.',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1O',
          required: true,
          premium: null,
        },
      },
      additionalCosts: {
        costs: null,
      },
      additionalInfo: {
        EARLY_PARTIAL_REPAYMENT: 'Brak opłaty w całym okresie kredytowania.',
        TRANCHE_SETTLEMENT:
          'Po przedłożeniu aktualnych zdjęć, kopii stron dziennika budowy (bezpłatnie) lub w oparciu o inspekcję pracowników banku / osobę upoważnioną (każdorazowo 150 zł). Przed wypłatą ostatniej transzy wymagana inspekcja. Faktury nie wymagane, nawet przy budowie domu. W przypadku budowy z deweloperem brak opłat (bank nie rozlicza transz).',
        EARLY_TOTAL_REPAYMENT: 'Brak opłaty w całym okresie kredytowania.',
        CONVERSION: 'nie dotyczy',
      },
      additionalConditions: [null],
      additionalInfoForSalesman: [
        'Marża niższa o 0,05 p.p. dla klienta Private Banking - osoba fizyczna, ze średnim miesięcznym poziomem aktywów zgromadzonych przez Klienta w ING w wysokości 500 tys. zł. PvB od minimum 3 miesięcy.',
        'Marża niższa o 0,1 p.p. dla klienta Wealth Management - osoba fizyczna ze średnim miesięcznym poziomem aktywów zgromadzonych przez Klienta w ING, co najmniej 4 mln zł lub ich równowartości w walucie wymienialnej. Klient musi należeć do tego segmentu od 3 miesięcy.',
        'Kwota na cel dowolny nie może przekroczyć 30% wartości nieruchomości i 50% łącznej kwoty kredytu. Przekroczenie tych wartości spowoduje konieczność zaciągnięcia dwóch zobowiązań: kredytu mieszkaniowego i pożyczki hipotecznej.',
        'Brak możliwości konsolidowania zobowiązań kredytem mieszkaniowym. Jakikolwiek udział konsolidacji w kredycie spowoduje, ze stanie się on kredytem konsolidacyjnym (wyższa marża o 1,5 p.p.).',
      ],
      available: true,
    },
    simulationSummary: {
      installment: {
        TOTAL: {
          value: 359.64,
          currency: 'PLN',
        },
        MORTGAGE_REGISTRATION: {
          value: 4.17,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 9.6,
          currency: 'PLN',
        },
        CAPITAL_AND_INTEREST: {
          value: 345.87,
          currency: 'PLN',
        },
      },
      finalInstallment: {
        TOTAL: {
          value: 357.01,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 9.6,
          currency: 'PLN',
        },
        CAPITAL_AND_INTEREST: {
          value: 347.41,
          currency: 'PLN',
        },
      },
      initialCosts: {
        COMMISSION: {
          value: 1100,
          currency: 'PLN',
        },
        TOTAL: {
          value: 1520,
          currency: 'PLN',
        },
        PROPERTY_VALUATION: {
          value: 420,
          currency: 'PLN',
        },
        PAYMENT_ON_ACCOUNT: {
          value: 0,
          currency: 'PLN',
        },
      },
      totalCosts: {
        COMMISSION: {
          value: 1100,
          currency: 'PLN',
        },
        TOTAL: {
          value: 51471.31,
          currency: 'PLN',
        },
        MORTGAGE_REGISTRATION: {
          value: 12.49,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 4032,
          currency: 'PLN',
        },
        INTERESTS: {
          value: 45906.82,
          currency: 'PLN',
        },
        PROPERTY_VALUATION: {
          value: 420,
          currency: 'PLN',
        },
        SCHEDULE_TOTAL: {
          value: 149951.31,
          currency: 'PLN',
        },
        PAYMENT_ON_ACCOUNT: {
          value: 0,
          currency: 'PLN',
        },
        CAPITAL: {
          value: 100000,
          currency: 'PLN',
        },
      },
    },
    available: true,
  },
  {
    id: '3d70e571-e81f-4be0-b81b-e60c3b054806',
    product: {
      definitionId: {
        id: 'MILLENNIUM-HIPOTECZNY',
        version: 1,
      },
      name: 'Kredyt hipoteczny',
      bankName: 'Millennium',
      eco: false,
    },
    simulation: {
      status: {
        status: 'AVAILABLE',
        reasons: [],
        available: true,
      },
      frozen: false,
      recalculated: false,
      creditAmount: {
        value: 100000,
        currency: 'PLN',
      },
      creditGrossAmount: {
        value: 100000,
        currency: 'PLN',
      },
      collateralAmount: {
        value: 250000,
        currency: 'PLN',
      },
      creditTranches: [
        {
          month: 1,
          amount: {
            value: 100000,
            currency: 'PLN',
          },
        },
      ],
      creditGrossValue: {
        value: {
          value: 100000,
          currency: 'PLN',
        },
      },
      ltv: 0.4,
      interestRate: {
        type: 'VARIABLE',
        rates: [
          {
            baseRate: null,
            customRate: null,
            marginRate: 0.021,
            period: {
              from: 0,
              to: 419,
            },
            rate: 0.0233,
            referenceRate: 0.0023,
            referenceRateType: 'W3',
          },
        ],
      },
      referenceRate: {
        rates: [
          {
            period: {
              from: 0,
              to: 419,
            },
            rate: 0.0023,
            type: 'W3',
          },
        ],
      },
      margin: {
        minimalRate: 0,
        rates: [
          {
            period: {
              from: 0,
              to: 419,
            },
            baseRate: 0.031,
            rate: 0.021,
            marginRate: null,
            customizable: true,
          },
        ],
        changers: [
          {
            id: 'LIFE_INSURANCE',
            value: -0.005,
          },
          {
            id: 'PAYMENT_ON_ACCOUNT',
            value: -0.002,
          },
          {
            id: 'DEBIT_CARD',
            value: -0.003,
          },
        ],
      },
      marginRange: {
        from: 0,
        to: 0.15,
      },
      customInstallmentsCount: null,
      installmentsCount: 420,
      installmentsCountRange: {
        from: 72,
        to: 420,
      },
      installmentType: 'EQUAL',
      apr: 0.0316,
      productComponents: {
        COMMISSION: {
          '@type': 'fee',
          code: 'COMMISSION',
          name: 'Prowizja',
          description: 'Prowizja',
          detailedDescription: null,
          applied: true,
          creditable: true,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          amount: {
            value: 0,
            currency: 'PLN',
          },
          rateRange: {
            from: 0,
            to: 0.2,
          },
          rate: 0,
          customRate: null,
        },
        MORTGAGE_REGISTRATION: {
          '@type': 'insurance',
          code: 'MORTGAGE_REGISTRATION',
          name: 'Wpis do hipoteki',
          description: 'Bank pobiera 0,0833% salda kredytu m-cznie. Składka pobierana jest z dołu 8-go dnia każdego m-ca.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: true,
          source: null,
          variant: null,
          applicationPeriod: 3,
          period: 3,
          availableVariants: null,
          availableSources: null,
          availableFrequencies: null,
          premium: {
            value: 83.17,
            currency: 'PLN',
          },
          rate: 0.000833,
        },
        PROPERTY_INSURANCE: {
          '@type': 'insurance',
          code: 'PROPERTY_INSURANCE',
          name: 'Ubezpieczenie nieruchomości',
          description: 'Zakres: ogień i inne zdarzenia losowe. Składka m-czna: 0,0075% wartości nieruchomości.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: true,
          source: 'BANK',
          variant: 'Millennium_Nieruchomość',
          applicationPeriod: 420,
          period: 420,
          availableVariants: ['Millennium_Nieruchomość'],
          availableSources: ['BANK', 'EXTERNAL'],
          availableFrequencies: ['1M'],
          premium: {
            value: 18.75,
            currency: 'PLN',
          },
          rate: 0.000075,
        },
        LIFE_INSURANCE: {
          '@type': 'insurance',
          code: 'LIFE_INSURANCE',
          name: 'Ubezpieczenie na życie',
          description:
            'Ub. objęci wszyscy kredytobiorcy proporcjonalnie do dochodów. Wymagane przez cały okres kredytowania, ale Bank przyjmuje polisy zewnętrzne. Brak polisy: marża + 0,5 p.p. (do rezygnacji z polisy potrzebna jest zgoda banku) Zakres: śmierć, niezdolność do pracy i samodzielnej egzystencji. Składka m-czna: 0,03% salda zadłużenia.',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1M',
          required: true,
          source: 'BANK',
          variant: 'Millennium_Życie',
          applicationPeriod: 420,
          period: 420,
          availableVariants: ['Millennium_Życie'],
          availableSources: ['BANK', 'EXTERNAL'],
          availableFrequencies: ['1M'],
          premium: {
            value: 30,
            currency: 'PLN',
          },
          rate: 0.0003,
        },
        PROPERTY_VALUATION: {
          '@type': 'property-valuation',
          code: 'PROPERTY_VALUATION',
          name: 'Wycena nieruchomości',
          description: 'Wycena nieruchomości',
          detailedDescription: null,
          applied: true,
          creditable: false,
          mustBeCredited: false,
          credited: false,
          frequency: '1O',
          required: true,
          availableTypes: ['EXTERNAL'],
          priceRange: {
            from: 0,
            to: 5000,
          },
          valuations: [
            {
              propertyType: 'FLAT',
              valuationType: 'EXTERNAL',
              amount: {
                value: 420,
                currency: 'PLN',
              },
            },
          ],
        },
      },
      subproducts: {
        ROR: {
          code: 'ROR',
          name: 'ROR',
          description: 'Dowolne konto (oprócz lokacyjnego).',
          detailedDescription:
            'Wszyscy kredytobiorcy muszą posiadać rachunek (może być wspólny). Wymagany przez cały okres kredytowania. Rezygnacja: marża + 0,3 p.p. lub 0,5 p.p. (jeżeli cross-sell obejmował wpływ wynagrodzenia na konto).',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1M',
          required: true,
          premium: {
            value: 0,
            currency: 'PLN',
          },
        },
        DEBIT_CARD: {
          code: 'DEBIT_CARD',
          name: 'Karta debetowa',
          description: 'Obrót min. 500 zł/m-c.',
          detailedDescription:
            'Co najmniej jeden kredytobiorca musi posiadać kartę przez cały okres kredytowania. Rezygnacja lub niespełnienie warunków: marża + 0,3 p.p. lub 0,5 p.p. (jeśli cross-sell obejmował wpływ wynagrodzenia na konto).',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1M',
          required: true,
          premium: {
            value: 0,
            currency: 'PLN',
          },
        },
        PAYMENT_ON_ACCOUNT: {
          code: 'PAYMENT_ON_ACCOUNT',
          name: 'Wpływ na rachunek',
          description: 'Wpływ wynagrodzenia lub innego dochodu, min. dwukrotność raty.',
          detailedDescription:
            'Warunek może być spełniony przez jednego kredytobiorcę, jeśli ma odpowiednią wysokość wynagrodzenia. Brak wpływów: marża + 0,5 p.p.',
          applied: true,
          creditable: false,
          mustBeCredited: false,
          frequency: '1O',
          required: true,
          premium: null,
        },
      },
      additionalCosts: {
        costs: null,
      },
      additionalInfo: {
        EARLY_PARTIAL_REPAYMENT: 'Brak opłaty w całym okresie kredytowania.',
        TRANCHE_SETTLEMENT:
          'Remont - rozliczenie po przedłożeniu aktualnych zdjęć, brak inspekcji. W indywidualnych przypadkach analityk może poprosić o przedłożenie faktur. Budowa - zdjęcia z budowy, zapisy w dzienniku budowy + kontrola inwestycji 300 zł',
        EARLY_TOTAL_REPAYMENT: 'Brak opłaty w całym okresie kredytowania.',
        CONVERSION: 'nie dotyczy',
      },
      additionalConditions: [
        'Weryfikacja powyższych warunków będzie następowała co 3 m-ce (w dniu zmiany stawki W3).  W przypadku nie spełnienia  marża wzrarsta o 0,5 p.p.',
      ],
      additionalInfoForSalesman: [
        'Weryfikacja spełnienia warunków cross-sell co 3 miesiące (w dniu zmian stawki W3).',
        'Ograniczono LTV dla wartości nieruchomości i lokalizacji • dla nieruchomości wartych powyżej 750 tys. z lokalizacji powyżej 250.000 mieszkańców, tj.: Białystok, Bydgoszcz, Katowice, Kraków, Lublin, Łódź, Poznań, Szczecin, Warszawa, Wrocław oraz Trójmieście, max LTV do 80% • dla nieruchomości wartych powyżej 500 tys. z pozostałych lokalizacji max LTV 80%',
      ],
      available: true,
    },
    simulationSummary: {
      installment: {
        TOTAL: {
          value: 480.37,
          currency: 'PLN',
        },
        MORTGAGE_REGISTRATION: {
          value: 83.17,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 18.75,
          currency: 'PLN',
        },
        LIFE_INSURANCE: {
          value: 30,
          currency: 'PLN',
        },
        CAPITAL_AND_INTEREST: {
          value: 348.45,
          currency: 'PLN',
        },
      },
      finalInstallment: {
        TOTAL: {
          value: 397.2,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 18.75,
          currency: 'PLN',
        },
        LIFE_INSURANCE: {
          value: 30,
          currency: 'PLN',
        },
        CAPITAL_AND_INTEREST: {
          value: 348.45,
          currency: 'PLN',
        },
      },
      initialCosts: {
        COMMISSION: {
          value: 0,
          currency: 'PLN',
        },
        TOTAL: {
          value: 420,
          currency: 'PLN',
        },
        PROPERTY_VALUATION: {
          value: 420,
          currency: 'PLN',
        },
        PAYMENT_ON_ACCOUNT: {
          value: 0,
          currency: 'PLN',
        },
      },
      totalCosts: {
        COMMISSION: {
          value: 0,
          currency: 'PLN',
        },
        TOTAL: {
          value: 62217.81,
          currency: 'PLN',
        },
        MORTGAGE_REGISTRATION: {
          value: 249.12,
          currency: 'PLN',
        },
        PROPERTY_INSURANCE: {
          value: 7875,
          currency: 'PLN',
        },
        INTERESTS: {
          value: 46348.17,
          currency: 'PLN',
        },
        LIFE_INSURANCE: {
          value: 7325.52,
          currency: 'PLN',
        },
        PROPERTY_VALUATION: {
          value: 420,
          currency: 'PLN',
        },
        SCHEDULE_TOTAL: {
          value: 161797.81,
          currency: 'PLN',
        },
        PAYMENT_ON_ACCOUNT: {
          value: 0,
          currency: 'PLN',
        },
        CAPITAL: {
          value: 100000,
          currency: 'PLN',
        },
      },
    },
    available: true,
  },
];
