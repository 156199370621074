import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row summary-parameters",
  ref: "filters"
}
const _hoisted_2 = { class: "col-md-4" }
const _hoisted_3 = { class: "d-flex label--tooltip" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-6 col-lg-3" }
const _hoisted_9 = { class: "col-md-6 col-lg-3" }
const _hoisted_10 = { class: "col-md-6 col-lg-3" }
const _hoisted_11 = { class: "col-md-6 col-lg-3 align-self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_VTooltip = _resolveComponent("VTooltip")!
  const _component_notus_form_input = _resolveComponent("notus-form-input")!
  const _component_notus_form_dropdown = _resolveComponent("notus-form-dropdown")!
  const _component_notus_form_switch = _resolveComponent("notus-form-switch")!
  const _component_notus_form_checkbox = _resolveComponent("notus-form-checkbox")!
  const _component_notus_expand_panel = _resolveComponent("notus-expand-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_notus_form_input, {
        controlName: _ctx.dynamicFormControlName['purposes-value'],
        fitToContainer: true,
        limitSigns: 8,
        placeholder: _ctx.purposesValuePlaceholder,
        suffix: "PLN"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.getLabel(_ctx.dynamicFormControlName['purposes-value'])) + " ", 1),
            (_ctx.showTooltip(_ctx.dynamicFormControlName['purpose']))
              ? (_openBlock(), _createBlock(_component_VTooltip, {
                  key: 0,
                  offset: [0, 12],
                  placement: 'right',
                  triggers: [ 'hover', 'click', 'focus'],
                  autoHide: true,
                  theme: 'center-tooltip'
                }, {
                  popper: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.$t(_ctx.getTooltipContent(_ctx.dynamicFormControlName['purpose']))
                    }, null, 8, _hoisted_4)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_notus_svg_icon, {
                      iconType: "help",
                      cover: false
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["controlName", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_notus_form_input, {
        controlName: _ctx.dynamicFormControlName['loan-value'],
        placeholder: _ctx.loanValuePlaceholder,
        limitSigns: 8,
        fitToContainer: true,
        suffix: "PLN"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('page.summary.parameters.loan_value')), 1)
        ]),
        _: 1
      }, 8, ["controlName", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_notus_form_input, {
        controlName: _ctx.dynamicFormControlName['loan-period'],
        placeholder: _ctx.loanPeriodPlaceholder,
        limitSigns: 2,
        fitToContainer: true,
        suffix: "general.suffix.year"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('page.summary.parameters.loan_period')), 1)
        ]),
        _: 1
      }, 8, ["controlName", "placeholder"])
    ]),
    _createVNode(_component_notus_expand_panel, {
      closedLabel: "page.summary.filters.label.closed",
      openedLabel: "page.summary.filters.label.opened",
      buttonText: 'page.summary.filters.submit',
      buttonDisabled: !_ctx.isValid,
      onButtonAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reloadResults())),
      "scroll-to-on-hide": _ctx.$refs.filters
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_notus_form_dropdown, {
              controlName: _ctx.dynamicFormControlName['purpose'],
              groups: _ctx.purposeOptions,
              fitToContainer: true
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getLabel(_ctx.dynamicFormControlName['purpose'])), 1)
              ]),
              _: 1
            }, 8, ["controlName", "groups"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_notus_form_switch, {
              options: _ctx.instalmentTypes,
              controlName: "instalmentType",
              customForm: _ctx.formFilters,
              fitToContainer: true
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('page.summary.filter.additionalOptions.instalment.label')), 1)
              ]),
              _: 1
            }, 8, ["options", "customForm"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_notus_form_switch, {
              options: _ctx.interestRateTypes,
              controlName: "interestRateTypes",
              customForm: _ctx.formFilters,
              fitToContainer: true
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('page.summary.filter.additionalOptions.interestRate.label')), 1)
              ]),
              _: 1
            }, 8, ["options", "customForm"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            (!_ctx.commission0Available)
              ? (_openBlock(), _createBlock(_component_VTooltip, {
                  key: 0,
                  offset: [0, 12],
                  placement: 'bottom',
                  triggers: ['hover', 'click'],
                  theme: 'center-tooltip'
                }, {
                  popper: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('page.summary.filter.additionalOptions.COMMISSION_0_PERCENT.tooltip')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_notus_form_checkbox, {
                      disabled: !_ctx.commission0Available,
                      isIncomeForm: false,
                      customForm: _ctx.formFilters,
                      controlName: "commission0Percent"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('page.summary.filter.additionalOptions.COMMISSION_0_PERCENT.label')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "customForm"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_notus_form_checkbox, {
                  key: 1,
                  disabled: !_ctx.commission0Available,
                  isIncomeForm: false,
                  customForm: _ctx.formFilters,
                  controlName: "commission0Percent"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('page.summary.filter.additionalOptions.COMMISSION_0_PERCENT.label')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "customForm"]))
          ])
        ])
      ]),
      _: 1
    }, 8, ["buttonText", "buttonDisabled", "scroll-to-on-hide"])
  ], 512))
}