import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {i18n} from '@/assets/i18n';
import {FormPurpose} from '../../page-form/enums/form-purpose.enum';
import {PropertyType} from '../../page-form/enums/property-type.enum';
import {Location} from '@/app/modules/shared/components/form-location/location-response-type';
import {FormIncomeType} from '../../page-form/enums/form-income-type.enum';
import {store} from '@/store';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {FormApplicant} from '../../page-form/enums/form-applicant.enum';
import {dereformatNumberValue} from './utilities.service';
import {Form} from '@/store/modules/FormModule/state';
import {FORM_CONFIG} from '@/app/config/form.config';

export function getValueTranslated(
  controlName: DynamicFormControlName,
  controlValue?: string | Location,
  forcePL = false,
  incomeForm?: Form
): string {
  const curLocale = i18n.global.locale;
  if (
    // controlName === DynamicFormControlName['purposes-finishing'] ||
    [
      DynamicFormControlName.EU_citizien_self,
      DynamicFormControlName.EU_citizien_multi,
      DynamicFormControlName.loan_applicant_multi,
      DynamicFormControlName.income_UoPMaternity,
    ].includes(controlName)
  ) {
    return i18n.global.t(`general.switch.${controlValue ? 'YES' : 'NO'}.label`, forcePL ? 'pl' : curLocale);
  } else if (!controlValue) {
    return '-';
  } else if (controlName === DynamicFormControlName.purpose) {
    return i18n.global.t('general.purpose.' + (controlValue as string), forcePL ? 'pl' : curLocale);
  } else if (controlName === DynamicFormControlName.property_type) {
    return i18n.global.t(
      'general.propertyType.' + PropertyType[parseInt(controlValue as string) as PropertyType] + '.title',
      forcePL ? 'pl' : curLocale
    );
  } else if (controlName === DynamicFormControlName.age) {
    return `${controlValue} ${i18n.global.tc(
      `general.suffix.year`,
      parseInt(controlValue as string),
      forcePL ? 'pl' : curLocale
    )}`;
  } else if (controlName === DynamicFormControlName['loan-period']) {
    return `${controlValue} ${i18n.global.tc(
      `general.suffix.year`,
      parseInt(controlValue as string),
      forcePL ? 'pl' : curLocale
    )}`;
  } else if (
    [
      DynamicFormControlName['loan-value'],
      DynamicFormControlName.property_value,
      DynamicFormControlName['purposes-value'],
      DynamicFormControlName['purposes-value-land'],
      // DynamicFormControlName['purposes-finishing-value'],
      DynamicFormControlName.outcome_monthly,
      DynamicFormControlName.outcome_limit_card,
      DynamicFormControlName.outcome_limit_account,
      DynamicFormControlName.income_monthly_value,
    ].includes(controlName)
  ) {
    return (controlValue as string) + ' PLN';
  } else if (controlName === DynamicFormControlName['property_details_location']) {
    const location = controlValue as Location;
    if (location) {
      return `${location.city}, pow. ${location.county}`;
    } else {
      return '-';
    }
  } else if (controlName === DynamicFormControlName.property_details_area) {
    return controlValue + ' m\u00b2';
  } else if (controlName === DynamicFormControlName.income_UoPType) {
    return i18n.global.t(`dynamic.form.page.income_UoPType.options.${controlValue}`, forcePL ? 'pl' : curLocale);
  } else if (controlName === DynamicFormControlName.income_taxType) {
    return i18n.global.t(`dynamic.form.page.income_taxType.options.${controlValue}.label`, forcePL ? 'pl' : curLocale);
  } else if (controlName === DynamicFormControlName.income_otherType) {
    return i18n.global.t(`dynamic.form.page.income_otherType.options.${controlValue}`, forcePL ? 'pl' : curLocale);
  } else if ([DynamicFormControlName.income_time_from, DynamicFormControlName.income_time_to].includes(controlName)) {
    return i18n.global.t(`general.period.${controlValue}.label`, forcePL ? 'pl' : curLocale);
  } else if (controlName === DynamicFormControlName['loan-begin']) {
    return i18n.global.t(`general.periodToBegin.${controlValue}.label`, forcePL ? 'pl' : curLocale);
  } else if (controlName === DynamicFormControlName.income_type) {
    let value = i18n.global.t(`general.incomeType.${controlValue}`, forcePL ? 'pl' : curLocale);

    if (controlValue === FormIncomeType.UoP) {
      const isMaterity = incomeForm ? incomeForm[DynamicFormControlName.income_UoPMaternity]?.value : false;
      if (isMaterity) {
        value += `, ${i18n.global.t('general.formLabels.income_UoPMaternity', forcePL ? 'pl' : curLocale).toLowerCase()}`;
      }
    }

    return value;
  }

  return controlValue as string;
}

export function getLabelTranslated(controlName: DynamicFormControlName, forcePL = false): string {
  const curLocale = i18n.global.locale;

  if (
    [
      DynamicFormControlName['purposes-value'],
      DynamicFormControlName['loan-value'],
      DynamicFormControlName['purposes-value-land'],
    ].includes(controlName)
  ) {
    const purpose = store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.purpose) as FormPurpose;

    if (controlName === DynamicFormControlName['purposes-value-land'] && (purpose === 'RENOVATION' || purpose === 'BUILD')) {
      return i18n.global.t(
        `dynamic.form.page.value_land.input.purposes-value-land.${purpose.toString()}.label`,
        forcePL ? 'pl' : curLocale
      );
    }
    return i18n.global.t(
      `general.formLabels.${DynamicFormControlName[controlName as DynamicFormControlName]}.${purpose}`,
      forcePL ? 'pl' : curLocale
    );
  }

  if (controlName === DynamicFormControlName.age) {
    const isMultiple = store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.loan_applicant_multi) as boolean;

    return i18n.global.t(
      `general.formLabels.${DynamicFormControlName[controlName as DynamicFormControlName]}.${
        isMultiple ? FormApplicant.MULTI : FormApplicant.SOLO
      }`,
      forcePL ? 'pl' : curLocale
    );
  }

  if ([DynamicFormControlName['EU_citizien_self'], DynamicFormControlName.EU_citizien_multi].includes(controlName)) {
    return i18n.global.t(`general.formLabels.EU_citizien`, forcePL ? 'pl' : curLocale);
  }

  return i18n.global.t(
    `general.formLabels.${DynamicFormControlName[controlName as DynamicFormControlName]}`,
    forcePL ? 'pl' : curLocale
  );
}

export function getIncomeDetailsName(incomeType?: FormIncomeType): DynamicFormControlName | undefined {
  if (incomeType === FormIncomeType.UoP) {
    return DynamicFormControlName.income_UoPType;
  } else if (incomeType === FormIncomeType.DG) {
    return DynamicFormControlName.income_taxType;
  } else if (incomeType === FormIncomeType.Other) {
    return DynamicFormControlName.income_otherType;
  }
}

export function calculateMaxLoanValue(): number {
  const purposeValue =
    dereformatNumberValue(store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName['purposes-value'])) ||
    dereformatNumberValue(FORM_CONFIG.defaultValues[DynamicFormControlName['purposes-value']]);
  return purposeValue;
  // const purpose = store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.purpose) as FormPurpose;
  // const purposeLandValue = dereformatNumberValue(
  //   store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName['purposes-value-land'])
  // );
  // const propertyValue = dereformatNumberValue(
  //   store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.property_value)
  // );

  // if (purpose === FormPurpose.PURCHASE) {
  //   return purposeValue;
  // } else if ([FormPurpose.BUILD, FormPurpose.RENOVATION].includes(purpose)) {
  //   return purposeValue + purposeLandValue;
  // } else {
  //   return propertyValue;
  // }
}
