
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {InputDataType} from '@/app/modules/shared/components/form-input/input-data.type';
import {DEFAULT_INPUT_LENGTH} from '@/app/modules/shared/components/form-input/input.config';
import NotusFormInputField from '@/app/modules/shared/components/form-input/form-input-field/form-input-field.component.vue';
import {FormHint} from '@/app/modules/page-form/interfaces/form-hint.interface';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';

@Options({
  name: 'NotusFormInput',
  components: {
    NotusFormInputField,
  },
})
export default class NotusFormInput extends Vue {
  @Prop({default: 0}) refresher!: number;
  @Prop({default: null}) customForm!: CustomFormGroup;
  @Prop({default: false}) isIncomeForm!: boolean;
  @Prop({default: 'number'}) inputDataType!: InputDataType;
  @Prop({default: null}) suffix!: string;
  @Prop({default: null}) placeholder!: string;
  @Prop({default: null}) description!: string;
  @Prop({default: null}) validationPrefix!: string;
  @Prop({default: DEFAULT_INPUT_LENGTH}) inputLength!: number;
  @Prop({default: null}) limitSigns!: number;
  @Prop({default: false}) fitToContainer!: boolean;
  @Prop() fieldHint?: FormHint;
  @Prop() controlName!: string;
}
