import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7690e5b4")
const _hoisted_1 = { class: "title title--medium benefits-title my-4 mt-3 mb-5 mx-0" }
const _hoisted_2 = {
  class: "benefits__components",
  ref: "benefit-equal"
}
const _hoisted_3 = { class: "benefit__component--inner" }
const _hoisted_4 = { class: "benefit__component--img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.getLeadContent('SECTION_TITLE', 'page.contact.benefits_title')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.benefits, (benefit, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "benefit__component",
          "data-equal": "benefit",
          key: benefit
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.getLeadContent(`ICON_${index + 1}_IMG`))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "cover",
                    src: _ctx.getLeadContent(`ICON_${index + 1}_IMG`),
                    alt: ""
                  }, null, 8, _hoisted_5))
                : (_openBlock(), _createBlock(_component_notus_svg_icon, {
                    key: 1,
                    iconType: benefit.icon,
                    cover: false
                  }, null, 8, ["iconType"]))
            ]),
            _createElementVNode("span", {
              class: "title title--xsmall benefit__component--title",
              innerHTML: _ctx.getLeadContent(`ICON_${index + 1}_TEXT`, benefit.title)
            }, null, 8, _hoisted_6)
          ])
        ]))
      }), 128))
    ], 512)
  ]))
}