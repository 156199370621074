
import {namespace} from 'vuex-class';
import {watch} from 'vue';
import {Options, Vue} from 'vue-class-component';
import {OffersActionTypes} from '@/store/modules/OffersModule/actions/action-types';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {ORDER_CRITERIA} from '@/app/modules/page-summary/components/summary-parameters/summary-parameters.config';
import NotusFormDropdown from '@/app/modules/shared/components/form-dropdown/form-dropdown.component.vue';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import { SelectGroup } from '@/app/modules/shared/components/form-dropdown/select-group.interface';

const offersModule = namespace('offers');
const formModule = namespace('form');

@Options({
  name: 'NotusOffers',
  components: {
    NotusFormDropdown,
  },
})
export default class NotusSorting extends Vue {
  @offersModule.Action(OffersActionTypes.setOrderType) setOrderType: any;
  @formModule.Getter(GetterTypes.formSorting) formSorting!: CustomFormGroup;

  form!: CustomFormGroup;

  public orderOptions: SelectGroup[] = [
    {
      options: ORDER_CRITERIA,
    },
  ];
  created() {
    watch(
      this.formSorting,
      (state) => {
        this.setOrderType(state.orderBy.value);
      },
      {deep: true, immediate: true}
    );
  }

  getCriteriaLabel(): string {
    return ORDER_CRITERIA.find((criteria) => criteria.value === this.formSorting.orderBy?.value)?.label || '';
  }
}
