import * as numeral from 'numeral';

export default {
  amountFilter(value: string | number, withDecimal = true): string {
    const pattern = withDecimal ? '0,0.00' : '0,0';
    return numeral(value).format(pattern);
  },
  roundFilter(value: string | number): string {
    const pattern = '0,0.00';
    let numValue = parseInt(numeral(value).value());
    numValue = numValue - (numValue % 1);
    return numeral(numValue).format(pattern);
  },
  percentCount(decimal: number | undefined, pattern = '0.00%'): string {
    return numeral(decimal).format(pattern);
  },
};
