
import {Options, Vue} from 'vue-class-component';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {Prop} from 'vue-property-decorator';
import {scrollToContactForm} from '@/app/modules/shared/helpers/scrollToContactForm.helper';

@Options({
  name: 'NotusIntro',
  components: {
    NotusButton,
    NotusSvgIcon,
  },
  methods: {
    scrollToContact() {
      scrollToContactForm(this.inner);
    },
  },
})
export default class NotusIntro extends Vue {
  @Prop({default: false}) inner!: boolean;
}
