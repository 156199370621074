import {FormInputProperty} from '@/store/modules/FormModule/state';
import {ValiationFn, ValidationErrorData, ValidatorTypes} from './validator-type';
import {isArray} from '@vue/shared';
import {dereformatNumberValue} from '@/app/modules/shared/services/utilities.service';
import {calculateMaxLoanValue} from '@/app/modules/shared/services/form-parameters.service';

export type Validator = {
  [ValidatorTypes.minNumber](min: number): ValiationFn;
  [ValidatorTypes.maxNumber](max: number): ValiationFn;
  [ValidatorTypes.minLength](minLength: number): ValiationFn;
  [ValidatorTypes.maxLength](maxLength: number): ValiationFn;
  [ValidatorTypes.loanPercentage](percentage: number): ValiationFn;
  [ValidatorTypes.required](): ValiationFn;
};
export const validators: Validator = {
  [ValidatorTypes.minNumber](min: number): ValiationFn {
    return (input: FormInputProperty) => {
      if (!input.dirty || !input.value) {
        return null;
      }
      const value = dereformatNumberValue(input.value as string | number);
      const isNotNumberError = checkIsNumber(value);
      if (isNotNumberError) {
        return isNotNumberError;
      }
      if (value < min) {
        return {
          key: 'minNumber',
          data: {value: min},
        };
      }
      return null;
    };
  },
  [ValidatorTypes.maxNumber](max: number): ValiationFn {
    return (input: FormInputProperty) => {
      if (!input.dirty || !input.value) {
        return null;
      }
      const value = dereformatNumberValue(input.value as string | number);

      const isNotNumberError = checkIsNumber(value);
      if (isNotNumberError) {
        return isNotNumberError;
      }

      if (value > max) {
        return {
          key: 'maxNumber',
          data: {value: max},
        };
      }
      return null;
    };
  },
  [ValidatorTypes.minLength](minLength: number): ValiationFn {
    return (input: FormInputProperty) => {
      if (!input.dirty) {
        return null;
      }
      if (input.value) {
        if (input.value.toString().replace(/ /g, '').length < minLength) {
          return {
            key: 'minLength',
            data: {value: minLength},
          };
        }
      }
      return null;
    };
  },
  [ValidatorTypes.maxLength](maxLength: number): ValiationFn {
    return (input: FormInputProperty) => {
      if (!input.dirty) {
        return null;
      }
      if (input.value) {
        if (input.value.toString().replace(/ /g, '').length > maxLength) {
          return {
            key: 'maxLength',
            data: {value: maxLength},
          };
        }
      }
      return null;
    };
  },
  [ValidatorTypes.loanPercentage](percentage: number): ValiationFn {
    return (input: FormInputProperty) => {
      if (!input.dirty) {
        return null;
      }
      const value = dereformatNumberValue(input.value as string | number);

      const isNotNumberError = checkIsNumber(value);
      if (isNotNumberError) {
        return isNotNumberError;
      }

      const sumValue = calculateMaxLoanValue();

      if (value > sumValue * percentage) {
        return {
          key: 'loanPercentage',
          data: {value: sumValue * percentage},
        };
      }
      return null;
    };
  },
  [ValidatorTypes.required]() {
    return (input: FormInputProperty) => {
      const value = input.value;

      if (value === undefined || (typeof value === 'string' && value === '') || (isArray(value) && (value as []).length === 0)) {
        return {
          key: 'required',
        };
      }
      return null;
    };
  },
};

function checkIsNumber(value: number): ValidationErrorData | undefined {
  if (isNaN(value)) {
    return {
      key: 'number',
    };
  }
}
