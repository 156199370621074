export enum FormIncomeType {
  'UoP' = 'UoP',
  'DG' = 'DG',
  'Other' = 'Other',
  'None' = 'None',
}

export const DEFAULT_INCOME_TYPES = [
  {
    label: 'general.incomeType.UoP',
    value: FormIncomeType.UoP,
  },
  {
    label: 'general.incomeType.DG',
    value: FormIncomeType.DG,
  },
  {
    label: 'general.incomeType.Other',
    value: FormIncomeType.Other,
  },
  {
    label: 'general.incomeType.None',
    value: FormIncomeType.None,
  },
];

export enum FormIncomeUoPType {
  'PERMANENT_CONTRACT' = 'PERMANENT_CONTRACT', // "Umowa o pracę na czas nieokreślony"
  'FIXED_TERM_CONTRACT' = 'FIXED_TERM_CONTRACT', // "Umowa o pracę na czas określony"
  'PERMANENT_CONTRACT_FROM_ABROAD' = 'PERMANENT_CONTRACT_FROM_ABROAD', // "Umowa o pracę na czas nieokreślony z zagranicy"
  'FIXED_TERM_CONTRACT_FROM_ABROAD' = 'FIXED_TERM_CONTRACT_FROM_ABROAD', // "Umowa o pracę na czas określony z zagranicy"
}
export enum FormIncomeDGType {
  'SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING' = 'SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING', // "Działalność gospodarcza, kpir"
  'SOLE_PROPRIETORSHIP_LUMP_SUM' = 'SOLE_PROPRIETORSHIP_LUMP_SUM', // "Działalność gospodarcza, ryczałt"
  'SOLE_PROPRIETORSHIP_TAX_CARD' = 'SOLE_PROPRIETORSHIP_TAX_CARD', // "Działalność gospodarcza, karta podatkowa"
  'SOLE_PROPRIETORSHIP_FULL_ACCOUNTING' = 'SOLE_PROPRIETORSHIP_FULL_ACCOUNTING', //"Działalność gospodarcza, pełna księgowość"
  'SOLE_PROPRIETORSHIP_FROM_ABROAD' = 'SOLE_PROPRIETORSHIP_FROM_ABROAD', // "Działalność gospodarcza prowadzona za granicą"
}
export enum FormIncomeOtherType {
  'CONTRACT_OF_MANDATE' = 'CONTRACT_OF_MANDATE', // "Umowa zlecenie"
  'CONTRACT_OF_COMMISSION' = 'CONTRACT_OF_COMMISSION', // "Umowa o dzieło"
  'RETIREMENT_PENSION' = 'RETIREMENT_PENSION', // "Emerytura"
  'FARMER' = 'FARMER', //"Rolnik"
  'CHILD_ALLOWANCE' = 'CHILD_ALLOWANCE', // "500+"
  'MAINTENANCE' = 'MAINTENANCE', // "Alimenty"
  'DRIVER' = 'DRIVER', // "Dieta kierowcy"
  'HOUSING_ALLOWANCE' = 'HOUSING_ALLOWANCE', // "Dodatek mieszkaniowy"
  'DIVIDEND' = 'DIVIDEND', // "Dywidenda"
  'RETIREMENT_INCOME_FROM_ABROAD' = 'RETIREMENT_INCOME_FROM_ABROAD', // "Emerytura z zagranicy"
  'CONTRACT' = 'CONTRACT', // "Kontrakt menadżerski"
  'SOLDIER' = 'SOLDIER', // "Kontrakt żołnierski"
  'PRIEST' = 'PRIEST', // "Ksiądz"
  'SAILOR' = 'SAILOR', // "Marynarz"
  'NOMINATION' = 'NOMINATION', // "Mianowanie, powołanie"
  'RENT' = 'RENT', // "Najem, dzierżawa"
  'DISABILITY_PENSION' = 'DISABILITY_PENSION', // "Renta inwalidzka bezterminowa"
  'TERM_DISABILITY_PENSION' = 'TERM_DISABILITY_PENSION', // "Renta inwalidzka terminowa"
  'FAMILY_ALLOWANCE_PENSION' = 'FAMILY_ALLOWANCE_PENSION', // "Renta rodzinna bezterminowa"
  'TERM_FAMILY_ALLOWANCE_PENSION' = 'TERM_FAMILY_ALLOWANCE_PENSION', // "Renta rodzinna terminowa"
  'PENSION_INCOME_FROM_ABROAD' = 'PENSION_INCOME_FROM_ABROAD', // "Renta z zagranicy bezterminowa"
  'TERM_PENSION_INCOME_FROM_ABROAD' = 'TERM_PENSION_INCOME_FROM_ABROAD', // "Renta z zagranicy terminowa"
  'FARMER_SPECIAL_DEPARTMENT' = 'FARMER_SPECIAL_DEPARTMENT', // "Rolnik prowadzący dział specjalny produkcji rolnej"
  'PRE_RETIREMENT_BENEFIT' = 'PRE_RETIREMENT_BENEFIT', // "Świadczenie przedemerytalne"
  'MATERNITY_ALLOWANCE' = 'MATERNITY_ALLOWANCE', // "Zasiłek macierzyński"
}
