import {createApp} from 'vue';
import vToolTip from 'v-tooltip';
import Toast, {POSITION} from 'vue-toastification';
import {dragscrollNext} from 'vue-dragscroll';
import {createRouter, createWebHistory} from 'vue-router';
import {i18n} from './assets/i18n';
import './styles';
import Vue3TouchEvents from 'vue3-touch-events';
import {store} from '@/store';
import App from '@/app/App.vue';
import {outsideClickDirective} from './app/modules/shared/directives/outside-click.directive';
import './app/modules/shared/numeral-settings';
import {elementScrollToPolyfill} from 'seamless-scroll-polyfill';

elementScrollToPolyfill();

vToolTip.options.offset = [0, 12];
vToolTip.options.boundariesElement = document.body;
vToolTip.options.themes.tooltip.delay = {
  show: 200,
  hide: 200,
};
vToolTip.options.themes['warn-tooltip'] = {
  $extend: 'tooltip',
  $resetCss: true,
};
vToolTip.options.themes['center-tooltip'] = {
  $extend: 'tooltip',
  $resetCss: true,
};
export const TOAST_TIMEOUT = 5 * 1000;

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:hash',
      component: App,
    },
    {
      path: '/porownywarka/:hash',
      component: App,
    },
  ],
});

app
  .use(i18n)
  .use(store)
  .use(router)
  .use(vToolTip)
  .use(Vue3TouchEvents)
  .use(Toast, {
    timeout: TOAST_TIMEOUT,
    position: POSITION.BOTTOM_CENTER,
    pauseOnHover: true,
    hideProgressBar: true,
    closeButton: false,
  })
  .directive('click-outside', outsideClickDirective)
  .directive('dragscroll', dragscrollNext)
  .mount('#app');
