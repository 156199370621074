import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-25f5dafe")
const _hoisted_1 = { class: "expand-page_content" }
const _hoisted_2 = {
  class: "scrollable",
  ref: "panelContentRef"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_button_scroll_top = _resolveComponent("notus-button-scroll-top")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["expand-page", {
      'expand-page--opened': _ctx.opened,
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 512)
      ])
    ], 2),
    (_ctx.showScrollButton)
      ? (_openBlock(), _createBlock(_component_notus_button_scroll_top, {
          key: 0,
          scrollRef: _ctx.$refs.panelContentRef
        }, null, 8, ["scrollRef"]))
      : _createCommentVNode("", true)
  ], 64))
}