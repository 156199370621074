export enum Period {
  'UP_TO_FEW_MONTHS' = 'UP_TO_FEW_MONTHS',
  'UP_TO_HALF_YEAR' = 'UP_TO_HALF_YEAR',
  'UP_TO_YEAR' = 'UP_TO_YEAR',
  'UP_TO_TWO_YEARS' = 'UP_TO_TWO_YEARS',
  'OVER_TWO_YEARS' = 'OVER_TWO_YEARS',
  'TEMP' = 'TEMP',
}

export const DEFAULT_PERIODS = [
  {
    label: 'general.period.OVER_TWO_YEARS.label',
    value: Period.OVER_TWO_YEARS,
  },
  {
    label: 'general.period.UP_TO_TWO_YEARS.label',
    value: Period.UP_TO_TWO_YEARS,
  },
  {
    label: 'general.period.UP_TO_YEAR.label',
    value: Period.UP_TO_YEAR,
  },
  {
    label: 'general.period.UP_TO_HALF_YEAR.label',
    value: Period.UP_TO_HALF_YEAR,
  },
  {
    label: 'general.period.UP_TO_FEW_MONTHS.label',
    value: Period.UP_TO_FEW_MONTHS,
  },
];
