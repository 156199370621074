export enum DynamicFormControlName {
  'purpose' = 1, // there could be problem in swithc with 0 value
  'purposes-value',
  'purposes-value-land',
  // 'purposes-finishing',
  // 'purposes-finishing-value',
  'loan-value' = 6,
  'loan-period',
  'loan-begin',
  'property_type',
  'property_value',
  'property_details_area',
  'property_details_location',
  'loan_applicant_multi',
  'age',
  'income_type',
  'income_UoPType',
  'income_UoPMaternity',
  'income_taxType',
  'income_otherType',
  'income_monthly_value',
  'income_monthly_currency',
  'income_time_from',
  'income_time_to',
  'income_others',
  'outcome_people',
  'outcome_monthly',
  'outcome_mortgages',
  'outcome_limit_card',
  'outcome_limit_account',
  'EU_citizien_self',
  'EU_citizien_multi',
  'break-line',
}
