import {FormIncomeOtherType, FormIncomeUoPType} from '@/app/modules/page-form/enums/form-income-type.enum';
import {DynamicFormControlName} from '../models/dynamic-form-control-name.enum';
import {PropertyType} from '../modules/page-form/enums/property-type.enum';

export const FORM_CONFIG = {
  debounce: 600,
  maxIncomesPerPerson: 4,
  defaultValues: {
    [DynamicFormControlName['purposes-value'].toString()]: '500 000',
    [DynamicFormControlName['loan-value'].toString()]: '400 000',
    [DynamicFormControlName['loan-period'].toString()]: '25',
  },
  hintCurious: {
    amount: 17,
    elementsWithSource: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  phone: {
    prohibitedNumbers: [
      '012345678',
      '000000000',
      '111111111',
      '222222222',
      '333333333',
      '444444444',
      '555555555',
      '666666666',
      '777777777',
      '888888888',
      '999999999',
      '123456789',
      '500500500',
      '100100100',
      '200200200',
      '300300300',
      '400400400',
      '500500500',
      '600600600',
      '700700700',
      '800800800',
      '900900900',
      '100000000',
      '200000000',
      '300000000',
      '400000000',
      '500000000',
      '600000000',
      '700000000',
      '800000000',
      '900000000',
      '121212121',
      '898989898',
      '989898989',
      '878787878',
      '676767676',
      '656565656',
      '565656565',
      '545454545',
      '123123123',
      '321321321',
      '600000000',
      '111222333',
      '500600700',
      '123321123',
      '666777888',
      '500000001',
      '789456123',
      '505505505',
      '777888999',
      '444555666',
      '501501501',
      '511111111',
      '123456788',
      '481111111',
      '555444333',
      '606606606',
      '500100100',
      '500123456',
      '123654789',
      '481234567',
      '600000001',
      '501000000',
      '623456789',
      '789789789',
      '222333444',
      '500100200',
      '666555444',
      '666333222',
      '790000000',
      '777666555',
      '123456780',
      '600100100',
      '600100200',
      '600500400',
      '333444555',
      '654654654',
      '321123123',
      '321123321',
      '555222333',
      '505000000',
      '510000000',
      '512345678',
      '666555222',
      '258258258',
      '333222111',
      '600700800',
      '601000000',
      '611111111',
      '666222333',
      '666999333',
      '600111111',
      '666999666',
      '888777666',
      '123123213',
      '699999999',
      '500000005',
      '543543543',
      '555555551',
      '666555333',
      '696696696',
      '123213213',
      '500111222',
      '500123123',
      '500200300',
      '500500501',
      '506666666',
      '515515515',
      '515515515',
      '555555554',
      '555555556',
      '555666999',
      '600123123',
      '601601601',
      '666000000',
      '666000666',
      '666111222',
      '666666665',
      '777222333',
      '654321987',
      '666555666',
      '666888999',
      '690000000',
      '555666555',
      '605605605',
      '666999888',
      '888075175',
      '888888881',
      '123000000',
      '123321123',
      '123454322',
      '321654987',
      '512000000',
      '600000006',
      '601111111',
      '601234567',
      '601423657',
      '666333555',
      '666777666',
      '888999777',
      '123123132',
      '234234234',
      '345345345',
      '510100100',
      '555333222',
      '555444222',
      '555666333',
      '555666444',
      '600111222',
      '602000000',
      '605000000',
      '666333999',
      '880000000',
      '222333555',
      '321456987',
      '501111111',
      '505501502',
      '501502503',
      '530000000',
      '533533533',
      '555222555',
      '555444555',
      '567567567',
      '555222555',
      '555444555',
      '600200200',
      '600500500',
      '600600601',
      '788888888',
      '799999999',
      '888555222',
      '123321456',
      '123456123',
      '222333222',
      '555000555',
      '555222111',
      '555888666',
      '555888999',
      '609000000',
      '655555555',
      '656656656',
      '666333666',
      '666666661',
      '666666669',
      '666777999',
      '711111111',
      '793000000',
      '888222333',
      '888499955',
      '888222333',
      '888888889',
      '888999888',
    ],
    PLsignsLimit: 9,
    otherSignsLimit: 15,
  },
  propertyTypesWithNoFinishing: [PropertyType.AGRICULTURAL_PARCEL, PropertyType.BUILDING_PLOT, PropertyType.RECREATIONAL_LAND],
  jobsWithFixedTime: [
    FormIncomeOtherType.SOLDIER,
    FormIncomeOtherType.CONTRACT,
    FormIncomeOtherType.NOMINATION,
    FormIncomeOtherType.RENT,
    FormIncomeOtherType.TERM_DISABILITY_PENSION,
    FormIncomeOtherType.TERM_FAMILY_ALLOWANCE_PENSION,
    FormIncomeOtherType.TERM_PENSION_INCOME_FROM_ABROAD,
    FormIncomeUoPType.FIXED_TERM_CONTRACT,
    FormIncomeUoPType.FIXED_TERM_CONTRACT_FROM_ABROAD,
  ],
  jobsWithUnknownFixedTime: [
    FormIncomeOtherType.SOLDIER,
    FormIncomeOtherType.CONTRACT,
    FormIncomeOtherType.NOMINATION,
    FormIncomeOtherType.RENT,
  ],
};
