
import {Options, Vue} from 'vue-class-component';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {Prop} from 'vue-property-decorator';
import NotusOfferDetailsAccordion from '@/app/modules/page-offer-details/components/offer-details-accordion/offer-details-accordion.vue';
import {InsuranceObjType, mapResultsToTableValues} from '@/app/modules/page-comparition-table/comparation-table.helper';
import filters from '@/app/modules/shared/filters';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import NotusLogoBank from '@/app/modules/shared/components/logo-bank/logo-bank.component.vue';
import NotusFormStatic from '@/app/modules/shared/components/form-static/form-static.component.vue';

import {mapState} from 'vuex';

enum detailsType {
  DETAILS = 'DETAILS',
  INSURANCE_PACKAGE = 'INSURANCE_PACKAGE',
  ADDITIONAL = 'ADDITIONAL',
}

@Options({
  name: 'NotusSingleOfferDetails',
  components: {NotusFormStatic, NotusLogoBank, NotusSvgIcon, NotusOfferDetailsAccordion},
  data: () => {
    return {
      detailsType,
      collapseStates: {
        [detailsType.DETAILS]: [false, false, false, false, false, false, false, false, false],
        [detailsType.INSURANCE_PACKAGE]: [false, false, false, false, false],
        [detailsType.ADDITIONAL]: [false, false, false, false],
      },
    };
  },
  computed: {
    ...mapState('offers', ['offers', 'orderType']),
    transformedOffer() {
      if (this.offer) {
        return mapResultsToTableValues(this.offer);
      }
      return null;
    },
    isAllDetailsOpened() {
      return this.collapseStates[detailsType.DETAILS].every((x: boolean) => x);
    },
    isAllInsurancesOpened() {
      return this.collapseStates[detailsType.INSURANCE_PACKAGE].every((x: boolean) => x);
    },
    isAllAdditionalOpened() {
      return this.collapseStates[detailsType.ADDITIONAL].every((x: boolean) => x);
    },
  },
  methods: {
    emptyOrNull(string: string | number): boolean {
      return !!(!string || (string && string.toString().includes('undefined')));
    },
    amountFilter(value: string | number) {
      return filters.amountFilter(value);
    },
    percentFilter(value: number) {
      return filters.percentCount(value);
    },
    roundedAmountFilter(value: string | number) {
      return filters.roundFilter(value);
    },
    insurancePeriod(insuranceObj: InsuranceObjType) {
      return !this.emptyOrNull(insuranceObj.value)
        ? `${this.$t(`page.comparition.table.frequency_${insuranceObj.text}`)}, ${this.$tc(
            'page.comparition.table.period',
            insuranceObj.period
          )}`
        : '';
    },
    switchAll(type: detailsType) {
      if (this.collapseStates[type].every((x: boolean) => x)) {
        this.collapseAll(type);
      } else {
        this.expandAll(type);
      }
    },
    expandAll(type: detailsType) {
      this.collapseStates[type] = this.collapseStates[type].map((x: boolean) => true);
    },
    collapseAll(type: detailsType) {
      this.collapseStates[type] = this.collapseStates[type].map((x: boolean) => false);
    },
  },
})
export default class NotusSingleOfferDetails extends Vue {
  @Prop({default: null}) offer!: OfferResponse;

  beforeMount() {
    this.$nextTick(() => {
      if (this.$refs.offerDetails) {
        (this.$refs.offerDetails as HTMLElement).closest('.scrollable')?.scrollTo({
          top: 0,
          left: 0,
        })
      }
    })
  }
}
