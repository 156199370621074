
import {Options, Vue} from 'vue-class-component';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {Prop} from 'vue-property-decorator';
import SlideUpDown from 'vue3-slide-up-down';
@Options({
  name: 'NotusOfferDetailsAccordion',
  components: {
    SlideUpDown,
  },
  methods: {
    handleClick() {
      this.$emit('update:active', !this.active);
    },
  },
})
export default class NotusOfferDetailsAccordion extends Vue {
  @Prop({default: {}}) offer!: OfferResponse;
  @Prop({default: false}) active!: boolean;
}
