import {ActionContext, ActionTree} from 'vuex';
import {MutationType} from '../mutations/mutation-types';
import {ActionTypes} from './action-types';
import {ActionTypes as ProgressActionTypes} from './../../ProgressModule/actions/action-types';
import {ActionTypes as FormActionTypes} from './../../FormModule/actions/action-types';
import {MutationType as FormMutations} from './../../FormModule/mutations/mutation-types';
import {Mutations} from '../mutations';
import {ProgressFormStageParams, ProgressFormStep} from '@/app/modules/page-form/interfaces/progress-form-step.interface';
import {
  PROGRESS_FORM_INCOME_INIT,
  PROGRESS_FORM_INCOME_ORDER,
  PROGRESS_FORM_MAIN_STEPS_ORDER,
  PROGRESS_FORM_OUTCOME_ORDER,
  PROGRESS_FORM_PURPOSE_ORDER,
} from '@/app/config/progress-form-order.config';
import {ProgressFormState} from '../state';
import {FormIncomeType} from '@/app/modules/page-form/enums/form-income-type.enum';
import {ProgressFormStage} from '@/app/modules/page-form/enums/progress-form-stage.enum';
import {FormApplicant} from '@/app/modules/page-form/enums/form-applicant.enum';
import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';
import {ProgressMainStage} from '@/app/models/progress-main-stage.enum';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {GetterTypes} from '../../FormModule/getters/getter-types';
import {FORM_CONFIG} from '@/app/config/form.config';
import {ProgressFormIncomeDynamicPagesIndex} from '@/app/modules/page-form/enums/progress-form-dynamic-pages-index.enum';
import {ContactReason} from '@/app/modules/page-contact/page-contact-reason.enum';
import {OffersActionTypes} from '../../OffersModule/actions/action-types';
import {genIncomeGroupName} from '../../FormModule/getters';

type ActionAugments = Omit<ActionContext<ProgressFormState, ProgressFormState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.nextStep](context: ActionAugments, nextStep: ProgressFormStep): void;
  [ActionTypes.nextFormStep](context: ActionAugments, nextStep: ProgressFormStep): void;
  [ActionTypes.nextPurposeStep](context: ActionAugments): void;
  [ActionTypes.nextIncomeStep](context: ActionAugments, incomeType: FormIncomeType): void;
  [ActionTypes.nextOutcomeStep](context: ActionAugments): void;
  [ActionTypes.addStep](context: ActionAugments, nextStep: ProgressFormStep): void;
  [ActionTypes.prevStep](context: ActionAugments): void;
  [ActionTypes.navigateBackTo](context: ActionAugments, controlName: ProgressFormStage): void;
  [ActionTypes.resetState](context: ActionAugments): void;
};

export const actions: ActionTree<ProgressFormState, ProgressFormState> & Actions = {
  [ActionTypes.nextStep]({dispatch, state, commit, rootGetters}, nextStep) {
    if (nextStep) {
      dispatch(ActionTypes.addStep, nextStep);
    } else {
      switch (state.currentStep.mainFormStage) {
        case ProgressFormStage.begin: {
          const purpose = rootGetters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.purpose) as FormPurpose;
          commit(MutationType.setPurpose, purpose);

          const nextStage = PROGRESS_FORM_PURPOSE_ORDER[purpose][0];
          dispatch(ActionTypes.nextFormStep, {subStage: nextStage});
          break;
        }
        case ProgressFormStage.purpose:
          dispatch(ActionTypes.nextPurposeStep);
          break;
        case ProgressFormStage.singleOrFriendly: {
          const multiAplicants = rootGetters[`form/${GetterTypes.valueByName}`](
            DynamicFormControlName.loan_applicant_multi
          ) as boolean;
          commit(MutationType.setMultiApplicant, multiAplicants);
          dispatch(
            `form/${FormActionTypes.setCurrentIncomeGroup}`,
            {
              applicant: FormApplicant.SOLO,
              idx: 0,
            },
            {
              root: true,
            }
          );
          dispatch(ActionTypes.nextFormStep, {});
          break;
        }
        case ProgressFormStage.income:
          if (state.currentStep.params?.incomePageName !== ProgressFormIncomeDynamicPagesIndex.other_incomes) {
            if (state.currentStep.params) {
              let incomeType;

              if (state.currentStep.params.incomePageName === PROGRESS_FORM_INCOME_INIT) {
                incomeType = rootGetters[`form/${GetterTypes.inputByName}`](DynamicFormControlName.income_type, true)
                  .value as FormIncomeType;

                if (incomeType === FormIncomeType.None) {
                  const incomeApplicant = state.currentStep.params.incomeApplicant;
                  if (incomeApplicant === FormApplicant.SOLO) {
                    const multiAplicants = rootGetters[`form/${GetterTypes.valueByName}`](
                      DynamicFormControlName.loan_applicant_multi
                    ) as boolean;
                    if (multiAplicants) {
                      startSubApplicantPath(dispatch);
                      break;
                    } else {
                      dispatch(
                        `progress/${ProgressActionTypes.nextStep}`,
                        {mainStage: ProgressMainStage.contact, params: {reason: ContactReason.NO_INCOME}},
                        {root: true}
                      );
                      break;
                    }
                  } else {
                    const onwGroupName = genIncomeGroupName(FormApplicant.SOLO, 0);
                    const hasOwnIncome =
                      (rootGetters[`form/${GetterTypes.valueByName}`](
                        DynamicFormControlName.income_type,
                        true,
                        onwGroupName
                      ) as FormIncomeType) !== FormIncomeType.None;

                    if (!hasOwnIncome) {
                      dispatch(
                        `progress/${ProgressActionTypes.nextStep}`,
                        {mainStage: ProgressMainStage.contact, params: {reason: ContactReason.NO_INCOME}},
                        {root: true}
                      );
                      break;
                    }
                  }
                }
              } else {
                incomeType = state.currentStep.params.incomeType;
              }

              commit(MutationType.setLastIncomeParams, state.currentStep.params);
              dispatch(ActionTypes.nextIncomeStep, incomeType);
            } else {
              throw new Error('No params defined, but required');
            }
          } else {
            const gotOtherIncome = rootGetters[`form/${GetterTypes.valueByName}`](
              DynamicFormControlName.income_others,
              true
            ) as boolean;
            const multiApplicant = rootGetters[`form/${GetterTypes.valueByName}`](
              DynamicFormControlName.loan_applicant_multi
            ) as boolean;
            if (state.lastIncomeParams) {
              if (gotOtherIncome) {
                const newParams: ProgressFormStageParams = {
                  incomeApplicant: state.lastIncomeParams.incomeApplicant,
                  incomeIdx: state.lastIncomeParams.incomeIdx + 1,
                  incomePageName: PROGRESS_FORM_INCOME_INIT,
                };
                dispatch(
                  `form/${FormActionTypes.setCurrentIncomeGroup}`,
                  {
                    applicant: newParams.incomeApplicant,
                    idx: newParams.incomeIdx,
                  },
                  {
                    root: true,
                  }
                );
                dispatch(ActionTypes.addStep, {
                  mainFormStage: ProgressFormStage.income,
                  subStage: PROGRESS_FORM_INCOME_INIT,
                  params: newParams,
                });
              } else if (multiApplicant && state.lastIncomeParams.incomeApplicant === FormApplicant.SOLO) {
                startSubApplicantPath(dispatch);
              } else {
                dispatch(ActionTypes.nextFormStep, {});
              }
            } else {
              throw new Error('Form progress params was not initialized');
            }
          }
          break;
        case ProgressFormStage.otherIncome: {
          console.error('No more otherIncome main path');
          break;
        }
        case ProgressFormStage.outcome:
          dispatch(ActionTypes.nextOutcomeStep);
          break;
        default:
          dispatch(ActionTypes.nextFormStep, {});
          break;
      }
    }
  },
  [ActionTypes.nextFormStep]({dispatch, state, rootGetters}, {subStage = undefined, params = undefined}) {
    const currentMainStep = state.currentStep.mainFormStage;
    const currentMainStepIdx = PROGRESS_FORM_MAIN_STEPS_ORDER.indexOf(currentMainStep);
    if (PROGRESS_FORM_MAIN_STEPS_ORDER[currentMainStepIdx + 1] === ProgressFormStage.otherIncome) {
      params = state.currentStep.params;
      if (params && params?.incomeIdx === FORM_CONFIG.maxIncomesPerPerson - 1) {
        const multiAplicants = rootGetters[`form/${GetterTypes.valueByName}`](
          DynamicFormControlName.loan_applicant_multi
        ) as boolean;
        if (multiAplicants && params.incomeApplicant === FormApplicant.SOLO) {
          startSubApplicantPath(dispatch);
          return;
        }
      }
    }

    if (PROGRESS_FORM_MAIN_STEPS_ORDER.length === currentMainStepIdx + 1) {
      dispatch(`progress/${ProgressActionTypes.nextStep}`, {}, {root: true});
    } else {
      const newMainStep = PROGRESS_FORM_MAIN_STEPS_ORDER[currentMainStepIdx + 1];
      if (newMainStep === ProgressFormStage.income && !params) {
        params = {
          incomeApplicant: FormApplicant.SOLO,
          incomeIdx: 0,
          incomePageName: PROGRESS_FORM_INCOME_INIT,
        };
      } else if (newMainStep === ProgressFormStage.outcome && !subStage) {
        subStage = PROGRESS_FORM_OUTCOME_ORDER[0];
      }
      dispatch(ActionTypes.addStep, {
        mainFormStage: newMainStep,
        subStage: subStage,
        params,
      });
    }
  },
  [ActionTypes.nextPurposeStep]({dispatch, state}) {
    if (state.purpose && state.currentStep.subStage) {
      const purposeOrder = PROGRESS_FORM_PURPOSE_ORDER[state.purpose];
      const currentPurposeOrderIdx = purposeOrder.indexOf(state.currentStep.subStage);

      const skipNextSubStage = false;

      // if (purposeOrder[currentPurposeOrderIdx + 1] === ProgressFormDynamicPagesIndex.property_finishing) {
      //   const propertyType = rootGetters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.property_type) as PropertyType;
      //   skipNextSubStage = FORM_CONFIG.propertyTypesWithNoFinishing.indexOf(propertyType) > -1;
      // }

      if (purposeOrder.length === currentPurposeOrderIdx + (skipNextSubStage ? 2 : 1)) {
        dispatch(ActionTypes.nextFormStep, {});
      } else {
        dispatch(ActionTypes.addStep, {
          mainFormStage: state.currentStep.mainFormStage,
          subStage: purposeOrder[currentPurposeOrderIdx + (skipNextSubStage ? 2 : 1)],
        });
      }
    } else {
      throw new Error('No purpose or subStage defined');
    }
  },
  [ActionTypes.nextIncomeStep]({dispatch, state}, incomeType) {
    if (!incomeType || !state.currentStep.params) {
      throw new Error('No incomeType defined');
    } else {
      const incomeOrder = PROGRESS_FORM_INCOME_ORDER[incomeType];
      const currentIncomeOrderIdx = incomeOrder.indexOf(state.currentStep.params.incomePageName);

      if (incomeOrder.length === currentIncomeOrderIdx + 1) {
        dispatch(ActionTypes.nextFormStep, {});
      } else {
        const newParams = {...state.currentStep.params};
        newParams.incomeType = incomeType;
        newParams.incomePageName = incomeOrder[currentIncomeOrderIdx + 1];
        dispatch(ActionTypes.addStep, {
          mainFormStage: state.currentStep.mainFormStage,
          subStage: state.currentStep.subStage,
          params: newParams,
        });
      }
    }
  },
  [ActionTypes.nextOutcomeStep]({dispatch, state}) {
    if (state.currentStep.subStage) {
      const currentOutcomeOrderIdx = PROGRESS_FORM_OUTCOME_ORDER.indexOf(state.currentStep.subStage);

      if (PROGRESS_FORM_OUTCOME_ORDER.length === currentOutcomeOrderIdx + 1) {
        dispatch(ActionTypes.nextFormStep, {});
      } else {
        dispatch(ActionTypes.addStep, {
          mainFormStage: state.currentStep.mainFormStage,
          subStage: PROGRESS_FORM_OUTCOME_ORDER[currentOutcomeOrderIdx + 1],
        });
      }
    } else {
      throw new Error('No subStage defined');
    }
  },
  [ActionTypes.prevStep]({commit, dispatch, state}) {
    const prevStep = state.stepsPassed[state.stepsPassed.length - 2];
    if (prevStep) {
      commit(MutationType.setCurrentPage, prevStep);
      commit(MutationType.removeLastPassedStep);
    } else {
      dispatch(`progress/${ProgressActionTypes.prevStep}`, {}, {root: true});
    }
  },
  [ActionTypes.addStep]({commit, dispatch, state}, newStep) {
    if (state.shallReloadOffersOnNextPage) {
      dispatch(`offers/${OffersActionTypes.reloadResults}`, state.currentStep.subStage || state.currentStep.mainFormStage, {
        root: true,
      });
    }
    state.shallReloadOffersOnNextPage = false;
    commit(MutationType.addPassedStep, newStep);
    commit(MutationType.setCurrentPage, newStep);
    dispatch(`form/${FormMutations.resetValidation}`, undefined, {root: true});
  },
  [ActionTypes.navigateBackTo]({state, commit, dispatch}, mainStageName) {
    let navigateToLast = false;
    let idx = state.stepsPassed.findIndex((step) => {
      if (
        (mainStageName === ProgressFormStage.purpose && step.mainFormStage === ProgressFormStage.begin) ||
        (mainStageName === ProgressFormStage.singleOrFriendly && step.mainFormStage === ProgressFormStage.singleOrFriendly)
      ) {
        return true;
      }
    });

    if (idx === -1) {
      idx = state.stepsPassed.length - 1;
      navigateToLast = true;
    }

    dispatch(`offers/${OffersActionTypes.resetState}`, {}, {root: true}).then((_) => {
      commit(MutationType.setCurrentPage, state.stepsPassed[idx]);
      commit(MutationType.setShallRefresh, false);

      state.stepsPassed = state.stepsPassed.slice(0, idx + 1);
      dispatch(`progress/${ProgressActionTypes.nextStep}`, {mainStage: ProgressMainStage.form}, {root: true});
      if (!navigateToLast) {
        dispatch(ActionTypes.nextStep);
      }
    });
  },
  [ActionTypes.resetState]({commit}) {
    commit(MutationType.resetState);
  },
};

function startSubApplicantPath(dispatch: any) {
  const newParams: ProgressFormStageParams = {
    incomeApplicant: FormApplicant.MULTI,
    incomeIdx: 0,
    incomePageName: PROGRESS_FORM_INCOME_INIT,
  };
  dispatch(
    `form/${FormActionTypes.setCurrentIncomeGroup}`,
    {
      applicant: newParams.incomeApplicant,
      idx: newParams.incomeIdx,
    },
    {
      root: true,
    }
  );
  dispatch(ActionTypes.addStep, {
    mainFormStage: ProgressFormStage.income,
    params: newParams,
  });
}
