import {AvailableFormValue, Form, FormInputProperty, FormState, PartnerConsent} from '../state';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {GetterTypes} from './getter-types';
import {ValidationErrorData} from '../validators/validator-type';
import {FormApplicant} from '@/app/modules/page-form/enums/form-applicant.enum';
import {getValidation, runValidation} from '@/app/modules/shared/services/utilities.service';
import {ValidationType} from '@/app/config/dynamic-form-validations.config';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';

export default {
  [GetterTypes.form]: (state: FormState): Form => {
    return state.form;
  },
  [GetterTypes.formFilters]: (state: FormState): CustomFormGroup => {
    return state.formFilters;
  },
  [GetterTypes.formSorting]: (state: FormState): CustomFormGroup => {
    return state.formSorting;
  },
  [GetterTypes.formLead]: (state: FormState): CustomFormGroup => {
    return state.formLead;
  },
  [GetterTypes.incomeForms]: (state: FormState): {[key: string]: Form} => {
    return state.incomeForms;
  },
  [GetterTypes.leadWasSend]: (state: FormState): boolean => {
    return state.leadWasSend ? true : false;
  },
  [GetterTypes.leadSentSuccess]: (state: FormState): boolean => {
    return state.leadSendSuccess ? true : false;
  },
  [GetterTypes.proposalId]: (state: FormState): string | undefined => {
    return state.proposalId;
  },
  [GetterTypes.commission0Available]: (state: FormState): boolean => {
    return state.commission0Available ? true : false;
  },
  [GetterTypes.installmentType]: (state: FormState): string => {
    return state.formFilters.instalmentType.value as string;
  },
  [GetterTypes.leadPartnersConstents]: (state: FormState): Array<PartnerConsent> => {
    return state.leadPartnerConstents || [];
  },
  [GetterTypes.inputByName]:
    (state: FormState) =>
    (inputName: DynamicFormControlName, isIncomeForm: boolean): FormInputProperty | undefined => {
      const input = getInput(state, inputName, isIncomeForm);

      if (input) {
        input.validations = getValidation(inputName);
      }
      return input;
    },
  [GetterTypes.valueByName]:
    (state: FormState) =>
    (inputName: DynamicFormControlName, isIncomeForm: boolean, incomeGroupName?: string): AvailableFormValue => {
      return getInput(state, inputName, isIncomeForm, incomeGroupName)?.value;
    },
  [GetterTypes.isValid]: (state: FormState): boolean => {
    let isValid = true;
    if (state.hasErrors !== null) {
      Object.keys(state.hasErrors).forEach((validationKey) => {
        if (state.hasErrors && state.hasErrors[validationKey]) {
          isValid = false;
        }
      });
    }
    return isValid;
  },
  [GetterTypes.isFormLeadValid]: (state: FormState): boolean => {
    return (state.formLead.lead_name.valid as boolean) && (state.formLead.lead_phone.valid as boolean);
  },
  [GetterTypes.validationsByName]:
    (state: FormState) =>
    (inputName: DynamicFormControlName, isIncomeForm: boolean): ValidationErrorData | null => {
      const input = getInput(state, inputName, isIncomeForm);
      if (input && input.validations) {
        const validationRule: ValidationType = input.validations;

        if (validationRule !== null) {
          const error = runValidation(input);
          if (error) {
            return error;
          }
        }
      }
      return null;
    },
};

export function genIncomeGroupName(applicant: FormApplicant, idx: number): string {
  return `income_${applicant === FormApplicant.SOLO ? 'own' : 'subApplicant'}_${idx}`;
}

function getInput(
  state: FormState,
  inputName: DynamicFormControlName,
  isIncomeForm: boolean,
  incomeGroupName?: string
): FormInputProperty | undefined {
  let input: FormInputProperty | undefined;

  if (isIncomeForm) {
    if (incomeGroupName) {
      input = state.incomeForms[incomeGroupName][inputName];
    } else {
      if (!state.currentIncomeGroup) {
        throw Error('No currentIncomeGroup defined!');
      }
      input = state.incomeForms[state.currentIncomeGroup][inputName];
    }
  } else {
    input = state.form[inputName];
  }
  if (input) {
    input.validations = getValidation(inputName); // Purpose or property value could change
  }
  return input;
}
