
import intlTelInput from 'intl-tel-input';

import {InputDataType} from '@/app/modules/shared/components/form-input/input-data.type';
import {Prop} from 'vue-property-decorator';
import {Options} from 'vue-class-component';
import {DEFAULT_INPUT_LENGTH} from '@/app/modules/shared/components/form-input/input.config';
import FormElementSimple from '@/app/modules/shared/components/FormElementSimple';
import filters from '@/app/modules/shared/filters';
import {
  reformatPLPhoneValue,
  dereformatNumberValue,
  reformatNumberValue,
  reformatPhoneValue,
  reformatTextValue,
} from '@/app/modules/shared/services/utilities.service';
import {FORM_CONFIG} from '@/app/config/form.config';
import { namespace } from 'vuex-class';
import {ActionTypes as FormActionTypes} from '@/store/modules/FormModule/actions/action-types';

const formModule = namespace('form');

@Options({
  name: 'NotusFormInputField',
})
export default class NotusFormInputField extends FormElementSimple {
  @Prop({default: 'number'}) inputDataType!: InputDataType;
  @Prop({default: null}) suffix!: string | null;
  @Prop({default: null}) placeholder?: string;
  @Prop({default: null}) description?: string | null = null;
  @Prop({default: DEFAULT_INPUT_LENGTH}) inputLength?: number;
  @Prop({default: null}) limitSigns!: number;
  @Prop({default: false}) widthMobileReduce!: boolean;

  @formModule.Action(FormActionTypes.setLeadPrefix) setLeadPrefix!: any;

  public inputWidth = 0;
  public paddingWhenSuffix = '';
  private paddingRightWithoutSuffix = 2;
  private intlTelInputComponent?: intlTelInput.Plugin;
  private _limitSigns?: number;

  get suffixStyles(): Record<string, unknown> {
    return {
      left: this.fitToContainer ? 'calc(100% - 2rem)' : this.inputWidth + 2 + 'rem',
    };
  }
  get styles(): Record<string, unknown> {
    return {
      width: this.fitToContainer ? 'calc(100% - 2rem)' : this.inputWidth + 'rem',
      'padding-right': this.fitToContainer ? '0' : this.paddingWhenSuffix,
    };
  }
  get amount() {
    return dereformatNumberValue(this.control.value as string | number);
  }

  created() {
    this.inputWidth = this.suffix ? Number(this.inputLength) + this.paddingRightWithoutSuffix : Number(this.inputLength) + 1;
    this.paddingWhenSuffix = this.getpaddingWhenSuffix();

    if (this.inputDataType === 'phone') {
      this.additionalValidation = this.validatePhoneNumber;
    }
  }

  mounted() {
    if (this.inputDataType === 'phone') {
      let phoneEl = this.$refs['input' + this.controlName] as Element;
      const utilsScriptPath = window.location.pathname.substr(0,'/porownywarka'.length) === '/porownywarka' ?
        '/porownywarka/vendors/intl-tel/js/utils.js' :
        '/vendors/intl-tel/js/utils.js';

      this.intlTelInputComponent = intlTelInput(phoneEl, {
        initialCountry: 'pl',
        nationalMode: true,
        autoPlaceholder: 'aggressive',
        utilsScript: utilsScriptPath,
        formatOnDisplay: false,
        preferredCountries: ['PL'],
        onlyCountries: ['PL', 'AT', 'BE', 'BG', 'CA', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', ' IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB', 'US']
      });
      this.setLeadPrefix(this.intlTelInputComponent?.getSelectedCountryData().dialCode);
      phoneEl.addEventListener('countrychange', () => {
        this.setLeadPrefix(this.intlTelInputComponent?.getSelectedCountryData().dialCode);
      });
    }

    if (this.inputDataType === 'phone') {
      this._limitSigns =
        this.intlTelInputComponent?.getSelectedCountryData().iso2 === 'pl'
          ? FORM_CONFIG.phone.PLsignsLimit
          : FORM_CONFIG.phone.otherSignsLimit;
    } else {
      this._limitSigns = this.limitSigns;
    }
  }

  onInputBlur(): void {
    this.setFocusHint();
    if (this.control) {
      const newValue = this.control;
      let formatedValue = newValue.value + '';

      if (this.inputDataType === 'string') {
        formatedValue = reformatTextValue(formatedValue);
      } else if (this.inputDataType === 'number' && formatedValue !== '') {
        formatedValue = reformatNumberValue(formatedValue);
      } else if (this.inputDataType === 'phone') {
        if (this.intlTelInputComponent?.getSelectedCountryData().iso2 === 'pl' && formatedValue !== '') {
          formatedValue = reformatPLPhoneValue(formatedValue);
        } else {
          formatedValue = reformatPhoneValue(formatedValue);
        }
      }

      if (this.inputDataType === 'number' && formatedValue !== '') {
        if (formatedValue.split('').length > 0) {
          formatedValue = filters.amountFilter(formatedValue, false);
          if (formatedValue !== newValue.value) {
            this._setControlValue(formatedValue)
          }
        }
      } else {
        if (formatedValue !== newValue.value) {
          this._setControlValue(formatedValue)
        }
      }
      setTimeout(() => {
        let formatedValue = newValue.value + '';
        if (formatedValue !== newValue.value) {
          this._setControlValue(formatedValue)
          return;
        }
      }, FORM_CONFIG.debounce)
    }
  }

  onInputFocus() {
    this.onFocus();

    if (this.inputDataType === 'string') {
      return;
    }

    const reg = new RegExp('[ ]+', 'g');
    const newValue = this.control.value?.toString().replace(reg, '');
    this._setControlValue(newValue)
  }

  onKeyPress(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;
    if (this._limitSigns && target.selectionStart === target.selectionEnd) {

      const value = this.control.value?.toString() || '';
      if (value.length >= this._limitSigns) {
        e.preventDefault();
      }
    }

    this.inputDataType === 'number' && '0123456789'.indexOf(e.key) === -1 && e.preventDefault();
    this.inputDataType === 'phone' && '0123456789'.indexOf(e.key) === -1 && e.preventDefault();
    this.inputDataType === 'string' && /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]/.test(e.key) && e.preventDefault();
  }

  getpaddingWhenSuffix(): string {
    let suffixLength = 0;
    if (this.suffix) {
      suffixLength = 2;
    }
    return `${suffixLength + this.paddingRightWithoutSuffix}rem`;
  }

  _setControlValue(newValue: string | undefined): void {
    this.control.value = newValue;
  }

  validatePhoneNumber(): string | null {
    const valueCopy = (' ' + this.control.value as string).slice(1);
    if (FORM_CONFIG.phone.prohibitedNumbers.includes(valueCopy.replace(/ /g, ''))) {
      return this.$t('page.contact.form.phone.error');
    }
    return null;
  }
}
