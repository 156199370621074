
import {Options} from 'vue-class-component';
import FormElementSimple from '@/app/modules/shared/components/FormElementSimple';
import {Prop} from 'vue-property-decorator';
import {SwitchOption, SwitchOptions} from '@/app/modules/page-form/interfaces/form-control-switch.interface';
import {AvailableFormValue} from '@/store/modules/FormModule/state';
import {watch} from '@vue/runtime-core';

@Options({
  name: 'NotusFormSwitch',
})
export default class NotusFormSwitch extends FormElementSimple {
  @Prop({default: null}) options!: SwitchOptions;

  get value(): AvailableFormValue {
    return this.control?.value;
  }

  created(): void {
    this.setInitialSwitchState();
    this.control.value = this.currentOption().value;
    this.setFocusHint(this.currentOption().hint);

    watch(
      () => this.control.value,
      (_) => {
        this.setInitialSwitchState();
      }
    );
  }

  currentOption(): SwitchOption {
    if (this.options.right.selected) {
      return this.options.right;
    }
    return this.options.left;
  }

  setInitialSwitchState(): void {
    this.options.left.selected = this.options.left.value === this.control.value || this.control.value === null;
    this.options.right.selected = this.options.right.value === this.control.value && !this.options.left.selected;
  }

  onSwitch(side: 'left' | 'right' | undefined) {
    if (side) {
      if (this.options[side].value !== this.control.value) {
        this.options.left.selected = side === 'left';
        this.options.right.selected = side === 'right';
        this.control.value = this.options[side].value;
        this.setFocusHint(this.options[side].hint);
      }
    }
  }

  onKeydown(event: KeyboardEvent): void {
    let side: 'left' | 'right' | undefined;
    if (event.code === 'Enter') {
      side = this.value === 'left' ? 'right' : 'left';
    } else if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
      side = event.code === 'ArrowLeft' && this.value !== 'left' ? 'left' : 'right';
    }
    this.onSwitch(side);
  }
}
