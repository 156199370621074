import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-546d98fb")
const _hoisted_1 = {
  ref: "logoNeighbourDesktop",
  class: "intro-logo-neighbour",
  style: {"align-self":"flex-start"}
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "intro__button" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_button = _resolveComponent("notus-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["intro mb-4 mb-md-5", {container: !_ctx.inner}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", {
          class: "title title--small mb-1 flex-shrink-10",
          ref: "logoNeighbourMobile",
          style: {"align-self":"flex-start"}
        }, _toDisplayString(_ctx.$t('section.intro.title')), 513)
      ]),
      _createElementVNode("div", {
        class: "intro__description mb-3",
        innerHTML: _ctx.$t('section.intro.description')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_notus_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToContact())),
          class: "px-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('section.intro.button')), 1)
          ]),
          _: 1
        })
      ])
    ], 512)
  ], 2))
}