
import {Options, Vue} from 'vue-class-component';
import {Prop, Emit} from 'vue-property-decorator';
import NotusLink from '@/app/modules/shared/components/link/link.component.vue';
import NotusLangSwitcher from '@/app/modules/shared/components/language-switcher/language-switcher.component.vue';
import NotusConfirmModal from '@/app/modules/shared/components/confirm-modal/confirm-modal.component.vue';
import {ConfirmModalOut} from '@/app/modules/shared/components/confirm-modal/confirm-modal.types';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {mapActions} from 'vuex';
import {HINT_ACTIONS} from '@/store/modules/HintModule/actions';
import {ActionTypes as FormActionTypes} from '@/store/modules/FormModule/actions/action-types';
import {ActionTypes} from '@/store/modules/ProgressFormModule/actions/action-types';

@Options({
  name: 'NotusHeader',
  components: {
    NotusSvgIcon,
    NotusConfirmModal,
    NotusLink,
    NotusLangSwitcher,
  },
  methods: {
    ...mapActions('hint', [HINT_ACTIONS.toggleMobileVisibility]),
    ...mapActions('form', [FormActionTypes.clearInvalids]),
    ...mapActions('formProgress', [ActionTypes.prevStep]),
    onBackClicked() {
      this[HINT_ACTIONS.toggleMobileVisibility](false);
      this[ActionTypes.prevStep]();
      this[FormActionTypes.clearInvalids]();
    },
  },
})
export default class NotusHeader extends Vue {
  @Prop(String) readonly partnerLogoUrl: string | undefined;
  @Prop({default: true}) readonly shallAskConfirmaton!: boolean;
  @Prop({default: false}) showBackButton!: boolean;
  @Prop({default: 'general.navigation.close'}) copyBackButton!: string;
  @Prop({default: false}) noBg!: boolean;
  private showModal = false;
  public i18nHidden = process.env.VUE_APP_I18N_HIDDEN === 'true';

  public notusConfirmModalConfig = {
    icon: true,
    message: 'general.close.message',
    subMessage: 'general.close.sub-message',
    contact: 'general.close.contact',
    yesText: 'general.close.stay',
    noText: 'general.close.leave',
    mustChoose: false,
  };

  @Emit()
  closeClicked(): boolean {
    return true;
  }

  onCloseHandler = (): void => {
    if (this.shallAskConfirmaton) {
      this.showModal = true;
    } else {
      this.closeClicked();
    }
  };

  onConfirmModal = (data: ConfirmModalOut) => {
    if (!data.isConfirm) {
      this.closeClicked();
    }
    this.showModal = false;
  };
}
