
  import {Options, Vue} from 'vue-class-component';
  import {RepresentativeExample as NotusRepresentativeExample} from '@/app/modules/shared/interface/offer.response.interface';
  import {Prop} from 'vue-property-decorator';

  @Options({
    name: 'RepresentativeExample',
    data() {
      return {
        active: false,
      }
    }
  })

  export default class RepresentativeExample extends Vue {
    @Prop({default: null}) representativeExample?: NotusRepresentativeExample;
  }
