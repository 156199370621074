import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2a6a4092")
const _hoisted_1 = { class: "cta-interested container" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center cta-interested__content" }
const _hoisted_3 = { class: "title title--small my-4 mb-3 mx-0" }
const _hoisted_4 = { class: "cta-interested_description lh-28" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_notus_button = _resolveComponent("notus-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_notus_svg_icon, { iconType: "look-up" }),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('section.cta-interested.title.' + (_ctx.offer !== null ? 'specific' : _ctx.hasNoOffers ? 'noOffers' : 'general'))), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('section.cta-interested.description.' + (_ctx.offer !== null ? 'specific' : _ctx.hasNoOffers ? 'noOffers' : 'general'))), 1),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClicked())),
        class: "d-inline-block mt-3 mt-md-4 cursor-pointer",
        tabindex: "0",
        role: "button"
      }, [
        _createVNode(_component_notus_button, {
          class: "px-4",
          size: "large",
          theme: "link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('section.cta-interested.button.' + (_ctx.hasNoOffers ? 'noOffers' : 'general'))), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_notus_svg_icon, {
          class: "icon rotated d-inline-block",
          iconType: "arrow_top_rounded"
        })
      ])
    ])
  ]))
}