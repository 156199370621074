
import FormElementSimple from '@/app/modules/shared/components/FormElementSimple';
import {Options} from 'vue-class-component';
import {SelectGroup} from '@/app/modules/shared/components/form-dropdown/select-group.interface';
import {Prop} from 'vue-property-decorator';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import NotusFormCheckedIcon from '@/app/modules/shared/components/form-checked-icon/form-checked-icon.component.vue';
import {RadioOptionWithAction} from '../form-radiobutton-group/radio-option.interface';
import {watch} from '@vue/runtime-core';
import {isArray} from 'lodash';

@Options({
  name: 'NotusFormDropdown',
  components: {
    NotusSvgIcon,
    NotusFormCheckedIcon,
  },
})
export default class NotusFormDropdown extends FormElementSimple {
  @Prop({default: []}) groups!: SelectGroup[];
  @Prop({default: 'general.select.none-value-label'}) noneValueLabel!: string;
  @Prop({default: false}) multiple!: boolean;
  @Prop({default: null}) description!: string | null;
  @Prop({default: false}) withAllOption!: boolean;
  @Prop({default: false}) isInline!: boolean;
  labels: string[] = [];
  hasGroups = true;
  isCollapsed = false;
  showOnly = 1;
  allSelected = false;
  preventCloseOnOuterClicked = false;

  get isNotEmptySelects(): boolean {
    return (this.control?.value as Array<string | number>)?.length > 0;
  }

  created(): void {
    this.hasGroups = this.isHasGroups();
    this.updateGroups();
    this.updateGroupsLabels();
    if (this.withAllOption) {
      this.updateAllSelected();
    }

    watch(() => this.isCollapsed, this.setScrollViewWidth);
  }

  isHasGroups(): boolean {
    return !((this.groups.length === 1 || this.groups.length === 0) && !(this.groups[0]?.name && this.groups[0].name !== ''));
  }

  onOutsideClick(): void {
    if (!this.preventCloseOnOuterClicked) {
      this.isCollapsed = false;
    }
  }

  updateGroups(): void {
    this.groups.map((group) => {
      group.options.map((option) => {
        if (this.control?.value) {
          if (isArray(this.control?.value)) {
            option.selected = (this.control?.value as Array<string | number>).includes(option.value as string | number);
          } else {
            option.selected = this.control?.value === option.value;
          }
        }
      });
    });
  }

  toggleAllOption() {
    this.allSelected = !this.allSelected;

    this.setAllSelected(this.allSelected);
    if (this.allSelected) {
      const values: string[] = [];

      this.groups.forEach((group) => {
        group.options.forEach((option) => {
          values.push(option.value as string);
        });
      });
      this.control.value = values;
    } else {
      this.control.value = [];
    }
    this.updateGroupsLabels();
  }

  selectOption(option: RadioOptionWithAction): void {
    const isSelected = !option.selected;

    this.preventCloseOnOuterClicked = true;
    setTimeout(() => {
      this.preventCloseOnOuterClicked = false;
    }, 100);
    if (!this.multiple) {
      this.isCollapsed = false;
    }

    if (isSelected) {
      if (this.multiple) {
        (this.control.value as Array<number | string>)?.push(option.value as string | number);
      } else {
        this.setAllSelected(false);
        option.selected = isSelected;
        this.control.value = option.value as string | number;
      }
    } else {
      if (this.multiple) {
        const indexInArray: number | null = this.findIndexInArrayIfExists(option.value as string | number);

        if (indexInArray !== null) {
          (this.control.value as Array<string | number>).splice(indexInArray, 1);
        }
      }
    }

    if (this.withAllOption) {
      this.updateAllSelected();
    }
    this.updateGroupsLabels();
    this.$forceUpdate();
  }

  toggleDropdown(): void {
    this.isCollapsed = !this.isCollapsed;
    this.preventCloseOnOuterClicked = true;
    setTimeout(() => {
      this.preventCloseOnOuterClicked = false;
    }, 100);
  }

  labelsShortcut(): string | null {
    if (this.labels.length <= this.showOnly) {
      return `<span class='d-inline-block text-truncate ${this.multiple ? 'v-truncated-128' : ''}'>${this.labels
        .map((label) => this.$t(label))
        .join(',')}</span>`;
    }

    if (this.labels.length > this.showOnly) {
      const othersCount: number = [...new Set(this.labels)].length - this.showOnly;
      return `<span class='d-inline-block text-truncate ${this.multiple ? 'v-truncated-128' : ''}'>${this.labels
        .slice(0, this.showOnly)
        .map((label) => this.$t(label))
        .join()}</span> +${othersCount}`;
    }

    return null;
  }

  updateGroupsLabels(): void {
    this.labels = [];
    (this.groups as SelectGroup[]).forEach((group) => {
      group.options.forEach((option) => {
        if (option.selected) {
          this.labels.push(option.label);
        }
      });
    });
  }

  setScrollViewWidth() {
    setTimeout(() => {
      if (this.$refs.scrollView && this.isCollapsed) {
        let scrollHeight = 0;
        const listElements = [...(this.$refs.scrollView as HTMLElement).querySelectorAll('label.dropdown__checkbox-label')];
        listElements.forEach((element, i) => {
          if (i < 5) {
            scrollHeight +=
              (element as HTMLElement).offsetHeight + (i === 4 || i === listElements.length - 1 || !this.multiple ? 0 : 24);
          }
        });
        (this.$refs.scrollView as HTMLElement).style.height = `${scrollHeight}px`;
      }
    });
  }

  private findIndexInArrayIfExists(value: string | number): number | null {
    let indexInArray: number | null = null;
    (this.control.value as Array<number | string>).forEach((inArrayValue: string | number, i: number) => {
      if (inArrayValue === value) {
        indexInArray = i;
      }
    });
    return indexInArray;
  }

  private updateAllSelected(): void {
    let isAllSelected = true;
    this.groups.forEach((group) => {
      isAllSelected = isAllSelected && group.options.every((option) => option.selected);
    });
    this.allSelected = isAllSelected;
  }

  private setAllSelected(value: boolean) {
    this.groups.map((group) => {
      group.options.map((option) => {
        option.selected = value;
      });
    });
  }
}
