import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {KEY_0_COMMISSION} from '../../FormModule/state';
import {FiltersType} from '../state';

export default function filterOffer(
  offer: OfferResponse,
  filteringParameters: FiltersType,
  simulate0Commision?: boolean
): boolean {
  // const fitBankName =
  // filteringParameters.creditors === 'ALL' || (filteringParameters.creditors as string[]).includes(offer.product.bankName);
  const fitInterestRateType = filteringParameters.interestRateTypes === offer.simulation.interestRate.type;
  const fitInstalmentType = filteringParameters.instalmentType === offer.simulation.installmentType;
  const fitCommission0Percent =
    filteringParameters[KEY_0_COMMISSION] || simulate0Commision
      ? offer.simulationSummary.initialCosts.COMMISSION.value === 0
      : true;
  // const fitLifeInsurance =
  //   filteringParameters.lifeInsurance === 'ALL' ||
  //   (filteringParameters.lifeInsurance === 'YES'
  //     ? offer.simulationSummary.initialCosts.LIFE_INSURANCE !== undefined
  //     : offer.simulationSummary.initialCosts.LIFE_INSURANCE === undefined);
  // const fitEco =
  //   filteringParameters.eco === 'ALL' ||
  //   (filteringParameters.eco === 'YES' ? offer.product.eco === true : offer.product.eco === false);
  // const fitRor =
  //   filteringParameters.ror === 'ALL' ||
  //   (filteringParameters.ror === 'YES'
  //     ? offer.simulation.subproducts.ROR !== undefined
  //     : offer.simulation.subproducts.ROR === undefined);
  // const fitPaymentOnAccount =
  //   filteringParameters.paymentOnAccount === 'ALL' ||
  //   (filteringParameters.paymentOnAccount === 'YES'
  //     ? offer.simulation.subproducts.PAYMENT_ON_ACCOUNT !== undefined
  //     : offer.simulation.subproducts.PAYMENT_ON_ACCOUNT === undefined);
  // const fitDebitCard =
  //   filteringParameters.debitCard === 'ALL' ||
  //   (filteringParameters.debitCard === 'YES'
  //     ? offer.simulation.subproducts.DEBIT_CARD !== undefined
  //     : offer.simulation.subproducts.DEBIT_CARD === undefined);
  // const fitCreditCard =
  //   filteringParameters.creditCard === 'ALL' ||
  //   (filteringParameters.creditCard === 'YES'
  //     ? offer.simulation.subproducts.CREDIT_CARD !== undefined
  //     : offer.simulation.subproducts.CREDIT_CARD === undefined);

  // console.log({
  //     eko: filteringParameters.eco,
  //     a: fitBankName && fitInterestRateType && fitInstalmentType && fitCommission0Percent && fitEco && fitLifeInsurance && fitRor && fitPaymentOnAccount && fitDebitCard && fitCreditCard,
  //     filter: filteringParameters.commission0Percent,
  //     installmentType: offer.simulation.installmentType,
  //     fitBankName,
  //     fitInterestRateType,
  //     fitInstalmentType,
  //     fitCommission0Percent,
  //     fitLifeInsurance,
  //     fitEco,
  //     fitRor,
  //     fitPaymentOnAccount,
  //     fitDebitCard,
  //     fitCreditCard,
  // })
  return fitInterestRateType && fitInstalmentType && fitCommission0Percent;
}
