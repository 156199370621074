
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Options({
  name: 'NotusHero',
})
export default class NotusHero extends Vue {
  @Prop({default: false}) inner!: boolean;
}
