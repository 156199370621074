import {FormApplicant} from '../modules/page-form/enums/form-applicant.enum';
import {FormPurpose} from '../modules/page-form/enums/form-purpose.enum';
import {FormObjectKey} from '../modules/page-form/models/form-title';
import {DynamicFormControlName} from '../models/dynamic-form-control-name.enum';

export type ValidationType = {
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  loanPercentage?: number;
  required?: boolean;
};

export const DYNAMIC_FORM_VALIDATIONS: {
  [key in DynamicFormControlName | string]?: ValidationType | {[key in FormObjectKey]?: ValidationType};
} = {
  [DynamicFormControlName.purpose]: {required: true},
  [DynamicFormControlName['purposes-value']]: {
    [FormPurpose.PURCHASE]: {min: 10 * 1000, max: 60 * 1000 * 1000},
    [FormPurpose.BUILD]: {min: 10 * 1000, max: 60 * 1000 * 1000},
    [FormPurpose.RENOVATION]: {min: 10 * 1000, max: 20 * 1000 * 1000},
    [FormPurpose.MORTGAGE]: {min: 10 * 1000, max: 20 * 1000 * 1000},
    [FormPurpose.CONSOLIDATION]: {min: 10 * 1000, max: 20 * 1000 * 1000},
    [FormPurpose.REFINANCING]: {min: 10 * 1000, max: 20 * 1000 * 1000},
  },
  [DynamicFormControlName['purposes-value-land']]: {required: true, min: 10 * 1000, max: 60 * 1000 * 1000},
  [DynamicFormControlName['loan-value']]: {
    [FormPurpose.PURCHASE]: {min: 10 * 1000, max: 20 * 1000 * 1000, loanPercentage: 0.9},
    [FormPurpose.BUILD]: {min: 10 * 1000, max: 20 * 1000 * 1000, loanPercentage: 0.9},
    [FormPurpose.RENOVATION]: {min: 10 * 1000, max: 20 * 1000 * 1000, loanPercentage: 0.9},
    [FormPurpose.MORTGAGE]: {min: 10 * 1000, max: 20 * 1000 * 1000, loanPercentage: 0.8},
    [FormPurpose.CONSOLIDATION]: {min: 10 * 1000, max: 20 * 1000 * 1000, loanPercentage: 0.8},
    [FormPurpose.REFINANCING]: {min: 10 * 1000, max: 20 * 1000 * 1000, loanPercentage: 0.9},
  },
  [DynamicFormControlName['loan-period']]: {
    [FormPurpose.PURCHASE]: {min: 1, max: 35},
    [FormPurpose.BUILD]: {min: 1, max: 35},
    [FormPurpose.RENOVATION]: {min: 1, max: 35},
    [FormPurpose.MORTGAGE]: {min: 1, max: 30},
    [FormPurpose.CONSOLIDATION]: {min: 1, max: 35},
    [FormPurpose.REFINANCING]: {min: 1, max: 35},
  },
  // [DynamicFormControlName['purposes-finishing-value']]: {required: true, min: 10 * 1000, max: 20 * 1000 * 1000},
  [DynamicFormControlName['property_details_area']]: {required: true, min: 20, max: 20 * 1000},
  [DynamicFormControlName['property_details_location']]: {required: true},
  [DynamicFormControlName.property_value]: {required: true, min: 10 * 1000, max: 60 * 1000 * 1000},
  [DynamicFormControlName['age']]: {required: true, min: 18, max: 99},
  [DynamicFormControlName.income_UoPType]: {required: true},
  [DynamicFormControlName.income_taxType]: {required: true},
  [DynamicFormControlName.income_otherType]: {required: true},
  [DynamicFormControlName['income_monthly_value']]: {required: true, min: 0, max: 500 * 1000},
  [DynamicFormControlName['income_monthly_value']]: {required: true, min: 0, max: 500 * 1000},
  [DynamicFormControlName['income_monthly_value']]: {required: true, min: 0, max: 500 * 1000},
  [DynamicFormControlName['outcome_people']]: {
    [FormApplicant.SOLO]: {required: true, min: 1, max: 15},
    [FormApplicant.MULTI]: {required: true, min: 2, max: 15},
  },
  [DynamicFormControlName['outcome_mortgages']]: {required: true, min: 0, max: 6},
  [DynamicFormControlName['outcome_monthly']]: {
    [FormApplicant.SOLO]: {required: true, min: 0, max: 50 * 1000},
    [FormApplicant.MULTI]: {required: true, min: 0, max: 100 * 1000},
  },
  [DynamicFormControlName['outcome_limit_card']]: {required: true, min: 0, max: 1 * 1000 * 1000},
  [DynamicFormControlName['outcome_limit_account']]: {required: true, min: 0, max: 1 * 1000 * 1000},
  lead_name: {},
  lead_phone: {required: true, minLength: 9},
};
