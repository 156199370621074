export const CONFIG = {
  cacheTime: 24 * 60 * 60 * 1000,
  social: {
    facebook: 'https://www.facebook.com/NOTUSFinanse/',
    twitter: 'https://twitter.com/NotusFinanse',
    youtube: 'https://www.youtube.com/channel/UC3vHx-sHMYNNKmMBBFyM1sA',
    linkedin: 'https://pl.linkedin.com/company/notus-finanse',
  },
  comparision: {
    maxElements: 8,
  },
};
