
import {Options, Vue} from 'vue-class-component';
import NotusBenefits from '@/app/modules/page-contact/components/benefits/benefits.component.vue';
import NotusBenefitsSlider from '@/app/modules/page-contact/components/benefits/benefits-slider.component.vue';
import NotusForm from '@/app/modules/page-contact/components/form/form.component.vue';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {namespace} from 'vuex-class';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {ActionTypes as FormActionTypes} from '@/store/modules/FormModule/actions/action-types';
import useIntersectionObserver from '@/app/modules/shared/services/useIntersectionObserver';
import { OffersActionTypes } from '@/store/modules/OffersModule/actions/action-types';

const offersModule = namespace('offers');
const formModule = namespace('form');
@Options({
  name: 'NotusContactUsSection',
  components: {
    NotusBenefits,
    NotusBenefitsSlider,
    NotusForm,
    NotusButton,
    NotusSvgIcon,
  },
})
export default class NotusContactUsSection extends Vue {
  @offersModule.Action(OffersActionTypes.setDetailedOffer) setDetailedOffer!: any;
  @formModule.Getter(GetterTypes.leadWasSend) leadWasSend!: any;
  @formModule.Getter(GetterTypes.leadSentSuccess) leadSentSuccess!: any;
  @formModule.Action(FormActionTypes.setLeadWasSend) setLeadWasSend!: any;
  @formModule.Action(FormActionTypes.clearLeadForm) clearLeadForm!: any;
  @formModule.Action(FormActionTypes.setLeadStatus) setLeadStatus!: any;
  @formModule.Action(FormActionTypes.loadPartnerConsents) loadPartnerConsents!: any;
  @formModule.Getter(GetterTypes.proposalId) proposalId?: string;

  successTime = 0;

  mounted(): void {
    useIntersectionObserver.observeElement(this.$refs.observer as HTMLElement, this.intersectCallback);

    setTimeout(() => {
      this.loadPartnerConsents(this.$route.params.hash);
    }, 500);
  }

  intersectCallback(entry: IntersectionObserverEntry): void {
    const ratio = entry.intersectionRatio;
    if (ratio === 0 && (this.successTime < (Date.now() - 1000))) {
      this.setLeadWasSend(false);
      this.setLeadStatus(false);
    }
  }

  scrollTop(): void {
    const scrollableElement = document.querySelector('.notus-container') as HTMLElement;

    scrollableElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.setDetailedOffer();
    this.setLeadWasSend(false);
    this.setLeadStatus(false);
  }
  scrollToSuccess(): void {
    this.successTime = Date.now();
    (this.$refs.successRef as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
