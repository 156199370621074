
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import NotusButtonScrollTop from '@/app/modules/shared/components/button-scroll-top/button-scroll-top.component.vue';
import {watch} from '@vue/runtime-core';

@Options({
  name: 'NotusExpandPage',
  components: {
    NotusButtonScrollTop,
  },
})
export default class NotusExpandPage extends Vue {
  @Prop({default: false}) opened!: boolean;
  showScrollButton = false;
  scrollbarSize = 0;

  toggle() {
    this.opened = !this.opened;
  }

  hasScrollBar() {
    return document.documentElement.scrollHeight > document.documentElement.clientHeight;
  }

  toggleOverflow(scroll: boolean) {
    if (!this.hasScrollBar()) return false;
    const el = document.documentElement;
    const scrollbarSize = this.getScrollbarSize();
    el.style.overflow = scroll ? 'hidden' : 'auto';
    el.style.marginRight = scroll && scrollbarSize ? `${scrollbarSize}px` : '0';
  }

  getScrollbarSize() {
    if (!this.scrollbarSize) {
      const scrollDiv = document.createElement('div');
      scrollDiv.classList['add']('default-scrollbar');
      scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
      document.body.appendChild(scrollDiv);
      this.scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      scrollDiv.classList['remove']('default-scrollbar');
    }
    return this.scrollbarSize;
  }
  created() {
    watch(
      () => this.opened,
      (newValue) => {
        this.toggleOverflow(newValue);
      }
    );
  }

  mounted() {
    this.showScrollButton = true;
    this.getScrollbarSize();
  }
  beforeUnmount() {
    this.toggleOverflow(false);
  }
}
