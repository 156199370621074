import {FormApplicant} from '@/app/modules/page-form/enums/form-applicant.enum';
import {DEFAULT_SWITCH_OPTIONS} from '@/app/modules/page-form/interfaces/form-control-switch.interface';
import {AvailableInputs} from '@/app/modules/page-form/models/form-dynamic-page';
import {FormControlSlider} from '@/app/modules/page-form/interfaces/form-control-slider.interface';
import {FormControlSelect} from '@/app/modules/page-form/interfaces/form-control-select.interface';
import {FormControlInput} from '@/app/modules/page-form/interfaces/form-control-input.interface';
import {
  DEFAULT_INCOME_TYPES,
  FormIncomeDGType,
  FormIncomeOtherType,
  FormIncomeType,
  FormIncomeUoPType,
} from '@/app/modules/page-form/enums/form-income-type.enum';
import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';
import {DYNAMIC_FORM_AVAILABLE_PROPERTY} from '../models/dynamic-form-available-property.enum';
import {DynamicFormControlName} from '../models/dynamic-form-control-name.enum';
import {LoanBegin} from '@/app/modules/page-form/enums/loan-begin.enum';
import {DEFAULT_PERIODS, Period} from '@/app/modules/page-form/enums/period.enum';
import {AvailableInputType} from '../modules/page-form/interfaces/form-control-abstract.interface';
import {FORM_CONFIG} from './form.config';

export const PROPERTY_TYPE_OPTIONS = {
  [FormPurpose.PURCHASE]: [
    DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.SUMMER_HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUSINESS_PREMISES,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUILDING_PLOT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.RECREATIONAL_LAND,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.AGRICULTURAL_PARCEL,
  ],
  [FormPurpose.BUILD]: [DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE, DYNAMIC_FORM_AVAILABLE_PROPERTY.SUMMER_HOUSE],
  [FormPurpose.RENOVATION]: [
    DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.SUMMER_HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUSINESS_PREMISES,
  ],
  [FormPurpose.MORTGAGE]: [
    DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.SUMMER_HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUSINESS_PREMISES,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUILDING_PLOT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.RECREATIONAL_LAND,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.AGRICULTURAL_PARCEL,
  ],
  [FormPurpose.CONSOLIDATION]: [
    DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.SUMMER_HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUSINESS_PREMISES,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUILDING_PLOT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.RECREATIONAL_LAND,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.AGRICULTURAL_PARCEL,
  ],
  [FormPurpose.REFINANCING]: [
    DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.SUMMER_HOUSE,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUSINESS_PREMISES,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.BUILDING_PLOT,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.RECREATIONAL_LAND,
    DYNAMIC_FORM_AVAILABLE_PROPERTY.AGRICULTURAL_PARCEL,
  ],
};

export const FORM_INPUTS_CONFIG: {[key in DynamicFormControlName]: AvailableInputs} = {
  [DynamicFormControlName.purpose]: {
    type: 'options-cards-with-icon',
    formControlName: DynamicFormControlName.purpose,
    defaultValue: FormPurpose.PURCHASE,
    options: [
      {
        label: 'dynamic.form.page.purpose.input.purpose.options.PURCHASE.label',
        value: FormPurpose.PURCHASE,
        icon: 'purpose_purchase',
        hint: {
          title: 'dynamic.form.page.purpose.input.purpose.options.PURCHASE.hint.title',
          description: 'dynamic.form.page.purpose.input.purpose.options.PURCHASE.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.purpose.input.purpose.options.BUILD.label',
        value: FormPurpose.BUILD,
        icon: 'purpose_build',
        hint: {
          title: 'dynamic.form.page.purpose.input.purpose.options.BUILD.hint.title',
          description: 'dynamic.form.page.purpose.input.purpose.options.BUILD.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.purpose.input.purpose.options.RENOVATION.label',
        value: FormPurpose.RENOVATION,
        icon: 'purpose_renovation',
        hint: {
          title: 'dynamic.form.page.purpose.input.purpose.options.RENOVATION.hint.title',
          description: 'dynamic.form.page.purpose.input.purpose.options.RENOVATION.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.purpose.input.purpose.options.MORTGAGE.label',
        value: FormPurpose.MORTGAGE,
        icon: 'purpose_mortgage',
        hint: {
          title: 'dynamic.form.page.purpose.input.purpose.options.MORTGAGE.hint.title',
          description: 'dynamic.form.page.purpose.input.purpose.options.MORTGAGE.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.purpose.input.purpose.options.CONSOLIDATION.label',
        value: FormPurpose.CONSOLIDATION,
        icon: 'purpose_consolidation',
        hint: {
          title: 'dynamic.form.page.purpose.input.purpose.options.CONSOLIDATION.hint.title',
          description: 'dynamic.form.page.purpose.input.purpose.options.CONSOLIDATION.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.purpose.input.purpose.options.REFINANCING.label',
        value: FormPurpose.REFINANCING,
        icon: 'purpose_refinancing',
        hint: {
          title: 'dynamic.form.page.purpose.input.purpose.options.REFINANCING.hint.title',
          description: 'dynamic.form.page.purpose.input.purpose.options.REFINANCING.hint.description',
        },
      },
    ],
  },
  [DynamicFormControlName['purposes-value']]: {
    type: 'input' as AvailableInputType,
    formControlName: DynamicFormControlName['purposes-value'],
    validationPrefix: 'dynamic.form.value.input.purposes-value.validation.',
    label: {
      [FormPurpose.PURCHASE]: 'dynamic.form.page.value.input.PURCHASE.label',
      [FormPurpose.BUILD]: 'dynamic.form.page.value.input.BUILD.label',
      [FormPurpose.RENOVATION]: 'dynamic.form.page.value.input.RENOVATION.label',
      [FormPurpose.MORTGAGE]: 'dynamic.form.page.value.input.MORTGAGE.label',
      [FormPurpose.CONSOLIDATION]: 'dynamic.form.page.value.input.CONSOLIDATION.label',
      [FormPurpose.REFINANCING]: 'dynamic.form.page.value.input.REFINANCING.label',
    },
    suffix: 'PLN',
    description: {
      [FormPurpose.RENOVATION]: 'dynamic.form.page.value.input.RENOVATION.description',
    },
    placeholder: getPlaceholder(DynamicFormControlName['purposes-value']),
    inputLength: 6,
    limitSigns: 8,
    classes: 'col-12 col-lg-8',
    dependsOn: [DynamicFormControlName.purpose],
  } as FormControlInput<DynamicFormControlName>,
  [DynamicFormControlName['purposes-value-land']]: {
    type: 'input',
    formControlName: DynamicFormControlName['purposes-value-land'],
    title: {
      [FormPurpose.BUILD]: 'dynamic.form.page.value_land.input.purposes-value-land.BUILD.title',
      [FormPurpose.RENOVATION]: 'dynamic.form.page.value_land.input.purposes-value-land.RENOVATION.title',
    },
    label: {
      [FormPurpose.BUILD]: 'dynamic.form.page.value_land.input.purposes-value-land.BUILD.label',
      [FormPurpose.RENOVATION]: 'dynamic.form.page.value_land.input.purposes-value-land.RENOVATION.label',
    },
    description: {
      [FormPurpose.BUILD]: 'dynamic.form.page.value_land.input.purposes-value-land.BUILD.description',
    },
    suffix: 'PLN',
    inputLength: 8,
    limitSigns: 8,
    classes: 'col-12 col-lg-8',
  } as FormControlInput<DynamicFormControlName>,
  [DynamicFormControlName['loan-value']]: {
    type: 'input',
    formControlName: DynamicFormControlName['loan-value'],
    title: {
      [FormPurpose.PURCHASE]: 'dynamic.form.page.loan_value.input.loan-value.PURCHASE.title',
      [FormPurpose.BUILD]: 'dynamic.form.page.loan_value.input.loan-value.BUILD.title',
      [FormPurpose.RENOVATION]: 'dynamic.form.page.loan_value.input.loan-value.RENOVATION.title',
    },
    label: {
      [FormPurpose.PURCHASE]: 'dynamic.form.page.loan_value.input.loan-value.PURCHASE.label',
      [FormPurpose.BUILD]: 'dynamic.form.page.loan_value.input.loan-value.BUILD.label',
      [FormPurpose.RENOVATION]: 'dynamic.form.page.loan_value.input.loan-value.RENOVATION.label',
      [FormPurpose.MORTGAGE]: 'dynamic.form.page.loan_value.input.loan-value.MORTGAGE.label',
      [FormPurpose.CONSOLIDATION]: 'dynamic.form.page.loan_value.input.loan-value.CONSOLIDATION.label',
      [FormPurpose.REFINANCING]: 'dynamic.form.page.loan_value.input.loan-value.REFINANCING.label',
    },
    description: {
      [FormPurpose.PURCHASE]: 'dynamic.form.page.loan_value.input.loan-value.PURCHASE.description',
      [FormPurpose.BUILD]: 'dynamic.form.page.loan_value.input.loan-value.BUILD.description',
      [FormPurpose.RENOVATION]: 'dynamic.form.page.loan_value.input.loan-value.RENOVATION.description',
      [FormPurpose.MORTGAGE]: 'dynamic.form.page.loan_value.input.loan-value.MORTGAGE.description',
      [FormPurpose.CONSOLIDATION]: 'dynamic.form.page.loan_value.input.loan-value.CONSOLIDATION.description',
      [FormPurpose.REFINANCING]: 'dynamic.form.page.loan_value.input.loan-value.REFINANCING.description',
    },
    suffix: 'PLN',
    inputLength: 7,
    limitSigns: 8,
    placeholder: getPlaceholder(DynamicFormControlName['loan-value']),
    classes: 'col-12 col-lg-8',
    dependsOn: [DynamicFormControlName['purposes-value'], DynamicFormControlName.purpose],
  } as FormControlInput<DynamicFormControlName>,
  [DynamicFormControlName['loan-period']]: {
    type: 'slider' as AvailableInputType,
    formControlName: DynamicFormControlName['loan-period'],
    placeholder: getPlaceholder(DynamicFormControlName['loan-period']),
    min: 1,
    max: 35,
    title: 'dynamic.form.page.loan_period.input.loan_period.title',
    length: 1,
    limitSigns: 2,
    suffix: 'general.suffix.year',
    classes: 'col-12 col-md-6 col-lg-4 col-xl-3',
    dependsOn: [DynamicFormControlName.purpose],
  } as FormControlSlider<DynamicFormControlName>,
  [DynamicFormControlName['loan-begin']]: {
    type: 'select' as AvailableInputType,
    formControlName: DynamicFormControlName['loan-begin'],
    defaultValue: [LoanBegin.ASAP],
    label: 'dynamic.form.page.loan_begin.input.load-begin.label',
    classes: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        options: [
          {
            label: 'dynamic.form.page.loan_begin.input.loan-begin.options.ASAP.label',
            value: LoanBegin.ASAP,
          },
          {
            label: 'dynamic.form.page.loan_begin.input.loan-begin.options.UP_TO_MONTH.label',
            value: LoanBegin.UP_TO_MONTH,
          },
          {
            label: 'dynamic.form.page.loan_begin.input.loan-begin.options.UP_TO_FEW_MONTHS.label',
            value: LoanBegin.UP_TO_FEW_MONTHS,
          },
          {
            label: 'dynamic.form.page.loan_begin.input.loan-begin.options.UP_TO_HALF_YEAR.label',
            value: LoanBegin.UP_TO_HALF_YEAR,
          },
          {
            label: 'dynamic.form.page.loan_begin.input.loan-begin.options.OVER_HALF_YEAR.label',
            value: LoanBegin.OVER_HALF_YEAR,
          },
          {
            label: 'dynamic.form.page.loan_begin.input.loan-begin.options.TBD.label',
            value: LoanBegin.TBD,
          },
        ],
      },
    ],
  } as FormControlSelect<DynamicFormControlName>,
  [DynamicFormControlName.property_type]: {
    type: 'options-cards',
    formControlName: DynamicFormControlName.property_type,
    defaultValue: {
      [FormPurpose.PURCHASE]: DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT.value,
      [FormPurpose.BUILD]: DYNAMIC_FORM_AVAILABLE_PROPERTY.HOUSE.value,
      [FormPurpose.RENOVATION]: DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT.value,
      [FormPurpose.MORTGAGE]: DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT.value,
      [FormPurpose.CONSOLIDATION]: DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT.value,
      [FormPurpose.REFINANCING]: DYNAMIC_FORM_AVAILABLE_PROPERTY.FLAT.value,
    },
    options: PROPERTY_TYPE_OPTIONS,
  },
  [DynamicFormControlName['property_value']]: {
    type: 'input',
    formControlName: DynamicFormControlName['property_value'],
    title: 'dynamic.form.page.property_value.input.property_value.title',
    label: 'dynamic.form.page.property_value.input.property_value.label',
    suffix: 'PLN',
    inputLength: 9,
    limitSigns: 8,
  } as FormControlInput<DynamicFormControlName>,
  // [DynamicFormControlName['purposes-finishing']]: {
  //   type: 'switch',
  //   formControlName: DynamicFormControlName['purposes-finishing'],
  //   defaultValue: false,
  //   title: 'dynamic.form.page.property_finishing.input.purposes-finishing.title',
  //   classes: 'col-12 col-md-6',
  //   options: {
  //     left: {
  //       ...JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS.left)),
  //       hint: {
  //         title: 'dynamic.form.page.property_finishing.input.purposes-finishing.options.left.hint.title',
  //         description: 'dynamic.form.page.property_finishing.input.purposes-finishing.options.left.hint.description',
  //       }
  //     },
  //     right: {
  //       ...JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS.right)),
  //       hint: {
  //         title: 'dynamic.form.page.property_finishing.input.purposes-finishing.options.right.hint.title',
  //         description: 'dynamic.form.page.property_finishing.input.purposes-finishing.options.right.hint.description',
  //       }
  //     }
  //   }
  // } as FormControlSwitch<DynamicFormControlName>,
  // [DynamicFormControlName['purposes-finishing-value']]: {
  //   type: 'input',
  //   formControlName: DynamicFormControlName['purposes-finishing-value'],
  //   title: 'dynamic.form.page.property_finishing.input.purposes-finishing-value.title',
  //   label: 'dynamic.form.page.property_finishing.input.purposes-finishing-value.label',
  //   inputLength: 6,
  //   classes: 'col-12 col-md-6',
  //   suffix: 'PLN',
  //   limitSigns: 8,
  //   description: 'dynamic.form.page.property_finishing.input.purposes-finishing-value.description',
  //   showIfPropertyIsTrue: DynamicFormControlName['purposes-finishing'].toString(),
  // } as FormControlInput<DynamicFormControlName>,
  [DynamicFormControlName['property_details_area']]: {
    type: 'input',
    formControlName: DynamicFormControlName['property_details_area'],
    title: 'dynamic.form.page.property_details.input.property_details_area.title',
    inputLength: 3,
    limitSigns: 5,
    classes: 'col-12 col-md-6 col-lg-4',
    suffix: 'm\u00b2',
  } as FormControlInput<DynamicFormControlName>,
  [DynamicFormControlName['property_details_location']]: {
    type: 'location' as AvailableInputType,
    formControlName: DynamicFormControlName['property_details_location'],
    title: 'dynamic.form.page.property_details.input.property_details_location.title',
    label: 'dynamic.form.page.property_details.input.property_details_location.label',
    classes: 'col-12 col-md-6 col-lg-4',
  },
  [DynamicFormControlName.loan_applicant_multi]: {
    type: 'switch',
    formControlName: DynamicFormControlName.loan_applicant_multi,
    defaultValue: false,
    classes: 'col-12 col-md-4 col-lg-3',
    options: JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS)),
  },
  [DynamicFormControlName['age']]: {
    type: 'input' as AvailableInputType,
    formControlName: DynamicFormControlName['age'],
    validationPrefix: 'dynamic.form.page.age.input.age.validation.',
    label: 'dynamic.form.page.age.input.age.label',
    suffix: 'general.suffix.year',
    inputLength: 1,
    limitSigns: 2,
    classes: 'col-12 col-md-6 col-lg-4',
  } as FormControlInput<DynamicFormControlName>,
  [DynamicFormControlName.income_type]: {
    type: 'options-cards',
    formControlName: DynamicFormControlName.income_type,
    defaultValue: FormIncomeType.UoP,
    options: JSON.parse(JSON.stringify(DEFAULT_INCOME_TYPES)),
  },
  [DynamicFormControlName.income_UoPType]: {
    type: 'options-cards',
    formControlName: DynamicFormControlName.income_UoPType,
    defaultValue: FormIncomeUoPType.PERMANENT_CONTRACT,
    options: [
      {
        label: 'dynamic.form.page.income_UoPType.options.PERMANENT_CONTRACT',
        value: FormIncomeUoPType.PERMANENT_CONTRACT,
      },
      {
        label: 'dynamic.form.page.income_UoPType.options.FIXED_TERM_CONTRACT',
        value: FormIncomeUoPType.FIXED_TERM_CONTRACT,
      },
      {
        label: 'dynamic.form.page.income_UoPType.options.PERMANENT_CONTRACT_FROM_ABROAD',
        value: FormIncomeUoPType.PERMANENT_CONTRACT_FROM_ABROAD,
      },
      {
        label: 'dynamic.form.page.income_UoPType.options.FIXED_TERM_CONTRACT_FROM_ABROAD',
        value: FormIncomeUoPType.FIXED_TERM_CONTRACT_FROM_ABROAD,
      },
    ],
  },
  [DynamicFormControlName.income_UoPMaternity]: {
    type: 'switch',
    formControlName: DynamicFormControlName.income_UoPMaternity,
    defaultValue: false,
    title: 'dynamic.form.page.income_UoPType.inputs.income_UoPMaternity.title',
    classes: 'col-12 col-md-6',
    options: JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS)),
  },
  [DynamicFormControlName.income_taxType]: {
    type: 'options-cards',
    formControlName: DynamicFormControlName.income_taxType,
    defaultValue: FormIncomeDGType.SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING,
    options: [
      {
        label: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING.label',
        value: FormIncomeDGType.SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING,
        hint: {
          title: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING.hint.title',
          description: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_TAX_CARD.label',
        value: FormIncomeDGType.SOLE_PROPRIETORSHIP_TAX_CARD,
        hint: {
          title: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_TAX_CARD.hint.title',
          description: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_TAX_CARD.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_LUMP_SUM.label',
        value: FormIncomeDGType.SOLE_PROPRIETORSHIP_LUMP_SUM,
        hint: {
          title: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_LUMP_SUM.hint.title',
          description: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_LUMP_SUM.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_FROM_ABROAD.label',
        value: FormIncomeDGType.SOLE_PROPRIETORSHIP_FROM_ABROAD,
        hint: {
          title: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_FROM_ABROAD.hint.title',
          description: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_FROM_ABROAD.hint.description',
        },
      },
      {
        label: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_FULL_ACCOUNTING.label',
        value: FormIncomeDGType.SOLE_PROPRIETORSHIP_FULL_ACCOUNTING,
        hint: {
          title: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_FULL_ACCOUNTING.hint.title',
          description: 'dynamic.form.page.income_taxType.options.SOLE_PROPRIETORSHIP_FULL_ACCOUNTING.hint.description',
        },
      },
    ],
  },
  [DynamicFormControlName.income_otherType]: {
    type: 'select',
    formControlName: DynamicFormControlName.income_otherType,
    label: 'dynamic.form.page.income_otherType.label',
    classes: 'col-12 col-md-7 col-lg-5',
    defaultValue: [FormIncomeOtherType.CONTRACT_OF_MANDATE],
    options: [
      {
        options: [
          {
            label: 'dynamic.form.page.income_otherType.options.SAILOR',
            value: FormIncomeOtherType.SAILOR,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.DRIVER',
            value: FormIncomeOtherType.DRIVER,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.SOLDIER',
            value: FormIncomeOtherType.SOLDIER,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.CONTRACT',
            value: FormIncomeOtherType.CONTRACT,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.NOMINATION',
            value: FormIncomeOtherType.NOMINATION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.RETIREMENT_PENSION',
            value: FormIncomeOtherType.RETIREMENT_PENSION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.RETIREMENT_INCOME_FROM_ABROAD',
            value: FormIncomeOtherType.RETIREMENT_INCOME_FROM_ABROAD,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.CONTRACT_OF_MANDATE',
            value: FormIncomeOtherType.CONTRACT_OF_MANDATE,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.CONTRACT_OF_COMMISSION',
            value: FormIncomeOtherType.CONTRACT_OF_COMMISSION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.RENT',
            value: FormIncomeOtherType.RENT,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.DISABILITY_PENSION',
            value: FormIncomeOtherType.DISABILITY_PENSION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.TERM_DISABILITY_PENSION',
            value: FormIncomeOtherType.TERM_DISABILITY_PENSION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.FAMILY_ALLOWANCE_PENSION',
            value: FormIncomeOtherType.FAMILY_ALLOWANCE_PENSION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.TERM_FAMILY_ALLOWANCE_PENSION',
            value: FormIncomeOtherType.TERM_FAMILY_ALLOWANCE_PENSION,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.PENSION_INCOME_FROM_ABROAD',
            value: FormIncomeOtherType.PENSION_INCOME_FROM_ABROAD,
          },
          {
            label: 'dynamic.form.page.income_otherType.options.TERM_PENSION_INCOME_FROM_ABROAD',
            value: FormIncomeOtherType.TERM_PENSION_INCOME_FROM_ABROAD,
          },
        ],
      },
    ],
  },
  [DynamicFormControlName.income_monthly_value]: {
    type: 'input',
    formControlName: DynamicFormControlName.income_monthly_value,
    label: 'dynamic.form.page.income_monthly.inputs.income_monthly_value.label',
    inputLength: 6,
    limitSigns: 6,
    classes: 'col-12 col-md-6 col-lg-5 col-xl-4',
    fitToContainer: true,
  },
  [DynamicFormControlName.income_monthly_currency]: {
    type: 'select',
    formControlName: DynamicFormControlName.income_monthly_currency,
    defaultValue: ['PLN'],
    label: 'dynamic.form.page.income_monthly.inputs.income_monthly_currency.label',
    description: 'dynamic.form.page.income_monthly.inputs.income_monthly_currency.description',
    classes: 'col-12 col-md-6 col-lg-7 col-xl-6',
    options: [
      {
        options: [
          {
            label: 'PLN',
            value: 'PLN',
          },
          {
            label: 'CHF',
            value: 'CHF',
          },
          {
            label: 'GBP',
            value: 'GBP',
          },
          {
            label: 'EUR',
            value: 'EUR',
          },
          {
            label: 'NOR',
            value: 'NOR',
          },
          {
            label: 'USD',
            value: 'USD',
          },
          {
            label: 'SEK',
            value: 'SEK',
          },
          {
            label: 'Inna',
            value: 'Other',
          },
        ],
      },
    ],
  },
  [DynamicFormControlName.income_time_from]: {
    type: 'select',
    formControlName: DynamicFormControlName.income_time_from,
    classes: 'col-12 col-md-6 col-xl-4',
    title: 'dynamic.form.page.income_time.inputs.income_time_from.title',
    label: 'dynamic.form.page.income_time.inputs.income_time_from.label',
    defaultValue: [Period.OVER_TWO_YEARS],
    options: [
      {
        options: JSON.parse(JSON.stringify(DEFAULT_PERIODS)),
      },
    ],
  },
  [DynamicFormControlName.income_time_to]: {
    type: 'select',
    formControlName: DynamicFormControlName.income_time_to,
    classes: 'col-12 col-md-6 col-xl-4',
    title: 'dynamic.form.page.income_time.inputs.income_time_to.title',
    label: 'dynamic.form.page.income_time.inputs.income_time_to.label',
    defaultValue: [Period.OVER_TWO_YEARS],
    options: [
      {
        options: [
          ...JSON.parse(JSON.stringify(DEFAULT_PERIODS)),
          {
            value: Period.TEMP,
            label: 'general.period.TEMP.label',
          },
        ],
      },
    ],
  },
  [DynamicFormControlName.income_others]: {
    type: 'switch',
    formControlName: DynamicFormControlName.income_others,
    defaultValue: false,
    classes: 'col-12 col-md-4 col-lg-3',
    options: JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS)),
  },
  [DynamicFormControlName.outcome_people]: {
    type: 'slider',
    formControlName: DynamicFormControlName.outcome_people,
    title: 'dynamic.form.page.outcome_people.inputs.outcome_people.title',
    classes: 'col-12 col-md-6 col-lg-4',
    max: 15,
    min: 1,
    step: 1,
    limitSigns: 2,
    defaultValue: {
      [FormApplicant.SOLO]: 1,
      [FormApplicant.MULTI]: 2,
    },
  },
  [DynamicFormControlName.outcome_monthly]: {
    type: 'input',
    formControlName: DynamicFormControlName.outcome_monthly,
    title: {
      [FormApplicant.SOLO]: 'dynamic.form.page.outcome_monthly.inputs.outcome_monthly.title.SOLO',
      [FormApplicant.MULTI]: 'dynamic.form.page.outcome_monthly.inputs.outcome_monthly.title.MULTI',
    },
    label: 'dynamic.form.page.outcome_monthly.inputs.outcome_monthly.label',
    suffix: 'PLN',
    defaultValue: '0',
    limitSigns: 5,
    classes: 'col-12 col-sm-10 col-md-8 col-xl-8',
    inputLength: 5,
    description: {
      [FormApplicant.MULTI]: 'dynamic.form.page.outcome_monthly.inputs.outcome_monthly.decription.MULTI',
    },
  },
  [DynamicFormControlName.outcome_mortgages]: {
    type: 'input',
    formControlName: DynamicFormControlName.outcome_mortgages,
    defaultValue: '0',
    inputLength: 2,
    limitSigns: 1,
    classes: 'col-12 col-sm-10 col-md-8 col-xl-8',
    title: 'dynamic.form.page.outcome_mortgages.inputs.outcome_mortgages.title',
    description: {
      [FormApplicant.MULTI]: 'dynamic.form.page.outcome_mortgages.inputs.outcome_mortgages.decription.MULTI',
    },
  },
  [DynamicFormControlName.outcome_limit_card]: {
    type: 'input',
    formControlName: DynamicFormControlName.outcome_limit_card,
    label: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_card.label',
    suffix: 'PLN',
    inputLength: 8,
    limitSigns: 7,
    classes: 'col-12 col-sm-11 col-md-9 col-xl-8 pb-4 pb-md-5',
    defaultValue: '0',
    hint: {
      title: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_card.hint.title',
      description: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_card.hint.description',
    },
    description: {
      [FormApplicant.MULTI]: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_card.decription.MULTI',
    },
  },
  [DynamicFormControlName.outcome_limit_account]: {
    type: 'input',
    formControlName: DynamicFormControlName.outcome_limit_account,
    label: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_account.label',
    suffix: 'PLN',
    inputLength: 8,
    limitSigns: 7,
    classes: 'col-12 col-sm-11 col-md-9 col-xl-8',
    defaultValue: '0',
    hint: {
      title: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_account.hint.title',
      description: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_account.hint.description',
    },
    description: {
      [FormApplicant.MULTI]: 'dynamic.form.page.outcome_limits.inputs.outcome_limit_account.decription.MULTI',
    },
  },
  [DynamicFormControlName.EU_citizien_self]: {
    type: 'switch',
    formControlName: DynamicFormControlName.EU_citizien_self,
    defaultValue: true,
    classes: 'col-12 col-md-8',
    options: JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS)),
  },
  [DynamicFormControlName.EU_citizien_multi]: {
    type: 'switch',
    formControlName: DynamicFormControlName.EU_citizien_multi,
    defaultValue: true,
    title: 'dynamic.form.page.EU_citizien.inputs.EU_citizien.title.FRIEND',
    classes: 'col-12 col-md-8',
    options: JSON.parse(JSON.stringify(DEFAULT_SWITCH_OPTIONS)),
  },
  [DynamicFormControlName['break-line']]: {
    type: 'break-line' as AvailableInputType,
    formControlName: DynamicFormControlName['break-line'],
  },
};

function getPlaceholder(property: DynamicFormControlName): string {
  return FORM_CONFIG.defaultValues[property.toString()];
}
