import {ProgressState} from '../state';
import {ProgressStep} from '@/store/modules/ProgressModule/state/progress-step.interface';
// eslint-disable-next-line @typescript-eslint/ban-types
export enum ProgressGettersTypes {
  'currentStep' = 'CURRENT_STEP',
}

export default {
  [ProgressGettersTypes.currentStep]: (state: ProgressState): ProgressStep | undefined => {
    return state.currentStep;
  },
};
