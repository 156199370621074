import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2ff469f6")
const _hoisted_1 = { class: "page-contact_description" }
const _hoisted_2 = { class: "page-contact_description--cta" }
const _hoisted_3 = { class: "fw-medium fs-14 mb-5" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["href"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_button = _resolveComponent("notus-button")!
  const _component_VTooltip = _resolveComponent("VTooltip")!
  const _component_notus_form_checkbox = _resolveComponent("notus-form-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_notus_button, {
        size: "large",
        class: "page-contact_button",
        disabled: !_ctx.isFormLeadValid,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submitClicked()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getLeadContent('FORM_BUTTON', 'page.contact.send_CTA')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.$t('page.contact.info_1')) + " ", 1),
        _createVNode(_component_VTooltip, {
          triggers: ['click'],
          offset: [-100, 12],
          placement: "top",
          autoHide: true,
          theme: 'center-tooltip'
        }, {
          popper: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.$t('page.contact.popup_1')
            }, null, 8, _hoisted_5)
          ]),
          default: _withCtx(() => [
            _createElementVNode("a", {
              class: "fw-semi-bold color-accent",
              href: "#",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($event.preventDefault()))
            }, _toDisplayString(_ctx.$t('page.contact.read_more')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.$t('page.contact.info_2')) + " ", 1),
        _createVNode(_component_VTooltip, {
          triggers: ['click'],
          offset: [0, 12],
          placement: "top",
          autoHide: true,
          theme: 'center-tooltip'
        }, {
          popper: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.$t('page.contact.popup_2')
            }, null, 8, _hoisted_7)
          ]),
          default: _withCtx(() => [
            _createElementVNode("a", {
              class: "fw-semi-bold color-accent",
              href: "#",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($event.preventDefault()))
            }, _toDisplayString(_ctx.$t('page.contact.read_more')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('page.contact.info_3')) + " ", 1),
        _createElementVNode("a", {
          class: "fw-semi-bold color-accent",
          href: _ctx.rulesUrl,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('page.contact.info_3_link')), 9, _hoisted_8)
      ]),
      _createVNode(_component_notus_form_checkbox, {
        customForm: _ctx.formLead,
        simpleStyle: true,
        controlName: "lead_constent",
        class: "fs-14"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('page.contact.form.consent')), 1)
        ]),
        _: 1
      }, 8, ["customForm"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadPartnerConstents, (partnerConsent) => {
        return (_openBlock(), _createElementBlock("div", {
          key: partnerConsent.name
        }, [
          _createVNode(_component_notus_form_checkbox, {
            customForm: _ctx.formLead['partner_constents'],
            simpleStyle: true,
            controlName: partnerConsent.name,
            class: "fs-14"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(partnerConsent.content), 1)
            ]),
            _: 2
          }, 1032, ["customForm", "controlName"])
        ]))
      }), 128))
    ])
  ]))
}