export enum MutationType {
  addPassedStep = 'ADD_PASSED_STEP',
  removeLastPassedStep = 'REMOVE_LAST_PASSED_STEP',
  setCurrentPage = 'SET_CURRENT_PAGE',
  setLastIncomeParams = 'SET_LAST_INCOME_PARAMS',
  setPurpose = 'SET_PURPOSE',
  setMultiApplicant = 'SET_MULTI_APPLICANT',
  setShallRefresh = 'SET_SHALL_REFRESH',
  resetState = 'RESET_STATE',
}
