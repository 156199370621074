import Vuex, {createLogger} from 'vuex';
import VuexPersistence from 'vuex-persist';
import {ProgressStore} from '@/store/modules/ProgressModule';
import {FormStore} from '@/store/modules/FormModule';
import {HintStore} from '@/store/modules/HintModule';
import {ProgressFormStore} from '@/store/modules/ProgressFormModule';
import {OffersStore} from '@/store/modules/OffersModule';
import {CompareStore} from '@/store/modules/CompareModule';
import {CONFIG} from '@/app/config/config';
import {INIT_COMPARE_STATE} from './modules/CompareModule/state';
import {INIT_HINT_STATE} from './modules/HintModule/state';
import {INIT_FORM_STATE} from './modules/FormModule/state';
import {INIT_FORM_PROGRESS_STATE} from './modules/ProgressFormModule/state';
import {INIT_PROGRESS_STATE} from './modules/ProgressModule/state';
import {INIT_OFFERS_STATE} from './modules/OffersModule/state';
import {ActionTypes} from './modules/FormModule/actions/action-types';

const VUEX_STORAGE_NAME = 'notus-form';
const VUEX_STORAGE_UPDATED_TIME = 'notus-form-time';
const VUEX_STORAGE: Storage = window.localStorage;

// const debug = process.env.NODE_ENV !== 'production';
const debug = false;
const plugins = debug ? [createLogger({})] : [];

const lastCorrectFormState = {
  form: INIT_FORM_STATE,
};

plugins.push(
  new VuexPersistence({
    storage: VUEX_STORAGE,
    key: VUEX_STORAGE_NAME,
    reducer: (state: any) => {
      storeUpdated();
      return state;
    },
  }).plugin
);

export const store = new Vuex.Store({
  modules: {
    compare: CompareStore,
    hint: HintStore,
    form: FormStore,
    formProgress: ProgressFormStore,
    progress: ProgressStore,
    offers: OffersStore,
  },
  plugins,
});

export function clearLeadForm() {
  store.dispatch(`form/${ActionTypes.clearLeadForm}`);
}

export function storeCorrectForm() {
  lastCorrectFormState.form = JSON.parse(JSON.stringify((store.state as any).form));
}

export function revertCorrectForm() {
  const newState = store.state as any;
  newState.form = lastCorrectFormState.form;
  store.replaceState(newState);
}

export function clearStore(onlyIfTimeouted?: boolean) {
  if (onlyIfTimeouted) {
    const lastUpdateTime = VUEX_STORAGE.getItem(VUEX_STORAGE_UPDATED_TIME);

    if (!lastUpdateTime || (lastUpdateTime && parseInt(lastUpdateTime) + CONFIG.cacheTime < Date.now())) {
      resetStore();
    }
  } else {
    resetStore();
  }
}

function storeUpdated() {
  VUEX_STORAGE.setItem(VUEX_STORAGE_UPDATED_TIME, Date.now().toString());
}

function resetStore() {
  VUEX_STORAGE.removeItem(VUEX_STORAGE_NAME);
  const newState: any = {};

  newState.compare = INIT_COMPARE_STATE;
  newState.hint = INIT_HINT_STATE;
  newState.form = INIT_FORM_STATE;
  newState.formProgress = INIT_FORM_PROGRESS_STATE;
  newState.progress = INIT_PROGRESS_STATE;
  newState.offers = {...INIT_OFFERS_STATE};

  store.replaceState(newState);
}
