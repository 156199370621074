import {FormControlAbstract} from './form-control-abstract.interface';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {FormHint} from './form-hint.interface';

export const DEFAULT_SWITCH_OPTIONS = {
  left: {
    label: 'general.switch.NO.label',
    value: false,
    selected: true,
  },
  right: {
    label: 'general.switch.YES.label',
    value: true,
  },
};
export interface SwitchOption {
  value: string | boolean | number;
  label: string;
  selected?: boolean; //if none of options selected left will be select
  hint?: FormHint;
}

export interface SwitchOptions {
  left: SwitchOption;
  right: SwitchOption;
}
export interface FormControlSwitch<T extends string | DynamicFormControlName> extends FormControlAbstract {
  type: 'switch';
  formControlName: T;
  options?: SwitchOptions;
}
