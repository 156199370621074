import {AvailableCustomInputs, CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {MutationTree} from 'vuex';
import {FormControlOptions} from '..';
import {Form, FormInputProperty, FormState} from '../state';
import {MutationType} from './mutation-types';

export type Mutations = {
  [MutationType.addControl](state: FormState, options: FormControlOptions): void;
  [MutationType.setValue](state: FormState, options: FormControlOptions): void;
  [MutationType.updateIncomeGroup](state: FormState, groupName: string): void;
  [MutationType.overrideIncomeGroup](state: FormState, newIncomeForms: {[key: string]: Form}): void;
  [MutationType.resetValidation](state: FormState): void;
  [MutationType.setValidation](state: FormState, options: {controlName: DynamicFormControlName; isInvalid: boolean}): void;
  [MutationType.setLeadWasSend](state: FormState, newState: boolean): void;
  [MutationType.setLeadForm](state: FormState, leadForm: CustomFormGroup): void;
  [MutationType.setLeadPrefix](state: FormState, leadPrefix: string): void;
  [MutationType.setLeadSendSuccess](state: FormState, newState: boolean): void;
  [MutationType.setCommission0Available](state: FormState, newState: boolean): void;
};

export const mutations: MutationTree<FormState> & Mutations = {
  [MutationType.addControl](state, options: FormControlOptions) {
    const input: FormInputProperty = {
      value: options.value,
      dirty: false,
      validations: options.validations,
      validationPrefix: options.validationPrefix,
      dependsOn: options.dependsOn,
    };
    if (options.isIncomeForm) {
      if (!state.currentIncomeGroup) {
        throw Error('No currentIncomeGroup defined!');
      }
      state.incomeForms[state.currentIncomeGroup][options.inputName] = input;
    } else {
      state.form[options.inputName] = input;
    }
    return options.value;
  },
  [MutationType.setValue](state, options: FormControlOptions) {
    let input: FormInputProperty | undefined;
    if (options.isIncomeForm) {
      if (!state.currentIncomeGroup) {
        throw Error('No currentIncomeGroup defined!');
      }
      input = state.incomeForms[state.currentIncomeGroup][options.inputName];
    } else {
      input = state.form[options.inputName];
    }
    if (input) {
      input.value = options.value;
    }
  },
  [MutationType.updateIncomeGroup](state, groupName) {
    if (!state.incomeForms[groupName]) {
      state.incomeForms[groupName] = {};
    }
    state.currentIncomeGroup = groupName;
  },
  [MutationType.overrideIncomeGroup](state, newIncomeForms) {
    state.incomeForms = newIncomeForms;
  },
  [MutationType.resetValidation](state) {
    state.hasErrors = null;
  },
  [MutationType.setLeadWasSend](state, newState = true) {
    state.leadWasSend = newState;
  },
  [MutationType.setLeadForm](state, formLead) {
    state.formLead = formLead;
  },
  [MutationType.setLeadPartnerConstents](state, leadPartnerConstents) {
    state.leadPartnerConstents = leadPartnerConstents;
  },
  [MutationType.setLeadPrefix](state, leadPrefix) {
    state.formLead['lead_prefix'] = {
      value: leadPrefix,
      type: 'input',
      formControlName: '',
    } as AvailableCustomInputs;
  },
  [MutationType.setLeadSendSuccess](state, newState) {
    state.leadSendSuccess = newState;
  },
  [MutationType.setProposalId](state, newProposalId) {
    state.proposalId = newProposalId;
  },
  [MutationType.setCommission0Available](state, newState) {
    state.commission0Available = newState;
  },
  [MutationType.setValidation](state, {controlName, isInvalid}: {controlName: DynamicFormControlName; isInvalid: boolean}) {
    if (state.hasErrors === null) {
      state.hasErrors = {};
    }
    state.hasErrors[controlName] = isInvalid;
  },
};
