export enum OffersMutationType {
  setLoading = 'SET_LOADING',
  setOffers = 'SET_OFFERS',
  setAllOffers = 'SET_OFFERS_ALL',
  setReloadPurpose = 'SET_RELOAD_PURPOSE',
  setDetailedOffer = 'SET_DETAILED_OFFER',
  setOrderType = 'SET_ORDER_TYPE',
  setFilters = 'SET_FILTERS',
  setInitialized = 'SET_INITIALIZED',
  resetState = 'RESET_OFFERS_STATE',
}
