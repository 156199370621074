import {MutationTree} from 'vuex';
import {INIT_FORM_PROGRESS_STATE, ProgressFormState} from '../state';
import {MutationType} from './mutation-types';
import {ProgressFormStageParams, ProgressFormStep} from '@/app/modules/page-form/interfaces/progress-form-step.interface';
import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';

export type Mutations = {
  [MutationType.addPassedStep](state: ProgressFormState, value: ProgressFormStep): void;
  [MutationType.removeLastPassedStep](state: ProgressFormState): void;
  [MutationType.setCurrentPage](state: ProgressFormState, value: ProgressFormStep): void;
  [MutationType.setLastIncomeParams](state: ProgressFormState, lastIncomeParams: ProgressFormStageParams): void;
  [MutationType.setPurpose](state: ProgressFormState, purpose: FormPurpose): void;
  [MutationType.setMultiApplicant](state: ProgressFormState, isMultiApplicants: boolean): void;
  [MutationType.setShallRefresh](state: ProgressFormState, shallReset: boolean): void;
  [MutationType.resetState](state: ProgressFormState): void;
};

export const mutations: MutationTree<ProgressFormState> & Mutations = {
  [MutationType.addPassedStep](state, nextStep) {
    state.stepsPassed.push(nextStep);
  },
  [MutationType.setCurrentPage](state, nextStep) {
    window.scrollTo(0, 0);
    state.currentStep = nextStep;
  },
  [MutationType.removeLastPassedStep](state) {
    state.stepsPassed.pop();
  },
  [MutationType.setLastIncomeParams](state, lastIncomeParams: ProgressFormStageParams) {
    state.lastIncomeParams = lastIncomeParams;
  },
  [MutationType.setPurpose](state, purpose: FormPurpose) {
    state.purpose = purpose;
  },
  [MutationType.setMultiApplicant](state, isMultiApplicants: boolean) {
    state.isMultiApplicants = isMultiApplicants;
  },
  [MutationType.setShallRefresh](state, shallReset = true) {
    state.shallReloadOffersOnNextPage = shallReset;
  },
  [MutationType.resetState](state) {
    state.currentStep = INIT_FORM_PROGRESS_STATE.currentStep;
    state.isMultiApplicants = INIT_FORM_PROGRESS_STATE.isMultiApplicants;
    state.lastIncomeParams = INIT_FORM_PROGRESS_STATE.lastIncomeParams;
    state.purpose = INIT_FORM_PROGRESS_STATE.purpose;
    state.shallReloadOffersOnNextPage = INIT_FORM_PROGRESS_STATE.shallReloadOffersOnNextPage;
    state.stepsPassed = INIT_FORM_PROGRESS_STATE.stepsPassed;
  },
};
