import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {FormApplicant} from '@/app/modules/page-form/enums/form-applicant.enum';
import {ActionContext, ActionTree} from 'vuex';
import {FormControlOptions} from '..';
import filterOffer from '../../OffersModule/actions/action-filterOffer';
import {OffersState} from '../../OffersModule/state';
import {genIncomeGroupName} from '../getters';
import {Mutations} from '../mutations';
import {MutationType} from '../mutations/mutation-types';
import {createLeadForm, Form, FormState, KEY_0_COMMISSION, PartnerConsent} from '../state';
import {ActionTypes} from './action-types';
import {APIService} from '@/app/modules/shared/services/api.sevice';

type ActionAugments = Omit<ActionContext<FormState, FormState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.initFormIfNotExists](context: ActionAugments, options?: FormControlOptions): void;
  [ActionTypes.setCurrentIncomeGroup](context: ActionAugments, options: {applicant: FormApplicant; idx: number}): void;
  [ActionTypes.overrideIncomeGroup](context: ActionAugments, newIncomeForms: {[key: string]: Form}): void;
  [ActionTypes.resetValidation](context: ActionAugments): void;
  [ActionTypes.setValidation](context: ActionAugments, options: {controlName: DynamicFormControlName; isInvalid: boolean}): void;
  [ActionTypes.clearInvalids](context: ActionAugments): void;
  [ActionTypes.clearLeadForm](context: ActionAugments): void;
  [ActionTypes.setLeadWasSend](context: ActionAugments, newState: boolean): void;
  [ActionTypes.setLeadStatus](context: ActionAugments, newState: boolean): void;
  [ActionTypes.setCommission0Available](context: ActionAugments, newState: boolean): void;
  [ActionTypes.calculateCommission0Available](context: ActionAugments, newState: boolean): void;
};

export const actions: ActionTree<FormState, FormState> & Actions = {
  [ActionTypes.initFormIfNotExists]({commit, state}, options: FormControlOptions) {
    let input;
    if (options.isIncomeForm) {
      if (!state.currentIncomeGroup) {
        throw Error('No currentIncomeGroup defined!');
      }
      input = state.incomeForms[state.currentIncomeGroup][options.inputName];
    } else {
      input = state.form[options.inputName];
    }
    if (input === undefined) {
      commit(MutationType.addControl, options);
    } else if (options.forceValue) {
      commit(MutationType.setValue, options);
    }
  },
  [ActionTypes.setCurrentIncomeGroup]({commit}, {applicant, idx}) {
    const groupName = genIncomeGroupName(applicant, idx);
    commit(MutationType.updateIncomeGroup, groupName);
  },
  [ActionTypes.overrideIncomeGroup]({commit}, newIncomeForms) {
    commit(MutationType.overrideIncomeGroup, newIncomeForms);
  },
  [ActionTypes.resetValidation]({commit}) {
    commit(MutationType.resetValidation);
  },
  [ActionTypes.clearInvalids]({commit, state, getters}) {
    if (state.hasErrors) {
      Object.keys(state.hasErrors).forEach((key) => {
        if (getters.validationsByName(key) !== null) {
          state.form[key] = undefined;
        }
      });
    }
    commit(MutationType.resetValidation);
  },
  [ActionTypes.clearLeadForm]({commit}) {
    commit(MutationType.setLeadForm, createLeadForm());
  },
  [ActionTypes.setValidation]({commit}, {controlName, isInvalid}: {controlName: DynamicFormControlName; isInvalid: boolean}) {
    commit(MutationType.setValidation, {controlName, isInvalid});
  },
  [ActionTypes.setLeadWasSend]({commit}, newState) {
    commit(MutationType.setLeadWasSend, newState);
  },
  [ActionTypes.setLeadStatus]({commit}, newState) {
    commit(MutationType.setLeadSendSuccess, newState);
  },
  [ActionTypes.setProposalId]({commit}, newProposalId) {
    commit(MutationType.setProposalId, newProposalId);
  },
  [ActionTypes.setLeadPrefix]({commit}, newPrefix) {
    commit(MutationType.setLeadPrefix, newPrefix);
  },
  [ActionTypes.loadPartnerConsents]({commit, state}, partnerHash) {
    if (!state.leadPartnerConstentsLoaded) {
      partnerHash = !partnerHash ? process.env['VUE_APP_LEAD_DEFAULT_PARTNER'] : partnerHash;

      APIService.get(`${process.env.VUE_APP_API_URL}lead/consents/${partnerHash}`).subscribe((response) => {
        commit(MutationType.setLeadPartnerConstents, response as Array<PartnerConsent>);
      });
    }
  },
  [ActionTypes.setCommission0Available]({commit, state}, isCommission0Available) {
    commit(MutationType.setCommission0Available, isCommission0Available);
    if (!isCommission0Available) {
      state.formFilters[KEY_0_COMMISSION].value = false;
    }
  },
  [ActionTypes.calculateCommission0Available]({dispatch, rootState}) {
    const offerState = (rootState as any).offers as OffersState;
    const offers = offerState.allOffers;
    const filters = offerState.filters;
    const filteredOffers = offers?.filter((offer) => filterOffer(offer, filters, true));
    dispatch(ActionTypes.setCommission0Available, filteredOffers?.length ? true : false);
  },
};
