import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-53195458")
const _hoisted_1 = { class: "language-switcher" }
const _hoisted_2 = {
  key: 0,
  class: "fw-semi-bold fs-16 color-primary"
}
const _hoisted_3 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `locale-${locale}`
      }, [
        index
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "|"))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          onClick: ($event: any) => (_ctx.setLang(locale)),
          class: _normalizeClass(["fw-semi-bold fs-16", [_ctx.$i18n.locale === locale ? 'color-accent' : 'color-primary cursor-pointer']])
        }, _toDisplayString(locale), 11, _hoisted_3)
      ], 64))
    }), 128))
  ]))
}