import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2e6e06f6")
const _hoisted_1 = { class: "form-input" }
const _hoisted_2 = ["for"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_form_input_field = _resolveComponent("notus-form-input-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.controlName,
      class: "input-label"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_2),
    _createVNode(_component_notus_form_input_field, {
      customForm: _ctx.customForm,
      controlName: _ctx.controlName,
      validationPrefix: _ctx.validationPrefix,
      suffix: _ctx.suffix,
      placeholder: _ctx.placeholder,
      description: _ctx.description,
      inputLength: _ctx.inputLength,
      inputDataType: _ctx.inputDataType,
      fitToContainer: _ctx.fitToContainer,
      isIncomeForm: _ctx.isIncomeForm,
      fieldHint: _ctx.fieldHint,
      limitSigns: _ctx.limitSigns,
      refresher: _ctx.refresher
    }, null, 8, ["customForm", "controlName", "validationPrefix", "suffix", "placeholder", "description", "inputLength", "inputDataType", "fitToContainer", "isIncomeForm", "fieldHint", "limitSigns", "refresher"])
  ]))
}