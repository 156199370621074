import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1d097300")
const _hoisted_1 = { class: "section-why" }
const _hoisted_2 = { class: "row section-why-content" }
const _hoisted_3 = { class: "section-why-icon" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.benefitsList, (benefit) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-12 col-md-6 col-lg-3 mt-4 mt-lg-0",
          key: benefit
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_notus_svg_icon, {
              iconType: benefit.icon,
              cover: false
            }, null, 8, ["iconType"])
          ]),
          _createElementVNode("h4", {
            class: "fw-bold fs-20 fs-md-24 lh-27 lh-md-36 color-primary mt-4 mb-0",
            innerHTML: _ctx.$t(benefit.title)
          }, null, 8, _hoisted_4),
          _createElementVNode("p", {
            class: "fw-medium fs-18 lh-24 lh-md-27 color-font mt-3",
            innerHTML: _ctx.$t(benefit.text)
          }, null, 8, _hoisted_5)
        ]))
      }), 128))
    ])
  ]))
}