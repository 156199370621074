import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {DYNAMIC_FORM_VALIDATIONS, ValidationType} from '@/app/config/dynamic-form-validations.config';
import {FormConfigFormsKey, FORMS_DEFINITION} from '@/app/config/dynamic-form.config';
import {ValidationErrorData, ValidatorTypes} from '@/store/modules/FormModule/validators/validator-type';
import {validators} from '@/store/modules/FormModule/validators/validators';
import {FormApplicant} from '../../page-form/enums/form-applicant.enum';
import {FormPurpose} from '../../page-form/enums/form-purpose.enum';
import {AvailableInputs} from '../../page-form/models/form-dynamic-page';
import {FormObjectKey, FormTitle} from '../../page-form/models/form-title';
import filters from '@/app/modules/shared/filters';
import {FormInputProperty} from '@/store/modules/FormModule/state';
import {store} from '@/store';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {calculateMaxLoanValue} from './form-parameters.service';

export function runValidation(input: FormInputProperty): ValidationErrorData | undefined {
  if (!input.validations) {
    return;
  }

  let error;
  if (input.validations.required) {
    error = validators[ValidatorTypes.required]()(input);
    if (error) return error;
  }
  if (input.validations.loanPercentage && input.validations.max) {
    const maxValue = input.validations.max;
    const maxLoanPercentaged = input.validations.loanPercentage * calculateMaxLoanValue();

    if (maxValue > maxLoanPercentaged) {
      error = validators[ValidatorTypes.loanPercentage](input.validations.loanPercentage)(input);
    } else {
      error = validators[ValidatorTypes.maxNumber](input.validations.max)(input);
    }
    if (error) return error;
  } else {
    if (input.validations.loanPercentage) {
      error = validators[ValidatorTypes.loanPercentage](input.validations.loanPercentage)(input);
      if (error) return error;
    }
    if (input.validations.max) {
      error = validators[ValidatorTypes.maxNumber](input.validations.max)(input);
      if (error) return error;
    }
  }
  if (input.validations.min) {
    error = validators[ValidatorTypes.minNumber](input.validations.min)(input);
    if (error) return error;
  }
  if (input.validations.minLength) {
    error = validators[ValidatorTypes.minLength](input.validations.minLength)(input);
    if (error) return error;
  }
  if (input.validations.maxLength) {
    error = validators[ValidatorTypes.maxLength](input.validations.maxLength)(input);
    if (error) return error;
  }
  return;
}

export function getValidation(key: DynamicFormControlName | string): ValidationType {
  const validationDefinition: ValidationType | {[key in FormObjectKey]?: ValidationType} | undefined =
    DYNAMIC_FORM_VALIDATIONS[key];

  let validations: ValidationType | undefined;

  if (!validationDefinition) {
    return {};
  }

  const isComplexDefinition = Object.keys(validationDefinition).every((key) => {
    return key in FormPurpose || key in FormApplicant;
  });

  if (isComplexDefinition) {
    const complexValidationDefinition = validationDefinition as {[key in FormObjectKey]?: ValidationType};
    const purpose = store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.purpose) as FormPurpose;
    const multiAplicants = store.getters[`form/${GetterTypes.valueByName}`](DynamicFormControlName.loan_applicant_multi)
      ? FormApplicant.MULTI
      : FormApplicant.SOLO;

    if (complexValidationDefinition[purpose]) {
      validations = complexValidationDefinition[purpose];
    } else {
      validations = complexValidationDefinition[multiAplicants];
    }
  } else {
    validations = validationDefinition as ValidationType;
  }

  return (validations as ValidationType) || {};
}

export function getInputs(
  key: FormConfigFormsKey,
  purpose?: FormPurpose,
  multiAplicants?: FormApplicant
): Array<AvailableInputs> {
  let inputs: Array<AvailableInputs> = [];
  const inputsObj = FORMS_DEFINITION[key].inputs;
  if (inputsObj && Array.isArray(inputsObj)) {
    inputs = inputsObj;
  } else if (inputsObj && purpose) {
    const purposeInputs = inputsObj[purpose];
    if (purposeInputs) {
      inputs = purposeInputs;
    }
  }
  if (inputsObj && !Array.isArray(inputsObj) && multiAplicants && !inputs.length) {
    const applicantInputs = inputsObj[multiAplicants];
    if (applicantInputs) {
      inputs = applicantInputs;
    }
  }
  if (!inputs.length) {
    inputs = [];
  }
  return inputs;
}

export function parseFormTitleValue(title: FormTitle | undefined, purpose?: FormPurpose, multiAplicants?: boolean) {
  let purposeTitle: string | undefined;
  if (!title) {
    return '';
  }
  if (typeof title == 'string') {
    return title;
  } else {
    if (!purpose) {
      // purpose = this.formService.form.value[DynamicFormControlName.purpose];
    }

    if (purpose) {
      purposeTitle = title[purpose];
      if (!purposeTitle) {
        // if (multiAplicants === undefined) {
        //   multiAplicants = this.formService.form.value[DynamicFormControlName.loan_applicant_multi];
        // }
        purposeTitle = title[multiAplicants ? FormApplicant.MULTI : FormApplicant.SOLO];
      }
    }
  }
  return purposeTitle || '';
}

export function reformatTextValue(value: string): string {
  const pCL = 'a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒąćęłńóśźżĄĆĘŁŃÓŚŹŻ';
  return value.replace(new RegExp(`[^${pCL} ()-]`, 'g'), '').replace(/ {2,}/, ' '); /* eslint-disable-line */
}
export function reformatPhoneValue(value: string): string {
  return value.replace(/[^0-9-()]/, '');
}

export function reformatPLPhoneValue(value: string): string {
  const result: string[] = [];
  const filteresSigns = value
    .replace(/[^0-9]/, '')
    .split('')
    .filter((char) => char != ' ');

  filteresSigns.forEach((sign, index) => {
    result.push(sign);
    if (index % 3 === 2 && index !== filteresSigns.length - 1) {
      result.push(' ');
    }
  });
  return result.join('');
}

export function reformatNumberValue(value: string): string {
  const partials = value.replace(/\D/g, '');
  if (partials.split('').filter((char) => char != '0' && char != ' ').length === 0) {
    return partials;
  } else {
    return filters.amountFilter(partials, false);
  }
}

export function dereformatNumberValue(value: string | number | undefined): number {
  let numberValue: number;

  if (value === undefined || value === '') {
    numberValue = 0;
  } else if (typeof value !== 'number') {
    numberValue = parseInt((value as string).replace(/\s/g, ''));
  } else {
    numberValue = value;
  }
  return numberValue;
}
