import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {Form, FormValues} from '@/store/modules/FormModule/state';

export function prepareFormValues(form: Form): FormValues {
  const formValues: FormValues = {};
  Object.entries(form).forEach((entry) => {
    formValues[parseInt(entry[0] as string) as DynamicFormControlName] = entry[1]?.value;
  });
  return formValues;
}

export function prepareIncomeFormValues(incomeForms: {[key: string]: Form}): {[key: string]: FormValues} {
  const incomeFormValues: {[key: string]: FormValues} = {};

  Object.entries(incomeForms).forEach((incomeEntry) => {
    incomeFormValues[incomeEntry[0]] = {};

    Object.entries(incomeEntry[1]).forEach((entry) => {
      incomeFormValues[incomeEntry[0]][parseInt(entry[0] as string) as DynamicFormControlName] = entry[1]?.value;
    });
  });
  return incomeFormValues;
}
