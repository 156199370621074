import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {DYNAMIC_FORM_VALIDATIONS, ValidationType} from '@/app/config/dynamic-form-validations.config';
import {AlternativeType} from '@/app/models/alternative.type';
import {Location} from '@/app/modules/shared/components/form-location/location-response-type';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {
  INSTALMENT_TYPES,
  INTEREST_RATES_TYPES,
  ORDER_CRITERIA,
} from '@/app/modules/page-summary/components/summary-parameters/summary-parameters.config';

export const KEY_0_COMMISSION = 'commission0Percent';
export type AvailableFormValue = string | number | boolean | string[] | undefined | AlternativeType | Location;
export type Form = {[key in DynamicFormControlName | string]?: FormInputProperty};
export type FormValues = {[key in DynamicFormControlName]?: AvailableFormValue};

export type PartnerConsent = {
  name: string;
  content: string;
  isSelected?: boolean;
};

export const INIT_FORM_STATE: FormState = {
  form: {},
  formFilters: _createFiltersForm(),
  formSorting: _createSortingForm(),
  formLead: createLeadForm(),
  leadPartnerConstentsLoaded: false,
  incomeForms: {},
  hasErrors: null,
  commission0Available: true,
};

export type FormInputProperty = {
  value: AvailableFormValue;
  validations?: ValidationType;
  validationPrefix?: string;
  valid?: boolean;
  dirty?: boolean;
  dependsOn?: Array<DynamicFormControlName>;
};

export type FormState = {
  form: Form;
  formFilters: CustomFormGroup;
  formSorting: CustomFormGroup;
  formLead: CustomFormGroup;
  leadPartnerConstents?: Array<PartnerConsent>;
  leadPartnerConstentsLoaded: boolean;
  incomeForms: {[key: string]: Form};
  currentIncomeGroup?: string;
  hasErrors: {[key in DynamicFormControlName | string]?: boolean} | null;
  leadWasSend?: boolean;
  leadSendSuccess?: boolean;
  proposalId?: string;
  commission0Available: boolean;
};

export const state: FormState = INIT_FORM_STATE;

function _createSortingForm(): CustomFormGroup {
  return {
    orderBy: {
      type: 'options-cards',
      formControlName: 'orderBy',
      value: ORDER_CRITERIA[0].value,
    },
  };
}
function _createFiltersForm(): CustomFormGroup {
  return {
    interestRateTypes: {
      type: 'switch',
      formControlName: 'interestRateTypes',
      value: INTEREST_RATES_TYPES.right.value,
    },
    instalmentType: {
      type: 'switch',
      formControlName: 'instalmentType',
      value: INSTALMENT_TYPES.left.value,
    },
    [KEY_0_COMMISSION]: {
      type: 'checkbox',
      formControlName: 'commis.zero',
      value: false,
    },
  };
}

export function createLeadForm(): CustomFormGroup {
  return {
    lead_name: {
      type: 'input',
      value: '',
      formControlName: 'lead_name',
      length: 12,
      validations: DYNAMIC_FORM_VALIDATIONS['lead_name'] as ValidationType,
      valid: false,
    },
    lead_phone: {
      type: 'input',
      value: '',
      formControlName: 'lead_phone',
      length: 8,
      validations: DYNAMIC_FORM_VALIDATIONS['lead_phone'] as ValidationType,
      valid: false,
    },
    lead_constent: {
      type: 'checkbox',
      value: false,
      formControlName: 'lead_constent',
      valid: true,
    },
    partner_constents: {},
  };
}
