
import {defineComponent, PropType} from 'vue';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import {ConfirmModalConfig, ConfirmModalOut} from '@/app/modules/shared/components/confirm-modal/confirm-modal.types';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {scrollToContactForm} from '@/app/modules/shared/helpers/scrollToContactForm.helper';

export default defineComponent({
  name: 'NotusConfirmModal',
  components: {
    NotusButton,
    NotusSvgIcon,
  },
  props: {
    config: {
      type: Object as PropType<ConfirmModalConfig>,
    },
  },
  emits: {
    onClose: null,
    onChoose: (value: ConfirmModalOut) => value,
  },
  methods: {
    scrollToContact() {
      this.onChooseHandler(true)
      scrollToContactForm();
    },
  },
  setup(props, {emit}) {
    const onCloseHandler = () => {
      if (!props.config?.mustChoose) {
        emit('onClose');
      }
    };
    const onChooseHandler = (isConfirm: boolean) => {
      emit('onChoose', {
        isConfirm,
        data: props.config?.data,
      });
    };

    return {
      onChooseHandler,
      onCloseHandler,
    };
  },
});
