
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IconStyle} from '@/app/modules/shared/components/form-checked-icon/icon-style.types';
import {IconType} from '@/app/modules/shared/components/svg-icon/svg-icon.types';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';

@Options({
  name: 'NotusFormCheckedIcon',
  components: {
    NotusSvgIcon,
  },
})
export default class NotusFormCheckedIcon extends Vue {
  @Prop() iconStyle!: IconStyle;
  @Prop() iconType!: IconType;
  @Prop({default: false}) isSelected!: boolean;

  get classes(): string {
    return (this.iconStyle ? this.iconStyle : '') + ' ' + (this.isSelected ? 'checked-icon' : '');
  }
}
