
import {ref} from 'vue';
import {Options} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {CheckboxType} from '@/app/modules/shared/components/form-checkbox/checkbox.types';
import {IconType} from '@/app/modules/shared/components/svg-icon/svg-icon.types';
import {IconStyle} from '@/app/modules/shared/components/form-checked-icon/icon-style.types';
import NotusFormCheckedIcon from '@/app/modules/shared/components/form-checked-icon/form-checked-icon.component.vue';
import FormElementSimple from '../FormElementSimple';
import {AvailableCustomInputs} from '@/app/models/custom-form-group.interface';

@Options({
  name: 'NotusFormCheckbox',
  components: {
    NotusFormCheckedIcon: NotusFormCheckedIcon,
  },
})
export default class NotusFormCheckbox extends FormElementSimple {
  @Prop({default: false}) value!: boolean;
  @Prop({default: false}) simpleStyle!: boolean;
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: 'normal'}) type!: CheckboxType;

  get iconType(): IconType {
    return this.type === 'around-border' ? 'checked-icon-white' : 'checked-icon-primary';
  }

  get iconStyle(): IconStyle {
    return this.type === 'normal' ? 'classic-checkbox' : 'rounded-invert';
  }

  get classes(): any {
    const simpleStyles = {
      'fs-14': true,
      'lh-21': true,
      'color-secondary': true,
    };
    const standardStyles = {
      'fs-18': true,
      'fw-semi-bold': true,
      'align-items-center': true,
    };
    return {
      'checkbox--normal': this.type === 'normal',
      'checkbox--around-border': this.type === 'around-border',
      'checkbox--checked': true,
      'checkbox--disabled': this.disabled,
      ...(this.simpleStyle ? simpleStyles : standardStyles),
    };
  }

  created() {
    if (!this.control) {
      if (this.customForm) {
        this.customForm[this.controlName] = {
          type: 'checkbox',
          formControlName: this.controlName,
          value: false,
        };
        this.control = this.customForm[this.controlName] as AvailableCustomInputs;
        this.controlRef = ref(this.control);
      }
    }
  }

  keySpaceDown(event: Event) {
    this.selectionChange();
    event.preventDefault();
  }

  selectionChange(): void {
    if (this.control.value == undefined) {
      this.control.value = true;
    } else {
      this.control.value = !this.control.value;
    }
    this.$forceUpdate();
  }
}
