
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {benefitsList} from './benefits.config';
import {getLeadContent} from '@/app/modules/shared/form-lead-content.service';

@Options({
  name: 'NotusBenefits',
  components: {
    NotusSvgIcon,
  },
})
export default class NotusBenefits extends Vue {
  public benefits = benefitsList;

  mounted() {
    this.equalSize(true);
    window.addEventListener('resize', this.handleResize);
  }

  getLeadContent(variableName: string, defaultI18nKey?: string, defaultValue?: string): string {
    return getLeadContent(variableName, defaultI18nKey, defaultValue);
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (window.innerWidth >= 768) {
      this.equalSize(true);
    }
  }

  equalSize(resize: boolean) {
    const table = this.$refs['benefit-equal'] as HTMLElement;
    let elements: HTMLElement[];
    elements = Array.from(table.querySelectorAll(`[data-equal="benefit"]`));

    const listElsWidths: number[] = [];
    let widest: number;

    if (resize) {
      elements.forEach((element: HTMLElement) => {
        element.style.height = 'auto';
      });
    }

    elements.forEach((element: HTMLElement) => {
      const elementWidth = element.clientWidth as number;
      listElsWidths.push(elementWidth);
    });

    widest = Math.max(...listElsWidths);

    elements.forEach((element: HTMLElement) => {
      element.style.height = widest + 'px';
      element.style.width = widest + 'px';
    });
  }
}
