import {Vue, Options} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import NotusHeader from '@/app/modules/shared/components/header/header.component.vue';
import {clearStore} from '@/store';
@Options({
  name: 'PageSimple',
  components: {
    NotusHeader,
  },
})
export default class PageSimple extends Vue {
  @Prop(String) readonly partnerLogo: string | undefined;

  notusClose() {
    clearStore();
    window.close(); // FOr ones which can be closed
    history.back(); // FOr ones which can not be closed
  }
}
