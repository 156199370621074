export enum GetterTypes {
  'form' = 'form',
  'formFilters' = 'formFilters',
  'formSorting' = 'formSorting',
  'formLead' = 'formLead',
  'leadPartnersConstents' = 'leadPartnersConstents',
  'incomeForms' = 'incomeForms',
  'inputByName' = 'inputByName',
  'isValid' = 'isValid',
  'isFormLeadValid' = 'isFormLeadValid',
  'valueByName' = 'valueByName',
  'validationsByName' = 'validationsByName',
  'leadWasSend' = 'leadWasSend',
  'leadSentSuccess' = 'leadSentSuccess',
  'proposalId' = 'proposalId',
  'installmentType' = 'installmentType',
  'commission0Available' = 'commission0Available',
}
