import {PROGRESS_MAIN_STAGES_ORDER} from '@/app/config/progress-order.config';
import {ProgressStep} from '@/store/modules/ProgressModule/state/progress-step.interface';

const INIT_STEP = {mainStage: PROGRESS_MAIN_STAGES_ORDER[0]};

export const INIT_PROGRESS_STATE: ProgressState = {
  currentStep: INIT_STEP,
  stepsPassed: [INIT_STEP],
};

export type ProgressState = {
  currentStep: ProgressStep;
  stepsPassed: Array<ProgressStep>;
};

export const state: ProgressState = INIT_PROGRESS_STATE;
