import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';
import {FormIncomeType} from '@/app/modules/page-form/enums/form-income-type.enum';
import {
  ProgressFormDynamicPagesIndex,
  ProgressFormIncomeDynamicPagesIndex,
  ProgressFormOutcomeDynamicPagesIndex,
} from '@/app/modules/page-form/enums/progress-form-dynamic-pages-index.enum';
import {ProgressFormStage} from '@/app/modules/page-form/enums/progress-form-stage.enum';
import {FormConfigFormsKey} from './dynamic-form.config';

export const PROGRESS_FORM_MAIN_STEPS_ORDER: Array<ProgressFormStage> = [
  ProgressFormStage.begin,
  ProgressFormStage.purpose,
  ProgressFormStage.singleOrFriendly,
  ProgressFormStage.age,
  ProgressFormStage.EU_citizien,
  ProgressFormStage.income,
  ProgressFormStage.outcome,
];

export const PROGRESS_FORM_PURPOSE_ORDER: {[key in FormPurpose]: Array<FormConfigFormsKey>} = {
  [FormPurpose.PURCHASE]: [
    ProgressFormDynamicPagesIndex.value,
    ProgressFormDynamicPagesIndex.loan_value,
    ProgressFormDynamicPagesIndex.loan_period,
    ProgressFormDynamicPagesIndex.loan_begin,
    ProgressFormDynamicPagesIndex.property_type,
    // ProgressFormDynamicPagesIndex.property_finishing,
    ProgressFormDynamicPagesIndex.property_details,
  ],
  [FormPurpose.BUILD]: [
    ProgressFormDynamicPagesIndex.value,
    ProgressFormDynamicPagesIndex.value_land,
    ProgressFormDynamicPagesIndex.loan_value,
    ProgressFormDynamicPagesIndex.loan_period,
    ProgressFormDynamicPagesIndex.loan_begin,
    ProgressFormDynamicPagesIndex.property_type,
    // ProgressFormDynamicPagesIndex.property_finishing,
    ProgressFormDynamicPagesIndex.property_details,
  ],
  [FormPurpose.RENOVATION]: [
    ProgressFormDynamicPagesIndex.value,
    ProgressFormDynamicPagesIndex.value_land,
    ProgressFormDynamicPagesIndex.loan_value,
    ProgressFormDynamicPagesIndex.loan_period,
    ProgressFormDynamicPagesIndex.loan_begin,
    ProgressFormDynamicPagesIndex.property_type,
    ProgressFormDynamicPagesIndex.property_details,
  ],
  [FormPurpose.MORTGAGE]: [
    ProgressFormDynamicPagesIndex.property_type,
    ProgressFormDynamicPagesIndex.property_value,
    ProgressFormDynamicPagesIndex.loan_value,
    ProgressFormDynamicPagesIndex.loan_period,
    ProgressFormDynamicPagesIndex.loan_begin,
    ProgressFormDynamicPagesIndex.property_details,
  ],
  [FormPurpose.CONSOLIDATION]: [
    ProgressFormDynamicPagesIndex.property_type,
    ProgressFormDynamicPagesIndex.property_value,
    ProgressFormDynamicPagesIndex.loan_value,
    ProgressFormDynamicPagesIndex.loan_period,
    ProgressFormDynamicPagesIndex.loan_begin,
    ProgressFormDynamicPagesIndex.property_details,
  ],
  [FormPurpose.REFINANCING]: [
    ProgressFormDynamicPagesIndex.property_type,
    ProgressFormDynamicPagesIndex.property_value,
    ProgressFormDynamicPagesIndex.loan_value,
    ProgressFormDynamicPagesIndex.loan_period,
    ProgressFormDynamicPagesIndex.loan_begin,
    ProgressFormDynamicPagesIndex.property_details,
  ],
};

export const PROGRESS_FORM_INCOME_INIT: ProgressFormIncomeDynamicPagesIndex = ProgressFormIncomeDynamicPagesIndex.income_type;

export const PROGRESS_FORM_INCOME_ORDER: {[key in FormIncomeType]: Array<ProgressFormIncomeDynamicPagesIndex>} = {
  [FormIncomeType.UoP]: [
    ProgressFormIncomeDynamicPagesIndex.income_UoPType,
    ProgressFormIncomeDynamicPagesIndex.income_monthly,
    ProgressFormIncomeDynamicPagesIndex.income_time,
    ProgressFormIncomeDynamicPagesIndex.other_incomes,
  ],
  [FormIncomeType.DG]: [
    ProgressFormIncomeDynamicPagesIndex.income_taxType,
    ProgressFormIncomeDynamicPagesIndex.income_monthly,
    ProgressFormIncomeDynamicPagesIndex.income_time,
    ProgressFormIncomeDynamicPagesIndex.other_incomes,
  ],
  [FormIncomeType.Other]: [
    ProgressFormIncomeDynamicPagesIndex.income_otherType,
    ProgressFormIncomeDynamicPagesIndex.income_monthly,
    ProgressFormIncomeDynamicPagesIndex.income_time,
    ProgressFormIncomeDynamicPagesIndex.other_incomes,
  ],
  [FormIncomeType.None]: [],
};

export const PROGRESS_FORM_OUTCOME_ORDER: Array<FormConfigFormsKey> = [
  ProgressFormOutcomeDynamicPagesIndex.outcome_people,
  ProgressFormOutcomeDynamicPagesIndex.outcome_mortgages,
  ProgressFormOutcomeDynamicPagesIndex.outcome_monthly,
  ProgressFormOutcomeDynamicPagesIndex.outcome_limits,
];
