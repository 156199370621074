import {FormHint} from '@/app/modules/page-form/interfaces/form-hint.interface';

export const INIT_HINT_STATE: HintState = {
  hint: {},
  progress: 0,
  leftMinutes: 5,
  shownOnMobile: false,
};

export type HintState = {
  hint: {
    mainHint?: FormHint;
    focusHint?: FormHint;
    hoverHint?: FormHint;
  };
  progress: number;
  leftMinutes: number;
  shownOnMobile: boolean;
};

export const state: HintState = INIT_HINT_STATE;
