import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1cb6775c")
const _hoisted_1 = {
  key: 0,
  class: "accent-label fw-medium fs-14"
}
const _hoisted_2 = { class: "row-content d-flex justify-content-between mb-1 mb-md-3" }
const _hoisted_3 = { class: "label fs-16 fw-normal" }
const _hoisted_4 = { class: "value fs-16 fw-semi-bold color-primary" }
const _hoisted_5 = { class: "row-content d-flex justify-content-between mb-1 mb-md-3" }
const _hoisted_6 = { class: "label fs-16 fw-normal" }
const _hoisted_7 = { class: "value fs-16 fw-semi-bold color-primary" }
const _hoisted_8 = { class: "row-content d-flex justify-content-between" }
const _hoisted_9 = { class: "label fs-16 fw-normal" }
const _hoisted_10 = { class: "value fs-16 fw-semi-bold color-primary" }
const _hoisted_11 = { class: "label less-width fs-16 fw-semi-bold" }
const _hoisted_12 = { class: "value fs-24 fw-bold color-primary text-nowrap" }
const _hoisted_13 = { class: "offer-box_read-more" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_logo_bank = _resolveComponent("notus-logo-bank")!
  const _component_notus_button = _resolveComponent("notus-button")!

  return (_ctx.offer)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["offer-box position-relative overflow-hidden", {
      'main-offer': _ctx.isMain && _ctx.variant !== 'row',
      'offer-box--row': _ctx.variant === 'row',
    }])
      }, [
        (_ctx.isMain && _ctx.variant !== 'row')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('page.summary.sort.types.' + _ctx.orderType)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["offer-box_header d-flex align-items-center justify-content-between", {
        'border-bottom': _ctx.variant !== 'row',
        'offer-box_header-on-row': _ctx.variant === 'row',
      }])
        }, [
          _createVNode(_component_notus_logo_bank, {
            bankName: _ctx.offer.product.bankName,
            size: _ctx.variant === 'row' ? 'large' : 'small'
          }, null, 8, ["bankName", "size"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["offer-box_content", {
        'border-bottom padding-y': _ctx.variant !== 'row',
        'offer-box_content-on-row': _ctx.variant === 'row',
      }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('page.summary.recommend.box.percents')), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.percentCount(_ctx.offer.simulation.interestRate.rates[0].rate)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('page.summary.recommend.box.rrso')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.percentCount(_ctx.offer.simulation.apr)), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('page.summary.recommend.box.total_amount')), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.amountFilter(_ctx.offer.simulationSummary.totalCosts.SCHEDULE_TOTAL?.value)) + " " + _toDisplayString(_ctx.offer.simulationSummary.totalCosts.SCHEDULE_TOTAL?.currency), 1)
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["offer-box_footer", {
        'offer-box_footer-on-row': _ctx.variant === 'row',
      }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["offer-box_bottom-content d-flex", {
          'offer-box_bottom-content-on-row': _ctx.variant === 'row',
        }])
          }, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('page.summary.recommend.box.monthly_rate')), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.amountFilter(_ctx.offer.simulationSummary.finalInstallment.CAPITAL_AND_INTEREST.value)) + " " + _toDisplayString(_ctx.offer.simulationSummary.finalInstallment.CAPITAL_AND_INTEREST.currency), 1)
          ], 2),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_notus_button, {
              type: "button",
              size: "normal",
              theme: "link",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDetailedOffer(_ctx.offer))),
              class: "flex-grow-1 mt-2 mt-md-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('page.summary.recommend.box.more_button')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_notus_button, {
              type: "button",
              size: "normal",
              onClick: _ctx.scrollToContact,
              class: "flex-grow-1 mt-2 mt-md-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('page.summary.recommend.box.CTA_contact')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}