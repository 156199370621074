const lang_pl = {
  components: {
    'hint-curious': {
      title: 'Czy wiesz, że...',
      curiouses: {
        0: {
          content:
            'padł rekord liczby mieszkań, które zaczęto budować w Polsce? W II kwartale 2021 r. było ich 80 883. Historyczny wynik – 88 860 – osiągnęła też liczba mieszkań, na budowę których wydano pozwolenie lub dokonano zgłoszenia z projektem budowlanym.',
          source: 'GUS',
        },
        1: {
          content:
            'w II kwartale 2021 r. średnie ceny mieszkań najbardziej rosły w Poznaniu, Białymstoku i Krakowie – odpowiednio o 4,87%, 3,71% i 3,46% za 1 m2 w stosunku do I kwartału 2021 r.? ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        2: {
          content:
            'średnia cena za 1 m2 mieszkania w Warszawie w II kwartale 2021 r. wyniosła nieco ponad 10 200 zł? To o 1,5% więcej niż w poprzednim kwartale. ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        3: {
          content:
            'w II kwartale 2021 r. średni czynsz najmu mieszkania w Warszawie wyniósł 1652 zł i był o... 2 zł niższy niż kwartał wcześniej? ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        4: {
          content: 'w pierwszym półroczu 2021 Polacy spłacali przeszło 2,5 mln kredytów mieszkaniowych? ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        5: {
          content:
            'w samym II kwartale 2021 r. banki udzieliły 67 tys. kredytów mieszkaniowych (o 17,21% więcej niż w I kwartale 2021 r.) o wartości ponad 22 mld zł? Oznacza to wzrost o niemal 4,2 mld zł (23,38%). ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        6: {
          content:
            'całkowita wartość zadłużenia polskich gospodarstw domowych z tytułu kredytów mieszkaniowych w ostatnim dniu II kwartału 2021 r. wyniosła nieco ponad 486 mld zł? Od kwietnia do czerwca 2021 r. wzrosła ona o 1,23%, czyli o 5,9 mld zł. ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        7: {
          content:
            'średnia kwota kredytu hipotecznego udzielonego w II kwartale 2021 r. wyniosła prawie 330 tys. zł – o niemal 16,5 tys. zł więcej w porównaniu z poprzednim kwartałem 2021 r.? ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        8: {
          content:
            'w II kwartale 2021 r. w skali wszystkich kredytów hipotecznych najbardziej wzrósł udział tych o kwotach od 500 tys. zł do 1 mln zł? Spadł natomiast udział kredytów o wartości poniżej 300 tys. zł. ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        9: {
          content:
            'klienci banków najczęściej decydują się na kredyt na mieszkanie spłacany przez 25-35 lat? W roku 2020 i 2021 ok. 2/3 kredytów udzielono na taki właśnie okres. ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        10: {
          content:
            'w czerwcu 2021 r. kredyty mające zaległości w spłacie stanowiły 1,97% w całości kredytów złotówkowych, co oznaczało spadek o 0,05 p.p w porównaniu do I kwartału 2021 r.? ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        11: {
          content:
            'największy odsetek mieszkańców posiadających kredyt ma woj. wielkopolskie (52,5%)? Na drugim i trzecim miejscu plasują się woj. dolnośląskie (52,4%) i pomorskie (51,7%). Najniższym odsetkiem mieszkańców z kredytem wyróżnia się woj. świętokrzyskie (38,5%). ',
          source: 'raport AMRON-SARFiN 2/2021',
        },
        12: {
          content: 'większość kredytów hipotecznych udzielonych w Polsce to zobowiązania z oprocentowaniem zmiennym?',
        },
        13: {
          content: 'nieruchomość obciążoną hipoteką na rzecz banku można sprzedać?',
        },
        14: {
          content:
            'wcale nie musisz mieć minimum 20% na swój wkład własny? Jest to tylko rekomendowana wysokość, a większość banków udzieli Ci kredytu również z 10% wkładem własnym przy dodatkowym ubezpieczeniu niskiego wkładu własnego.',
        },
        15: {
          content:
            'brak historii kredytowej wcale nie oznacza odmowy kredytu? Posiadanie historii kredytowej pomaga w uzyskaniu pozytywnej decyzji, ale nie jest w tej kwestii decydujące.',
        },
        16: {
          content:
            'zasady udzielania kredytów są szczegółowo określone w przepisach prawa i wytycznych KNF? Dają one kredytobiorcy solidną ochronę przed nieuczciwymi praktykami instytucji finansowych.',
        },
      },
    },
    formLocation: {
      noResults: 'Brak wyników, spróbuj inną frazę.',
    },
    'form-editable': {
      change: 'Zmień',
      close: 'Zamknij',
      accept: 'Zastosuj',
      addIncome: 'Dodaj źródło dochodu',
    },
  },
  dictionary: {
    interestRateType: {
      VARIABLE: 'Zmienne',
      CONSTANT: 'Stałe',
    },
  },
  dynamic: {
    form: {
      page: {
        EU_citizien: {
          hint: {
            description: 'Jeżeli masz obywatelstwo polskie lub innego kraju Unii Europejskiej, wybierz TAK.',
            title: 'Obywatelstwo.',
          },
          inputs: {
            EU_citizien: {
              title: {
                FRIEND: 'Współwnioskodawca',
                YOU: 'Ty',
              },
            },
          },
          title: {
            MULTI: 'Czy jesteście obywatelami Unii Europejskiej?',
            SOLO: 'Czy jesteś obywatelem Unii Europejskiej?',
          },
        },
        age: {
          hint: {
            description: 'Pamiętaj, że Twój wiek ma wpływ na maksymalny okres kredytowania.',
            title: 'Wiek.',
          },
          input: {
            age: {
              label: 'Podaj wiek',
              validation: {
                minNumber: 'Kredyty są oferowane osobom powyżej 18 roku życia',
              },
            },
          },
          title: {
            MULTI: 'Podaj wiek najstarszego współwnioskodawcy',
            SOLO: 'Ile masz lat?',
          },
        },
        income_UoPType: {
          inputs: {
            income_UoPMaternity: {
              title: 'Urlop macierzyński',
            },
          },
          options: {
            FIXED_TERM_CONTRACT: 'Na czas określony',
            FIXED_TERM_CONTRACT_FROM_ABROAD: 'Na czas określony z zagranicy',
            PERMANENT_CONTRACT: 'Na czas nieokreślony',
            PERMANENT_CONTRACT_FROM_ABROAD: 'Na czas nieokreślony z zagranicy',
          },
          title: 'Wybierz rodzaj umowy o pracę',
        },
        income_monthly: {
          hint: {
            description:
              'Zgodnie z wymaganiami Komisji Nadzoru Finansowego kredyt musi być spłacany w walucie, w jakiej uzyskiwane są dochody kredytobiorcy.',
            title: 'Zarabiasz w euro, spłacasz w euro.',
          },
          inputs: {
            income_monthly_currency: {
              label: 'Waluta',
              description:
                'Kwota netto jest to kwota, którą otrzymujesz “na rękę” - czyli nie musisz już nic oddawać z tej kwoty.',
            },
            income_monthly_value: {
              label: 'Wysokość dochodu',
            },
          },
          title: 'Jaka jest wysokość dochodu miesięcznego netto z tego tytułu?',
        },
        income_otherType: {
          label: 'Wybierz inne źródło dochodu',
          options: {
            CONTRACT_OF_COMMISSION: 'Umowa dzieło',
            CONTRACT_OF_MANDATE: 'Umowa zlecenie',
            CONTRACT: 'Kontrakt menagerski',
            DISABILITY_PENSION: 'Renta inwalidzka bezterminowa',
            DRIVER: 'Dieta kierowcy',
            FAMILY_ALLOWANCE_PENSION: 'Renta rodzinna bezterminowa',
            NOMINATION: 'Mianowanie/powołanie ',
            PENSION_INCOME_FROM_ABROAD: 'Renta z zagranicy bezterminowa',
            RENT: 'Najem/dzierżawa',
            RETIREMENT_INCOME_FROM_ABROAD: 'Emerytura z zagranicy',
            RETIREMENT_PENSION: 'Emerytura',
            SAILOR: 'Marynarz',
            SOLDIER: 'Kontrakt żołnierski',
            TERM_DISABILITY_PENSION: 'Renta inwalidzka terminowa',
            TERM_FAMILY_ALLOWANCE_PENSION: 'Renta rodzinna terminowa',
            TERM_PENSION_INCOME_FROM_ABROAD: 'Renta z zagranicy terminowa',
          },
          title: 'Wybierz spośród innych źródeł dochodu',
        },
        income_taxType: {
          hint: {
            description:
              'Jeżeli nie masz pewności, w jaki sposób rozliczasz podatki w US, wsparciem może być telefon do przyjaciela – Twojej księgowej/księgowego.',
            title: 'Zapytaj w księgowości.',
          },
          options: {
            SOLE_PROPRIETORSHIP_FROM_ABROAD: {
              label: 'Działalność za granicą',
              hint: {
                description:
                  'Działalność za granicą to taka, która jest prowadzona poza granicami Polski (podatki rozliczane są w innym kraju).',
                title: 'Sprawdź, czym jest działalność za granicą.',
              },
            },
            SOLE_PROPRIETORSHIP_FULL_ACCOUNTING: {
              label: 'Pełna księgowość',
              hint: {
                description:
                  ' Pełna księgowość to najbardziej złożona forma rozliczenia z Urzędem Skarbowym. Uwzględnia wszystkie przychody, koszty firmy, a także stan majątku.',
                title: 'Sprawdź, czym jest pełna księgowość.',
              },
            },
            SOLE_PROPRIETORSHIP_LUMP_SUM: {
              label: 'Ryczałt',
              hint: {
                description:
                  ' Pamiętaj, że w przypadku ryczałtu podatek jest naliczany od przychodu bez możliwości pomniejszania go o koszty uzyskania.',
                title: 'Zasady dotyczące ryczałtu.',
              },
            },
            SOLE_PROPRIETORSHIP_SIMPLIFIED_ACCOUNTING: {
              label: 'Księga Przychodów i Rozchodów',
              hint: {
                description:
                  ' Księga przychodów i rozchodów jest przedstawieniem wszystkich osiąganych przychodów i kosztów w ramach prowadzonej działalności/firmy.',
                title: 'Sprawdź, czym jest KPiR.',
              },
            },
            SOLE_PROPRIETORSHIP_TAX_CARD: {
              label: 'Karta podatkowa',
              hint: {
                description:
                  'Karta podatkowa to najprostsza forma opodatkowania, w przypadku której nie musisz prowadzić księgowości, a kwota podatku nie jest zależna od dochodu.',
                title: 'Sprawdź, czym jest Karta podatkowa.',
              },
            },
          },
          title: 'Wybierz sposób opodatkowania działalności',
        },
        income_time: {
          hint: {
            description:
              'Banki, udzielając długoterminowego kredytu hipotecznego, weryfikują wysokość i stabilność dochodów wnioskodawców. Każdy bank ma własne reguły. Dzięki tym informacjom zwiększamy prawdopodobieństwo dostępności wyświetlanych na końcu ofert.',
            title: 'Weryfikacja dochodów.',
          },
          inputs: {
            income_time_from: {
              label: 'Wybierz okres',
              title: 'Od kiedy uzyskiwany jest dochód z tego tytułu?',
            },
            income_time_to: {
              label: 'Wybierz okres',
              title: 'Jak długo jeszcze będzie uzyskiwany dochód z tego tytułu?',
            },
          },
          title: 'Czas uzyskiwania dochodów',
        },
        income_type: {
          hint: {
            description:
              'Jeżeli masz więcej niż jedno źródło dochodu, uzupełnij po kolei. Radzimy zacząć uzupełnianie od tego źródła, z którego zarabiasz najwięcej.',
            title: 'Kilka źródeł dochodu',
          },
          title: {
            MULTI: 'Jakie jest źródło dochodu współwnioskodawcy?',
            SOLO: 'Jakie jest Twoje źródło dochodu?',
          },
        },
        loan_begin: {
          hint: {
            description:
              'Nasi specjaliści służą kompleksowym wsparciem. Zadbają o kompletność i poprawność wniosku kredytowego. Warto pamiętać, że czas potrzebny do uzyskania kredytu hipotecznego jest różny i zależy od banku.',
            title: 'Przygotuj się do złożenia wniosku kredytowego!',
          },
          input: {
            'load-begin': {
              label: 'Przedział czasowy',
            },
            'loan-begin': {
              options: {
                ASAP: {
                  label: 'Jak najszybciej',
                },
                OVER_HALF_YEAR: {
                  label: 'Więcej niż 6 m-cy',
                },
                TBD: {
                  label: 'Jeszcze nie wiem',
                },
                UP_TO_FEW_MONTHS: {
                  label: 'W ciągu 2-3 m-cy',
                },
                UP_TO_HALF_YEAR: {
                  label: 'W ciągu 4-6 m-cy',
                },
                UP_TO_MONTH: {
                  label: 'W ciągu 30 dni',
                },
              },
            },
          },
          title: 'Kiedy planujesz transakcję?',
        },
        loan_period: {
          hint: {
            description:
              'Pamiętaj, że im dłuższy okres kredytowania, tym mniejsza miesięczna rata z tytułu kredytu, jednak całkowity koszt kredytu wzrośnie, ponieważ odsetki liczone są od całości okresu spłaty.',
            title: 'Ważna informacja dotycząca okresu kredytowania.',
          },
          input: {
            loan_period: {
              title: 'Okres spłaty',
            },
          },
          title: 'Jaki okres kredytowania Cię interesuje?',
        },
        loan_value: {
          hint: {
            PURCHASE: {
              description:
                'Są to pieniądze, których potrzebujesz z banku na zakup nieruchomości, po uwzględnieniu Twojego wkładu własnego.',
              title: 'Sprawdź, czym jest kwota kredytu.',
            },
            BUILD: {
              description: 'W takiej sytuacji wpisz tylko kwotę kredytu, jaką chcesz otrzymać z banku.',
              title: 'Finansujesz budowę częściowo ze środków własnych?',
            },
            REFINANCING: {
              description:
                'Jeżeli posiadasz kredyt w obcej walucie (innej niż PLN), podaj orientacyjną aktualną wysokość zadłużenia w PLN (pomnóż kwotę zadłużenia w walucie przez średni kurs NBP tej waluty).',
              title: 'Obca waluta.',
            },
          },
          input: {
            'loan-value': {
              BUILD: {
                description: 'Kwota kredytu nie może przekraczać 90% wartości nieruchomości stanowiącej zabezpieczenie kredytu.',
                label: 'Wysokość kredytu',
                title: 'Podaj kwotę kredytu',
              },
              CONSOLIDATION: {
                description: 'Kwota kredytu nie może przekraczać 80% wartości nieruchomości stanowiącej zabezpieczenie kredytu.',
                label: 'Kwota konsolidacji',
              },
              MORTGAGE: {
                description: 'Kwota kredytu nie może przekraczać 80% wartości nieruchomości stanowiącej zabezpieczenie kredytu.',
                label: 'Kwota pożyczki',
              },
              PURCHASE: {
                description: 'Kwota kredytu nie może przekraczać 90% wartości nieruchomości stanowiącej zabezpieczenie kredytu.',
                label: 'Wysokość kredytu',
                title: 'Podaj kwotę kredytu',
              },
              REFINANCING: {
                description: 'Kwota kredytu nie może przekraczać 90% wartości nieruchomości stanowiącej zabezpieczenie kredytu.',
                label: 'Kwota refinansowania',
              },
              RENOVATION: {
                description: 'Kwota kredytu nie może przekraczać 90% wartości nieruchomości stanowiącej zabezpieczenie kredytu.',
                label: 'Wysokość kredytu',
                title: 'Podaj kwotę kredytu',
              },
            },
          },
          title: {
            BUILD: 'Jak wysoki kredyt chcesz uzyskać?',
            CONSOLIDATION: 'Jaka jest kwota konsolidacji?',
            MORTGAGE: 'Jaka jest kwota pożyczki?',
            PURCHASE: 'Jak wysoki kredyt chcesz uzyskać?',
            REFINANCING: 'Jaka jest pozostała kwota do spłaty refinansowanego kredytu hipotecznego?',
            RENOVATION: 'Jak wysoki kredyt chcesz uzyskać',
          },
        },
        other_incomes: {
          title: {
            SOLO: 'Czy masz inne źródła dochodu?',
            MULTI: 'Czy współwnioskodawca posiada inne źródła dochodu?',
          },
        },
        outcome_EU_citizien: {
          hint: {
            description: 'W Warszawie najwięcej ludzi....',
            title: 'Zobacz, na jakie kredyty biorą ludzie z Twojego miasta',
          },
          inputs: {
            outcome_EU_citizien: {
              title: {
                FRIEND: 'Współwnioskodawca',
                YOU: 'Ty',
              },
            },
          },
          title: {
            MULTI: 'Czy jesteście obywatelami Unii Europejskiej?',
            SOLO: 'Czy jesteś obywatelem Unii Europejskiej?',
          },
        },
        outcome_limits: {
          hint: {
            description:
              'Banki często przyznają limity na kartach kredytowych lub w posiadanych rachunkach. Sprawdź, czy posiadasz tego typu produkty i uzupełnij dane.',
            title: 'Możliwe limity.',
          },
          inputs: {
            outcome_limit_account: {
              decription: {
                MULTI: 'Podaj sumę debetów w kontach Twoich oraz współkredytobiorcy',
              },
              hint: {
                description: 'Zsumuj wszystkie przyznane debety i wpisz ich wartość.',
                title: 'Limit w rachunku to też debet.',
              },
              label: 'Wysokość debetów w kontach',
            },
            outcome_limit_card: {
              decription: {
                MULTI: 'Podaj sumę limitów na kartach kredytowych Twoich oraz współkredytobiorcy',
              },
              hint: {
                description:
                  'Tego typu limity dotyczą kart kredytowych, czyli takich, kiedy płacąc korzystasz z limitu karty, a nie ze środków zgromadzonych na koncie. Pamiętaj, by podać kwotę przyznanego limitu (a nie tylko wykorzystanego).',
                title: 'Wysokość limitów.',
              },
              label: 'Wysokość limitów na kartach',
            },
          },
          title: 'Podaj wysokość przyznanych limitów na kartach kredytowych i debetów w kontach',
        },
        outcome_monthly: {
          hint: {
            description:
              'Podaj wszelkie miesięczne obciążenia z tytułu zobowiązań (np. raty kredytów hipotecznych, gotówkowych, ratalnych).\nJeżeli masz kredyt walutowy, przelicz jego wartość na PLN po średnim kursie NBP (podaj przybliżoną wartość obciążenia w PLN i zaokrąglij liczbę).',
            title: 'Zobowiązania.',
          },
          inputs: {
            outcome_monthly: {
              decription: {
                MULTI: 'Podaj zobowiązania Twoje i współkredytobiorcy łącznie, oraz takich, które macie wspólnie',
              },
              label: 'Wysokość zobowiązań',
              title: {
                MULTI: 'Podaj sumę miesięcznych rat kredytów',
                SOLO: 'Podaj sumę Twoich miesięcznych rat kredytów',
              },
            },
          },
          title: 'Jaka jest wysokość miesięcznych zobowiązań z tytułu kredytów?',
        },
        outcome_mortgages: {
          inputs: {
            outcome_mortgages: {
              decription: {
                MULTI:
                  'Podaj liczbę kredytów hipotecznych Twoich i współkredytobiorcy łącznie, oraz takich, które macie wspólnie.',
              },
              title: 'Liczba kredytów hipotecznych',
            },
          },
          title: 'Podaj liczbę posiadanych kredytów hipotecznych',
        },
        outcome_people: {
          hint: {
            description: 'Jeżeli masz niepełnoletnie dzieci na utrzymaniu, uwzględnij je.',
            title: 'Jaką liczbę osób podać?',
          },
          inputs: {
            outcome_people: {
              title: 'Liczba osób',
            },
          },
          title: 'Podaj liczbę osób w gospodarstwie domowym',
        },
        property_details: {
          hint: {
            description: 'Nic nie szkodzi! Podaj dane tej, którą interesujesz się najbardziej.',
            title: 'Nie masz konkretnie wybranej nieruchomości?',
          },
          input: {
            property_details_area: {
              title: 'Powierzchnia nieruchomości',
            },
            property_details_location: {
              label: 'Podaj miejscowość',
              title: 'Lokalizacja nieruchomości',
            },
          },
          title: 'Opowiedz nam więcej o swojej nieruchomości',
        },
        property_finishing: {
          input: {
            'purposes-finishing-value': {
              description: 'Koszt remontu powiększy aktualną wartość nieruchomości.',
              label: 'Koszt remontu / wykończenia',
              title: 'Podaj kwotę, jaką chcesz przeznaczyć',
            },
            'purposes-finishing': {
              title: 'Planuję remont / wykończenie',
              label: 'Koszt remontu / wykończenia',
              options: {
                left: {
                  hint: {
                    description:
                      'Kredytem hipotecznym możesz sfinansować wszystko, co jest trwale połączone ze ścianami Twojego domu/mieszkania, natomiast części ruchome finansujesz z własnych środków (lub z kredytu gotówkowego).',
                    title: 'Zastanów się, czy będziesz chciał zrobić remont.',
                  },
                },
                right: {
                  hint: {
                    description:
                      'Może to być kontrola terenowa wykwalifikowanego pracownika banku lub obowiązek przedłożenia dokumentacji fotograficznej. Warto porozmawiać o tym na spotkaniu z naszym specjalistą.',
                    title: 'Bank może zweryfikować wykorzystanie środków na remont nieruchomości.',
                  },
                },
              },
            },
          },
          title: 'Czy planujesz dodatkowo remont / wykończenie?',
        },
        property_type: {
          hint: {
            PURCHASE: {
              description:
                'Jeśli kupujesz segment, wybierz mieszkanie (w bankach segment definiowany jest jako mieszkanie w zabudowie szeregowej). Jeżeli zamierzasz kupić działkę, na której w przyszłości wybudujesz dom, zaznacz działkę.',
              title: 'Sprawdź, co zaznaczyć.',
            },
            BUILD: {
              description: 'Pamiętaj, że większość banków finansuje także budowę całorocznych domków letniskowych.',
              title: 'Finansowanie domków letniskowych.',
            },
            REFINANCING: {
              description:
                'Jeśli refinansujesz kredyt, którego zabezpieczeniem hipotecznym będzie segment, wybierz opcję mieszkanie (w bankach segment definiowany jest jako mieszkanie w zabudowie szeregowej)',
              title: 'Sprawdź, co zaznaczyć.',
            },
          },
          title: {
            BUILD: 'Jaką nieruchomość budujesz?',
            CONSOLIDATION: 'Jaką nieruchomość proponujesz jako zabezpieczenie kredytu?',
            MORTGAGE: 'Jaką nieruchomość proponujesz jako zabezpieczenie kredytu?',
            PURCHASE: 'Jaką nieruchomość kupujesz?',
            REFINANCING: 'Jaką nieruchomość proponujesz jako zabezpieczenie kredytu?',
            RENOVATION: 'Jaką nieruchomość chcesz wyremontować / wykończyć?',
          },
        },
        property_value: {
          hint: {
            description:
              'To inaczej cena, za jaką możesz sprzedać nieruchomość na wolnym rynku. Jeżeli nie znasz dokładnej kwoty, podaj szacunkową.',
            title: 'Sprawdź, czym jest wartość nieruchomości.',
          },
          input: {
            property_value: {
              label: 'Wartość nieruchomości',
              title: 'Podaj wartość nieruchomości',
            },
          },
          title: 'Jaka jest wartość tej nieruchomści?',
        },
        purpose: {
          hint: {
            description:
              'Kredyt hipoteczny może mieć różne cele, które widzisz obok. Wspólną ich cechą jest to, że jednym z zabezpieczeń spłaty zobowiązania w dłuższym czasie jest ustanowienie przez bank hipoteki.',
            title: 'Hipoteka jako zabezpieczenie',
          },
          input: {
            purpose: {
              options: {
                BUILD: {
                  hint: {
                    description:
                      'Gdy chcesz wybudować dom metodą gospodarczą (zarówno samodzielnie, jak i poprzez firmę). Gdy zabrakło Ci środków na dokończenie budowy, a także gdy potrzebujesz rozbudować swój dom.',
                    title: 'Sprawdź, kiedy celem kredytu jest budowa.',
                  },
                  label: 'Budowa',
                },
                CONSOLIDATION: {
                  hint: {
                    description:
                      'Gdy masz dwa kredyty lub więcej i chcesz je połączyć w jeden. Dzięki konsolidacji cieszysz się płatnością jednej raty.',
                    title: 'Sprawdź, kiedy dokonać konsolidacji.',
                  },
                  label: 'Konsolidacja',
                },
                MORTGAGE: {
                  hint: {
                    description:
                      'Gdy potrzebujesz pieniędzy na dowolny cel realizujący Twoje marzenia, możesz zabezpieczyć go hipoteką na nieruchomości.',
                    title: 'Sprawdź, kiedy wziąć pożyczkę pod zastaw.',
                  },
                  label: 'Pożyczka pod zastaw nieruchomości',
                },
                PURCHASE: {
                  hint: {
                    description:
                      'Zakup to przeniesienie prawa własności pomiędzy Sprzedającym a Kupującym. Umowy dotyczące zobowiązania do przeniesienia własności nieruchomości i umowy przenoszące własność nieruchomości muszą być sporządzone w formie aktu notarialnego. Inne możliwe formy przeniesienia własności majątku to m.in. darowizna i spadek.',
                    title: 'Sprawdź, czym jest zakup.',
                  },
                  label: 'Zakup nieruchomości',
                },
                REFINANCING: {
                  hint: {
                    description:
                      'Gdy masz już kredyt i jesteś zainteresowany zmianą banku, zaoszczędzeniem na miesięcznej racie lub wtedy, gdy chcesz porównać aktualne warunki cenowe.',
                    title: 'Sprawdź, kiedy dokonać refinansowania kredytu.',
                  },
                  label: 'Refinansowanie kredytu hipotecznego',
                },
                RENOVATION: {
                  hint: {
                    description:
                      'Gdy masz dom/mieszkanie oddane do użytku i potrzebujesz pieniędzy na wykończenie go w środku. Możesz sfinansować wszystko, co jest trwale połączone ze ścianami Twojego domu/mieszkania, natomiast części ruchome finansujesz z własnych środków (lub z kredytu gotówkowego). ',
                    title: 'Sprawdź, kiedy celem kredytu jest remont.',
                  },
                  label: 'Remont/wykończenie',
                },
              },
            },
          },
          description: 'Zacznijmy od kilku pytań dotyczących oferty',
          title: 'Jaki jest cel kredytu?',
        },
        single_or_friendly: {
          description: 'Jeszcze kilka pytań na temat Ciebie',
          hint: {
            description:
              'Jeżeli jesteś w związku małżeńskim bez rozdzielności majątkowej, do kredytu przystępujecie oboje. Banki umożliwiają zaciągnięcie kredytu z partnerem/partnerką czy też inną osobą, która nie będzie docelowo właścicielem nieruchomości.',
            title: 'Kredyt we dwoje.',
          },
          option: {
            loan_with_friend: {
              false: 'NIE',
              true: 'TAK',
            },
          },
          title: 'Planujesz zaciągnąć kredyt ze współwnioskodawcą?',
        },
        value: {
          hint: {
            PURCHASE: {
              description:
                'Podaj jej przybliżoną cenę, czyli kwotę, jaką jesteś gotowy zapłacić sprzedawcy, by stać się właścicielem.',
              title: 'Nie masz wybranej konkretnej nieruchomości?',
            },
            BUILD: {
              description:
                'Jeżeli ruszyły już prace budowlane, podaj wartość uwzględniającą poniesione koszty oraz koszty, które poniesiesz w przyszłości. Pamiętaj, że budując dom za pieniądze z banku musisz co najmniej doprowadzić budowę do etapu oddania do użytku.',
              title: 'Sprawdź, czym jest koszt budowy.',
            },
            REFINANCING: {
              description:
                'Jeżeli posiadasz kredyt w obcej walucie (innej niż PLN), podaj orientacyjną aktualną wysokość zadłużenia w PLN (pomnóż kwotę zadłużenia w walucie przez średni kurs NBP tej waluty).',
              title: 'Obca waluta.',
            },
            RENOVATION: {
              description:
                ' W tym miejscu podaj koszt całego planowanego remontu/wykończenia. Uwzględnij ewentualne środki własne.',
              title: 'Sprawdź, co wpisać.',
            },
          },
          input: {
            BUILD: {
              label: 'Koszt budowy',
            },
            CONSOLIDATION: {
              label: 'Kwota konsolidacji',
            },
            MORTGAGE: {
              label: 'Kwota pożyczki',
            },
            PURCHASE: {
              label: 'Cena zakupu',
            },
            REFINANCING: {
              label: 'Kwota refinansowania',
            },
            RENOVATION: {
              description: 'Koszt remontu powiększy aktualną wartość nieruchomości.',
              label: 'Koszt remontu / wykończenia',
            },
          },
          title: {
            BUILD: 'Jaki jest koszt planowanej budowy?',
            CONSOLIDATION: 'Jaka jest kwota konsolidacji?',
            MORTGAGE: 'Jaka jest kwota pożyczki?',
            PURCHASE: 'Jaka jest cena zakupu nieruchomości?',
            REFINANCING: 'Jaka jest pozostała kwota do spłaty refinansowanego kredytu hipotecznego?',
            RENOVATION: 'Jakie są koszty planowanego remontu / wykończenia?',
          },
        },
        value_land: {
          hint: {
            BUILD: {
              description: 'W takiej sytuacji wystarczy, że podasz przybliżoną kwotę.',
              title: 'Nie znasz dokładnej wartości swojej działki?',
            },
            RENOVATION: {
              description:
                'To inaczej cena, za jaką możesz sprzedać nieruchomość na wolnym rynku. Pamiętaj, by podać wartość nieruchomości przed remontem/wykończeniem..',
              title: 'Sprawdź, czym jest wartość nieruchomości.',
            },
          },
          input: {
            'purposes-value-land': {
              BUILD: {
                description: 'Wartość działki zostanie doliczona do ogólnej wartości nieruchomości.',
                label: 'Wartość działki',
                title: 'Podaj wartość działki',
              },
              RENOVATION: {
                label: 'Wartość nieruchomości',
                title: 'Podaj wartość nieruchomości (przed remontem / wykończeniem)',
              },
            },
          },
          title: {
            BUILD: 'Jaka jest / będzie wartość działki, na której realizujesz / zrealizujesz budowę?',
            RENOVATION: 'Jaka jest wartość remontowanej / wykańczanej nieruchomości?',
          },
        },
      },
      validation: {
        max: 'Maksymalna wartość: {value}',
        maxNumber: 'Max. możliwa wartość to {value}',
        maxLength: 'Podaj maksymalnie {value} znaków',
        min: 'Minimalna wartość: {value}',
        minLength: 'Podaj minimalnie {value} znaków',
        minNumber: 'Min. możliwa wartość to {value}',
        loanPercentage: 'Max. możliwa wartość to {value}',
        number: 'Wartość musi być liczbą',
        required: 'Pole jest wymagane',
      },
    },
  },
  general: {
    popular: 'Popularne',
    contactLabel: 'Zadaj pytanie',
    all: {
      male: 'Wszyscy',
      female: 'Wszystkie',
      others: 'Wszystko',
    },
    close: {
      leave: 'Wychodzę',
      message: 'Czy na pewno chcesz wyjść?',
      stay: 'Zostaję',
      contact: 'Chcę porozmawiać',
      'sub-message': 'Wychodząc tracisz okazję na znalezienie korzystnego kredytu. Może chcesz abyśmy zadzwonili i pomogli?',
    },
    confirm: {
      no: 'Nie',
      yes: 'Tak',
    },
    purpose: {
      PURCHASE: 'Zakup nieruchomości',
      BUILD: 'Budowa',
      RENOVATION: 'Remont/wykończenie',
      MORTGAGE: 'Pożyczka pod zastaw nieruchomości',
      CONSOLIDATION: 'Konsolidacja',
      REFINANCING: 'Refinansowanie kredytu hipotecznego',
      label: 'Budowa',
    },
    header: {
      logo: {
        alt: 'Notus S.A.',
      },
      'partner-logo': {
        alt: 'Partner Notus S.A.',
      },
    },
    incomeType: {
      DG: 'Działalność gospodarcza',
      None: 'Brak dochodu',
      Other: 'Inne',
      UoP: 'Umowa o pracę',
    },
    navigation: {
      back: 'Wstecz',
      close: 'Zamknij porównywarkę',
      next: 'Dalej',
    },
    periodToBegin: {
      ASAP: {
        label: 'Jak najszybciej',
      },
      OVER_HALF_YEAR: {
        label: 'Więcej niż 6 m-cy',
      },
      TBD: {
        label: 'Jeszcze nie wiem',
      },
      UP_TO_FEW_MONTHS: {
        label: 'W ciągu 2-3 m-cy',
      },
      UP_TO_HALF_YEAR: {
        label: 'W ciągu 4-6 m-cy',
      },
      UP_TO_MONTH: {
        label: 'W ciągu 30 dni',
      },
    },
    period: {
      OVER_TWO_YEARS: {
        label: 'Powyżej 24 m-cy',
      },
      TEMP: {
        label: 'Czas nieokreślony',
      },
      UP_TO_FEW_MONTHS: {
        label: '1-3 m-ce',
      },
      UP_TO_HALF_YEAR: {
        label: '4-6 m-cy',
      },
      UP_TO_TWO_YEARS: {
        label: '13-24 m-ce',
      },
      UP_TO_YEAR: {
        label: '7-12 m-cy',
      },
    },
    formLabels: {
      purpose: 'Cel kredytu',
      'purposes-value': {
        BUILD: 'Koszt budowy',
        CONSOLIDATION: 'Wartość nieruchomości',
        MORTGAGE: 'Wartość nieruchomości',
        PURCHASE: 'Cena zakupu',
        REFINANCING: 'Wartość nieruchomości',
        RENOVATION: 'Koszt remontu / wykończenia',
      },
      property_type: 'Rodzaj nieruchomości',
      property_value: 'Wartość nieruchomości',
      'loan-value': {
        BUILD: 'Wysokość kredytu',
        CONSOLIDATION: 'Kwota konsolidacji',
        MORTGAGE: 'Kwota pożyczki',
        PURCHASE: 'Wysokość kredytu',
        REFINANCING: 'Kwota refinansowania',
        RENOVATION: 'Wysokość kredytu',
      },
      'loan-period': 'Okres spłaty',
      loan_value: 'Kwota kredytu',
      loan_begin: 'Termin realizacji transakcji',
      'loan-begin': 'Termin realizacji transakcji',
      property_details_area: 'Powierzchnia nieruchomości',
      property_details_location: 'Lokalizacja nieruchomości',
      purposes_finishing: 'Remont / wykończenie',
      'purposes-finishing': 'Remont / wykończenie',
      purposes_finishing_value: 'Cena remontu / wykończenia',
      'purposes-finishing-value': 'Cena remontu / wykończenia',
      loan_applicant_multi: 'Współwnioskodawca',
      EU_citizien: 'Obywatelstwo UE',
      income_monthly_value: 'Dochód mies.',
      income_monthly_currency: 'Waluta',
      age: {
        MULTI: 'Wiek najstarszego współwniosk.',
        SOLO: 'Wiek wnioskodawcy',
      },
      income_time_from: 'Dochód wstecz',
      income_time_to: 'Dochód w przód',
      income_type: 'Źródło dochodu',
      income_UoPType: 'Rodzaj umowy o pracę',
      income_taxType: 'Opodatkowanie',
      income_otherType: 'Inne źródło dochodu',
      income_UoPMaternity: 'Urlop macierzyński',
      outcome_people: 'Ile osób w gospodarstwie',
      outcome_monthly: 'Zobowiązania mies.',
      outcome_limit_card: 'Limity na kartach',
      outcome_limit_account: 'Debety w kontach',
      outcome_mortgages: 'Liczba kredytów hipotecznych',
    },
    propertyType: {
      AGRICULTURAL_PARCEL: {
        title: 'Działka rolna',
      },
      BUILDING_PLOT: {
        title: 'Działka budowlana',
      },
      BUSINESS_PREMISES: {
        title: 'Lokal użytkowy',
      },
      FLAT: {
        title: 'Mieszkanie',
      },
      HABITAT: {
        title: 'Siedlisko',
      },
      HOUSE: {
        title: 'Dom',
      },
      RECREATIONAL_LAND: {
        title: 'Działka rekreacyjna',
      },
      SUMMER_HOUSE: {
        title: 'Dom letniskowy',
      },
    },
    required: {
      yes: {
        label: 'Wymagany',
      },
      no: {
        label: 'Niewymagany',
      },
    },
    select: {
      'none-value-label': 'Wybierz',
    },
    suffix: {
      year: 'rok | lata | lat',
      month: 'miesiąc | miesiące | miesięcy',
    },
    switch: {
      NO: {
        label: 'Nie',
      },
      YES: {
        label: 'Tak',
      },
    },
    hint: {
      'open-label': 'Więcej informacji',
      'close-label': 'Mniej informacji',
    },
  },
  page: {
    begin: {
      button: 'Zaczynamy',
      description: 'Odpowiedz na kilka pytań i ciesz się najlepszym kredytem.',
      help: {
        description: 'Szukaj podobnych podpowiedzi po prawej stronie ekranu jeśli nie wiesz co wpisać.',
        title: 'Pomoc na każdym etapie',
      },
      hint: 'Pamiętaj, że pytamy wyłącznie o informacje niezbędne do przedstawienia propozycji najlepiej dopasowanych do Twoich potrzeb.',
      image: {
        alt: 'Szczęśliwa rodzina na kanapie',
      },
      title: 'Znajdź najlepsze finansowanie na spełnienie marzeń',
    },
    form: {
      footer: {
        offers: {
          amount: 'kredyt | kredyty | kredytów',
          begin: 'Zobacz znalezione',
          none: 'Nie znaleziono kredytów spełniających podane kryteria',
          updating: 'Aktualizujemy listę kredytów',
        },
      },
      help: {
        leftTime: 'Do końca mniej niż minuta | Do końca tylko {minutes} minuty | Do końca tylko {minutes} minut',
        title: 'Odpowiedz na więcej pytań, aby dostać najlepsze oferty',
      },
    },
    generator: {
      description: 'Za chwilę je zobaczysz.',
      title: 'Twoje zestawienie jest generowane',
      help: {
        title: 'Czy wiesz, że...',
        description:
          'W Warszawie najwięcej ludzi bierze kredyt w wysokości 550 000 zł na okres 30 lat. Dzięki temu prawdopodobnie będzie Cię stać, żeby kupić 3 pokojowe mieszkanie w umiarkowanej odległości od centrum miasta',
      },
    },
    summary: {
      filter: {
        tooltips: {
          PURCHASE: '',
          BUILD: 'Suma kosztu budowy i wartości działki',
          RENOVATION: 'Suma wartości nieruchomości i kosztu remontu/wykończenia',
          MORTGAGE: 'Deklarowana obecna wartość nieruchomości',
          CONSOLIDATION: 'Deklarowana obecna wartość nieruchomości',
          REFINANCING: '',
        },
        additionalOptions: {
          COMMISSION_0_PERCENT: {
            label: 'Prowizja 0%',
            tooltip: 'Dla wybranych kryteriów brak ofert z prowizją 0%',
          },
          bankName: {
            label: 'Nazwa banku',
          },
          eco: {
            label: 'EKO',
            info: 'Oferty EKO są związane z rozwiązaniami energooszczędnymi, np. budowa domu z pompą ciepła, czy też z panelami fotowoltaicznymi',
          },
          instalment: {
            DECREASING: {
              label: 'Malejące',
            },
            EQUAL: {
              label: 'Równe',
            },
            label: 'Raty',
          },
          interestRate: {
            label: 'Rodzaj oprocentowania',
          },
          lifeInsurance: {
            label: 'Ubezpieczenie na życie',
          },
          title: 'Opcje dodatkowe',
        },
        additionalProducts: {
          title: 'Produkty dodatkowe',
          description:
            'Jeśli zdecydujesz się na produkty dodatkowe - propozycje kredytów będą dla Ciebie dużo korzystniejsze. Nie odrzucaj tego od razu.',
        },
        subProduct: {
          CREDIT_CARD: {
            label: 'Karty kredytowe',
          },
          DEBIT_CARD: {
            label: 'Karty debetowe',
          },
          PAYMENT_ON_ACCOUNT: {
            label: 'Wpłata na konto',
          },
          ROR: {
            label: 'ROR',
          },
        },
      },
      filters: {
        label: {
          closed: 'Więcej filtrów',
          opened: 'Mniej filtrów',
        },
        submit: 'Znajdź oferty',
      },
      otherOffers: {
        title: 'Pozostałe kredyty',
        info: '{amount} inny dostępny kredyt | {amount} inne dostępne kredyty | {amount} innych dostępnych kredytów',
        more: 'Pokaż więcej kredytów',
      },
      parameters: {
        loan_period: 'Okres spłaty',
        loan_value: 'Kwota kredytu',
        reload: {
          label: 'Odświeżyć ofertę?',
          no: 'Później',
          title: 'Zmiana parametrów',
          yes: 'Odśwież',
        },
        update: {
          label: 'Utracisz wówczas pozycje dodane do porównywarki.',
          title: 'Czy na pewno chcesz zmienić ten parametr?',
        },
        updateParam: {
          label: 'Poprosimy Cię wówczas o ponowne odpowiedzi na nasze pytania.',
          titlePurpose: 'Czy na pewno chcesz zmienić ten parametr?',
          titleSubApplicant: 'Czy na pewno chcesz zmienić ten parametr?',
        },
        titles: {
          loan_applicant_info: 'Informacje o kredytobiorcach',
          income_own: 'Twoje źródło dochodów',
          income_subApplicant: 'Źródła dochodów współwnioskodawcy',
        },
      },
      recommend: {
        box: {
          monthly_rate: 'Rata miesięczna',
          more_button: 'Zobacz szczegóły',
          CTA_contact: 'Zapytaj o ofertę',
          percents: 'Oprocentowanie',
          rrso: 'RRSO',
          total_amount: 'Całkowita kwota spłaty',
        },
        info: {
          begin: 'Zobacz {amount} kredyt | Zobacz {amount} kredyty | Zobacz {amount} kredytów',
          end: 'z {amount}, który jest najlepiej dopasowany do Twoich potrzeb | z {amount}, które są najlepiej dopasowane do Twoich potrzeb | z {amount}, które są najlepiej dopasowane do Twoich potrzeb',
        },
        title: 'Rekomendowane kredyty',
      },
      sort: {
        title: 'Sortuj wg',
        types: {
          FINAL_INSTALMENT_TOTAL: 'Najniższa rata',
          INTEREST_RATE: 'Najniższe oprocentowanie',
          TOTAL_COST: 'Najniższa całkowita kwota spłaty',
          COMMISSION: 'Najniższa marża',
        },
        label: {
          closed: 'Pokaż filtry',
          opened: 'Ukryj filtry',
        },
      },
      fetched: 'Oto nowe oferty odpowiadające wybranym parametrom',
      title:
        'Znaleźliśmy dla Ciebie {amount} kredyt | Znaleźliśmy dla Ciebie {amount} kredyty | Znaleźliśmy dla Ciebie {amount} kredytów',
      loader: {
        loading: 'Prosimy o chwilę cierpliwości – aktualizujemy listę najlepszych ofert',
        invalid:
          'Sprawdź poprawność wprowadzonych powyżej wartości lub&nbsp;skorzystaj z&nbsp;pomocy Eksperta i&nbsp;wypełnij formularz kontaktowy',
      },
      section: {
        compare: {
          button: 'Porównaj',
          begin: 'Wybrano',
          amount: '{amount} kredyt do porównania | {amount} kredyty do porównania  | {amount} kredytów do porównania ',
          beginMobile: 'Wybrano do porównania',
          amountMobile: '{amount} kredyt | {amount} kredyty | {amount} kredytów ',
        },
        why: {
          benefits: {
            wallet: {
              title: 'Mobilni Eksperci w&nbsp;całej Polsce',
              text: 'Spotkania w dogodnym miejscu i&nbsp;terminie, również online',
            },
            time: {
              title: 'Jesteśmy na rynku od&nbsp;20&nbsp;lat',
              text: 'Jedna z największych i najlepiej ocenianych firm pośrednictwa finansowego',
            },
            guard: {
              title: 'Ponad 1&nbsp;500&nbsp;000 zadowolonych klientów',
              text: 'Co 6 kredyt hipoteczny jest wypłacany z&nbsp;naszą pomocą',
            },
            expert: {
              title: 'Ponad 1&nbsp;500&nbsp;Ekspertów Finansowych',
              text: 'Doświadczeni profesjonaliści do&nbsp;Twojej dyspozycji',
            },
          },
        },
        footer: {
          copyright: 'Copyright © {year} NOTUS Finanse. Wszelkie prawa zastrzeżone',
          disclaimer:
            'NOTUS Finanse S.A. jest powiązanym pośrednikiem kredytu hipotecznego, wpisanym przez Komisję Nadzoru Finansowego do Rejestru pośredników kredytowych – Dział I, pod numerem wpisu RPH000030. Zgodnie z art. 17 Ustawy z dnia 23.03.2017 r. o kredycie hipotecznym oraz o nadzorze nad pośrednikami kredytu hipotecznego i agentami wszelkie informacje dla Klienta są dostępne na stronie internetowej <a href="https://www.notus.pl" target="_blank">notus.pl</a>',
          social: {
            facebookTitle: 'Odwiedź nas na Facebooku',
            twitterTitle: 'Odwiedź nas na Twitterze',
            youtubeTitle: 'Odwiedź nas na YouTube',
            linkedinTitle: 'Odwiedź nas na LinkedIn',
          },
        },
      },
    },
    comparition: {
      expand_all: 'Rozwiń wszystko',
      collapse_all: 'Zwiń wszystko',
      table: {
        title: {
          OWN: 'Twoje zestawienie kredytów',
          EXAMPLE: 'Przykładowe zestawienie kredytów',
        },
        section: {
          parameters: {
            general: 'Informacje ogólne',
            loan_applicant_info: 'Informacje o kredytobiorcach',
            sources_of_income: 'Twoje źródła dochodów',
            sources_of_income_co_applicant: 'Źródła dochodów współwnioskodawcy',
            showMore: 'Pokaż parametry',
            hideMore: 'Ukryj parametry',
          },
        },
        button: 'Pobierz porównanie',
        disclaimer:
          'Szczegóły oferty dostępne u Ekspertów NOTUS Finanse S.A. Informujemy, że ostateczne warunki oferty kredytowej są wyłączną decyzją banku, opartą na analizie indywidualnej sytuacji Klienta, dlatego też zaproponowane Klientowi warunki kredytowe (w tym wartość kredytu, wysokość raty, wymagane ubezpieczenia) mogą się różnić od obliczeń dokonanych przez NOTUS Finanse S.A. W ocenie zdolności kredytowej Bank bierze pod uwagę m.in. dochód wnioskodawcy i stan spłaty Jego dotychczasowych zobowiązań.  Materiał ma charakter wyłącznie informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 § 1 kodeksu cywilnego oraz innych właściwych przepisów prawa. Zawarcie umowy kredytu hipotecznego wiąże się z koniecznością zapewnienia bankowi zabezpieczenia w postaci hipoteki na kredytowanej lub innej nieruchomości.',
        parameters: {
          detailed_information: 'Informacje szczegółowe',
          loan_period: 'Okres spłaty',
          interest_rate: 'Oprocentowanie',
          interest_rate_type: 'Rodzaj oprocentowania',
          monthly_rate: 'Rata miesięczna',
          instalment_type: 'Rodzaj raty',
          margin: 'Marża',
          commission: 'Prowizja',
          valuation: 'Wycena',
          initial_costs: 'Koszty początkowe',
          total_amount_to_paid: 'Całkowita kwota do spłaty',
          LTV: 'LTV',
          insurance: 'Ubezpieczenia',
          mortage_insurance: 'Ubezpieczenie pomostowe',
          insurance_missing_contribution: 'Ubezpieczenie brakującego wkładu własnego',
          real_estate_insurance: 'Ubezpieczenie nieruchomości',
          life_insurance: 'Ubezpieczenie na życie',
          employment_insurance: 'Ubezpieczenie od utraty pracy',
          additional_products: 'Produkty dodatkowe',
          ROR: 'ROR',
          ROR_income_protection: 'Zabezpieczenie wpływów ROR',
          credit_card: 'Karta kredytowa',
          debit_card: 'Karta debetowa',
          monthly_instalment: 'Rata miesięczna',
          RRSO: 'RRSO',
        },
        tooltips: {
          interest_rate_type: `
          <p class="fw-bold mt-0 mb-0">Stałe</p>
          w tym przypadku obowiązuje zasada stałości oprocentowania kredytu w umówionym okresie (najczęściej 5 lat), czego konsekwencją jest stała rata kapitałowo - odsetkowa.
          <p class="fw-bold mb-0">Zmienne</p>
          oprocentowanie składa się z 2 składników: stałej marży banku oraz Wiboru, które ulega zmianie co 3 lub 6 m-cy (w zależności od banku).
          `,
          monthly_rate: 'Wysokość miesięcznej raty kapitałowo-odsetkowej',
          valuation: 'Oszacowanie wartości nieruchomości stanowiącej zabezpieczenie kredytu',
          initial_costs: 'Są to koszty, które ponosimy przed uruchomieniem kredytu w związku z jego zaciągnięciem.',
          LTV:
            '(z ang. Loan To Value), stosunek kwoty kredytu do wartości nieruchomości będącej jego' +
            ' zabezpieczeniem. Wysokość tego wskaźnika ma wpływ na oprocentowanie – im niższe LTV, tym niższe oprocentowanie.',
          loan_period:
            'Okres od dnia uruchomienia środków do dnia całkowitej spłaty kredytu wraz z odsetkami. Dla Twojej wygody podajemy go w latach. Natomiast okres kredytowania wyrażony w miesiącach jest równy liczbie rat kredytu.',
          interest_rate: 'Cena (koszt) za korzystanie z udostępnionego przez bank kapitału w skali roku.',
          commission: 'Opłata za przyznanie kredytu, płatna przed jego uruchomieniem lub doliczana do kwoty przyznanego kredytu.',
          margin: `<p> Element składowy oprocentowania kredytu, odzwierciedlający zysk banku z udzielenia kredytu. Wysokość marży zależy od poziomu wskaźnika LTV i kwoty kredytu.</p>
            <p>LTV (z ang. Loan To Value) to stosunek kwoty kredytu do wartości nieruchomości będącej jego zabezpieczeniem. Wysokość tego wskaźnika ma wpływ na oprocentowanie - im mniejsze LTV, tym niższe oprocentowanie.</p>`,
          instalment_type: `
          <p class="fw-bold mt-0 mb-0">Raty równe</p>
          Raty kapitałowo-odsetkowe, które zakładają spłatę kapitału i odsetek tak, żeby wysokość raty w całym okresie spłaty była taka sama; w miarę spłaty kredytu udział raty kapitałowej rośnie, a raty odsetkowej maleje.
          <p class="fw-bold mb-0">Raty malejące</p>
          Raty kapitałowo-odsetkowe złożone z równej raty kapitału oraz malejącej raty odsetkowej naliczonej od aktualnej kwoty zadłużenia – rata kapitałowa jest stała, rata odsetkowa zmniejsza się wraz ze zmniejszającą się kwotą zadłużenia.
          `,
          total_amount_to_paid:
            'Kwota, którą spłacimy przez cały okres kredytowania. Obejmuje pożyczony kapitał, a' +
            ' także odsetki, prowizje i inne opłaty związane z obsługą kredytu.',
          rrso:
            'Rzeczywista Roczna Stopa Oprocentowania - wyrażony procentowo wskaźnik pokazujący całkowity koszt' +
            ' kredytu. W przeciwieństwie do oprocentowania nominalnego do wyliczenia RRSO brane pod uwagę są nie tylko zapłacone odsetki, ale także inne koszty związane z obsługą kredytu (prowizja, ubezpieczenia, itp.).',
          mortage_insurance:
            'Ubezpieczenie kredytu hipotecznego stosowane przez bank, które stanowi zabezpieczenie' +
            ' banku do czasu uzyskania wpisu hipoteki do księgi wieczystej.',
          insurance_missing_contribution:
            'Dodatkowe ubezpieczenie, które stosuje bank w przypadku braku' +
            ' wystarczających środków finansowych na pokrycie wkładu własnego.',
          real_estate_insurance:
            'Polisa obejmuje najczęściej ubezpieczenie murów od ognia i innych zdarzeń losowych' +
            ' i jest dodatkowym zabezpieczeniem dla banku.',
          life_insurance: `<p>Zabezpieczenie banku, ale i kredytobiorcy i jego rodziny w przypadku:</p>
            <ul>
              <li>śmierci – polisa na życie na okres kredytu pozwala uniknąć problemu związanego z dziedziczeniem długu.</li>
              <li>trwałego inwalidztwa – dotyczy trwałego uszkodzenia ciała, które uniemożliwia wykonywanie pracy.</li>
              <li>niezdolności do pracy – jest to rozszerzenie pakietu dotyczącego trwałego inwalidztwa. Zazwyczaj obejmuje również ciężkie choroby.</li>
            </ul>`,
          employment_insurance:
            'Ubezpieczenie, które zapewnia wsparcie finansowe kredytobiorcy w sytuacji od utraty' +
            ' pracy. Może być ono wykupione indywidualnie w wybranym towarzystwie lub poprzez bank, w którym zaciągany jest kredyt hipoteczny',
          ROR: 'Rachunek Oszczędnościowo Rozliczeniowy to dodatkowy i opcjonalny produkt banku, który może uatrakcyjnić warunki cenowe kredytu.',
          ROR_income_protection:
            'Wymóg zapewnienia co miesiąc określonych wpływów na ROR i aktywne korzystanie z karty płatniczej, debetowej lub kredytowej.',
          credit_card: 'Dodatkowy i opcjonalny produkt banku, który może uatrakcyjnić warunki cenowe kredytu.',
          debit_card: 'Dodatkowy i opcjonalny produkt banku, który może uatrakcyjnić warunki cenowe kredytu.',
        },
        lackOf: {
          real_estate_insurance: 'Obowiązkowe, we własnym zakresie.',
        },
        offer_details:
          'Szczegóły oferty dostępne u Ekspertów NOTUS Finanse S.A. Informujemy, że ostateczne warunki oferty kredytowej są wyłączną decyzją banku, opartą na analizie indywidualnej sytuacji Klienta, dlatego też zaproponowane Klientowi warunki kredytowe (w tym wartość kredytu, wysokość raty, wymagane ubezpieczenia) mogą się różnić od obliczeń dokonanych przez NOTUS Finanse S.A. W ocenie zdolności kredytowej Bank bierze pod uwagę m.in. dochód wnioskodawcy i stan spłaty Jego dotychczasowych zobowiązań.  Materiał ma charakter wyłącznie informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 § 1 kodeksu cywilnego oraz innych właściwych przepisów prawa. Zawarcie umowy kredytu hipotecznego wiąże się z koniecznością zapewnienia bankowi zabezpieczenia w postaci hipoteki na kredytowanej lub innej nieruchomości.',
        interest_rate_type_VARIABLE: 'Zmienne',
        interest_rate_type_CONSTANT: 'Stałe',
        instalment_type_EQUAL: 'Równa',
        instalment_type_DECREASING: 'Malejąca',
        percent: '%',
        months: 'm.',
        frequency_6M: 'Półrocznie',
        frequency_48M: 'Co cztery lata',
        frequency_36M: 'Co trzy lata',
        frequency_1Y: 'Rocznie',
        frequency_1Q: 'Kwartalnie',
        frequency_1O: 'Jednorazowo',
        frequency_1M: 'Miesięcznie',
        frequency_12M: 'Rocznie',
        frequency_undefined: '-',
        period: ', {n} miesiąc | {n} miesiące | {n} miesięcy',
        valuations: {
          BANK: 'Wycena przez bank',
          EXTERNAL: 'Wycena samodzielna',
        },
      },
      boxes: {
        button: 'Zmień kryteria',
        title: 'Porównanie kredytów',
        section: {
          showMore: {
            title: 'Porównaj znalezione kredyty w każdym aspekcie',
            description:
              'Wystarczy, że podasz swoje imię i numer telefonu, a otrzymasz od nas profesjonalne, szczegółowe porównanie kredytów.',
            exampleButton: 'Zobacz przykładowe porównanie',
            detailedButton: 'Zobacz pełne porównanie',
          },
        },
      },
      limitInfo: {
        title: 'Maksymalnie możesz porównać 8 kredytów',
        description: 'Pozwoli Ci to łatwiej porównać najważniejsze informacje o zaproponowanych ofertach.',
        buttonTitle: 'Porównaj',
      },
    },
    contact: {
      backButton: 'Wstecz',
      title: {
        LEADING_DETAILS: 'Uzupełnij dane dotyczące kontaktu telefonicznego',
        INCORRECT_AGE: 'Klientów, w Twoim wieku traktujemy wyjątkowo',
        NO_INCOME: 'Zrobimy wszystko, aby Twoje marzenia się spełniły',
        NO_OFFERS: 'Zrobimy wszystko, aby Twoje marzenia się spełniły',
        NON_PLN_CURRENCY: 'Aby wziąć kredyt w obcej walucie - zostaw dane kontaktowe',
        SUMMARY_NO_OFFERS: 'Zrobimy wszystko, aby Twoje marzenia się spełniły',
        CTA_INTERESTED: 'Zrobimy wszystko, aby Twoje marzenia się spełniły',
        ERROR: 'Zrobimy wszystko, aby Twoje marzenia się spełniły',
        CHAT: 'Uzupełnij dane dotyczące kontaktu telefonicznego',
      },
      form: {
        title: 'Umów się na spotkanie z ekspertem',
        sub_title: 'Oddzwonimy i pomożemy!',
        name: {
          label: 'Twoje imię',
        },
        phone: {
          label: 'Twój numer telefonu',
          error: 'Nieprawidłowy numer telefonu',
        },
        consent:
          'Chcę być informowany telefonicznie o ofertach promocyjnych, akcjach specjalnych, produktach i usługach oferowanych przez NOTUS Finanse S.A.',
      },
      benefits_title: 'Dlaczego warto spotkać się z ekspertem Notus',
      benefits_list_1: '<b>bezpłatnie dobierze ofertę</b> do Twoich indywidualnych potrzeb',
      benefits_list_2: 'pomoże zrozumieć szczegóły oferty <b>i&nbsp;odpowie na wszystkie pytania</b>',
      benefits_list_3: 'pomoże złożyć wniosek  <b>i&nbsp;szybko uzyskać decyzję kredytową</b>',
      benefits_list_4: 'zadba o Twoje finanse osobiste <b>i&nbsp;zapewni kompleksową obsługę</b>',
      sub_title: 'Nasz specjalista pomoże Ci znaleźć drogę do uzyskania kredytu.',
      send_CTA: 'Umów kontakt',
      info_1: 'Informujemy, że ostateczny kształt oferty kredytowej jest wyłączną decyzją banków...',
      info_2: 'Administratorem danych jest NOTUS Finanse S.A...',
      info_3: 'Zapoznaj się z',
      info_3_link: 'Regulaminem',
      read_more: 'Czytaj całość',
      search_title: 'W którym oddziale się spotkamy?',
      search_label: 'Wpisz miejscowość',
      info_window: {
        title: 'Notus Finanse',
        phone: 'Tel.:',
        weekdays: 'Poniedziałek-Piątek:',
        saturday: 'Sobota:',
        sunday: 'Niedziela:',
        closed: 'Zamknięte',
      },
      popup_1:
        'Szczegóły oferty dostępne u Ekspertów NOTUS Finanse S.A. Informujemy, że ostateczne warunki oferty kredytowej są wyłączną decyzją banku, opartą na analizie indywidualnej sytuacji Klienta, dlatego też zaproponowane Klientowi warunki kredytowe (w tym wartość kredytu, wysokość raty, wymagane ubezpieczenia) mogą się różnić od obliczeń dokonanych przez NOTUS Finanse S.A. W ocenie zdolności kredytowej Bank bierze pod uwagę m.in. dochód wnioskodawcy i stan spłaty Jego dotychczasowych zobowiązań.  \n' +
        '<p>Materiał ma charakter wyłącznie informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 § 1 kodeksu cywilnego oraz innych właściwych przepisów prawa.</p>\n' +
        'Zawarcie umowy kredytu hipotecznego wiąże się z koniecznością zapewnienia bankowi zabezpieczenia w postaci hipoteki na kredytowanej lub innej nieruchomości.\n',
      popup_2: `<p class="fw-bold">Klauzula informacyjna dotycząca przetwarzania danych osobowych przez NOTUS Finanse S.A.</p>
            <p class="fw-bold">Administrator danych</p>
            <p>Administratorem Pani/Pana danych jest NOTUS Finanse S.A. z siedzibą w Warszawie (02-566), przy ul. Puławskiej 2.</p>
            <p class="fw-bold">Dane kontaktowe</p>
            Z administratorem można się skontaktować poprzez<br/>
            adres email: <a class="color-accent" href="mailto:iod{'@'}notus.pl">iod{'@'}notus.pl</a>,<br/>
            pod numerem telefonu <a class="color-accent" href="tel:+48 (22) 596 39 62">+48 (22) 596 39 62</a>,
            <a class="color-accent" href="tel:+48 (22) 596 39 63">+48 (22) 596 39 63</a>  <br/>
            lub pisemnie (adres siedziby administratora).
            <p>
              U administratora danych wyznaczony jest inspektor ochrony danych, z którym można się skontaktować<br/>
               poprzez email: <a class="color-accent" href="mailto:iod{'@'}notus.pl">iod{'@'}notus.pl</a>, <br/>
               pod numerem telefonu <a class="color-accent" href="tel:+48 (22) 596 39 62">+48 (22) 596 39 62</a>,
              <a class="color-accent" href="tel:+48 (22) 596 39 63">+48 (22) 596 39 63</a> <br/>
              lub pisemnie (adres siedziby administratora). Z inspektorem ochrony danych można się kontaktować we wszystkich sprawach
              dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych.
            </p>
            <p class="fw-bold">Cele przetwarzania oraz podstawa prawna przetwarzania</p>
            <p>Pani/Pana dane będą przetwarzane:</p>
            <ul>
              <li>
                w celu realizacji zamówionego, zindywidualizowanego kontaktu z przedstawicielem NOTUS
                Finanse S.A., dostosowaniu rozmowy do Pani/Pana preferencji i potrzeb — podstawą prawną
                przetwarzania danych jest prawnie uzasadniony interes administratora polegający na możliwości
                realizacji zamówionego, zindywidualizowanego kontaktu z przedstawicielem NOTUS Finanse S.A.
                (art. 6 ust. 1 lit. f ogólnego rozporządzenia o ochronie danych osobowych nr 2016/679, dalej: RODO);
              </li>
              <li>
                w celach marketingowych – tj. skontaktowania się z Panem/Panią drogą telefoniczną
                (rozmowa telefoniczna, komunikaty głosowe IVR) w sprawie przekazania Panu/Pani informacji
                o ofertach instytucji finansowych współpracujących z NOTUS Finanse S.A.,
                jeśli wyraził Pan/wyraziła Pani uprzednio zgodę na kontakt telefoniczny ww. celu,
              </li>
              <li>
                w celu przygotowania zestawienia kredytów – podstawą prawną przetwarzania danych
                jest prawnie uzasadniony interes administratora polegający na możliwości
                zaprezentowania zestawienia kredytów zgodnie z Pani/Pana założeniami (art. 6 ust. 1 lit. f RODO);
              </li>
              <li>
                w celu ewentualnego ustalenia i dochodzenia roszczeń lub obrony przed roszczeniami
                – podstawą prawną przetwarzania jest prawnie uzasadniony interes administratora
                polegający na ochronie jego praw (art. 6 ust. 1 lit. f RODO).
              </li>
            </ul>
            <p class="fw-bold">Okres przez który dane będą przechowywane</p>
            Pani/Pana dane osobowe będą przetwarzane do momentu wyrażenia, przez Panią/Pana,
            skutecznego sprzeciwu wobec przetwarzania danych osobowych,
            jednak nie dłużej jak do czasu realizacji zamówionego kontaktu.
            <p>Okres przetwarzania danych może być przedłużony w przypadku, gdy dalsze przetwarzanie stanie się
            niezbędne do ustalenia i dochodzenia ewentualnych roszczeń lub obrony przed roszczeniami,
            a po tym czasie jedynie w przypadku i w zakresie, w jakim będą wymagać tego przepisy prawa.</p>
            Po upływie okresu przetwarzania dane są nieodwracalnie usuwane lub anonimizowane.
            <p class="fw-bold">Odbiorcy danych</p>
            Pani/Pana dane mogą być przekazywane podmiotom przetwarzającym dane osobowe na
            zlecenie administratora tj. dostawcom zapewniającym obsługę klienta (Call Center)
            – przy czym takie podmioty przetwarzają dane osobowe na podstawie umowy z administratorem
            i wyłącznie zgodnie z poleceniami administratora.
            <p class="fw-bold">Prawa osoby, której dane dotyczą</p>
            Przysługuje Pani/Panu prawo dostępu do Pani/Pana danych oraz prawa żądania ich sprostowania,
            usunięcia, ograniczenia przetwarzania.
            <p>W zakresie, w jakim podstawą przetwarzania Pani/Pana danych osobowych jest przesłanka prawnie
             uzasadnionego interesu administratora, przysługuje Pani/Panu prawo do wniesienia w dowolnym momencie
             sprzeciwu wobec przetwarzania Pani/Pana danych osobowych, z przyczyn związanych z Pani/Pana szczególną sytuacją.
            </p>
            <p>W zakresie, w jakim podstawą przetwarzania Pani/Pana danych osobowych jest zgoda, przysługuje Panu/Pani
            prawo wycofania powyższej zgody w dowolnym momencie poprzez wysłanie wiadomości e-mail na adres
             <a class="color-accent" href="mailto:zgoda{'@'}notus.pl">zgoda{'@'}notus.pl</a>.
            Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania w celach marketingowych dokonanego przed jej wycofaniem.
            </p>
            W celu realizacji powyższych praw należy skontaktować się z administratorem danych. Można to zrobić,
            np. przesyłając stosowny wniosek na adres siedziby administratora lub mailowo na adres
            <a class="color-accent" href="mailto:zgoda{'@'}notus.pl">zgoda{'@'}notus.pl</a>, bądź kontaktując się z jego przedstawicielem.
            <p>
              Przysługuje Pani/Panu także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych
              osobowych — w szczególności w państwie członkowskim UE Pani/Pana zwykłego pobytu, miejsca pracy
              lub miejsca popełnienia domniemanego naruszenia — gdy uzna Pani/Pan, że przetwarzanie Pani/Pana danych
              osobowych narusza przepisy Rozporządzenia 2016/679. W Polsce takim organem nadzorczym jest
              Prezes Urzędu Ochrony Danych Osobowych.
            </p>
            <p class="fw-bold">Informacja o zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu</p>
            W związku z przetwarzaniem Pani/Pana danych osobowych w powyższym celu nie zachodzi
            zautomatyzowane podejmowanie decyzji, w tym profilowanie.
            <p class="fw-bold">Informacja o dobrowolności podania danych</p>
            Podanie danych osobowych w celu realizacji zamówionego kontaktu z przedstawicielem NOTUS
            Finanse S.A. jest dobrowolne, ale niezbędne do realizacji tego kontaktu.
            <p>Podanie danych w celu zaprezentowania zestawienia kredytów jest dobrowolne, ale niezbędne do
              przygotowania zestawienia.</p>`,
      success: {
        title: 'Dziękujemy!',
        sub_title: 'Pomyślnie wysłano formularz kontaktowy!',
        call: 'Spodziewaj się naszego telefonu:',
        period: 'pon. - pt.  8:00 - 19:00',
        footer_title: 'Nadal masz możliwość zapoznać się z zestawieniem kredytów!',
        footer_sub_title: 'Wróć do listy ofert i dowolnie zmieniaj parametry wyszukiwania.',
        cta: 'Idź do ofert',
      },
      error: {
        title: 'Coś poszło nie tak',
        sub_title: 'Nasz system dostał chwilowego zawrotu głowy. Prosimy spróbuj jeszcze raz.',
        cta: 'Powrót do wyszukiwarki',
      },
    },
    info: {
      title: {
        LEAD_DETAILS: 'Pomyślnie wysłano dane dotyczące kontaktu',
        CHAT: 'Pomyślnie wysłano dane dotyczące kontaktu',
        ERROR: 'Coś poszło nie tak',
        WRONG_HASH: 'Coś poszło nie tak',
        OFFER_NOT_FOUND: 'Niestety nie udało się znaleźć odpowiedniej oferty',
        SUCCESS_WITH_BEGIN: 'Pomyślnie wysłano dane dotyczące kontaktu',
      },
      subtitle: {
        OFFER_NOT_FOUND: 'Możesz zacząć od nowa, modyfikując niektóre odpowiedzi.',
        CHAT: 'Możesz wrócić do listy ofert <span class="fw-bold">i otrzymać pełne porównanie wybranych kredytów.</span>',
        LEAD_DETAILS:
          'Zapoznaj się z Twoim pełnym zestawieniem kredytów. Możesz również wrócić do listy ofert i dowolnie zmieniać parametry kredytów.',
        ERROR: 'Prosimy spróbuj jeszcze raz',
        WRONG_HASH: 'Prosimy spróbuj jeszcze raz',
        SUCCESS_WITH_BEGIN:
          'Możesz poszukać innych ofert <span class="fw-bold">i otrzymać pełne porównanie wybranych kredytów</span>',
      },
      primaryButton: {
        LEAD_DETAILS: 'Pokaż szczegółowe porównanie',
        CHAT: 'Wyświetl przykładowe oferty',
        ERROR: 'Zacznij od nowa',
        WRONG_HASH: 'Wróć do aplikacji',
        OFFER_NOT_FOUND: 'Zacznij od nowa',
        SUCCESS_WITH_BEGIN: 'Powrót do początku',
      },
      secondaryButton: {
        CHAT: 'Wróć do odpowiedzi na pytania',
        LEAD_DETAILS: 'Powrót do listy ofert',
        ERROR: 'Skontaktuj się z nami',
        WRONG_HASH: '<a class="color-accent" href="tel:+48603804805"> Zadzwoń </a>',
        OFFER_NOT_FOUND: 'Skontaktuj się z nami',
      },
    },
  },
  section: {
    intro: {
      title: 'Porównywarka kredytów hipotecznych',
      description: 'Zapytaj o kredyt mieszkaniowy <b>„na Start”</b>',
      button: 'Umów kontakt',
    },
    hero: {
      paragraph1: 'Na start znajdź dla siebie kredyt',
      paragraph2: 'My zajmiemy się resztą',
      alt: 'Wygodni polecają',
    },
    'cta-interested': {
      title: {
        general: 'Zainteresowały Cię znalezione oferty?',
        noOffers: 'Wygląda na to, że żadna oferta nie spełniła Twoich wymagań, ale to nie koniec!',
        specific: 'Zainteresował Cię ten kredyt?',
      },
      description: {
        general: 'Skontaktuj się z nami, jeśli masz jakieś pytania lub wątpliwości. Nasi konsultanci chętnie pomogą.',
        noOffers:
          'Skontaktuj się z naszym Ekspertem, który przeanalizuje Twoje potrzeby, zoptymalizuje Twoje możliwości kredytowe i pomoże Ci w całym procesie!',
        specific: 'Jeśli zainteresowała Cię ta oferta lub masz pytania – skontaktuj się z nami',
      },
      button: {
        general: 'Zapytaj o kredyt',
        noOffers: 'Idź do formularza',
      },
    },
  },
};

export {lang_pl};
