
import {Options, Vue} from 'vue-class-component';
import NotusFormInput from '@/app/modules/shared/components/form-input/form-input.component.vue';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {namespace} from 'vuex-class';

const formModule = namespace('form');
@Options({
  name: 'NotusLeadContact',
  components: {NotusFormInput},
})
export default class NotusLeadContact extends Vue {
  @formModule.State('formLead') formLead!: CustomFormGroup;
}
