import getters from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {FormInputProperty, state} from './state';

export type FormControlOptions = FormInputProperty & {
  inputName: DynamicFormControlName;
  isIncomeForm?: boolean;
  forceValue?: boolean;
};

export const FormStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
