import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1e267a52")
const _hoisted_1 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.svgPath)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.svgPath,
        class: _normalizeClass(["svg-icon", {'svg-icon--covered': _ctx.cover}])
      }, null, 10, _hoisted_1))
    : _createCommentVNode("", true)
}