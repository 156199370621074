
import {Options, Vue} from 'vue-class-component';
import {namespace} from 'vuex-class';
import {watch} from 'vue';
import * as _ from 'lodash';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import NotusFormInput from '@/app/modules/shared/components/form-input/form-input.component.vue';
import NotusFormDropdown from '@/app/modules/shared/components/form-dropdown/form-dropdown.component.vue';
import NotusFormSwitch from '@/app/modules/shared/components/form-switch/form-switch.component.vue';
import NotusFormCheckbox from '@/app/modules/shared/components/form-checkbox/form-checkbox.component.vue';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import NotusExpandPanel from '@/app/modules/shared/components/expand-panel/expand-panel.component.vue';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {OffersActionTypes} from '@/store/modules/OffersModule/actions/action-types';
import {ActionTypes as FormActionTypes} from '@/store/modules/FormModule/actions/action-types';
import {debounce} from 'lodash';
import {FORM_CONFIG} from '@/app/config/form.config';
import {getLabelTranslated} from '@/app/modules/shared/services/form-parameters.service';
import {FORM_INPUTS_CONFIG} from '@/app/config/input-form.config';
import {FormControlOptionsCard} from '@/app/modules/page-form/interfaces/form-control-option-card.interface';
import {SelectGroup} from '@/app/modules/shared/components/form-dropdown/select-group.interface';
import {RadioOption} from '@/app/modules/shared/components/form-radiobutton-group/radio-option.interface';
import {AvailableCustomInputs, CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {SwitchOptions} from '@/app/modules/page-form/interfaces/form-control-switch.interface';
import {INSTALMENT_TYPES, INTEREST_RATES_TYPES} from './summary-parameters.config';
import {AvailableFormValue, KEY_0_COMMISSION} from '@/store/modules/FormModule/state';
import {Tooltip as VTooltip} from 'v-tooltip';
import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';
const formModule = namespace('form');
const offersModule = namespace('offers');

@Options({
  name: 'NotusSummaryParameters',
  components: {
    NotusFormInput,
    NotusExpandPanel,
    NotusFormDropdown,
    NotusFormSwitch,
    NotusFormCheckbox,
    VTooltip,
    NotusSvgIcon,
  },
})
export default class NotusSummaryParameters extends Vue {
  @formModule.Getter(GetterTypes.form) form: any;
  @formModule.Getter(GetterTypes.formFilters) formFilters!: CustomFormGroup;
  @formModule.Getter(GetterTypes.isValid) isValid!: boolean;
  @formModule.Getter(GetterTypes.commission0Available) commission0Available!: boolean;
  @formModule.Action(FormActionTypes.setValidation) setValidation: any;
  @formModule.Action(FormActionTypes.clearInvalids) clearInvalids: any;
  @formModule.Action(FormActionTypes.resetValidation) resetValidation: any;
  @formModule.Action(FormActionTypes.setCommission0Available) setCommission0Available: any;
  @formModule.Getter(GetterTypes.valueByName) valueByName!: any;
  @offersModule.Action(OffersActionTypes.setFilters) setFilters: any;
  @offersModule.Action(OffersActionTypes.filterOffers) filterOffers: any;
  @offersModule.Action(OffersActionTypes.reloadResults) reloadResults: any;

  public dynamicFormControlName = DynamicFormControlName;
  public loanValueControlName = DynamicFormControlName['loan-value'];
  public loanPeriodControlName = DynamicFormControlName['loan-period'];

  public purposesValuePlaceholder = FORM_INPUTS_CONFIG[DynamicFormControlName['purposes-value']].placeholder;
  public loanValuePlaceholder = FORM_INPUTS_CONFIG[DynamicFormControlName['loan-value']].placeholder;
  public loanPeriodPlaceholder = FORM_INPUTS_CONFIG[DynamicFormControlName['loan-period']].placeholder;

  public purposeOptions: SelectGroup[] = [
    {
      options: (FORM_INPUTS_CONFIG[DynamicFormControlName.purpose] as FormControlOptionsCard<DynamicFormControlName>)
        .options as Array<RadioOption>,
    },
  ];

  //OrderBy Options
  instalmentTypes: SwitchOptions = INSTALMENT_TYPES;
  interestRateTypes: SwitchOptions = INTEREST_RATES_TYPES;

  loanValueRefresher = 0;
  prevFilterProperties: {[key: string]: AvailableFormValue} = {};

  created(): void {
    let wasInitDebounce = false;
    watch(
      this.form,
      debounce((newValue) => {
        const value = newValue[this.loanValueControlName];
        const period = newValue[this.loanPeriodControlName];

        this.setValidation({controlName: this.loanValueControlName, isInvalid: !value.valid});
        this.setValidation({controlName: this.loanPeriodControlName, isInvalid: !period.valid});
        if (wasInitDebounce) {
          this.setCommission0Available(true);
        }
        wasInitDebounce = true;
        this.loanValueRefresher++;
        this.$forceUpdate();
      }, FORM_CONFIG.debounce)
    );

    watch(
      this.formFilters,
      (state) => {
        let filterProperties: {[key: string]: AvailableFormValue} = {};
        let isNon0CommisionChanged = false;

        Object.entries(state as CustomFormGroup).forEach((entire) => {
          let input = entire[1] as AvailableCustomInputs;
          if (input.type === 'select') {
            filterProperties[entire[0]] = (input.value as Array<any>)[0];
          } else {
            filterProperties[entire[0]] = input.value;
          }
          if (entire[0] !== KEY_0_COMMISSION && this.prevFilterProperties[entire[0]] !== filterProperties[entire[0]]) {
            isNon0CommisionChanged = true;
          }
        });
        this.prevFilterProperties = filterProperties;
        if (isNon0CommisionChanged) {
          this.setCommission0Available(true);
        }
        this.setFilters(filterProperties);
      },
      {deep: true, immediate: true}
    );
  }

  unmounted() {
    this.clearInvalids();
    this.resetValidation();
  }

  getLabel(controlName: DynamicFormControlName): string {
    return getLabelTranslated(controlName);
  }

  showTooltip(controlName: DynamicFormControlName): boolean {
    const type = this.valueByName(controlName);
    return !(type === FormPurpose.PURCHASE || type === FormPurpose.REFINANCING);
  }

  getTooltipContent(controlName: DynamicFormControlName): string {
    return `page.summary.filter.tooltips.${this.valueByName(controlName)}`;
  }

}
