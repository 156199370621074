import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {FormConfigFormsKey} from '@/app/config/dynamic-form.config';
import {OrderType} from '@/app/modules/page-summary/models/order-type.enum';

export type FiltersType = {[key: string]: string | boolean | string[]};

export const INIT_OFFERS_STATE: OffersState = {
  isLoading: false,
  isInitialized: false,
  allOffers: [],
  offers: [],
  reloadPurpose: null,
  orderType: undefined,
  filters: {},
};

export type OffersState = {
  isLoading: boolean;
  isInitialized: boolean;
  allOffers: OfferResponse[] | undefined;
  offers: OfferResponse[] | undefined;
  reloadPurpose?: FormConfigFormsKey | null;
  detailedOffer?: OfferResponse;
  orderType?: OrderType;
  filters: FiltersType;
};

export const state: OffersState = {...INIT_OFFERS_STATE};
