import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-c2efeea8")
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "link fs-18"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.href)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "link fs-18",
        href: _ctx.href
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
}