import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2cfd1a48")
const _hoisted_1 = ["for"]
const _hoisted_2 = ["name", "value", "checked"]
const _hoisted_3 = ["name", "value", "checked"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: _ctx.controlName,
      class: "input-label"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_1),
    (_ctx.options)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["switch d-flex align-items-center justify-content-center", {'w-100 switch_fit-to-container': _ctx.fitToContainer}])
        }, [
          _createElementVNode("div", {
            tabindex: "0",
            class: "switch__control position-relative",
            onKeydown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onKeydown($event)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["switch__control-btn position-absolute", {
          'on-left': _ctx.options.left.selected,
          'on-right': _ctx.options.right.selected,
        }])
            }, null, 2),
            _createElementVNode("label", {
              class: _normalizeClass(["switch__control-side side-left position-absolute d-flex align-items-center justify-content-center fw-semi-bold fs-18", {'active-side': _ctx.options.left.selected}]),
              onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHoverHint(_ctx.options.left.hint, true))),
              onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setHoverHint())),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSwitch('left')))
            }, [
              _createElementVNode("input", {
                type: "radio",
                name: _ctx.controlName,
                value: _ctx.options.left.value,
                checked: _ctx.options.left.selected,
                class: "invisible w-0 h-0 m-0"
              }, null, 8, _hoisted_2),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.options.left.label)), 1)
            ], 34),
            _createElementVNode("label", {
              class: _normalizeClass(["switch__control-side side-right position-absolute d-flex align-items-center justify-content-center fw-semi-bold fs-18", {'active-side': _ctx.options.right.selected}]),
              onMouseover: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setHoverHint(_ctx.options.right.hint, true))),
              onMouseout: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setHoverHint())),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSwitch('right')))
            }, [
              _createElementVNode("input", {
                type: "radio",
                name: _ctx.controlName,
                value: _ctx.options.right.value,
                checked: _ctx.options.right.selected,
                class: "invisible w-0 h-0 m-0"
              }, null, 8, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.options.right.label)), 1)
            ], 34)
          ], 32)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}