import {PropertyType} from '@/app/modules/page-form/enums/property-type.enum';

export const DYNAMIC_FORM_AVAILABLE_PROPERTY = {
  FLAT: {
    label: 'general.propertyType.FLAT.title',
    value: PropertyType.FLAT,
  },
  HOUSE: {
    label: 'general.propertyType.HOUSE.title',
    value: PropertyType.HOUSE,
  },
  BUSINESS_PREMISES: {
    label: 'general.propertyType.BUSINESS_PREMISES.title',
    value: PropertyType.BUSINESS_PREMISES,
  },
  SUMMER_HOUSE: {
    label: 'general.propertyType.SUMMER_HOUSE.title',
    value: PropertyType.SUMMER_HOUSE,
  },
  BUILDING_PLOT: {
    label: 'general.propertyType.BUILDING_PLOT.title',
    value: PropertyType.BUILDING_PLOT,
  },
  RECREATIONAL_LAND: {
    label: 'general.propertyType.RECREATIONAL_LAND.title',
    value: PropertyType.RECREATIONAL_LAND,
  },
  AGRICULTURAL_PARCEL: {
    label: 'general.propertyType.AGRICULTURAL_PARCEL.title',
    value: PropertyType.AGRICULTURAL_PARCEL,
  },
  HABITAT: {
    label: 'general.propertyType.HABITAT.title',
    value: PropertyType.HABITAT,
  },
};
