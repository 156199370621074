import numeral from 'numeral';

numeral.register('locale', 'pl', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'tys.',
    million: 'mln',
    billion: 'mld',
    trillion: 'bln',
  },
  ordinal: function () {
    return '.';
  },
  currency: {
    symbol: 'PLN',
  },
});

numeral.locale('pl');
