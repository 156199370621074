import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';

export const INIT_COMPARE_STATE: CompareState = {
  selectedToCompare: [],
};

export type CompareState = {
  selectedToCompare: OfferResponse[];
};

export const state: CompareState = INIT_COMPARE_STATE;
