import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-56acad00")
const _hoisted_1 = {
  key: 0,
  class: "input-label"
}
const _hoisted_2 = { class: "dropdown__formcontrol position-relative d-flex" }
const _hoisted_3 = { class: "dropdown__control-label fw-semi-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 0,
  class: "d-block group-label text-uppercase fs-12 d-flex align-items-center text-nowrap"
}
const _hoisted_8 = ["onClick", "onKeydown"]
const _hoisted_9 = {
  key: 1,
  class: "input_description mx-4 mb-0 mt-3 mt-lg-0"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_notus_form_checked_icon = _resolveComponent("notus-form-checked-icon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown", {'dropdown--inline': _ctx.isInline}])
  }, [
    (!_ctx.isInline)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["dropdown__control d-flex align-items-center justify-content-center", {'w-100': _ctx.fitToContainer, 'has-selected': _ctx.isNotEmptySelects, 'content-open': _ctx.isCollapsed}]),
        tabindex: "0",
        onMouseup: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDropdown())),
        onKeydown: [
          _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.toggleDropdown()), ["enter"])),
          _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.toggleDropdown()), ["space"]))
        ]
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isInline)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isInline && _ctx.labels.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "d-flex align-items-center justify-content-between fw-semi-bold",
                innerHTML: _ctx.labelsShortcut()
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (!_ctx.isNotEmptySelects)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t(_ctx.noneValueLabel)), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["icon", {rotated: _ctx.isCollapsed}])
        }, [
          _createVNode(_component_notus_svg_icon, { iconType: "chevron-down" })
        ], 2)
      ], 34),
      (_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["dropdown__content", {'dropdown__content--multiple': _ctx.multiple, 'w-100': _ctx.fitToContainer}])
          }, [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass(["dropdown__scroll", {'pe-0 me-0': !_ctx.multiple}]),
              ref: "scrollView"
            }, [
              (_ctx.multiple && _ctx.withAllOption)
                ? (_openBlock(), _createElementBlock("label", {
                    key: 0,
                    tabindex: "0",
                    class: "dropdown__checkbox-label d-flex align-items-center fw-semi-bold fs-18 mb-4 mt-2",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleAllOption())),
                    onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.toggleAllOption()), ["enter"]))
                  }, [
                    _createVNode(_component_notus_form_checked_icon, {
                      isSelected: _ctx.allSelected,
                      iconType: 'checked-icon-primary',
                      iconStyle: 'classic-checkbox'
                    }, null, 8, ["isSelected"]),
                    _createElementVNode("span", {
                      class: _normalizeClass({'ms-2': _ctx.multiple, selected: _ctx.allSelected})
                    }, _toDisplayString(_ctx.$t('general.all.female')), 3)
                  ], 32))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: group.name
                }, [
                  (_ctx.hasGroups)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(group.name)), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, (option, j) => {
                    return (_openBlock(), _createElementBlock("label", {
                      tabindex: "0",
                      class: _normalizeClass(["dropdown__checkbox-label d-flex align-items-center fw-semi-bold fs-18", {
                'mb-4': !(i === _ctx.groups.length - 1 && j === group.options.length - 1) && _ctx.multiple,
                'select-bg': (option.selected || false) && !_ctx.multiple,
                'py-2 px-4': !_ctx.multiple,
              }]),
                      key: option.value,
                      onClick: ($event: any) => (_ctx.selectOption(option)),
                      onKeydown: _withKeys(($event: any) => (_ctx.selectOption(option)), ["enter"])
                    }, [
                      (_ctx.multiple)
                        ? (_openBlock(), _createBlock(_component_notus_form_checked_icon, {
                            key: 0,
                            isSelected: option.selected || false,
                            iconType: 'checked-icon-primary',
                            iconStyle: 'classic-checkbox'
                          }, null, 8, ["isSelected"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        class: _normalizeClass({'ms-2': _ctx.multiple, selected: option.selected || false})
                      }, _toDisplayString(_ctx.$t(option.label)), 3)
                    ], 42, _hoisted_8))
                  }), 128))
                ], 64))
              }), 128))
            ], 2), [
              [_directive_click_outside, _ctx.onOutsideClick]
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t(_ctx.description)), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}