import {Form} from '@/store/modules/FormModule/state';
import {Observable, from} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {APIService} from '../../shared/services/api.sevice';
import {prepareFormValues} from '../../shared/services/request.service';
import {generateLeadRequest} from './lead.dto';
import {LeadResponse} from './lead.type';
import {AxiosResponse} from 'axios';
import { CustomFormGroup } from '@/app/models/custom-form-group.interface';

export function sendLead(
  form: Form,
  hash: string,
  firstName: string,
  prefix: string,
  phone: string,
  partnerConstents: CustomFormGroup,
  consent?: boolean
): Observable<LeadResponse> {
  const defaultHash = process.env['VUE_APP_LEAD_DEFAULT_PARTNER'];

  if (!hash) {
    hash = defaultHash;
  }

  return req(form, hash, firstName, prefix, phone, partnerConstents, consent).pipe(
    catchError((err) => {
      if (err.response.data === 'PartnerID not valid' && hash !== defaultHash) {
        return from(req(form, defaultHash, firstName, prefix, phone, partnerConstents, consent));
      }

      throw err;
    }),
    map((response) => response.data)
  );
}

function req(
  form: Form,
  hash: string,
  firstName: string,
  prefix: string,
  phone: string,
  partnerConstents: CustomFormGroup,
  consent?: boolean
): Observable<AxiosResponse<LeadResponse>> {
  return APIService.post<LeadResponse>(
    `${process.env.VUE_APP_API_URL}lead/${hash}`,
    generateLeadRequest(prepareFormValues(form), hash, firstName, prefix, phone, partnerConstents, consent)
  );
}
