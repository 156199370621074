
import {Options, Vue} from 'vue-class-component';
import NotusLink from '@/app/modules/shared/components/link/link.component.vue';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {namespace} from 'vuex-class';
import {ActionTypes} from '@/store/modules/ProgressModule/actions/action-types';
import {ProgressMainStage} from '@/app/models/progress-main-stage.enum';
import {ContactReason} from '@/app/modules/page-contact/page-contact-reason.enum';
import {ComparitionReason} from '@/app/modules/page-comparition-table/page-comparition-reason.enum';

const progressModule = namespace('progress');
const formModule = namespace('form');

@Options({
  name: 'NotusCompareCta',
  components: {
    NotusLink,
    NotusButton,
    NotusSvgIcon,
  },
})
export default class NotusCompareCta extends Vue {
  @progressModule.Action(ActionTypes.nextStep) nextStep: any;
  @formModule.State('leadWasSend') leadWasSend!: boolean;

  onExampleClicked() {
    this.nextStep({
      mainStage: ProgressMainStage.comparition,
      params: {
        reason: ComparitionReason.EXAMPLE,
      },
    });
  }

  onDetailedClicked() {
    if (this.leadWasSend) {
      this.nextStep({
        mainStage: ProgressMainStage.comparition,
        params: {
          reason: ComparitionReason.OWN,
        },
      });
    } else {
      this.nextStep({
        mainStage: ProgressMainStage.contact,
        params: {
          reason: ContactReason.LEADING_DETAILS,
        },
      });
    }
  }
}
