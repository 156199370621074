import {CONFIG} from '@/app/config/config';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {ActionContext, ActionTree} from 'vuex';
import {Mutations} from '../mutations';
import {MutationType} from '../mutations/mutation-types';
import {CompareState} from '../state';
import {CompareActionTypes} from './action-types';

type ActionAugments = Omit<ActionContext<CompareState, CompareState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [CompareActionTypes.addCompareElement](context: ActionAugments, element: OfferResponse): void;
  [CompareActionTypes.removeCompareElement](context: ActionAugments, elementId: string): void;
  [CompareActionTypes.clearCompareElements](context: ActionAugments): void;
};

export const actions: ActionTree<CompareState, CompareState> & Actions = {
  [CompareActionTypes.addCompareElement]({commit, state}, element: OfferResponse) {
    if (state.selectedToCompare.length >= CONFIG.comparision.maxElements) {
      return false;
    } else {
      commit(MutationType.addCompareElement, element);
      return true;
    }
  },
  [CompareActionTypes.removeCompareElement]({commit}, elementId: string) {
    commit(MutationType.dropCompareElement, elementId);
  },
  [CompareActionTypes.clearCompareElements]({commit}) {
    commit(MutationType.clearCompareElements);
  },
};
