
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {Prop} from 'vue-property-decorator';

@Options({
  name: 'NotusButtonScrollTop',
  components: {
    NotusSvgIcon,
  },
})
export default class NotusButtonScrollTop extends Vue {
  @Prop({default: null}) scrollRef!: HTMLElement;
  isButtonVisible = false;
  scrollableElement?: HTMLElement;

  mounted(): void {
    this.scrollableElement = document.querySelector('.notus-container') as HTMLElement;
    this.$nextTick(() => {
      this.visibleButtonChecker();
      if (this.scrollRef) {
        this.scrollRef.addEventListener('scroll', this.visibleButtonChecker);
      } else {
        this.scrollableElement?.addEventListener('scroll', this.visibleButtonChecker);
      }
    });
  }

  visibleButtonChecker(): void {
    if (this.scrollRef) {
      this.isButtonVisible = this.scrollRef.scrollTop >= 150;
    } else if (this.scrollableElement) {
      this.isButtonVisible = this.scrollableElement.scrollTop >= 150;
    }
    this.$forceUpdate();
  }

  scrollTop(): void {
    if (this.scrollRef) {
      this.scrollRef.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      this.scrollableElement?.scrollTo({
        top: -100,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
