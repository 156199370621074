import {PROGRESS_FORM_MAIN_STEPS_ORDER} from '@/app/config/progress-form-order.config';
import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';
import {ProgressFormStageParams, ProgressFormStep} from '@/app/modules/page-form/interfaces/progress-form-step.interface';

const INIT_STEP = {mainFormStage: PROGRESS_FORM_MAIN_STEPS_ORDER[0]};

export const INIT_FORM_PROGRESS_STATE: ProgressFormState = {
  currentStep: INIT_STEP,
  stepsPassed: [INIT_STEP],
  shallReloadOffersOnNextPage: false,
  isMultiApplicants: undefined,
  lastIncomeParams: undefined,
  purpose: undefined,
};

export type ProgressFormState = {
  currentStep: ProgressFormStep;
  stepsPassed: Array<ProgressFormStep>;
  purpose?: FormPurpose;
  isMultiApplicants?: boolean;
  shallReloadOffersOnNextPage: boolean;
  lastIncomeParams?: ProgressFormStageParams;
};

export const state: ProgressFormState = INIT_FORM_PROGRESS_STATE;
