import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7a0db0a5")
const _hoisted_1 = { class: "summary-comparition-bar" }
const _hoisted_2 = { class: "container d-flex flex-row justify-content-between align-items-center" }
const _hoisted_3 = { class: "flex-start d-none d-md-block" }
const _hoisted_4 = { class: "font-black d-block d-md-inline-block" }
const _hoisted_5 = { class: "flex-start d-block d-md-none" }
const _hoisted_6 = { class: "font-black d-block d-md-inline-block color-accent" }
const _hoisted_7 = { class: "flex-end d-flex align-items-center" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_button = _resolveComponent("notus-button")!
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_notus_page_comparition_boxes = _resolveComponent("notus-page-comparition-boxes")!
  const _component_notus_expand_page = _resolveComponent("notus-expand-page")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('page.summary.section.compare.begin')) + " ", 1),
        _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.$tc('page.summary.section.compare.amount', _ctx.compareElementsAmount, {amount: _ctx.compareElementsAmount})), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString(_ctx.$t('page.summary.section.compare.beginMobile')) + " ", 1),
        _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.$tc('page.summary.section.compare.amountMobile', _ctx.compareElementsAmount, {amount: _ctx.compareElementsAmount})), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_notus_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDetails(true))),
          class: "px-5 d-none d-lg-inline-block",
          size: "large",
          disabled: !_ctx.isValid || _ctx.compareElementsAmount < 2
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('page.summary.section.compare.button')), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createElementVNode("span", {
          role: "button",
          class: "cursor-pointer d-inline-block d-lg-none",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDetails(true)))
        }, [
          (_ctx.compareElementsAmount >= 2)
            ? (_openBlock(), _createBlock(_component_notus_svg_icon, {
                key: 0,
                iconType: "arrow-right-primary"
              }))
            : _createCommentVNode("", true),
          (_ctx.compareElementsAmount < 2)
            ? (_openBlock(), _createBlock(_component_notus_svg_icon, {
                key: 1,
                iconType: "arrow-right-muted"
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_notus_expand_page, { opened: _ctx.showDetails }, {
      default: _withCtx(() => [
        _createVNode(_component_notus_page_comparition_boxes, {
          onCloseClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleDetails(false))),
          showDetails: _ctx.showDetails
        }, null, 8, ["showDetails"])
      ]),
      _: 1
    }, 8, ["opened"])
  ]))
}