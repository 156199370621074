export enum ActionTypes {
  initFormIfNotExists = 'initFormIfNotExists',
  setCurrentIncomeGroup = 'setCurrentIncomeGroup',
  overrideIncomeGroup = 'overrideIncomeGroup',
  resetValidation = 'resetValidation',
  setValidation = 'setValidation',
  setValue = 'setValue',
  clearInvalids = 'clearInvalids',
  clearLeadForm = 'clearLeadForm',
  loadPartnerConsents = 'loadPartnerConsents',
  setLeadWasSend = 'setLeadWasSend',
  setLeadStatus = 'setLeadStatus',
  setProposalId = 'setProposalId',
  setLeadPrefix = 'setLeadPrefix',
  setInstalmentType = 'setInstalmentType',
  setCommission0Available = 'setCommission0Available',
  calculateCommission0Available = 'calculateCommission0Available',
}
