export const environment = {
  production: false,
  component: false,
  filterOfferName: 'Testowy',
  API: {
    accio_API: 'https://test1-accio.notuskredyt.pl/api/v1/pk-filter/',
    departmentsURL: 'https://lp-api.notuskredyt.pl/api/department/',
    googleMapsApiKey: 'AIzaSyAykMeg88NDzut2d5lo-wMZQLtR8vtJJdw',
  },
  assets: {
    rulesUrl: 'https://notus.pl/app/uploads/2024/06/Regulamin-PI_24.10.2022.pdf',
    bankLogos: {
      prefix: 'https://grafika.notus.pl/instytucje/logo_',
      suffix: '.png',
    },
  },
};
