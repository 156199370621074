
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {OfferResponse} from '../../interface/offer.response.interface';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {namespace} from 'vuex-class';
import {ActionTypes} from '@/store/modules/ProgressModule/actions/action-types';
import {ProgressMainStage} from '@/app/models/progress-main-stage.enum';
import {ContactReason} from '@/app/modules/page-contact/page-contact-reason.enum';
import {scrollToContactForm} from '@/app/modules/shared/helpers/scrollToContactForm.helper';

const offersModule = namespace('offers');
const progressModule = namespace('progress');

@Options({
  name: 'NotusCtaInterested',
  components: {
    NotusButton,
    NotusSvgIcon,
  },
})
export default class NotusCtaInterested extends Vue {
  @offersModule.State('offers') offers!: OfferResponse[];
  @progressModule.Action(ActionTypes.nextStep) nextStep: any;
  @Prop({default: null}) offer!: OfferResponse;

  get hasNoOffers(): boolean {
    return this.offers && this.offers.length === 0;
  }
  onClicked() {
    if (this.hasNoOffers) {
      this.scrollToContact();
    } else {
      this.nextStep({
        mainStage: ProgressMainStage.contact,
        params: {
          reason: ContactReason.CTA_INTERESTED,
        },
      });
    }
  }

  scrollToContact() {
    scrollToContactForm();
  }
}
