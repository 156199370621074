
import {Options, Vue} from 'vue-class-component';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusFormCheckbox from '@/app/modules/shared/components/form-checkbox/form-checkbox.component.vue';
import {Emit, Prop} from 'vue-property-decorator';
import {Tooltip as VTooltip} from 'v-tooltip';
import {environment} from '@/environments/environment';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {namespace} from 'vuex-class';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {getLeadContent} from '@/app/modules/shared/form-lead-content.service';
import { PartnerConsent } from '@/store/modules/FormModule/state';

const formModule = namespace('form');

@Options({
  name: 'NotusLeadInfo',
  components: {NotusButton, VTooltip, NotusFormCheckbox},
})
export default class NotusLeadInfo extends Vue {
  @Prop({default: false}) mobile!: boolean;

  @formModule.Getter(GetterTypes.formLead) formLead!: CustomFormGroup;
  @formModule.Getter(GetterTypes.isFormLeadValid) isFormLeadValid!: boolean;
  @formModule.Getter(GetterTypes.leadPartnersConstents) leadPartnerConstents!: Array<PartnerConsent>;

  public rulesUrl = environment.assets.rulesUrl;

  @Emit()
  submitClicked(): boolean {
    return true;
  }

  getLeadContent(variableName: string, defaultI18nKey?: string): string {
    return getLeadContent(variableName, defaultI18nKey);
  }
}
