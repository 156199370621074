import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4e2cf20a")
const _hoisted_1 = ["disabled"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_form_checked_icon = _resolveComponent("notus-form-checked-icon")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["checkbox d-inline-flex", _ctx.classes]),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keySpaceDown && _ctx.keySpaceDown(...args)), ["space"])),
    tabindex: "0"
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "invisible w-0 h-0 m-0",
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectionChange && _ctx.selectionChange(...args)))
    }, null, 40, _hoisted_1),
    _createVNode(_component_notus_form_checked_icon, {
      iconStyle: _ctx.iconStyle,
      isSelected: _ctx.control.value,
      iconType: _ctx.iconType
    }, null, 8, ["iconStyle", "isSelected", "iconType"]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 34))
}