export enum ProgressFormStage {
  'begin' = 1, // there could be problem in swithc with 0 value
  'purpose',
  'singleOrFriendly',
  'age',
  'EU_citizien',
  'income',
  'otherIncome',
  'outcome',
}
