export enum MutationType {
  addControl = 'addControl',
  setValue = 'setValue',
  updateIncomeGroup = 'updateIncomeGroup',
  overrideIncomeGroup = 'overrideIncomeGroup',
  resetValidation = 'resetValidation',
  setValidation = 'setValidation',
  setLeadWasSend = 'setLeadWasSend',
  setProposalId = 'setProposalId',
  setLeadForm = 'setLeadForm',
  setLeadPrefix = 'setLeadPrefix',
  setLeadPartnerConstents = 'setLeadPartnerConstents',
  setLeadSendSuccess = 'setLeadSendSuccess',
  setInstalmentType = 'setInstalmentType',
  setCommission0Available = 'setCommission0Available',
}
