interface IBenefitsValues {
  icon: string;
  title: string;
}

export const benefitsList: IBenefitsValues[] = [
  {
    icon: 'offers',
    title: 'page.contact.benefits_list_1',
  },
  {
    icon: 'knowledge',
    title: 'page.contact.benefits_list_2',
  },
  {
    icon: 'decision',
    title: 'page.contact.benefits_list_3',
  },
  {
    icon: 'protection',
    title: 'page.contact.benefits_list_4',
  },
];
