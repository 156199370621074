import {ActionContext, ActionTree} from 'vuex';
import {OffersActionTypes} from '@/store/modules/OffersModule/actions/action-types';
import {FiltersType, OffersState} from '@/store/modules/OffersModule/state';
import {OffersMutations} from '@/store/modules/OffersModule/mutations';
import {FormConfigFormsKey} from '@/app/config/dynamic-form.config';
import {environment} from '@/environments/environment';
import {APIService} from '@/app/modules/shared/services/api.sevice';
import {generateSimulationRequest} from '@/app/modules/shared/dto/offers-request.dto';
import {OffersMutationType} from '@/store/modules/OffersModule/mutations/mutation-types';
import {ProgressFormIncomeDynamicPagesIndex} from '@/app/modules/page-form/enums/progress-form-dynamic-pages-index.enum';
import {Form, FormState} from '../../FormModule/state';
import {ContactReason} from '@/app/modules/page-contact/page-contact-reason.enum';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import sortOffers from './action-sortOffers';
import {OrderType} from '@/app/modules/page-summary/models/order-type.enum';
import filterOffer from './action-filterOffer';
import fetchOnePerBank from './action-fetchOnePerBank';
import {ProgressFormStage} from '@/app/modules/page-form/enums/progress-form-stage.enum';
import {ProgressMainStage} from '@/app/models/progress-main-stage.enum';
import {ActionTypes as ProgressActionTypes} from './../../ProgressModule/actions/action-types';
import {InfoReason} from '@/app/modules/page-info/page-info-reason.enum';
import {prepareFormValues, prepareIncomeFormValues} from '@/app/modules/shared/services/request.service';
import {COMPARITION_EXAMPLE} from '@/app/modules/page-comparition-table/example-comparition';
import {storeCorrectForm} from '@/store';
import {i18n} from '@/assets/i18n';
import {useToast} from 'vue-toastification';
import {ActionTypes as FormActionTypes} from '../../FormModule/actions/action-types';
import {TOAST_TIMEOUT} from '@/main';
import { AxiosResponse } from 'axios';

type ActionAugments = Omit<ActionContext<OffersState, OffersState>, 'commit'> & {
  commit<K extends keyof OffersMutations>(key: K, payload?: Parameters<OffersMutations[K]>[1]): ReturnType<OffersMutations[K]>;
};

let wasInitialized = false;
let wasOrderInitialized = false;

export type Actions = {
  [OffersActionTypes.reloadResults](
    context: ActionAugments,
    reloadPurpose?: FormConfigFormsKey | ProgressFormStage | ProgressMainStage
  ): void;
  [OffersActionTypes.filterOffers](
    context: ActionAugments,
    filteringParameters: {[key: string]: string | boolean | string[]}
  ): void;
  [OffersActionTypes.propagateResponse](context: ActionAugments, payload: OffersState): void;
  [OffersActionTypes.setDetailedOffer](context: ActionAugments, offer?: OfferResponse): void;
  [OffersActionTypes.setOrderType](context: ActionAugments, orderType: OrderType): void;
  [OffersActionTypes.setFilters](context: ActionAugments, filters: FiltersType): void;
  [OffersActionTypes.resetState](context: ActionAugments): void;
};

export const actions: ActionTree<OffersState, OffersState> & Actions = {
  [OffersActionTypes.reloadResults]({dispatch, rootState, state, rootGetters}, reloadPurpose) {
    if (!rootGetters['form/isValid']) {
      dispatch(OffersActionTypes.propagateResponse, {isLoading: false, offers: null, reloadPurpose});
      return;
    }

    dispatch(OffersActionTypes.propagateResponse, {isLoading: true, offers: COMPARITION_EXAMPLE});
    const formValues = prepareFormValues(((rootState as any).form as FormState).form as Form);
    const incomeFormsValues = prepareIncomeFormValues(
      ((rootState as any).form as FormState).incomeForms as {[key: string]: Form}
    );

    APIService.post<OfferResponse[]>(
      process.env.VUE_APP_API_URL + 'simulation',
      generateSimulationRequest(formValues, incomeFormsValues)
    ).subscribe(
      (res) => {
        const offers: OfferResponse[] = (res as AxiosResponse).data as OfferResponse[];
        let _offers: OfferResponse[] = [];
        if (environment.filterOfferName) {
          _offers = offers.filter((offer) => offer.product.name !== environment.filterOfferName);
        } else {
          _offers = offers;
        }

        _offers = _offers.filter((offer) => {
          return filterOffer(offer, state.filters);
        });
        if (_offers && _offers.length === 0) {
          if (reloadPurpose !== ProgressMainStage.summary) {
            console.log('Brak ofert', {
              reloadPurpose,
            });
            let reason: ContactReason | undefined = undefined;
            switch (reloadPurpose) {
              case ProgressFormStage.age:
                reason = ContactReason.INCORRECT_AGE;
                break;
              case ProgressFormIncomeDynamicPagesIndex.income_monthly:
                reason = ContactReason.NON_PLN_CURRENCY;
                break;
              default:
                dispatch(
                  `progress/${ProgressActionTypes.nextStep}`,
                  {mainStage: ProgressMainStage.info, params: {reason: InfoReason.OFFER_NOT_FOUND}},
                  {root: true}
                );
            }
            if (reason) {
              dispatch(
                `progress/${ProgressActionTypes.nextStep}`,
                {mainStage: ProgressMainStage.contact, params: {reason}},
                {root: true}
              );
            }
          }
          dispatch(OffersActionTypes.propagateResponse, {isLoading: false, offers: _offers, reloadPurpose});
          return;
        }

        storeCorrectForm();
        dispatch(OffersActionTypes.propagateResponse, {isLoading: false, offers: _offers, reloadPurpose});
      },
      (err) => {
        console.error('Napotkano problem komunikacji', err);
        dispatch(
          `progress/${ProgressActionTypes.nextStep}`,
          {mainStage: ProgressMainStage.info, params: {reason: InfoReason.ERROR}},
          {root: true}
        );
      }
    );
  },
  [OffersActionTypes.setOrderType]({commit, state}, orderType: OrderType) {
    commit(OffersMutationType.setOrderType, orderType);
    if (state.allOffers) {
      const sortedOffers = state.allOffers.sort((offerA, offerB) => sortOffers(offerA, offerB));
      commit(OffersMutationType.setOffers, fetchOnePerBank(sortedOffers));
      if (wasOrderInitialized) {
        showToast();
      }
      wasOrderInitialized = true;
    }
  },
  [OffersActionTypes.setFilters]({commit}, filters: FiltersType) {
    commit(OffersMutationType.setFilters, filters);
  },
  [OffersActionTypes.filterOffers]({commit, state}, filteringParameters: {[key: string]: string | boolean | string[]}) {
    if (state.allOffers) {
      const filteredOffers = state.allOffers.filter((offer) => {
        return filterOffer(offer, filteringParameters);
      });
      commit(OffersMutationType.setOffers, fetchOnePerBank(filteredOffers));
    }
  },
  [OffersActionTypes.resetState]({commit}) {
    commit(OffersMutationType.resetState);
  },
  [OffersActionTypes.propagateResponse]({dispatch, commit, state}, {isLoading, offers, reloadPurpose}) {
    commit(OffersMutationType.setInitialized, true);
    commit(OffersMutationType.setLoading, isLoading);
    commit(OffersMutationType.setAllOffers, offers);
    commit(OffersMutationType.setReloadPurpose, reloadPurpose);

    if (!offers) {
      commit(OffersMutationType.setOffers, undefined);
    } else {
      commit(OffersMutationType.setOffers, fetchOnePerBank(state.orderType ? offers.sort(sortOffers) : offers));

      if (!isLoading) {
        if (offers.length) {
          dispatch(`form/${FormActionTypes.calculateCommission0Available}`, null, {root: true});
          if (wasInitialized) {
            showToast();
            setTimeout(() => {
              document.getElementById('anchor-offersSection')?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }, 100);
          }
          wasInitialized = true;
        } else {
          dispatch(`form/${FormActionTypes.setCommission0Available}`, false, {root: true});
        }
      }
    }
  },
  [OffersActionTypes.setDetailedOffer]({commit}, offer?: OfferResponse) {
    commit(OffersMutationType.setDetailedOffer, offer);
  },
};

function showToast() {
  const toast = useToast();
  toast.clear();
  toast.success(i18n.global.t('page.summary.fetched'), {
    closeButton: false,
  });
  const upArrows = document.querySelectorAll('.btn-scroll-top');

  upArrows.forEach((upArrow) => {
    upArrow.classList.add('btn-scroll-top--up');

    setTimeout(() => {
      upArrow.classList.remove('btn-scroll-top--up');
    }, TOAST_TIMEOUT + 500);
  });
}
