import IntersectionObserver from 'intersection-observer-polyfill';

type intersectionCallback = (entry: IntersectionObserverEntry) => void;

const intersectionHandler = (isIntersectingFn: intersectionCallback) =>
  function handleIntersection(entries: Array<IntersectionObserverEntry>) {
    entries.forEach((entry) => {
      isIntersectingFn(entry);
    });
  };

function observeElement(element: HTMLElement, isIntersectingFn: intersectionCallback, options?: IntersectionObserverInit): void {
  const handleIntersection = intersectionHandler(isIntersectingFn);
  const observer = new IntersectionObserver(handleIntersection, options);
  observer.observe(element);
}

export default {observeElement};
