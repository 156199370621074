
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {namespace} from 'vuex-class';
import {GetterTypes} from '@/store/modules/CompareModule/getters/getter-types';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import {ActionTypes} from '@/store/modules/ProgressModule/actions/action-types';
import NotusExpandPage from '@/app/modules/shared/components/expand-page/expand-page.component.vue';
import NotusPageComparitionBoxes from '@/app/modules/page-comparition-boxes/page-comparition-boxes.component.vue';
import {ProgressGettersTypes} from '@/store/modules/ProgressModule/getters';
import {GetterTypes as FormGetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {ProgressStep} from '@/store/modules/ProgressModule/state/progress-step.interface';
import {watch} from '@vue/runtime-core';

const compareModule = namespace('compare');
const progressModule = namespace('progress');
const formModule = namespace('form');

@Options({
  name: 'NotusSummaryComparitionBar',
  components: {
    NotusSvgIcon,
    NotusButton,
    NotusExpandPage,
    NotusPageComparitionBoxes,
  },
  watch: {},
})
export default class NotusSummaryComparitionBar extends Vue {
  @compareModule.Getter(GetterTypes.compareElementsAmount) compareElementsAmount!: number;
  @progressModule.Getter(ProgressGettersTypes.currentStep) currentStep!: ProgressStep;
  @progressModule.Action(ActionTypes.nextStep) nextStep!: any;
  @progressModule.Action(ActionTypes.setParamsToCurrentStep) setParamsToCurrentStep!: any;
  @formModule.Getter(FormGetterTypes.isValid) isValid!: boolean;

  showDetails = false;

  created() {
    this.showDetails = this.currentStep.params?.hadOpenCompareModal ? true : false;

    watch(
      () => this.compareElementsAmount,
      (newValue) => {
        if (newValue < 2) {
          this.showDetails = false;
        }
      }
    );
    watch(
      () => this.showDetails,
      (newValue) => {
        this.setParamsToCurrentStep({
          ...this.currentStep.params,
          hadOpenCompareModal: newValue,
        });
      }
    );
  }

  toggleDetails(forceState: boolean) {
    if (this.compareElementsAmount >= 2) {
      this.showDetails = forceState !== undefined ? forceState : !this.showDetails;
    }
  }
}
