import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_offer_box = _resolveComponent("notus-offer-box")!
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_ctx.compareElements)
    ? (_openBlock(), _createBlock(_component_carousel, {
        key: 0,
        settings: _ctx.settings,
        breakpoints: _ctx.breakpoints,
        wrapAround: true
      }, {
        addons: _withCtx(() => [
          (_ctx.compareElements.length > 3)
            ? (_openBlock(), _createBlock(_component_navigation, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_pagination)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.compareElements, (offer, i) => {
            return (_openBlock(), _createBlock(_component_slide, { key: i }, {
              default: _withCtx(() => [
                _createVNode(_component_notus_offer_box, {
                  offer: offer,
                  variant: "card"
                }, null, 8, ["offer"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["settings", "breakpoints"]))
    : _createCommentVNode("", true)
}