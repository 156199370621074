
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {CONFIG} from '@/app/config/config';

@Options({
  name: 'NotusSummaryFooterSection',
  components: {
    NotusSvgIcon,
  },
})
export default class NotusSummaryFooterSection extends Vue {
  socialLinks = CONFIG.social;
  currentYear = (new Date).getFullYear();
}
