
import {Options, Vue} from 'vue-class-component';
import {i18n} from '@/assets/i18n';

@Options({
  name: 'NotusLangSwitcher',
  methods: {
    setLang(lang: string) {
      i18n.global.locale = lang;
    },
  },
})
export default class NotusLangSwitcher extends Vue {}
