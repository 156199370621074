import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-127be9f9")
const _hoisted_1 = { class: "content" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageSummary = _resolveComponent("PageSummary")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notus-container", {'pb-plus-offers': _ctx.isInitialized()}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageSummary, { partnerLogo: _ctx.partnerLogo }, null, 8, ["partnerLogo"])
    ])
  ], 2))
}