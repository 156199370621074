
import {FORM_INPUTS_CONFIG} from '@/app/config/input-form.config';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import NotusPageOfferDetails from '@/app/modules/page-offer-details/page-offer-details.component.vue';
import PageSimple from '@/app/modules/page-simple';
import NotusOffers from '@/app/modules/page-summary/components/offers/offers.component.vue';
import NotusContactUsSection from '@/app/modules/page-contact/components/contact-us.component.vue';
import NotusSorting from '@/app/modules/page-summary/components/sorting/sorting.component.vue';
import NotusSummaryComparitionBar from '@/app/modules/page-summary/components/summary-comparition-bar/summary-comparition-bar.component.vue';
import NotusSummaryFooterSection from '@/app/modules/page-summary/components/summary-footer/summary-footer.component.vue';
import NotusSummaryParameters from '@/app/modules/page-summary/components/summary-parameters/summary-parameters.component.vue';
import NotusWhyUsSection from '@/app/modules/page-summary/components/why-us/why-us.component.vue';
import NotusButtonScrollTop from '@/app/modules/shared/components/button-scroll-top/button-scroll-top.component.vue';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import NotusExpandPage from '@/app/modules/shared/components/expand-page/expand-page.component.vue';
import NotusCtaInterested from '@/app/modules/shared/sections/cta-interested/cta-interested.component.vue';
import NotusIntro from '@/app/modules/shared/sections/intro/intro.component.vue';
import NotusHero from '@/app/modules/shared/sections/hero/hero.component.vue';
import {prepareInputOptions} from '@/app/modules/shared/services/form-inputs.service';
import {GetterTypes} from '@/store/modules/CompareModule/getters/getter-types';
import {ActionTypes as FormActionType} from '@/store/modules/FormModule/actions/action-types';
import {GetterTypes as FormGetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {OffersActionTypes} from '@/store/modules/OffersModule/actions/action-types';
import {OffersGetter} from '@/store/modules/OffersModule/getters';
import {ActionTypes} from '@/store/modules/ProgressModule/actions/action-types';
import {ProgressStep} from '@/store/modules/ProgressModule/state/progress-step.interface';
import {Options} from 'vue-class-component';
import {mapActions} from 'vuex';
import {namespace} from 'vuex-class';
import {OfferResponse} from '../shared/interface/offer.response.interface';
import {scrollToContactForm} from '@/app/modules/shared/helpers/scrollToContactForm.helper';

const compareModule = namespace('compare');
const offersModule = namespace('offers');
const progressModule = namespace('progress');
const formModule = namespace('form');

@Options({
  name: 'PageSummary',
  components: {
    NotusSvgIcon,
    NotusButton,
    NotusIntro,
    NotusHero,
    NotusSummaryParameters,
    NotusCtaInterested,
    NotusSorting,
    NotusOffers,
    NotusWhyUsSection,
    NotusSummaryFooterSection,
    NotusSummaryComparitionBar,
    NotusExpandPage,
    NotusPageOfferDetails,
    NotusButtonScrollTop,
    NotusContactUsSection,
  },
  methods: {
    ...mapActions('form', [FormActionType.initFormIfNotExists]),
    initProperty(property: DynamicFormControlName) {
      this[FormActionType.initFormIfNotExists](prepareInputOptions(FORM_INPUTS_CONFIG[property]));
    },
  },
  created() {
    this.initProperty(DynamicFormControlName['purpose']);
    this.initProperty(DynamicFormControlName['loan-value']);
    this.initProperty(DynamicFormControlName['purposes-value']);
    this.initProperty(DynamicFormControlName['loan-period']);
    this.reloadResults();
  },
})
export default class PageSummary extends PageSimple {
  @offersModule.State('offers') offers!: OfferResponse[];
  @offersModule.State('isLoading') isLoading!: boolean;
  @offersModule.Getter(OffersGetter.detailedOffer) detailedOffer!: OfferResponse | undefined;
  @offersModule.Action(OffersActionTypes.reloadResults) reloadResults: any;
  @progressModule.State('currentStep') currentStep!: ProgressStep;
  @progressModule.Action(ActionTypes.prevStep) prevStep!: any;
  @compareModule.Getter(GetterTypes.compareElementsAmount) compareElementsAmount!: number;
  @formModule.Getter(FormGetterTypes.isValid) isValid!: boolean;

  scrollToContact() {
    scrollToContactForm();
  }
}
