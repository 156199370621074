
import {Options, Vue} from 'vue-class-component';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';

interface IBenefitsListValues {
  icon: string;
  title: string;
  text: string;
}

@Options({
  name: 'NotusWhyUsSection',
  components: {
    NotusSvgIcon,
  },
})
export default class NotusWhyUsSection extends Vue {
  public benefitsList: IBenefitsListValues[] = [
    {
      icon: 'mobile-experts',
      title: 'page.summary.section.why.benefits.wallet.title',
      text: 'page.summary.section.why.benefits.wallet.text',
    },
    {
      icon: 'years',
      title: 'page.summary.section.why.benefits.time.title',
      text: 'page.summary.section.why.benefits.time.text',
    },
    {
      icon: 'clients',
      title: 'page.summary.section.why.benefits.guard.title',
      text: 'page.summary.section.why.benefits.guard.text',
    },
    {
      icon: 'expert',
      title: 'page.summary.section.why.benefits.expert.title',
      text: 'page.summary.section.why.benefits.expert.text',
    },
  ];
}
