
import {environment} from '@/environments/environment';
import {watch} from '@vue/runtime-core';
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {LogoBankSize} from './logo-bank.types';

@Options({
  name: 'NotusLogoBank',
})
export default class NotusLogoBank extends Vue {
  @Prop(String) readonly bankName!: string;
  @Prop({default: 'small'}) readonly size!: LogoBankSize;
  @Prop({default: false}) centerVertical!: boolean;

  public bankLink?: string;

  get classes(): string {
    return `logo-bank logo-bank--${this.size}`;
  }

  created() {
    this.bankLink = environment.assets.bankLogos.prefix + this.prepareLogoName() + environment.assets.bankLogos.suffix;

    watch(
      () => this.bankName,
      () => {
        this.bankLink = environment.assets.bankLogos.prefix + this.prepareLogoName() + environment.assets.bankLogos.suffix;
      }
    );
  }

  prepareLogoName(): string {
    let fileName = this.bankName;
    const regexArray = {
      ' ': '',
      ą: 'a',
      ć: 'c',
      ę: 'e',
      ł: 'l',
      ń: 'n',
      ó: 'o',
      ś: 's',
      ź: 'z',
      ż: 'z',
    };
    Object.entries(regexArray).forEach((entry) => {
      fileName = fileName.replace(new RegExp(`[${entry[0]}]`, 'g'), entry[1]);
    });
    return fileName.toLowerCase();
  }
}
