import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {genIncomeGroupName} from '@/store/modules/FormModule/getters';
import {FormValues} from '@/store/modules/FormModule/state';
import {FormApplicant} from '../../page-form/enums/form-applicant.enum';
import {FormIncomeType} from '../../page-form/enums/form-income-type.enum';
import {getIncomeDetailsName, getLabelTranslated, getValueTranslated} from '../../shared/services/form-parameters.service';
import {Constent, IS_CONSENT_SELECTED, LeadRequest} from './lead.type';
import {Location} from '@/app/modules/shared/components/form-location/location-response-type';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';

const CONSENT_ACCEPTED_PARAMS = {
  consent_marketing: IS_CONSENT_SELECTED.T,
  consent_phone: IS_CONSENT_SELECTED.T,
};

export function generateLeadRequest(
  form: FormValues,
  hash: string,
  firstName: string,
  prefix: string,
  phone: string,
  partnerConstents: CustomFormGroup,
  consentAccepted?: boolean
): LeadRequest {
  return {
    ...(consentAccepted ? CONSENT_ACCEPTED_PARAMS : {}),
    test: process.env.VUE_APP_LEAD_WITH_TEST_FLAG == 'true',
    param_id: hash,
    first_name: firstName,
    prefix,
    phone,
    description: generateDescription(form),
    consents: generatePartnerConstents(partnerConstents),
  };
}

function generatePartnerConstents(constents: CustomFormGroup): Array<Constent> {
  return Object.entries(constents).map(([key, value]) => ({
    name: key,
    accepted: value.value ? true : false,
  }));
}

export function generateDescription(form: FormValues): string {
  return `
Lead wygenerowany za pomocą Porównywarki Internetowej Notus.

Informacje ogólne:
${getRow(form, DynamicFormControlName.purpose)}
${getRow(form, DynamicFormControlName['purposes-value'])}
${getRow(form, DynamicFormControlName['loan-value'])}
${getRow(form, DynamicFormControlName['loan-period'])}
`;
}

function getIncomeData(incomeForms: {[key: string]: FormValues}) {
  let data = '';
  let incomeIdx = 0;
  let incomeForm = incomeForms[genIncomeGroupName(FormApplicant.SOLO, incomeIdx++)];
  while (incomeForm) {
    data += `
Dochód nr. ${incomeIdx} wnioskodawcy:
${getRow(incomeForm, DynamicFormControlName.income_type)}
${getRow(
  incomeForm,
  getIncomeDetailsName(incomeForm[DynamicFormControlName.income_type] as FormIncomeType) as DynamicFormControlName
)}
${getRow(incomeForm, DynamicFormControlName.income_monthly_value)} ${incomeForm[DynamicFormControlName.income_monthly_currency]}
${getRow(incomeForm, DynamicFormControlName.income_time_from)}
${getRow(incomeForm, DynamicFormControlName.income_time_to)}
${
  incomeForm[DynamicFormControlName.income_type] === FormIncomeType.UoP && [DynamicFormControlName.income_UoPMaternity]
    ? getRow(incomeForm, DynamicFormControlName.income_UoPMaternity)
    : ''
}`;
    incomeForm = incomeForms[genIncomeGroupName(FormApplicant.SOLO, incomeIdx++)];
  }

  incomeIdx = 0;
  incomeForm = incomeForms[genIncomeGroupName(FormApplicant.MULTI, incomeIdx++)];
  while (incomeForm) {
    data += `
Dochód nr. ${incomeIdx} współwnioskodawcy:
${getRow(incomeForm, DynamicFormControlName.income_type)}
${getRow(
  incomeForm,
  getIncomeDetailsName(incomeForm[DynamicFormControlName.income_type] as FormIncomeType) as DynamicFormControlName
)}
${getRow(incomeForm, DynamicFormControlName.income_monthly_value)} ${incomeForm[DynamicFormControlName.income_monthly_currency]}
${getRow(incomeForm, DynamicFormControlName.income_time_from)}
${getRow(incomeForm, DynamicFormControlName.income_time_to)}
${
  incomeForm[DynamicFormControlName.income_type] === FormIncomeType.UoP && [DynamicFormControlName.income_UoPMaternity]
    ? getRow(incomeForm, DynamicFormControlName.income_UoPMaternity)
    : ''
}`;
    incomeForm = incomeForms[genIncomeGroupName(FormApplicant.MULTI, incomeIdx++)];
  }
  return data;
}

function getRow(form: FormValues, controlName: DynamicFormControlName): string {
  return `${form[controlName] ? `${getLabel(controlName)}: ${getValue(form, controlName)}` : ''}`;
}

function getLabel(controlName: DynamicFormControlName) {
  return getLabelTranslated(controlName, true);
}
function getValue(form: FormValues, controlName: DynamicFormControlName) {
  const value =
    controlName === DynamicFormControlName.property_details_location
      ? (form[controlName] as unknown as Location)
      : form[controlName]?.toString();
  return getValueTranslated(controlName, value, true);
}
