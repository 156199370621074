import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {MutationTree} from 'vuex';
import {CompareState} from '../state';
import {MutationType} from './mutation-types';

export type Mutations = {
  [MutationType.addCompareElement](state: CompareState, element: OfferResponse): void;
  [MutationType.dropCompareElement](state: CompareState, elementId: string): void;
  [MutationType.clearCompareElements](state: CompareState): void;
};

export const mutations: MutationTree<CompareState> & Mutations = {
  [MutationType.addCompareElement](state, element: OfferResponse) {
    state.selectedToCompare.push(element);
  },
  [MutationType.dropCompareElement](state, elementId) {
    state.selectedToCompare = state.selectedToCompare.filter((el) => el.id !== elementId);
  },
  [MutationType.clearCompareElements](state) {
    state.selectedToCompare = [];
  },
};
