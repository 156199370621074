import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotusSvgIcon = _resolveComponent("NotusSvgIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["form-checked-icon d-flex align-items-center justify-content-center me-2", _ctx.classes])
  }, [
    (_ctx.isSelected && _ctx.iconType)
      ? (_openBlock(), _createBlock(_component_NotusSvgIcon, {
          key: 0,
          "icon-type": _ctx.iconType
        }, null, 8, ["icon-type"]))
      : _createCommentVNode("", true)
  ], 2))
}