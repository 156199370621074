import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9d36f9a0")
const _hoisted_1 = { class: "benefits-slider__component" }
const _hoisted_2 = { class: "title title--medium benefits-title benefits-slider--title mt-4 mb-4" }
const _hoisted_3 = { class: "benefit__component" }
const _hoisted_4 = { class: "benefit__component--inner" }
const _hoisted_5 = { class: "benefit__component--img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_slide = _resolveComponent("slide")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.getLeadContent('SECTION_TITLE', 'page.contact.benefits_title')), 1),
    (_ctx.benefits)
      ? (_openBlock(), _createBlock(_component_carousel, {
          key: 0,
          settings: _ctx.settings,
          breakpoints: _ctx.breakpoints,
          wrapAround: false,
          ref: "benefitsSlider"
        }, {
          addons: _withCtx(() => [
            _createVNode(_component_pagination)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.benefits, (benefit, index) => {
              return (_openBlock(), _createBlock(_component_slide, { key: index }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          (_ctx.getLeadContent(`ICON_${index + 1}_IMG`))
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _ctx.getLeadContent(`ICON_${index + 1}_IMG`),
                                alt: ""
                              }, null, 8, _hoisted_6))
                            : (_openBlock(), _createBlock(_component_notus_svg_icon, {
                                key: 1,
                                iconType: benefit.icon,
                                cover: false
                              }, null, 8, ["iconType"]))
                        ]),
                        _createElementVNode("span", {
                          class: "benefit__component--title",
                          innerHTML: _ctx.getLeadContent(`ICON_${index + 1}_TEXT`, benefit.title)
                        }, null, 8, _hoisted_7)
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["settings", "breakpoints"]))
      : _createCommentVNode("", true)
  ]))
}