
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Options({
  name: 'NotusLink',
})
export default class NotusLink extends Vue {
  @Prop(String) readonly href: string | undefined;
}
