export enum IS_CONSENT_SELECTED {
  T = 'T',
  N = 'N',
}

export type Constent = {
  name: string;
  accepted: boolean;
};

export type LeadRequest = {
  test: boolean;
  param_id: string;
  phone: string;
  prefix: string;
  first_name: string;
  description: string;
  consent_marketing?: IS_CONSENT_SELECTED;
  consent_phone?: IS_CONSENT_SELECTED;
  consents?: Array<Constent>;
};

export type LeadResponse = {
  proposalId: string;
};
