import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!

  return (_ctx.isButtonVisible)
    ? (_openBlock(), _createBlock(_component_notus_svg_icon, {
        key: 0,
        iconType: "arrow_top_rounded",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollTop())),
        class: _normalizeClass(["btn-scroll-top cursor-pointer", {
      'on-fixed': _ctx.scrollRef !== null,
    }])
      }, null, 8, ["class"]))
    : _createCommentVNode("", true)
}