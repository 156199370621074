
import {Options, Vue} from 'vue-class-component';
import NotusOfferBox from '@/app/modules/page-summary/components/offer-box/offer-box.component.vue';
import {namespace} from 'vuex-class';
import {GetterTypes} from '@/store/modules/CompareModule/getters/getter-types';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import {watch} from '@vue/runtime-core';
import {Prop} from 'vue-property-decorator';

const compareModule = namespace('compare');

@Options({
  name: 'NotusCompartionRotor',
  components: {
    NotusOfferBox,
    NotusSvgIcon,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
})
export default class NotusCompartionRotor extends Vue {
  @Prop({default: false}) showDetails!: boolean;
  @compareModule.Getter(GetterTypes.compareElements) compareElements!: OfferResponse[];
  settings: any = {
    itemsToShow: 3,
    snapAlign: 'center',
  };
  breakpoints = {
    0: {
      itemsToShow: 1.1,
      snapAlign: 'center',
    },
    360: {
      itemsToShow: 1.15,
      snapAlign: 'center',
    },
    380: {
      itemsToShow: 1.2,
      snapAlign: 'center',
    },
    400: {
      itemsToShow: 1.3,
      snapAlign: 'center',
    },
    460: {
      itemsToShow: 1.5,
      snapAlign: 'center',
    },
    767: {
      itemsToShow: 2,
      snapAlign: 'center',
    },
    1200: {
      itemsToShow: 3,
      snapAlign: 'center',
    },
  };

  created() {
    watch(
      () => this.compareElements,
      () => {
        this.updateBreakpoints();
      }
    );
    watch(
      () => this.showDetails,
      () => {
        this.updateBreakpoints();
      }
    );
    this.updateBreakpoints();
  }

  updateBreakpoints(): void {
    if (this.compareElements) {
      this.breakpoints['0'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
      this.breakpoints['360'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
      this.breakpoints['380'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
      this.breakpoints['400'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
      this.breakpoints['460'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
      this.breakpoints['767'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
      this.breakpoints['1200'].snapAlign = this.compareElements.length <= 2 ? 'left' : 'center';
    }
  }
}
