import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-48395be0")
const _hoisted_1 = { class: "offers" }
const _hoisted_2 = { class: "offers_results" }
const _hoisted_3 = { class: "offers_results_line" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_offer_box = _resolveComponent("notus-offer-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offers, (offer) => {
          return (_openBlock(), _createBlock(_component_notus_offer_box, {
            key: offer.id,
            offer: offer,
            variant: "row",
            class: "mt-3"
          }, null, 8, ["offer"]))
        }), 128))
      ])
    ])
  ]))
}