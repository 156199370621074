import {FormInputProperty} from '@/store/modules/FormModule/state';

export interface ValidationErrorData {
  key: string;
  data?: any;
}

export interface ValiationFn {
  (input: FormInputProperty): ValidationErrorData | null;
}

export enum ValidatorTypes {
  minNumber = 'MIN_NUMBER',
  maxNumber = 'MAX_NUMBER',
  minLength = 'MIN_LENGTH',
  maxLength = 'MAX_LENGTH',
  loanPercentage = 'LOAN_PERCENTAGE',
  required = 'REQUIRED',
}
