
import {Options, Vue} from 'vue-class-component';
import NotusLeadContact from '@/app/modules/page-contact/components/contact/contact.component.vue';
import NotusLeadInfo from '@/app/modules/page-contact/components/info/info.component.vue';
import {sendLead} from '@/app/modules/page-contact/lead/lead.service';
import {first} from 'rxjs/operators';
import {Form, PartnerConsent} from '@/store/modules/FormModule/state';
import {namespace} from 'vuex-class';
import {ActionTypes as FormActionTypes} from '@/store/modules/FormModule/actions/action-types';
import {Emit} from 'vue-property-decorator';
import {CustomFormGroup} from '@/app/models/custom-form-group.interface';
import {getLeadContent} from '@/app/modules/shared/form-lead-content.service';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';

const formModule = namespace('form');

@Options({
  name: 'NotusForm',
  components: {
    NotusLeadContact,
    NotusLeadInfo,
  },
  methods: {
    getLeadContent(variableName: string, defaultI18nKey?: string): string {
      return getLeadContent(variableName, defaultI18nKey)
    },
  },
})
export default class NotusForm extends Vue {
  @formModule.Getter(GetterTypes.formLead) formLead!: CustomFormGroup;
  @formModule.Getter(GetterTypes.leadPartnersConstents) leadPartnersConstents!: Array<PartnerConsent>;
  @formModule.State('form') form!: Form;
  @formModule.Action(FormActionTypes.setLeadWasSend) setLeadWasSend!: any;
  @formModule.Action(FormActionTypes.clearLeadForm) clearLeadForm!: any;
  @formModule.Action(FormActionTypes.setLeadStatus) setLeadStatus!: any;
  @formModule.Action(FormActionTypes.setProposalId) setProposalId!: any;

  @Emit()
  leadWasSent(): boolean {
    return true;
  }

  onSubmitClicked(): void {
    sendLead(
      this.form,
      this.$route.params.hash as string,
      this.formLead.lead_name?.value as string,
      this.formLead.lead_prefix?.value as string,
      this.formLead.lead_phone?.value as string,
      this.formLead.partner_constents as CustomFormGroup,
      this.formLead.lead_constent?.value as boolean,
    )
      .pipe(first())
      .subscribe(
        (response) => {
          this.leadWasSent();
          this.setLeadWasSend(true);
          this.setLeadStatus(true);
          this.setProposalId(response.proposalId);
          this.clearLeadForm();
        },
        (_) => {
          this.leadWasSent();
          this.setLeadWasSend(true);
          this.setLeadStatus(false);
        }
      );
  }
}
