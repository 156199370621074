import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0d297c50")
const _hoisted_1 = {
  key: 0,
  class: "aligner"
}
const _hoisted_2 = ["src", "alt"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.centerVertical)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      src: _ctx.bankLink,
      class: "logo",
      alt: 'Logo ' + _ctx.bankName
    }, null, 8, _hoisted_2)
  ], 2))
}