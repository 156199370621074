import {CompareState} from '../state';
import {GetterTypes} from './getter-types';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import sortOffers from '../../OffersModule/actions/action-sortOffers';

export default {
  [GetterTypes.compareElements]: (state: CompareState): OfferResponse[] => {
    return state.selectedToCompare.sort((objA, objB) => sortOffers(objA, objB));
  },
  [GetterTypes.compareElementsAmount]: (state: CompareState): number => {
    return state.selectedToCompare.length;
  },
  [GetterTypes.isSelected]:
    (state: CompareState) =>
    (element: OfferResponse): boolean => {
      return state.selectedToCompare.some((selectedElement) => selectedElement.id === element.id);
    },
};
