export enum ProgressFormDynamicPagesIndex {
  'purpose' = 1, // there could be problem in swithc with 0 value
  'value',
  'value_land',
  'loan_value',
  'loan_period',
  'loan_begin',
  'property_type',
  'property_value',
  // 'property_finishing',
  'property_details' = 10,
  'loan_with_friend',
  'age',
  'EU_citizien',
}

export enum ProgressFormIncomeDynamicPagesIndex {
  'income_type' = 101, // there could be problem in swithc with 0 value
  'income_UoPType',
  'income_taxType',
  'income_otherType',
  'income_monthly',
  'income_time',
  'other_incomes',
}

export enum ProgressFormOutcomeDynamicPagesIndex {
  'outcome_people' = 201, // there could be problem in swithc with 0 value
  'outcome_monthly',
  'outcome_mortgages',
  'outcome_limits',
}
