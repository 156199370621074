
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Options({
  name: 'NotusFormStatic',
})
export default class NotusFormStatic extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly value!: string;
}
