import {MutationTree} from 'vuex';
import {OffersMutationType} from '@/store/modules/OffersModule/mutations/mutation-types';
import {FiltersType, INIT_OFFERS_STATE, OffersState} from '@/store/modules/OffersModule/state';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {FormConfigFormsKey} from '@/app/config/dynamic-form.config';
import {OrderType} from '@/app/modules/page-summary/models/order-type.enum';

export type OffersMutations = {
  [OffersMutationType.setLoading](state: OffersState, value: boolean): void;
  [OffersMutationType.setOffers](state: OffersState, offers: OfferResponse[]): void;
  [OffersMutationType.setAllOffers](state: OffersState, offers: OfferResponse[]): void;
  [OffersMutationType.setReloadPurpose](state: OffersState, reloadPurpose: FormConfigFormsKey | null): void;
  [OffersMutationType.setDetailedOffer](state: OffersState, offer?: OfferResponse): void;
  [OffersMutationType.setOrderType](state: OffersState, orderType: OrderType): void;
  [OffersMutationType.setFilters](state: OffersState, filters: FiltersType): void;
  [OffersMutationType.setInitialized](state: OffersState, newInitailized: boolean): void;
  [OffersMutationType.resetState](state: OffersState, newOrderType: OrderType): void;
};

export const mutations: MutationTree<OffersState> & OffersMutations = {
  [OffersMutationType.setLoading](state, value) {
    state.isLoading = value;
  },
  [OffersMutationType.setOffers](state, offers) {
    state.offers = offers;
  },
  [OffersMutationType.setAllOffers](state, offers) {
    state.allOffers = offers;
  },
  [OffersMutationType.setReloadPurpose](state, reloadPurpose) {
    state.reloadPurpose = reloadPurpose;
  },
  [OffersMutationType.setDetailedOffer](state, offer) {
    state.detailedOffer = offer;
  },
  [OffersMutationType.setOrderType](state, orderType) {
    state.orderType = orderType;
  },
  [OffersMutationType.setFilters](state, filters) {
    state.filters = filters;
  },
  [OffersMutationType.setInitialized](state, newInitialized) {
    state.isInitialized = newInitialized;
  },
  [OffersMutationType.resetState](state) {
    state.allOffers = JSON.parse(JSON.stringify(INIT_OFFERS_STATE.allOffers));
    state.isInitialized = JSON.parse(JSON.stringify(INIT_OFFERS_STATE.isInitialized));
    state.isLoading = JSON.parse(JSON.stringify(INIT_OFFERS_STATE.isLoading));
    state.offers = JSON.parse(JSON.stringify(INIT_OFFERS_STATE.offers));
    state.reloadPurpose = JSON.parse(JSON.stringify(INIT_OFFERS_STATE.reloadPurpose));
    state.detailedOffer = undefined;
    state.orderType = undefined;
  },
};
