import {FORM_CONFIG} from '@/app/config/form.config';
import {FormHint} from '@/app/modules/page-form/interfaces/form-hint.interface';
import {i18n} from '@/assets/i18n';
import {HintState} from './state';

export enum HINT_MUTATION {
  'setMainHint' = 101,
  'setHoverHint',
  'setFocusHint',
  'setProgress',
  'setMobileVisibility',
}

export const mutations = {
  [HINT_MUTATION.setMainHint](state: HintState, hint: FormHint): void {
    if (hint !== undefined) {
      state.hint.mainHint = hint;
    } else {
      const curiousIdx = Math.floor(Math.random() * FORM_CONFIG.hintCurious.amount);

      state.hint.mainHint = {
        title: i18n.global.t('components.hint-curious.title'),
        description: i18n.global.t(`components.hint-curious.curiouses.${curiousIdx}.content`),
        source: undefined,
      };
      if (FORM_CONFIG.hintCurious.elementsWithSource.indexOf(curiousIdx) !== -1) {
        state.hint.mainHint.source = i18n.global.t(`components.hint-curious.curiouses.${curiousIdx}.source`);
      }
    }
  },
  [HINT_MUTATION.setHoverHint](state: HintState, hint: FormHint): void {
    state.hint.hoverHint = hint;
  },
  [HINT_MUTATION.setFocusHint](state: HintState, hint: FormHint): void {
    state.hint.focusHint = hint;
  },
  [HINT_MUTATION.setProgress](state: HintState, newProgress: {progress: number; leftMinutes: number}): void {
    state.progress = newProgress.progress;
    state.leftMinutes = newProgress.leftMinutes;
  },
  [HINT_MUTATION.setMobileVisibility](state: HintState, mobileVisibility: boolean): void {
    state.shownOnMobile = mobileVisibility;
  },
};
