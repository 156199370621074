import {ActionContext, ActionTree} from 'vuex';
import {MutationType} from '../mutations/mutation-types';
import {ActionTypes} from './action-types';
import {Mutations} from '../mutations';
import {ProgressState} from '../state';
import {ProgressStep} from '@/store/modules/ProgressModule/state/progress-step.interface';
import {ProgressMainStage} from '@/app/models/progress-main-stage.enum';
import {PROGRESS_MAIN_STAGES_ORDER} from '@/app/config/progress-order.config';
import {isObject} from 'lodash';

type ActionAugments = Omit<ActionContext<ProgressState, ProgressState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.nextStep](context: ActionAugments, nextStep?: ProgressStep): void;
  [ActionTypes.nextMainStep](context: ActionAugments): void;
  [ActionTypes.addStep](context: ActionAugments, nextStep: ProgressStep): void;
  [ActionTypes.prevStep](context: ActionAugments, untilStep: ProgressMainStage): void;
  [ActionTypes.setParamsToCurrentStep](context: ActionAugments, newParams: any): void;
};

export const actions: ActionTree<ProgressState, ProgressState> & Actions = {
  [ActionTypes.nextStep]({dispatch, state}, nextStep?: ProgressStep) {
    if (nextStep && nextStep.mainStage) {
      dispatch(ActionTypes.addStep, nextStep);
    } else {
      switch (state.currentStep.mainStage) {
        case ProgressMainStage.begin:
          dispatch(ActionTypes.nextMainStep);
          break;
        case ProgressMainStage.form:
          dispatch(ActionTypes.nextMainStep);
          break;
        case ProgressMainStage.generator:
          dispatch(ActionTypes.nextMainStep);
          break;
        case ProgressMainStage.summary:
          dispatch(ActionTypes.nextMainStep);
          break;
      }
    }
  },
  [ActionTypes.nextMainStep]({dispatch, state}) {
    const currentMainStep = state.currentStep.mainStage;
    const nextPage: ProgressStep = {
      mainStage: PROGRESS_MAIN_STAGES_ORDER[PROGRESS_MAIN_STAGES_ORDER.indexOf(currentMainStep) + 1],
    };
    dispatch(ActionTypes.addStep, nextPage);
  },
  [ActionTypes.prevStep]({commit, state}, untilStep?: ProgressMainStage) {
    let backIdx = 2;
    let prevStep = state.stepsPassed[state.stepsPassed.length - backIdx];

    if (untilStep && !isObject(untilStep)) {
      while (prevStep && prevStep.mainStage !== untilStep) {
        prevStep = state.stepsPassed[state.stepsPassed.length - ++backIdx];
      }
    }
    if (prevStep) {
      commit(MutationType.setCurrentPage, prevStep);
      commit(MutationType.removeLastPassedStep);
    } else {
      throw Error('No previous state');
    }
  },
  [ActionTypes.setParamsToCurrentStep]({commit}, newParams: any) {
    commit(MutationType.setCurrentParams, newParams);
  },
  [ActionTypes.addStep]({commit}, newStep?: ProgressStep) {
    commit(MutationType.addPassedStep, newStep);
    commit(MutationType.setCurrentPage, newStep);
  },
};
