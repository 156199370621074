import {Location} from '../components/form-location/location-response-type';

export interface Value {
  value: number;
  currency: string;
}

export interface Purpose {
  type: string; // cel (ze słownika, creditPurpose)
  value: Value; // wartość kredytu, ile kredytu bierzemy
}

export interface Collateral {
  type: string; // typ (ze słownika, propertyType)
  value: Value; // jezeli jest dodatkowy cel, kwota powieksza wartosc kredytu i wartosc zabezpiecznia
  thirdPartyOwner: boolean; //false; czy nieruchomość osoby trzeciej --- zawsze nie
  area: number; // powierzchnia
  localization?: Location; // lokalizacja (ze słownika, collateralLocalization)
}

export interface CreditedProperty {
  type: string; // typ (ze słownika, propertyType) - to zawsze bedzie tylko jedna
  area: number; // powierzchnia - jednostka w metrach
}

export interface Tranch {
  amount: Value;
  month: number; //  miesiac wypłaty transzy
}

export interface Income {
  durationBackwardIncome?: string; // okres uzyskiwania dochodu wstecz (LocalDate) - czas uzyskiwania dochodu - obliczamy tak samo jak wiek
  durationBackwardIncomeMonths?: number; // okres uzyskiwania dochodu wstecz (miesiące) -
  durationOnwardIncome?: string; // okres uzyskiwania dochodu wprzód (LocalDate) - obliczamy tak samo jak wiek
  durationOnwardIncomeMonths?: number; // okres uzyskiwania dochodu wprzód (miesiące)
  incomeCurrency: string; // waluta dochodu (ze słownika, currencyCode)
  incomeSource?: string; // źrodło dochodu (ze słownika, incomeType)
  netMonthlyIncome: number; // wysokośc dochodu miesiecznego netto
}

export interface Borrower {
  age?: number; // wiek kredytobiorcy
  birthDate?: string; // data urodzenia kredytobiorcy (LocalDate) - nie podajemy daty urodzenia - data biezaca - wiek kredytobiorcy
  isForeigner?: boolean;
  citizenship: string; // obywatelstwo (ze słownika filterCitizenship) - do dogadania
  documentType?: string; // dokument potwierdzający prawo pobytu (residencyDocumentType) - zostawiamy puste
  expDateDocument?: string; // termin ważności karty pobytu (LocalDate) - zostawaiamy puste
  expMonthDocument?: string; // termin ważności karty pobytu (miesiące) - zostawiamy puste
  incomes: Income[];
  mortgageSum?: number; // Ilosc posiadanych kredytów hipotecznych - z ekranow
  primaryBank?: string; //""; bank prowadzący głowny rachunek klienta (ze słownika, bankArchive) - puste
}

export interface AdditionalFilters {
  borrowers: Borrower[];
}

export interface OfferRequest {
  purposes?: Purpose[]; // cele kredytu
  collaterals?: Collateral[]; // zabezpieczenie - wszystkie dane z celu kredytu
  creditedPropertyIsCollateral?: boolean; //true; czy finansowana nieruchomość stanowi zabezpieczenie kredytu zawsze true
  creditedProperties?: CreditedProperty[]; // finansowana nieruchomość
  installmentsCount?: number; // okres kredytowania - podawac w miesiacach
  installmentType?: string; // EQUAL; rata (EQUAL lub DECREASING, ENUM) - rata równa - dopiero na filtrach jest mozliwosc zmiany na malejaca
  marketType?: 'PRIMARY' | 'SECONDARY'; // SECONDARY; pierwotny czy wtórny (PRIMARY lub SECONDARY, ENUM) - wszystko rynek wtory - stala
  buildType?: string; // typ (ze słownika, buildType) -
  gracePeriod?: number; // okres karencjib - przyjmujemy jako wartosc stala 0
  mortgageInsurancePeriod?: number; //3; okres trwania ubezpieczenia pomostowego - wartosc stala 3 - nie ma tego na froncie
  tranches?: Tranch[]; // transze  uzupelniamy 1 transza, cala kwota
  additionalFilters?: AdditionalFilters;
}

export const INIT_OFFER_REQUEST: OfferRequest = {
  buildType: 'DEVELOPER',
  creditedPropertyIsCollateral: true,
  gracePeriod: 0,
  marketType: 'SECONDARY',
  mortgageInsurancePeriod: 3,
};
