
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {OfferBoxVariant} from '@/app/modules/page-summary/components/offer-box/offer-box-variant.type';
import NotusLogoBank from '@/app/modules/shared/components/logo-bank/logo-bank.component.vue';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import filters from '@/app/modules/shared/filters';
import {namespace} from 'vuex-class';
import {OffersActionTypes} from '@/store/modules/OffersModule/actions/action-types';
import {OffersGetter} from '@/store/modules/OffersModule/getters';
import {OrderType} from '../../models/order-type.enum';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {scrollToContactForm} from '@/app/modules/shared/helpers/scrollToContactForm.helper';

const offersModule = namespace('offers');
const formModule = namespace('form');

@Options({
  name: 'NotusOfferBox',
  components: {
    NotusButton,
    NotusLogoBank,
  },
})
export default class NotusOfferBox extends Vue {
  @offersModule.Action(OffersActionTypes.setDetailedOffer) setDetailedOffer!: any;
  @offersModule.Getter(OffersGetter.orderType) orderType!: OrderType;
  @formModule.Getter(GetterTypes.isValid) isValid!: boolean;
  @Prop({default: null}) offer!: OfferResponse;
  @Prop({default: false}) isMain!: boolean;
  @Prop({default: 'card'}) variant!: OfferBoxVariant;

  amountFilter(value: string | number) {
    return filters.amountFilter(value, true);
  }

  percentCount(decimal: number) {
    return filters.percentCount(decimal);
  }

  scrollToContact() {
    scrollToContactForm();
  }
}
