import axios, { AxiosResponse } from 'axios';
import {from, Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';

class AxiosService {
  get(url: string, baseURL = '') {
    return from(axios.get(baseURL + url)).pipe(
      map((res) => {
        if (res.status >= 400) {
          throwError(res.status);
        } else {
          return res.data;
        }
      })
    );
  }

  post<T>(url: string, data?: unknown, baseURL = '', config?: any): Observable<AxiosResponse<T>> {
    return from(axios.post(baseURL + url, data, config)).pipe(
      map((res) => {
        if (res.status >= 400) {
          throwError(res.status);
        } else {
          return res;
        }
        return res;
      })
    );
  }
}

export const APIService = new AxiosService();
