import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/top-porównywarka.png'
import _imports_1 from '@/assets/images/wygodni-polecaja.svg'


_pushScopeId("data-v-a0714dc8")
const _hoisted_1 = { class: "hero mb-4 mb-md-5" }
const _hoisted_2 = { class: "container d-flex justify-content-center justify-content-xl-end align-items-center h-100" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "me-xl-5" }
const _hoisted_6 = { class: "hero-white hero-paragraph fw-bold mb-0" }
const _hoisted_7 = { class: "hero-orange hero-paragraph fw-bold mt-0 mt-md-4" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: _ctx.$t('section.hero.alt'),
        class: "hero-image d-none d-xl-block",
        width: "428",
        height: "444"
      }, null, 8, _hoisted_3),
      _createElementVNode("img", {
        src: _imports_1,
        alt: _ctx.$t('section.hero.alt'),
        class: "hero-image__text d-none d-xl-block",
        width: "222",
        height: "82"
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('section.hero.paragraph1')), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('section.hero.paragraph2')), 1)
      ])
    ])
  ]))
}