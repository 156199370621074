
import {Options, Vue} from 'vue-class-component';
import {Prop, Emit} from 'vue-property-decorator';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import NotusButton from '@/app/modules/shared/components/button/button.component.vue';
import {scrollToElement} from '@/app/modules/shared/helpers/mobile.helper';

@Options({
  name: 'NotusExpandPanel',
  components: {
    NotusSvgIcon,
    NotusButton,
  },
})
export default class NotusExpandPanel extends Vue {
  @Prop({default: 'page.summary.filters.label.opened'}) openedLabel!: string;
  @Prop({default: 'page.summary.filters.label.closed'}) closedLabel!: string;
  @Prop({default: null}) buttonText?: string;
  @Prop({default: false}) buttonDisabled?: boolean;
  @Prop({default: null}) scrollToOnHide?: HTMLElement;
  @Emit()
  buttonAction(): boolean {
    return true;
  }
  private isExpand = false;

  toggle() {
    this.isExpand = !this.isExpand;
    this.$forceUpdate();

    if (!this.isExpand) {
      this.scrollToOnHide && scrollToElement(this.scrollToOnHide);
    }
  }

  get label(): string | undefined {
    return this.isExpand ? this.openedLabel : this.closedLabel;
  }
}
