import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sorting" }
const _hoisted_2 = { class: "text-center fw-semi-bold" }
const _hoisted_3 = { class: "color-accent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_form_dropdown = _resolveComponent("notus-form-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_notus_form_dropdown, {
      controlName: "orderBy",
      customForm: _ctx.formSorting,
      groups: _ctx.orderOptions,
      isInline: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('page.summary.sort.title')) + ": ", 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.getCriteriaLabel())), 1)
        ])
      ]),
      _: 1
    }, 8, ["customForm", "groups"])
  ]))
}