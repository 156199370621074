import {OrderType} from '@/app/modules/page-summary/models/order-type.enum';
import {OfferResponse, Rate} from '@/app/modules/shared/interface/offer.response.interface';
import _ from 'lodash';
import {store} from '@/store';
import {OffersGetter} from '../getters';

export default function sortOffers(offerA: OfferResponse, offerB: OfferResponse): number {
  const sortingType = store.getters[`offers/${OffersGetter.orderType}`] as OrderType;

  switch (sortingType) {
    case OrderType.FINAL_INSTALMENT_TOTAL:
      return (
        _.get(offerA, 'simulationSummary.finalInstallment.CAPITAL_AND_INTEREST.value') -
        _.get(offerB, 'simulationSummary.finalInstallment.CAPITAL_AND_INTEREST.value')
      );
    case OrderType.INTEREST_RATE:
      return (
        (_.get(offerA, 'simulation.interestRate.rates') as Rate[])[0].rate -
        (_.get(offerB, 'simulation.interestRate.rates') as Rate[])[0].rate
      );
    case OrderType.TOTAL_COST:
      return (
        _.get(offerA, 'simulationSummary.totalCosts.SCHEDULE_TOTAL.value') -
        _.get(offerB, 'simulationSummary.totalCosts.SCHEDULE_TOTAL.value')
      );
    case OrderType.COMMISSION:
      return _.get(offerA, 'simulation.interestRate.type') !== _.get(offerB, 'simulation.interestRate.type')
        ? _.get(offerB, 'simulation.interestRate.type') === 'CONSTANT'
          ? -1
          : 1
        : _.get(offerA, 'simulation.margin.rates[0].rate') - _.get(offerB, 'simulation.margin.rates[0].rate');
  }
}
