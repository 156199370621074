import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-05f9c71a")
const _hoisted_1 = { class: "confirm-modal py-5 position-relative" }
const _hoisted_2 = { class: "confirm-modal_content d-flex flex-column position-relative" }
const _hoisted_3 = { class: "confirm-modal_message text-center" }
const _hoisted_4 = {
  key: 0,
  class: "icon mb-4 mt-4"
}
const _hoisted_5 = {
  key: 1,
  class: "fw-black text-center mb-4 mt-0 mb-2 color-primary"
}
const _hoisted_6 = {
  key: 2,
  class: "fw-normal text-center color-secondary"
}
const _hoisted_7 = { class: "confirm-modal_btns d-flex align-items-center justify-content-center w-100" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notus_svg_icon = _resolveComponent("notus-svg-icon")!
  const _component_notus_button = _resolveComponent("notus-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.config?.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_notus_svg_icon, {
                  iconType: "error",
                  cover: false
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.config?.message)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.config?.message ? _ctx.$t(_ctx.config.message) : ''), 1))
            : _createCommentVNode("", true),
          (_ctx.config?.subMessage)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.config?.subMessage ? _ctx.$t(_ctx.config.subMessage) : ''), 1))
            : _createCommentVNode("", true),
          (_ctx.config?.contact)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "fw-bold text-center fs-18 color-accent mb-5 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToContact && _ctx.scrollToContact(...args)))
              }, _toDisplayString(_ctx.config?.contact ? _ctx.$t(_ctx.config.contact) : ''), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_notus_button, {
            type: "button",
            class: "mb-md-0 me-sm-4 mb-4 mb-sm-0",
            theme: "secondary",
            size: "large",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChooseHandler(false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.config?.noText ? _ctx.$t(_ctx.config.noText) : _ctx.$t('general.confirm.no')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_notus_button, {
            type: "button",
            theme: "primary",
            size: "large",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChooseHandler(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.config?.yesText ? _ctx.$t(_ctx.config.yesText) : _ctx.$t('general.confirm.yes')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "overlayer",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onCloseHandler && _ctx.onCloseHandler(...args)))
    })
  ]))
}