
import {Options} from 'vue-class-component';
import PageSimple from '@/app/modules/page-simple';
import NotusWhyUsSection from '@/app/modules/page-summary/components/why-us/why-us.component.vue';
import NotusSummaryFooterSection from '@/app/modules/page-summary/components/summary-footer/summary-footer.component.vue';
import NotusFormStatic from '@/app/modules/shared/components/form-static/form-static.component.vue';
import NotusCompareCta from '@/app/modules/page-comparition-boxes/sections/compare-cta/compare-cta.component.vue';
import {Emit, Prop} from 'vue-property-decorator';
import {mapState} from 'vuex';
import NotusCompartionRotor from '@/app/modules/page-comparition-boxes/sections/compartion-rotor/compartion-rotor.component.vue';
import {DynamicFormControlName} from '@/app/models/dynamic-form-control-name.enum';
import {namespace} from 'vuex-class';
import {GetterTypes} from '@/store/modules/FormModule/getters/getter-types';
import {FormPurpose} from '../page-form/enums/form-purpose.enum';
import {getValueTranslated, getLabelTranslated} from '@/app/modules/shared/services/form-parameters.service';

const formModule = namespace('form');

@Options({
  name: 'NotusPageComparitionBoxes',
  components: {
    NotusWhyUsSection,
    NotusSummaryFooterSection,
    NotusCompartionRotor,
    NotusCompareCta,
    NotusFormStatic,
  },
  computed: {
    ...mapState('compare', ['selectedToCompare']),
  },
})
export default class NotusPageComparitionBoxes extends PageSimple {
  @formModule.Getter(GetterTypes.valueByName) valueByName!: any;
  @Prop({default: false}) showDetails!: boolean;

  @Emit()
  closeClicked(): boolean {
    return true;
  }

  get valueControlName(): DynamicFormControlName {
    if (
      [FormPurpose.PURCHASE, FormPurpose.BUILD, FormPurpose.RENOVATION].includes(this.valueByName(DynamicFormControlName.purpose))
    ) {
      return DynamicFormControlName['purposes-value'];
    } else {
      return DynamicFormControlName.property_value;
    }
  }

  getValue(controlName: DynamicFormControlName): string {
    return getValueTranslated(controlName, this.valueByName(controlName).toString());
  }

  getLabel(controlName: DynamicFormControlName): string {
    return getLabelTranslated(controlName);
  }
}
