import {OrderType} from '@/app/modules/page-summary/models/order-type.enum';
import {SwitchOptions} from '@/app/modules/page-form/interfaces/form-control-switch.interface';
import {RadioOption, RadioOptionWithAction} from '@/app/modules/shared/components/form-radiobutton-group/radio-option.interface';
import dictionary from '@/assets/jsons/dictionaries.json';

export const ORDER_CRITERIA: RadioOption[] = [
  {
    value: OrderType.FINAL_INSTALMENT_TOTAL, //simulationSummary.finalInstallment.CAPITAL_AND_INTEREST
    label: 'page.summary.sort.types.FINAL_INSTALMENT_TOTAL',
  },
  {
    value: OrderType.INTEREST_RATE, //simulation.interestRate.rates (list)
    label: 'page.summary.sort.types.INTEREST_RATE',
  },
  {
    value: OrderType.TOTAL_COST, //simulationSummary.totalCosts.TOTAL
    label: 'page.summary.sort.types.TOTAL_COST',
  },
  {
    value: OrderType.COMMISSION, //simulationSummary.totalCosts.COMMISSION
    label: 'page.summary.sort.types.COMMISSION',
  },
];

export const INSTALMENT_TYPES: SwitchOptions = {
  left: {
    value: 'EQUAL',
    label: 'page.summary.filter.additionalOptions.instalment.EQUAL.label',
    selected: true,
  },
  right: {
    value: 'DECREASING',
    label: 'page.summary.filter.additionalOptions.instalment.DECREASING.label',
    selected: false,
  },
};

export const INTEREST_RATES_TYPES: SwitchOptions = {
  left: {
    value: dictionary.interestRateType[0].code,
    label: dictionary.interestRateType[0].name,
    selected: false,
  },
  right: {
    value: dictionary.interestRateType[1].code,
    label: dictionary.interestRateType[1].name,
    selected: true,
  },
};

export const DEFAULT_YES_NO_ALL: RadioOptionWithAction[] = [
  {
    value: 'ALL',
    label: 'general.all.others',
    selected: true,
  },
  {
    value: 'YES',
    label: 'general.switch.YES.label',
    selected: false,
  },
  {
    value: 'NO',
    label: 'general.switch.NO.label',
    selected: false,
  },
];
export const DEFAULT_REQUIRED_ALL: RadioOptionWithAction[] = [
  {
    value: 'ALL',
    label: 'general.all.female',
    selected: true,
  },
  {
    value: 'YES',
    label: 'general.required.yes.label',
    selected: false,
  },
  {
    value: 'NO',
    label: 'general.required.no.label',
    selected: false,
  },
];

export const LIFE_INSURANCE_TYPES: RadioOptionWithAction[] = JSON.parse(JSON.stringify(DEFAULT_YES_NO_ALL));

export const ECO_TYPES: RadioOptionWithAction[] = JSON.parse(JSON.stringify(DEFAULT_YES_NO_ALL));

export const ROR_TYPES: RadioOptionWithAction[] = JSON.parse(JSON.stringify(DEFAULT_REQUIRED_ALL));

export const PAYMENT_ON_ACCOUNT_TYPES: RadioOptionWithAction[] = JSON.parse(JSON.stringify(DEFAULT_REQUIRED_ALL));

export const DEBIT_CARD_TYPES: RadioOptionWithAction[] = JSON.parse(JSON.stringify(DEFAULT_REQUIRED_ALL));

export const CREDIT_CARD_TYPES: RadioOptionWithAction[] = JSON.parse(JSON.stringify(DEFAULT_REQUIRED_ALL));
