export enum PropertyType {
  'FLAT' = 1,
  'HOUSE',
  'BUSINESS_PREMISES',
  'SUMMER_HOUSE',
  'BUILDING_PLOT',
  'RECREATIONAL_LAND',
  'AGRICULTURAL_PARCEL',
  'HABITAT',
}
