import {i18n} from '@/assets/i18n';

export function getLeadContent(variableName: string, defaultI18nKey?: string, defaultValue?: string): string {
  const currentLocale = i18n.global.locale.toUpperCase();
  const variableValue = process.env[`VUE_APP_LEAD_${currentLocale}_${variableName}`];

  if (variableValue) {
    return variableValue;
  } else if (defaultI18nKey) {
    return i18n.global.t(defaultI18nKey);
  } else {
    return defaultValue || '';
  }
}
