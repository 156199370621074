import {FormHint} from '@/app/modules/page-form/interfaces/form-hint.interface';
import {HintState} from './state';
import * as _ from 'lodash';

export enum HINT_GETTER {
  'hintTitle' = 101,
  'hintDescription',
  'hintSource',
  'mainHint',
  'shownOnMobile',
  'progress',
  'leftMinutes',
}

export default {
  [HINT_GETTER.hintTitle]: (state: HintState): string | undefined => {
    return getCurrentHint(state)?.title;
  },
  [HINT_GETTER.hintDescription]: (state: HintState): string | undefined => {
    return getCurrentHint(state)?.description;
  },
  [HINT_GETTER.hintSource]: (state: HintState): string | undefined => {
    return getCurrentHint(state)?.source;
  },
  [HINT_GETTER.mainHint]: (state: HintState): Partial<FormHint> => {
    return _.pick<FormHint>(state.hint.mainHint, ['title', 'description', 'source']);
  },
  [HINT_GETTER.shownOnMobile]: (state: HintState): boolean => {
    return state.shownOnMobile;
  },
  [HINT_GETTER.progress]: (state: HintState): number => {
    return state.progress;
  },
  [HINT_GETTER.leftMinutes]: (state: HintState): number => {
    return state.leftMinutes;
  },
};

function getCurrentHint(state: HintState): FormHint | undefined {
  return state.hint.hoverHint ? state.hint.hoverHint : state.hint.focusHint ? state.hint.focusHint : state.hint.mainHint;
}
