
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IconType} from '@/app/modules/shared/components/svg-icon/svg-icon.types';

@Options({
  name: 'NotusSvgIcon',
})
export default class NotusSvgIcon extends Vue {
  @Prop(String) readonly iconType: IconType | undefined;
  @Prop(Boolean) readonly cover: boolean | undefined;

  get svgPath(): string {
    return this.iconType ? require(`@/assets/icons/${this.iconType}.svg`) : undefined;
  }
}
