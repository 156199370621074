import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-35b09ff4")
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "suffix d-flex align-items-center fw-medium"
}
const _hoisted_3 = ["inputmode", "placeholder", "id", "name"]
const _hoisted_4 = {
  key: 0,
  class: "input-label mx-4 mb-0"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "form-input-field",
    onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setHoverHint(_ctx.fieldHint, true))),
    onMouseout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setHoverHint()))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-input-field_content d-block", {'has-suffix': _ctx.suffix}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$tc(_ctx.suffix, _ctx.amount)), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          inputmode: _ctx.inputDataType === 'string' ? 'text' : 'tel',
          class: _normalizeClass(["fw-medium form-input-field__input", {'form-input--error': _ctx.validationMessage, 'with-suffix': _ctx.suffix, 'width-reduce': _ctx.widthMobileReduce}]),
          placeholder: _ctx.placeholder ? _ctx.placeholder : '',
          id: _ctx.controlName,
          name: _ctx.controlName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.control.value = $event)),
          style: _normalizeStyle(_ctx.styles),
          autocomplete: "off",
          ref: 'input' + _ctx.controlName,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputFocus())),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInputBlur())),
          onKeypress: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onKeyPress($event)))
        }, null, 46, _hoisted_3), [
          [_vModelText, _ctx.control.value]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["error-message", {'visually-hidden': !_ctx.validationMessage}])
        }, _toDisplayString(_ctx.validationMessage || ''), 3)
      ]),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.description)), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 32))
}