import {createI18n} from 'vue-i18n';

import {lang_pl} from './lang_pl';
import {lang_en} from './lang_en';

const messages = {
  pl: lang_pl,
  en: lang_en,
};

const makeChoice = (n: any): number => {
  if (n === 0) {
    return 2;
  }

  if (parseInt(n, 10) === 1) {
    return 0;
  }

  const int = n % 1 === 0;
  const mod10 = n % 10;
  const mod100 = n % 100;

  if (int && mod10 >= 2 && mod10 <= 4 && (mod100 < 12 || mod100 > 14)) {
    return 1; // np. miesiące
  }

  if (int && (mod10 === 0 || mod10 === 1 || (mod10 >= 5 && mod10 <= 9) || (mod100 >= 12 && mod100 <= 14))) {
    return 2; // np. miesięcy
  }

  return 0; // np. miesiąc
};

const i18n = createI18n({
  locale: 'pl',
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true,
  pluralizationRules: {
    pl: function (choice: any, choicesLength: number) {
      if (choicesLength < 2) {
        return 0;
      }
      return makeChoice(choice);
    },
  },
});

export {i18n};
