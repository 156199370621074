
import {Options, Vue} from 'vue-class-component';
import NotusOfferBox from '@/app/modules/page-summary/components/offer-box/offer-box.component.vue';
import NotusSvgIcon from '@/app/modules/shared/components/svg-icon/svg-icon.component.vue';
import {namespace} from 'vuex-class';
import {OfferResponse} from '@/app/modules/shared/interface/offer.response.interface';

const offersModule = namespace('offers');

@Options({
  name: 'NotusOffers',
  components: {
    NotusOfferBox,
    NotusSvgIcon,
  },
})
export default class NotusOffers extends Vue {
  @offersModule.State('offers') offers!: OfferResponse[];
}
