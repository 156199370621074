import {FormHint} from '@/app/modules/page-form/interfaces/form-hint.interface';
import {HINT_MUTATION} from './mutations';

export enum HINT_ACTIONS {
  'setMainHint' = 'setMainHint',
  'setFocusHint' = 'setFocusHint',
  'setHoverHint' = 'setHoverHint',
  'setProgress' = 'setProgress',
  'toggleMobileVisibility' = 'toggleMobileVisibility',
}
export const actions = {
  [HINT_ACTIONS.setMainHint]({commit}: any, hint: FormHint): void {
    commit(HINT_MUTATION.setMainHint.toString(), hint);
  },
  [HINT_ACTIONS.setFocusHint]({commit}: any, hint: FormHint): void {
    commit(HINT_MUTATION.setFocusHint.toString(), hint);
  },
  [HINT_ACTIONS.setHoverHint]({commit}: any, hint: FormHint): void {
    commit(HINT_MUTATION.setHoverHint.toString(), hint);
  },
  [HINT_ACTIONS.setProgress]({commit}: any, newProgress: {progress: number; leftMinutes: number}): void {
    commit(HINT_MUTATION.setProgress.toString(), newProgress);
  },
  [HINT_ACTIONS.toggleMobileVisibility]({commit, state}: any, forceState: boolean): void {
    if (forceState !== undefined) {
      commit(HINT_MUTATION.setMobileVisibility.toString(), forceState);
    } else {
      commit(HINT_MUTATION.setMobileVisibility.toString(), !state.shownOnMobile);
    }
  },
};
