import {FormApplicant} from '@/app/modules/page-form/enums/form-applicant.enum';
import {DynamicFormPage} from '@/app/modules/page-form/models/form-dynamic-page';

import {FormPurpose} from '@/app/modules/page-form/enums/form-purpose.enum';
import {
  ProgressFormDynamicPagesIndex,
  ProgressFormIncomeDynamicPagesIndex,
  ProgressFormOutcomeDynamicPagesIndex,
} from '@/app/modules/page-form/enums/progress-form-dynamic-pages-index.enum';
import {DynamicFormControlName} from '../models/dynamic-form-control-name.enum';
import {FORM_INPUTS_CONFIG} from './input-form.config';

export type FormConfigFormsKey =
  | ProgressFormDynamicPagesIndex
  | ProgressFormIncomeDynamicPagesIndex
  | ProgressFormOutcomeDynamicPagesIndex;

export const FORM_DYNAMIC_PAGES_CONFIG: {
  simpleForms: {[key in FormConfigFormsKey]: DynamicFormPage};
} = {
  simpleForms: {
    [ProgressFormDynamicPagesIndex.purpose]: {
      title: 'dynamic.form.page.purpose.title',
      description: 'dynamic.form.page.purpose.description',
      hint: {
        title: 'dynamic.form.page.purpose.hint.title',
        description: 'dynamic.form.page.purpose.hint.description',
      },
      progress: 0,
      leftMinutes: 3,
      refreshOffersAfter: false,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.purpose]],
    },
    [ProgressFormDynamicPagesIndex.value]: {
      title: {
        [FormPurpose.PURCHASE]: 'dynamic.form.page.value.title.PURCHASE',
        [FormPurpose.BUILD]: 'dynamic.form.page.value.title.BUILD',
        [FormPurpose.RENOVATION]: 'dynamic.form.page.value.title.RENOVATION',
        [FormPurpose.MORTGAGE]: 'dynamic.form.page.value.title.MORTGAGE',
        [FormPurpose.CONSOLIDATION]: 'dynamic.form.page.value.title.CONSOLIDATION',
        [FormPurpose.REFINANCING]: 'dynamic.form.page.value.title.REFINANCING',
      },
      hint: {
        [FormPurpose.PURCHASE]: {
          title: 'dynamic.form.page.value.hint.PURCHASE.title',
          description: 'dynamic.form.page.value.hint.PURCHASE.description',
        },
        [FormPurpose.BUILD]: {
          title: 'dynamic.form.page.value.hint.BUILD.title',
          description: 'dynamic.form.page.value.hint.BUILD.description',
        },
        [FormPurpose.RENOVATION]: {
          title: 'dynamic.form.page.value.hint.RENOVATION.title',
          description: 'dynamic.form.page.value.hint.RENOVATION.description',
        },
        [FormPurpose.REFINANCING]: {
          title: 'dynamic.form.page.value.hint.REFINANCING.title',
          description: 'dynamic.form.page.value.hint.REFINANCING.description',
        },
      },
      progress: 6,
      leftMinutes: 3,
      refreshOffersAfter: false,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName['purposes-value']]],
    },
    [ProgressFormDynamicPagesIndex.value_land]: {
      title: {
        [FormPurpose.BUILD]: 'dynamic.form.page.value_land.title.BUILD',
        [FormPurpose.RENOVATION]: 'dynamic.form.page.value_land.title.RENOVATION',
      },
      hint: {
        [FormPurpose.BUILD]: {
          description: 'dynamic.form.page.value_land.hint.BUILD.description',
          title: 'dynamic.form.page.value_land.hint.BUILD.title',
        },
        [FormPurpose.RENOVATION]: {
          description: 'dynamic.form.page.value_land.hint.RENOVATION.description',
          title: 'dynamic.form.page.value_land.hint.RENOVATION.title',
        },
      },
      progress: 8,
      leftMinutes: 3,
      refreshOffersAfter: false,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName['purposes-value-land']]],
    },
    [ProgressFormDynamicPagesIndex.loan_value]: {
      title: {
        [FormPurpose.PURCHASE]: 'dynamic.form.page.loan_value.title.PURCHASE',
        [FormPurpose.BUILD]: 'dynamic.form.page.loan_value.title.BUILD',
        [FormPurpose.RENOVATION]: 'dynamic.form.page.loan_value.title.RENOVATION',
        [FormPurpose.MORTGAGE]: 'dynamic.form.page.loan_value.title.MORTGAGE',
        [FormPurpose.CONSOLIDATION]: 'dynamic.form.page.loan_value.title.CONSOLIDATION',
        [FormPurpose.REFINANCING]: 'dynamic.form.page.loan_value.title.REFINANCING',
      },
      hint: {
        [FormPurpose.PURCHASE]: {
          title: 'dynamic.form.page.loan_value.hint.PURCHASE.title',
          description: 'dynamic.form.page.loan_value.hint.PURCHASE.description',
        },
        [FormPurpose.BUILD]: {
          title: 'dynamic.form.page.loan_value.hint.BUILD.title',
          description: 'dynamic.form.page.loan_value.hint.BUILD.description',
        },
        [FormPurpose.RENOVATION]: {
          title: 'dynamic.form.page.loan_value.hint.PURCHASE.title',
          description: 'dynamic.form.page.loan_value.hint.PURCHASE.description',
        },
        [FormPurpose.REFINANCING]: {
          title: 'dynamic.form.page.loan_value.hint.REFINANCING.title',
          description: 'dynamic.form.page.loan_value.hint.REFINANCING.description',
        },
      },
      progress: 10,
      leftMinutes: 3,
      refreshOffersAfter: false,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName['loan-value']]],
    },
    [ProgressFormDynamicPagesIndex.loan_period]: {
      title: 'dynamic.form.page.loan_period.title',
      hint: {
        title: 'dynamic.form.page.loan_period.hint.title',
        description: 'dynamic.form.page.loan_period.hint.description',
      },
      progress: 15,
      leftMinutes: 3,
      refreshOffersAfter: true,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName['loan-period']]],
    },
    [ProgressFormDynamicPagesIndex.loan_begin]: {
      title: 'dynamic.form.page.loan_begin.title',
      hint: {
        title: 'dynamic.form.page.loan_begin.hint.title',
        description: 'dynamic.form.page.loan_begin.hint.description',
      },
      progress: 20,
      leftMinutes: 3,
      refreshOffersAfter: false,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName['loan-begin']]],
    },
    [ProgressFormDynamicPagesIndex.property_type]: {
      title: {
        [FormPurpose.PURCHASE]: 'Jaką nieruchomość kupujesz?',
        [FormPurpose.BUILD]: 'Jaką nieruchomość budujesz?',
        [FormPurpose.RENOVATION]: 'Jaką nieruchomość chcesz wyremontować / wykończyć?',
        [FormPurpose.MORTGAGE]: 'Jaką nieruchomość proponujesz jako zabezpieczenie kredytu?',
        [FormPurpose.CONSOLIDATION]: 'Jaką nieruchomość proponujesz jako zabezpieczenie kredytu?',
        [FormPurpose.REFINANCING]: 'Jaką nieruchomość proponujesz jako zabezpieczenie kredytu?',
      },
      hint: {
        [FormPurpose.PURCHASE]: {
          description: 'dynamic.form.page.property_type.hint.PURCHASE.description',
          title: 'dynamic.form.page.property_type.hint.PURCHASE.title',
        },
        [FormPurpose.BUILD]: {
          description: 'dynamic.form.page.property_type.hint.BUILD.description',
          title: 'dynamic.form.page.property_type.hint.BUILD.title',
        },
        [FormPurpose.REFINANCING]: {
          description: 'dynamic.form.page.property_type.hint.REFINANCING.description',
          title: 'dynamic.form.page.property_type.hint.REFINANCING.title',
        },
      },
      progress: 25,
      leftMinutes: 3,
      refreshOffersAfter: {
        [FormPurpose.PURCHASE]: true,
        [FormPurpose.BUILD]: true,
        [FormPurpose.RENOVATION]: true,
        [FormPurpose.MORTGAGE]: false,
        [FormPurpose.CONSOLIDATION]: false,
        [FormPurpose.REFINANCING]: false,
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.property_type]],
    },
    [ProgressFormDynamicPagesIndex.property_value]: {
      title: 'dynamic.form.page.property_value.title',
      progress: 26,
      leftMinutes: 3,
      refreshOffersAfter: {
        [FormPurpose.PURCHASE]: true,
        [FormPurpose.BUILD]: true,
        [FormPurpose.RENOVATION]: true,
        [FormPurpose.MORTGAGE]: false,
        [FormPurpose.CONSOLIDATION]: false,
        [FormPurpose.REFINANCING]: false,
      },
      hint: {
        description: 'dynamic.form.page.property_value.hint.description',
        title: 'dynamic.form.page.property_value.hint.title',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.property_value]],
    },
    // [ProgressFormDynamicPagesIndex.property_finishing]: {
    //   title: 'dynamic.form.page.property_finishing.title',
    //   progress: 28,
    //   leftMinutes: 3,
    //   refreshOffersAfter: true,
    //   hint: {
    //     title: 'dynamic.form.page.property_finishing.input.purposes-finishing.options.right.hint.title',
    //     description: 'dynamic.form.page.property_finishing.input.purposes-finishing.options.right.hint.description',
    //   },
    //   inputs: [
    //     FORM_INPUTS_CONFIG[DynamicFormControlName['purposes-finishing']],
    //     FORM_INPUTS_CONFIG[DynamicFormControlName['break-line']],
    //     FORM_INPUTS_CONFIG[DynamicFormControlName['purposes-finishing-value']],
    //   ],
    // },
    [ProgressFormDynamicPagesIndex.property_details]: {
      title: 'dynamic.form.page.property_details.title',
      progress: 44,
      leftMinutes: 2,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.property_details.hint.title',
        description: 'dynamic.form.page.property_details.hint.description',
      },
      inputs: [
        FORM_INPUTS_CONFIG[DynamicFormControlName['property_details_area']],
        FORM_INPUTS_CONFIG[DynamicFormControlName['break-line']],
        FORM_INPUTS_CONFIG[DynamicFormControlName['property_details_location']],
      ],
    },
    [ProgressFormDynamicPagesIndex.loan_with_friend]: {
      title: 'dynamic.form.page.single_or_friendly.title',
      description: 'dynamic.form.page.single_or_friendly.description',
      progress: 48,
      leftMinutes: 2,
      refreshOffersAfter: false,
      hint: {
        title: 'dynamic.form.page.single_or_friendly.hint.title',
        description: 'dynamic.form.page.single_or_friendly.hint.description',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.loan_applicant_multi]],
    },
    [ProgressFormDynamicPagesIndex.age]: {
      title: {
        [FormApplicant.SOLO]: 'dynamic.form.page.age.title.SOLO',
        [FormApplicant.MULTI]: 'dynamic.form.page.age.title.MULTI',
      },
      progress: 51,
      leftMinutes: 2,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.age.hint.title',
        description: 'dynamic.form.page.age.hint.description',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.age]],
    },
    [ProgressFormDynamicPagesIndex.EU_citizien]: {
      title: {
        [FormApplicant.SOLO]: 'dynamic.form.page.EU_citizien.title.SOLO',
        [FormApplicant.MULTI]: 'dynamic.form.page.EU_citizien.title.MULTI',
      },
      progress: 54,
      leftMinutes: 1,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.EU_citizien.hint.title',
        description: 'dynamic.form.page.EU_citizien.hint.description',
      },
      inputs: {
        [FormApplicant.SOLO]: [FORM_INPUTS_CONFIG[DynamicFormControlName.EU_citizien_self]],
        [FormApplicant.MULTI]: [
          {
            ...FORM_INPUTS_CONFIG[DynamicFormControlName.EU_citizien_self],
            title: 'dynamic.form.page.EU_citizien.inputs.EU_citizien.title.YOU',
          },
          FORM_INPUTS_CONFIG[DynamicFormControlName.EU_citizien_multi],
        ],
      },
    },
    [ProgressFormIncomeDynamicPagesIndex.income_type]: {
      title: {
        [FormApplicant.SOLO]: 'dynamic.form.page.income_type.title.SOLO',
        [FormApplicant.MULTI]: 'dynamic.form.page.income_type.title.MULTI',
      },
      progress: 57,
      leftMinutes: 2,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.income_type.hint.title',
        description: 'dynamic.form.page.income_type.hint.description',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.income_type]],
    },
    [ProgressFormIncomeDynamicPagesIndex.income_UoPType]: {
      title: 'dynamic.form.page.income_UoPType.title',
      progress: 64,
      leftMinutes: 2,
      refreshOffersAfter: true,
      inputs: [
        FORM_INPUTS_CONFIG[DynamicFormControlName.income_UoPType],
        FORM_INPUTS_CONFIG[DynamicFormControlName.income_UoPMaternity],
      ],
    },
    [ProgressFormIncomeDynamicPagesIndex.income_taxType]: {
      title: 'dynamic.form.page.income_taxType.title',
      progress: 64,
      leftMinutes: 2,
      refreshOffersAfter: false,
      hint: {
        title: 'dynamic.form.page.income_taxType.hint.title',
        description: 'dynamic.form.page.income_taxType.hint.description',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.income_taxType]],
    },
    [ProgressFormIncomeDynamicPagesIndex.income_otherType]: {
      title: 'dynamic.form.page.income_otherType.title',
      progress: 64,
      leftMinutes: 2,
      refreshOffersAfter: true,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.income_otherType]],
    },
    [ProgressFormIncomeDynamicPagesIndex.income_monthly]: {
      title: 'dynamic.form.page.income_monthly.title',
      progress: 69,
      leftMinutes: 2,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.income_monthly.hint.title',
        description: 'dynamic.form.page.income_monthly.hint.description',
      },
      inputs: [
        FORM_INPUTS_CONFIG[DynamicFormControlName.income_monthly_value],
        FORM_INPUTS_CONFIG[DynamicFormControlName.income_monthly_currency],
      ],
    },
    [ProgressFormIncomeDynamicPagesIndex.income_time]: {
      title: 'dynamic.form.page.income_time.title',
      progress: 77,
      leftMinutes: 2,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.income_time.hint.title',
        description: 'dynamic.form.page.income_time.hint.description',
      },
      inputs: [
        FORM_INPUTS_CONFIG[DynamicFormControlName.income_time_from],
        FORM_INPUTS_CONFIG[DynamicFormControlName['break-line']],
        FORM_INPUTS_CONFIG[DynamicFormControlName.income_time_to],
      ],
    },
    [ProgressFormIncomeDynamicPagesIndex.other_incomes]: {
      title: {
        [FormApplicant.SOLO]: 'dynamic.form.page.other_incomes.title.SOLO',
        [FormApplicant.MULTI]: 'dynamic.form.page.other_incomes.title.MULTI',
      },
      progress: 79,
      leftMinutes: 2,
      refreshOffersAfter: true,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.income_others]],
    },
    [ProgressFormOutcomeDynamicPagesIndex.outcome_people]: {
      title: 'dynamic.form.page.outcome_people.title',
      progress: 80,
      leftMinutes: 1,
      refreshOffersAfter: false,
      hint: {
        title: 'dynamic.form.page.outcome_people.hint.title',
        description: 'dynamic.form.page.outcome_people.hint.description',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.outcome_people]],
    },
    [ProgressFormOutcomeDynamicPagesIndex.outcome_monthly]: {
      title: 'dynamic.form.page.outcome_monthly.title',
      progress: 90,
      leftMinutes: 1,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.outcome_monthly.hint.title',
        description: 'dynamic.form.page.outcome_monthly.hint.description',
      },
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.outcome_monthly]],
    },
    [ProgressFormOutcomeDynamicPagesIndex.outcome_mortgages]: {
      title: 'dynamic.form.page.outcome_mortgages.title',
      progress: 85,
      leftMinutes: 1,
      refreshOffersAfter: true,
      inputs: [FORM_INPUTS_CONFIG[DynamicFormControlName.outcome_mortgages]],
    },
    [ProgressFormOutcomeDynamicPagesIndex.outcome_limits]: {
      title: 'dynamic.form.page.outcome_limits.title',
      progress: 96,
      leftMinutes: 1,
      refreshOffersAfter: true,
      hint: {
        title: 'dynamic.form.page.outcome_limits.hint.title',
        description: 'dynamic.form.page.outcome_limits.hint.description',
      },
      inputs: [
        FORM_INPUTS_CONFIG[DynamicFormControlName.outcome_limit_card],
        FORM_INPUTS_CONFIG[DynamicFormControlName['break-line']],
        FORM_INPUTS_CONFIG[DynamicFormControlName.outcome_limit_account],
      ],
    },
  },
};

export const FORMS_DEFINITION = FORM_DYNAMIC_PAGES_CONFIG.simpleForms;
